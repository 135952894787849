import React from 'react';
import { Body, BodyTitle, BodySubtitle } from './style';

const SelectorBody = ({ title, subtitle, disabled, align, ...rest }) => {
  return (
    <Body align={align} {...rest}>
      {title && <BodyTitle disabled={disabled}>{title}</BodyTitle>}
      {subtitle && <BodySubtitle disabled={disabled}>{subtitle}</BodySubtitle>}
      {rest.children}
    </Body>
  );
};

export default SelectorBody;
