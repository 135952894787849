import styled, { css, keyframes } from 'styled-components';

export const FADE_IN_DURATION = 250;
export const FADE_OUT_DURATION = 200;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const Fade = styled.span`
  ${({ state }) => {
    switch (state) {
      case 'entering':
        return css`
          animation: ${fadeIn} ease-in ${FADE_IN_DURATION}ms;
        `;
      case 'entered':
        return css`
          opacity: 1;
        `;
      case 'exiting':
        return css`
          animation: ${fadeOut} ease-out ${FADE_OUT_DURATION}ms;
        `;
      case 'exited':
        return css`
          opacity: 0;
        `;
    }
  }}
`;
