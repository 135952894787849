import { useState, useLayoutEffect, useCallback } from 'react';

/**
 * @param {object} rowSelection row selection config
 * @param {object[]} data initial data source
 * @return {{
 *  onSelect: function,
 *  onSelectAll: function,
 *  getRecordIndex: function,
 *  isAllSelected: boolean
 * }}
 */

const noIdError =
  'record in datasource does not have id attribute, you may try to use `defaultIdAttr` to customize your id attribute name.';

const useSelect = ({ rowSelection = {}, data = [] }) => {
  //collection of selected row ids
  const [selectedRowIdList, setSelectedRowIdList] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);

  const getIdKeyName = useCallback(() => rowSelection.defaultIdAttr || 'id', [
    rowSelection.defaultIdAttr
  ]);

  const getRecordIndex = useCallback(
    record => {
      const keyName = getIdKeyName();
      return selectedRowIdList.findIndex(item => item === record[keyName]);
    },
    [getIdKeyName, selectedRowIdList]
  );

  const onSelectAll = useCallback(
    (event, selected) => {
      const keyName = getIdKeyName();
      const selectedRowData = selected ? data : [];
      const newSelectedRowIdList = selectedRowData.map(item => {
        if (item[keyName]) {
          return item[keyName];
        } else {
          throw noIdError;
        }
      });

      setSelectedRowIdList(newSelectedRowIdList);
      rowSelection.onSelect &&
        rowSelection.onSelect({
          record: data,
          selected,
          selectedRowIds: newSelectedRowIdList,
          event
        });
    },
    [getIdKeyName]
  );

  const onSelect = useCallback(
    (event, selected, record) => {
      const keyName = getIdKeyName();
      const result = [...selectedRowIdList];
      if (rowSelection.type === 'radio') {
        rowSelection.onSelect &&
          rowSelection.onSelect({
            record,
            selected,
            selectedRowIds: [record[keyName]],
            event
          });
        setSelectedRowIdList([record[keyName]]);
      }
      if (rowSelection.type === 'checkbox') {
        if (selected) {
          if (record[keyName]) {
            //insert new item into list
            result.push(record[keyName]);
          } else {
            throw noIdError;
          }
        } else {
          const index = getRecordIndex(record);
          //remove item from list
          index > -1 && result.splice(index, 1);
        }
        rowSelection.onSelect &&
          rowSelection.onSelect({
            record,
            selected,
            selectedRowIds: result,
            event
          });
        setSelectedRowIdList(result);
      }
    },
    [getIdKeyName, getRecordIndex]
  );

  useLayoutEffect(() => {
    Array.isArray(rowSelection.selectedRowIds) &&
      setSelectedRowIdList(rowSelection.selectedRowIds);
  }, [rowSelection.selectedRowIds]);

  useLayoutEffect(() => {
    //check whether all records in dataSource were selected
    const isAllRecordsSelected = selectedRowIdList?.length
      ? data.every(record => getRecordIndex(record) > -1)
      : false;
    setAllSelected(isAllRecordsSelected);
  }, [selectedRowIdList]);

  return { onSelect, onSelectAll, getRecordIndex, isAllSelected };
};

export default useSelect;
