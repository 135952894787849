/**
 * Debounce function call for a given wait time.
 *
 * @param {Function} callback target function to be debounced
 * @param {number} wait time in milliseconds
 * @param {boolean} immediate Flag to call the function immediately.
 *        The function will run once immediately and once after the timer expires.
 * @return {Function}
 */
function debounce(callback, wait, { immediate = false } = {}) {
  let timeout = null;

  return function() {
    const callNow = immediate && !timeout;
    const next = () => {
      return callback.apply(this, arguments);
    };

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      next();
    }
  };
}

export default debounce;
