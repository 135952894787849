import { api } from '../configs';
import { isLoading } from './index';
import { sendApiToGaEvent } from '../helpers';

export const setUser = user => ({
  type: 'SET_USER',
  user
});

export function getUserLogin(user, pass) {
  const url = api.cco.login;

  const params = {
    username: user,
    password: pass
  };

  const searchParams = Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    })
    .join('&');

  const options = {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: searchParams,
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('userLogin', response.status);

        // Login failed
        if (response.status !== 200) {
          dispatch(isLoading(false));
          dispatch(
            setUser({
              authStatus: 'FAILED'
            })
          );
          return response;
        }

        dispatch(
          setUser({
            authToken: response.headers.get('x-auth-token')
          })
        );
        return response;
      })
      .then(response => response.json())
      .then(response => {
        dispatch(
          setUser({
            role: response.role,
            authStatus: 'SUCCESS'
          })
        );
        dispatch(isLoading(false));
      })
      .catch(() => {});
  };
}
