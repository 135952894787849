import React, { useState, useLayoutEffect, isValidElement } from 'react';
import { withClearInteraction, withStyledAttrs } from '../../helpers/styled';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Text from '../Text';
import { noop } from '../../helpers';
import CheckboxTick from './tick.svg';
import { withTheme } from '../../core/ThemeProvider';
import useDevice from '../../core/hooks/useDevice';

const CheckboxInput = ({
  label,
  name,
  checked,
  error,
  id,
  onChange,
  ...rest
}) => {
  const { isMobile } = useDevice();
  const [isChecked, setCheckboxValue] = useState(checked);

  const handleClick = e => {
    setCheckboxValue(!isChecked);
    onChange(e, !isChecked);
  };

  useLayoutEffect(() => {
    setCheckboxValue(!!checked);
  }, [checked]);

  return (
    <CheckboxWrapper data-testid={rest['data-testid']}>
      <input
        {...rest}
        name={name}
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        data-testid="checkboxInputEl"
      />

      {/* todo: Consider disable interaction for disabled checkboxes */}
      <CheckboxContainer>
        <Checkbox
          enableInteraction={true}
          onClick={handleClick}
          data-testid="checkbox-button"
          error={error}
          checked={isChecked}
        >
          {/* TODO allow viewBox prop in svg loader used in webpack */}
          {/* `viewBox` attr is part of the original svg but is */}
          {/* removed by the webpack loader */}
          <CheckboxTick
            viewBox="0 0 16 11"
            width={isMobile ? 12 : 16}
            height={isMobile ? 12 : 16}
          />
        </Checkbox>
      </CheckboxContainer>
      {label &&
        (isValidElement(label) ? (
          <LabelText>{label}</LabelText>
        ) : (
          <LabelText dangerouslySetInnerHTML={{ __html: label }} />
        ))}
    </CheckboxWrapper>
  );
};

CheckboxInput.defaultProps = {
  checked: false,
  onChange: noop
};

CheckboxInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * checked value
   */
  checked: PropTypes.bool,
  /**
   * Error state for the checkbox
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * id
   */
  id: PropTypes.string,
  /**
   * label text
   */
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * On Change event
   */
  onChange: PropTypes.func
};

export default CheckboxInput;

const CheckboxContainer = styled.div`
  align-self: flex-start;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  box-sizing: initial;

  input {
    position: absolute;
    left: -999999px;
    top: 0;
    opacity: 0;
    width: 1px;
    height: 1px;
  }

  label {
    display: none;
  }
`;

const LabelText = withTheme(
  withStyledAttrs(Text, {
    type: 'body',
    lineHeight: 'compact',
    fontSize: [20, 20, 20, 20, 20]
  })(
    p => `
  padding-left: 16px;
  a {
    color: ${p.coreTheme.cl_sec_d1};
    font-family: ${p.coreTheme.ff_sec_bold};
    text-decoration: none;
  }
`
  )
);

const Checkbox = withClearInteraction(
  withTheme(
    styled.div(({ checked, error, coreTheme }) => {
      const showError = error && !checked;
      return css`
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: ${coreTheme.space_6}px;
        width: ${coreTheme.space_6}px;
        border-radius: ${coreTheme.space_1}px;

        @media (min-width: ${coreTheme.brk_xs}) {
          height: ${coreTheme.space_8}px;
          width: ${coreTheme.space_8}px;
          border-radius: ${coreTheme.space_2}px;
        }
        display: inline-flex;
        vertical-align: top;
        margin: 0;
        line-height: 0;
        border: 1px solid
          ${checkboxBorderColor(checked, showError, {
            checked: coreTheme.cl_sec_d1,
            error: coreTheme.cl_pri_d3,
            default: coreTheme.cl_ter_l4
          })};
        background-color: ${checked ? coreTheme.cl_sec_d1 : coreTheme.cl_white};
        cursor: pointer;
      `;
    })
  ),

  12,
  7
);

const checkboxBorderColor = (checked, error, colors) => {
  if (checked) {
    return colors.checked;
  } else if (error) {
    return colors.error;
  } else {
    return colors.default;
  }
};
