import React, { useEffect, useState } from 'react';
import { noop } from '../../helpers';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import space from '@styled-system/space';
import ArrowDownIcon from '@dls/assets/icons/ArrowDown';
import ArrowUpIcon from '@dls/assets/icons/ArrowUp';
import FormField, {
  FormFieldContext
} from '../../core/components/Forms/FormField';
import FormInput from '../../core/components/Forms/FormInput';
import LabelText from '../../core/components/Forms/LabelText';
import FormFieldHint from '../../core/components/Forms/FormFieldHint';
import HintText from '../../core/components/Forms/HintText';
import { withTheme } from '../../core/ThemeProvider';
import Divider from '../Divider';
import DropdownActionItem from './DropdownActionItem';
import DropdownActionLink from './DropdownActionLink';

/* eslint-disable-next-line react/display-name */
const DropdownInput = React.forwardRef(function DropdownInput(
  {
    zIndex,
    value,
    defaultValue,
    id,
    items = [],
    label,
    hintMessage,
    onChange,
    hasSearch,
    onSearch,
    error,
    coreTheme,
    bgColor
  },

  ref
) {
  const [dropdownVisible, setDropDownVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [inputText, setInputText] = useState('');

  const onDropdownClick = (_, formField) => {
    focusOnField(formField);
    setDropDownVisible(true);
  };

  const onItemClick = item => {
    setSelectedItem(item);
    setInputText(
      hasSearch || !item.rightText
        ? item.text
        : `${item.text} ${item.rightText}`
    );

    setDropDownVisible(false);
    return onChange(item);
  };

  const handleType = (event, formField) => {
    const text = event.target.value;
    focusOnField(formField);
    setInputText(text);
    onSearch(event, text);

    if (!dropdownVisible) {
      setDropDownVisible(true);
    }
  };

  const isSelected = ({ text, rightText }) => {
    const current = rightText ? `${text} ${rightText}` : text;
    return inputText === current;
  };

  const focusOnField = formField => {
    if (!formField.hasFocus) {
      formField.onFocus();
    }
  };

  const handleValueChange = val => {
    const currentItem = items.find(item => item.value && item.value === val);
    if (currentItem) {
      setSelectedItem(currentItem);
      setInputText(
        hasSearch || !currentItem.rightText
          ? currentItem.text
          : `${currentItem.text} ${currentItem.rightText}`
      );
    } else {
      setSelectedItem({});
      setInputText('');
    }
  };

  useEffect(() => {
    handleValueChange(value);
  }, [value]);

  // Always keep it as last to avoid calling multiple re render
  useEffect(() => {
    !value && defaultValue && handleValueChange(defaultValue);
  }, []);

  return (
    <div>
      <FormField
        defaultValue={defaultValue || value}
        data-testid="dropdownId"
        error={error}
        bgColor={bgColor}
      >
        {/*TODO: Refactor dropdown api to use lego blocks pattern. */}
        <FormFieldContext.Consumer>
          {formFieldContext => (
            <>
              <FieldWrapper>
                <LabelWrapper>
                  {selectedItem && selectedItem.leftImage && (
                    <ImageContainer pr={3} pt={1}>
                      <img
                        src={selectedItem.leftImage}
                        alt={selectedItem.text}
                      />
                    </ImageContainer>
                  )}

                  <FormInput
                    id={id}
                    ref={ref}
                    type="text"
                    readOnly={!hasSearch}
                    onChange={e => handleType(e, formFieldContext)}
                    value={inputText || ''}
                    onClick={e => onDropdownClick(e, formFieldContext)}
                    onBlur={() => setDropDownVisible(false)}
                    autoComplete="off"
                    data-testid="inputTextId"
                  />
                </LabelWrapper>
                <IconWrapper>
                  {dropdownVisible ? (
                    <ArrowUpIcon size={24} color={coreTheme.cl_sec_d1} />
                  ) : (
                    <ArrowDownIcon size={24} color={coreTheme.cl_sec_d1} />
                  )}
                </IconWrapper>
                <LabelText>{label}</LabelText>
              </FieldWrapper>
              <ItemsWrapper
                zIndex={zIndex}
                dropdownVisible={dropdownVisible && formFieldContext.hasFocus}
              >
                <OptionViewport>
                  {items.map((item, index) => {
                    if (React.isValidElement(item)) {
                      return item;
                    }
                    switch (item.type) {
                      case 'header':
                        return (
                          <SectionTitle key={index}>{item.text}</SectionTitle>
                        );

                      case 'divider':
                        return (
                          <DividerWrapper key={index}>
                            <Divider />
                          </DividerWrapper>
                        );

                      default:
                        return (
                          <OptionItem
                            onClick={e => {
                              e.preventDefault();
                              formFieldContext.onBlur();
                              return onItemClick(item);
                            }}
                            key={index}
                            data-testid={'optArrayId-' + index}
                          >
                            {/* image */}
                            {item.leftImage && (
                              <ImageContainer pr={3}>
                                <img src={item.leftImage} alt={item.text} />
                              </ImageContainer>
                            )}

                            <MainText isSelected={isSelected(item)}>
                              {item.text}
                            </MainText>
                            {/* right text */}
                            {item.rightText && (
                              <RightText isSelected={isSelected(item)}>
                                {item.rightText}
                              </RightText>
                            )}
                          </OptionItem>
                        );
                    }
                  })}
                </OptionViewport>
              </ItemsWrapper>
            </>
          )}
        </FormFieldContext.Consumer>
      </FormField>
      {hintMessage && (
        <FormFieldHint>
          <HintText error={error} data-testid="errorId">
            {hintMessage}
          </HintText>
        </FormFieldHint>
      )}
    </div>
  );
});

DropdownInput.defaultProps = {
  zIndex: 1,
  error: false,
  onChange: noop,
  onSearch: noop,
  bgColor: 'white'
};

DropdownInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Background color of Input fields.
   * @default white
   */
  bgColor: PropTypes.oneOf(['haze', 'white']),
  /**
   * Default value of the pre selected option
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Label of the field
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Allow typing into input field
   */
  hasSearch: PropTypes.bool,
  /**
   * Hint message for the dropdown
   */
  hintMessage: PropTypes.string,
  /**
   * List of items to be rendered in the dropdown. <br>`value` and `text` are required fields
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.any,
      value: PropTypes.any
    })
  ).isRequired,
  /**
   * Label of the field
   */
  label: PropTypes.string,
  /**
   * On change handler, invoked when the user selects an item from the dropdown
   */
  onChange: PropTypes.func,
  /**
   * Event handler when typing into input field
   */
  onSearch: PropTypes.func,
  /**
   * css z-index
   */
  zIndex: PropTypes.number
};

export const FieldWrapper = withTheme(styled.div`
  display: flex;
  padding-right: ${({ coreTheme }) => coreTheme.space_9}px;
`);

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 100%;
`;

export const IconWrapper = withTheme(styled.div`
  position: absolute;
  top: 30px;
  right: ${({ coreTheme }) => coreTheme.space_4}px;
`);

export const SelectedText = withTheme(styled.div`
  ${({ coreTheme }) => css`
    font-family: ${coreTheme.ff_sec_thin};
    color: ${coreTheme.cl_ter_base};
    height: ${coreTheme.space_6}px;
    line-height: ${coreTheme.space_6}px;
    margin-top: 4px;
    font-size: ${coreTheme.fs_xl};
  `};
  transition: 0.2s ease;
  opacity: ${p => (p.hasValue ? 1 : 0)};
`);

const ItemsWrapper = withTheme(styled.div`
  position: absolute;
  width: 100%;
  top: calc(100% + 8px);
  left: 0;
  border-radius: 8px;
  ${({ coreTheme }) => css`
    font-family: ${coreTheme.ff_sec_thin};
    border: 1px solid ${coreTheme.cl_ter_l4};
    background-color: ${coreTheme.cl_white};
  `};
  padding: 16px 0;
  box-sizing: border-box;
  z-index: ${({ zIndex }) => zIndex};
  overflow: hidden;
  display: ${({ dropdownVisible }) => (dropdownVisible ? 'block' : 'none')};
  transition-property: all;
  transition-duration: 0.2s;
`);

const SectionTitle = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      font-family: ${coreTheme.ff_sec_bold};
      font-size: ${coreTheme.fs_md};
      line-height: ${coreTheme.space_6}px;
      color: ${coreTheme.cl_ter_base};
      padding: ${coreTheme.space_2}px ${coreTheme.space_4}px;
    `
  )
);

export const DividerWrapper = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      padding: ${coreTheme.space_6}px ${coreTheme.space_4}px;
    `
  )
);

const OptionViewport = withTheme(styled.div`
  height: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ coreTheme }) => css`
    background-color: ${coreTheme.cl_white};

    @media (min-width: ${/* sc-value */ coreTheme.brk_md}) {
      ${css`
        max-height: 316px;
      `};
    }
  `}
`);

const OptionItem = withTheme(
  styled.span(
    ({ coreTheme }) => css`
      display: flex;
      transition: all 0.2s ease;
      font-family: ${coreTheme.secondaryThin};
      font-size: ${coreTheme.fs_xl};
      align-content: center;
      background-color: ${coreTheme.cl_white};
      padding: ${coreTheme.space_2}px ${coreTheme.space_4}px;
      color: ${coreTheme.cl_ter_base};

      &:hover {
        background-color: ${coreTheme.cl_sec_l2};

        span {
          ${HighlightTextCss(coreTheme)}
        }
      }
    `
  )
);

const ImageContainer = styled.div`
  ${space};
  width: 36px;
  height: auto;
  flex-grow: 0;

  > img {
    width: 100%;
  }
`;

export const MainText = withTheme(styled.span`
  display: block;
  flex: 1 1 50%;
  word-break: break-all;
  ${({ isSelected, coreTheme }) => isSelected && HighlightTextCss(coreTheme)}
`);

const RightText = withTheme(styled.span`
  display: flex;
  ${({ isSelected, coreTheme }) => isSelected && HighlightTextCss(coreTheme)}
`);

const HighlightTextCss = coreTheme => css`
  font-weight: 900;
  font-family: ${coreTheme.ff_sec_regular};
  color: ${coreTheme.cl_sec_d1};
`;

const ThemedDropdownInput = withTheme(DropdownInput);
ThemedDropdownInput.ActionItem = DropdownActionItem;
ThemedDropdownInput.ActionLink = DropdownActionLink;

export default ThemedDropdownInput;
