import { timerValueInMilliseconds } from '../configs';
import { resetSession } from './customer';

export const navigateWithTokenisedUrl = (push, url) => {
  return (_, getState) => {
    const {
      customer: { nricToken }
    } = getState();

    if (nricToken) {
      const tokenisedUrl = `${url}?token=${nricToken}`;
      push(tokenisedUrl);
    } else {
      // hide query string if no token is present
      push(url);
    }
  };
};

export const isLoading = loading => ({
  type: 'SET_LOADING',
  loading
});

export const resetRoiState = () => ({
  type: 'RESET_ROI_STATE'
});

export const setSessionTimeout = () => ({
  type: 'SET_SESSION_TIMEOUT'
});

export const resetState = () => ({
  type: 'RESET_STATE'
});

export const endTimer = () => ({
  type: 'END_TIMER'
});

export const clearTimer = timerId => {
  clearTimeout(timerId);
  return {
    type: 'CLEAR_TIMER'
  };
};

export const startTimer = () => {
  const currentTime = Date.now();
  const expireTime = currentTime + timerValueInMilliseconds;
  const remainingTime = expireTime - currentTime;

  return (dispatch, getState) => {
    const {
      campaign: { id },
      customer: { nricUnMasked, nricToken },
      timerId
    } = getState();

    const onTimerEnd = () => {
      dispatch(endTimer());
      dispatch(resetSession(id, nricUnMasked, nricToken, true));
    };

    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(onTimerEnd, remainingTime);

    dispatch({
      type: 'START_TIMER',
      currentTime,
      expireTime,
      timerId: newTimerId
    });
  };
};

export * from './campaign';
export * from './customer';
export * from './product';
export * from './order';
export * from './timeslot';
export * from './user';
