import styled, { css } from 'styled-components';
import { remCalc } from '@lux/helpers';
import { withTheme } from '../../core/ThemeProvider';
import Grid from '../Grid';
import Row from '../Row';
import Text from '../Text';

export const FooterGrid = withTheme(
  styled(Grid)(
    props => css`
      && {
        background-color: ${props.coreTheme.footer.backgroundColor};
        padding-top: 0;
        padding-bottom: 0;
      }
    `
  )
);

export const FooterPagesRow = withTheme(
  styled(Row)(
    props => css`
      margin-top: ${remCalc(40)};
      margin-bottom: ${remCalc(40)};
      display: none;

      @media (min-width: ${props.coreTheme.brk_md}) {
        display: flex;
      }
    `
  )
);

export const HeadingAnchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      font-family: ${props.coreTheme.footer.footerPage.headingFont};
      font-size: ${props.coreTheme.footer.footerPage.headingFontSize};
      color: ${props.coreTheme.footer.footerPage.headingFontColor};
      margin-bottom: ${remCalc(10)};
    `
  )
);

export const FooterPage = styled.div`
  margin-bottom: ${remCalc(10)};
`;

export const FooterPageAnchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      font-family: ${props.coreTheme.footer.footerPage.itemFont};
      font-size: ${props.coreTheme.footer.footerPage.itemFontSize};
      color: ${props.coreTheme.footer.footerPage.itemFontColor};
    `
  )
);

export const FooterLinksRow = styled(Row)`
  margin-top: ${remCalc(40)};
  margin-bottom: ${remCalc(40)};
`;

export const Divider = withTheme(
  styled.hr(
    props => css`
      display: none;
      border: none;
      border-bottom: ${props.coreTheme.footer.dividerBorder};

      @media (min-width: ${props.coreTheme.brk_xs}) {
        display: block;
      }
    `
  )
);

export const FlexGrid = withTheme(
  styled.div(
    props => css`
      display: flex;
      flex-direction: column;

      @media (min-width: ${props.coreTheme.brk_xs}) {
        flex-direction: row;
      }
    `
  )
);

export const Navigation = withTheme(
  styled.nav(
    props => css`
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      flex-direction: column;

      @media (min-width: ${props.coreTheme.brk_xs}) {
        flex-direction: row;
      }
    `
  )
);

export const Anchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      font-family: ${props.coreTheme.footer.linkFont};
      color: ${props.coreTheme.footer.linkFontColor};
      font-size: ${props.coreTheme.footer.linkFontSize};
      flex: 1 1 auto;
      text-align: center;
      margin-bottom: ${remCalc(20)};

      @media (min-width: ${props.coreTheme.brk_xs}) {
        flex: 0 1 auto;
        margin-right: ${remCalc(22)};
        text-align: left;
        margin-bottom: 0;
      }
    `
  )
);

export const Copyright = withTheme(
  styled.div(
    props => css`
      font-family: ${props.coreTheme.footer.copyRightFont};
      font-size: ${props.coreTheme.footer.copyRightFontSize};
      color: ${props.coreTheme.footer.copyRightFontColor};
      flex: 1 1 auto;
      text-align: center;

      @media (min-width: ${props.coreTheme.brk_xs}) {
        text-align: right;
        margin-left: ${remCalc(16)};
      }
    `
  )
);

export const Social = withTheme(
  styled.div(
    props => css`
      margin-bottom: ${remCalc(10)};
      text-align: center;

      @media (min-width: ${props.coreTheme.brk_xs}) {
        text-align: right;
      }
    `
  )
);

export const SocialLink = withTheme(
  styled.a(
    props => css`
      display: inline-block;
      margin: 0 ${remCalc(15)};

      @media (min-width: ${props.coreTheme.brk_xs}) {
        margin: 0 0 0 ${remCalc(10)};
      }
    `
  )
);

export const SocialIcon = styled.img`
  margin: 0;
  height: ${remCalc(30)};
`;

export const FooterText = withTheme(
  styled.span(
    props =>
      css`
        display: block;
        font-weight: normal;
        font-size: ${props.coreTheme.footer.footerTextFontSize};
        font-family: ${props.coreTheme.footer.footerTextFont};
        color: ${props.coreTheme.footer.footerTextFontColor};
        letter-spacing: ${props.coreTheme.footer.footerTextFontSpacing};
        line-height: 1.5;
        text-align: center;

        @media (min-width: ${props.coreTheme.brk_xs}) {
          text-align: left;
        }
      `
  )
);
