import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './index';
import { noop } from '../../helpers';
import supportedFormats from './supportedFormats';
import { DateRangeCalendar } from './calendarViews';

const DateRangePicker = ({
  label,
  hintMessage,
  error,
  defaultValue,
  onChange,
  onClear,
  onConfirm,
  onBlur,
  formatter,
  calendarProps: { availableDays, defaultOptions } = {},
  disabledDays,
  bgColor,
  ...rest
}) => {
  return (
    <DatePicker
      {...rest}
      label={label}
      hintMessage={hintMessage}
      error={error}
      data-testid={rest['data-testid']}
      defaultValue={defaultValue}
      formatter={formatter}
      bgColor={bgColor}
      onBlur={onBlur}
      renderCalendar={props => (
        <DateRangeCalendar
          {...props}
          calendarProps={{ availableDays, defaultOptions }}
          datePickerProps={{
            onChange,
            onClear,
            onConfirm
          }}
          formatter={formatter}
          disabledDays={disabledDays}
        />
      )}
    />
  );
};

DateRangePicker.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  value: PropTypes.shape({
    from: PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.instanceOf(Date).isRequired
  })
};

DateRangePicker.defaultProps = {
  onChange: noop,
  onClear: noop,
  onConfirm: noop,
  formatter: supportedFormats['dd MMM yyyy']
};

export default DateRangePicker;
