import { addDays, subDays } from 'date-fns';

/**
 * Get lists of left and right next days with the given disabled days
 *
 * @param {Date | Date[] | Object | Object[]} disabledDays
 * @return {
 *  nextLeftDisabledDays: {Date | Date[] | Object | Object[]},
 *  nextRightDisabledDays: {Date | Date[] | Object | Object[]},
 * }
 */
export const getDaysNearDisabledDays = disabledDays => {
  let nextLeftDisabledDays;
  let nextRightDisabledDays;

  if (disabledDays instanceof Date) {
    // A single Date object
    nextLeftDisabledDays = subDays(disabledDays, 1);
    nextRightDisabledDays = addDays(disabledDays, 1);
  } else if (disabledDays instanceof Array) {
    // An array of objects
    nextLeftDisabledDays = [];
    nextRightDisabledDays = [];

    disabledDays.forEach(day => {
      if (day instanceof Date) {
        // a date object
        nextLeftDisabledDays.push(subDays(day, 1));
        nextRightDisabledDays.push(addDays(day, 1));
      } else {
        // an object (Date[] | Object)
        // use recursion
        const {
          nextLeftDisabledDays: nextLeft,
          nextRightDisabledDays: nextRight
        } = getDaysNearDisabledDays(day);

        if (nextLeft instanceof Array) {
          nextLeftDisabledDays = nextLeftDisabledDays.concat(nextLeft);
          nextRightDisabledDays = nextRightDisabledDays.concat(nextRight);
        } else {
          nextLeftDisabledDays.push(nextLeft);
          nextRightDisabledDays.push(nextRight);
        }
      }
    });
  } else if (disabledDays instanceof Object) {
    const { from, to, before, after, daysOfWeek } = disabledDays;

    if (from && to) {
      nextLeftDisabledDays = subDays(from, 1);
      nextRightDisabledDays = addDays(to, 1);
    } else if (before || after) {
      nextLeftDisabledDays = after;
      nextRightDisabledDays = before;
    } else if (daysOfWeek) {
      const nextLeftDaysOfWeek = [];
      const nextRightDaysOfWeek = [];

      daysOfWeek.forEach(day => {
        if (day === 0) {
          // Sunday
          nextRightDaysOfWeek.push(day + 1);
        } else if (day === 6) {
          // Saturday
          nextLeftDaysOfWeek.push(day - 1);
        } else {
          // Weekdays
          nextRightDaysOfWeek.push(day + 1);
          nextLeftDaysOfWeek.push(day - 1);
        }
      });
      nextLeftDisabledDays = { daysOfWeek: nextLeftDaysOfWeek };
      nextRightDisabledDays = { daysOfWeek: nextRightDaysOfWeek };
    }
  }

  return { nextLeftDisabledDays, nextRightDisabledDays };
};
