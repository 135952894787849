import React from 'react';
import Column from '../Column';
import Row from '../Row';
import {
  FooterGrid,
  FooterPagesRow,
  HeadingAnchor,
  FooterPage,
  FooterPageAnchor,
  FooterLinksRow,
  Divider,
  FlexGrid,
  Navigation,
  Anchor,
  Copyright,
  Social,
  SocialLink,
  SocialIcon,
  FooterText
} from './style';
import { testIdProps } from '../../helpers';
import PropTypes from 'prop-types';

const SocialLinks = ({ socialLinks }) => (
  <Social>
    {socialLinks.map((item, key) => (
      <SocialLink key={key} href={item.link} target="_blank">
        <SocialIcon src={item.icon} alt={item.text} />
      </SocialLink>
    ))}
  </Social>
);

const Footer = ({
  baseUrl,
  footerPage,
  links,
  copyrightText,
  socialLinks,
  footerText,
  ...rest
}) => {
  const calculatedHrefUrl = link => {
    if (link && (link.startsWith('http://') || link.startsWith('https://'))) {
      return link;
    }
    if (baseUrl) {
      return baseUrl + link;
    }
    return link;
  };

  return (
    <FooterGrid {...testIdProps(rest)}>
      {footerPage && (
        <FooterPagesRow>
          {footerPage.map((item, key) => {
            return (
              <Column key={key}>
                <HeadingAnchor
                  type="link"
                  href={calculatedHrefUrl(item.heading.src)}
                >
                  {item.heading.title}
                </HeadingAnchor>
                {item.children &&
                  item.children.map((child, key) => {
                    return (
                      <FooterPage sm={12} key={key}>
                        <FooterPageAnchor
                          type="link"
                          href={calculatedHrefUrl(child.src)}
                        >
                          {child.title}
                        </FooterPageAnchor>
                      </FooterPage>
                    );
                  })}
              </Column>
            );
          })}
        </FooterPagesRow>
      )}

      <FooterLinksRow>
        {links && (
          <Column xs={12}>
            <Divider />
            <FlexGrid>
              <Navigation>
                {links.map((item, key) => {
                  return (
                    <Anchor
                      type="link"
                      key={key}
                      href={calculatedHrefUrl(item.link)}
                    >
                      {item.text}
                    </Anchor>
                  );
                })}
              </Navigation>
              <Copyright>{copyrightText}</Copyright>
              {!copyrightText && socialLinks && (
                <SocialLinks socialLinks={socialLinks} />
              )}
            </FlexGrid>
          </Column>
        )}

        <Row style={{ width: '100%' }}>
          {footerText && (
            <Column xs={12} sm={copyrightText && socialLinks ? 9 : 12}>
              <FooterText>{footerText}</FooterText>
            </Column>
          )}
          {copyrightText && socialLinks && (
            <Column xs={12} sm={3}>
              <SocialLinks socialLinks={socialLinks} />
            </Column>
          )}
        </Row>
      </FooterLinksRow>
    </FooterGrid>
  );
};

Footer.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * baseUrl for footer links (does not apply to socialLinks)
   */
  baseUrl: PropTypes.string,
  /**
   * copyright text
   */
  copyrightText: PropTypes.string,
  /**
   * heading and childern for footerPage
   */
  footerPage: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired
        })
      ),
      heading: PropTypes.shape({
        src: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      }).isRequired
    })
  ),
  /**
   * configure footer textfield
   */
  footerText: PropTypes.node,
  /**
   * footer links
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  /**
   * socialmedia icons and link
   */
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      text: PropTypes.string
    })
  )
};

export default Footer;
