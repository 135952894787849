import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTheme } from '../../core/ThemeProvider';
import { testIdProps } from '../../helpers';

const Inline = ({ space, align, ...rest }) => {
  const addHorizontalSpace = align != 'spaceBetween';
  return (
    <InlineContainer {...testIdProps(rest)} {...rest} align={align}>
      {React.Children.map(rest.children, child => (
        <InlineChild space={addHorizontalSpace ? space : 0}>
          {child}
        </InlineChild>
      ))}
    </InlineContainer>
  );
};

Inline.defaultProps = {
  space: 1
};

Inline.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Horizontal alignment of the child elements.
   * Actual space applied is in multiples of 4.
   */
  align: PropTypes.oneOf(['center', 'left', 'right', 'spaceBetween']),
  /**
   * Vertical alignment of the child elements.
   */
  alignY: PropTypes.oneOf(['bottom', 'center', 'none', 'top']),
  /**
   * Horizontal Space between the child components.
   */
  space: PropTypes.number
};

export default Inline;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${p => p.align !== 'none' && `justify-content: ${alignFlexStyles[p.align]};`}
  ${p => p.alignY !== 'none' && `align-items: ${alignYFlexStyles[p.alignY]};`}
`;

const InlineChild = withTheme(styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: ${p => p.space * 8}px;
  }
`);

const alignFlexStyles = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  spaceBetween: 'space-between'
};

const alignYFlexStyles = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};
