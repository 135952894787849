/* istanbul ignore next */
const reducer = (state = {}, action) => {
  let customer;
  let campaign;
  let user;
  switch (action.type) {
    case 'RESET_STATE':
      return {
        ...state,
        customer: {
          nricToken: state.customer.nricToken
        },
        reservedProducts: {},
        selectedProducts: {},
        locationHeader: undefined,
        currentTime: undefined,
        expireTime: undefined,
        timerId: undefined,
        reservedLocationAndTimeSlot: undefined,
        products: undefined,
        timeslots: undefined
      };
    case 'RESET_ROI_STATE':
      return {
        ...state,
        customer: {
          name: '',
          mobile: '',
          email: '',
          portIn: ''
        },
        loading: false,
        reservedProducts: {},
        selectedProducts: {},
        user: {},
        products: undefined
      };
    case 'SET_SESSION_TIMEOUT':
      return {
        ...state,
        sessionTimeOutError: true
      };
    case 'CLEAR_SESSION_TIMEOUT':
      return {
        ...state,
        sessionTimeOutError: false
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading
      };
    case 'SET_CAMPAIGN_NAME':
      campaign = Object.assign({}, state.campaign);
      campaign.name = action.name;
      return {
        ...state,
        campaign: campaign
      };
    case 'SET_NRIC':
      customer = Object.assign({}, state.customer);
      customer.nric = action.nric.toUpperCase();
      customer.nricUnMasked = action.nric.toUpperCase();
      return {
        ...state,
        customer: customer
      };
    case 'SET_NRIC_TOKEN':
      customer = Object.assign({}, state.customer);
      customer.nricToken = action.token;
      return {
        ...state,
        customer: customer
      };
    case 'SET_GOOGLE_RECAPTCHA_KEY':
      return {
        ...state,
        gReCaptchaSiteKey: action.key
      };
    case 'SET_AUTH_TOKEN':
      customer = Object.assign({}, state.customer);
      customer.authToken = action.token;
      return {
        ...state,
        customer: customer
      };
    case 'SET_REGISTRATION_ACCESS_TOKEN':
      customer = Object.assign({}, state.customer);
      customer.registrationAccessToken = action.registrationAccessToken;
      return {
        ...state,
        customer: customer
      };
    case 'SET_CAMPAIGN_STATUS_ERROR':
      return {
        ...state,
        technicalError: true
      };
    case 'SET_CAMPAIGN_STATUS_SUCCESS':
      return {
        ...state,
        campaign: action.campaign
      };
    case 'SET_CAMPAIGN_CONTEXT':
      return {
        ...state,
        campaignContext: action.context
      };
    case 'SET_CUSTOMER_REGISTRATION_SUCCESS':
      customer = Object.assign({}, state.customer);
      customer.registered = action.registered;
      return {
        ...state,
        customer: customer
      };
    case 'SET_PRODUCT_CATALOG_SUCCESS':
      return {
        ...state,
        products: action.products
      };
    case 'SET_SELECTED_PRODUCT':
      state.selectedProducts[action.index] = {
        ...state.selectedProducts[action.index],
        ...action.product
      };
      return {
        ...state
      };
    case 'SET_RESERVED_PRODUCT':
      state.reservedProducts[action.index] = action.product;
      return {
        ...state
      };
    case 'SET_SELECTED_PRODUCTS':
      return {
        ...state,
        selectedProducts: action.products
      };
    case 'SET_RESERVED_PRODUCTS':
      return {
        ...state,
        reservedProducts: action.products
      };
    case 'SET_CUSTOMER_DETAILS':
      // extend existing state.customer
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.customer
        }
      };
    case 'SET_SUBMIT_CUSTOMER_REGISTRATION_SUCCESS':
      customer = Object.assign({}, state.customer);
      customer.registered = true;
      return {
        ...state,
        customer: customer
      };
    case 'SET_SUBMIT_CUSTOMER_REGISTRATION_ERROR':
      customer = Object.assign({}, state.customer);
      customer.registrationError = true;
      return {
        ...state,
        customer: customer
      };
    case 'SET_SUBMIT_CUSTOMER_APPOINTMENT_SUCCESS':
      customer = Object.assign({}, state.customer);
      customer.appointmentBooked = true;
      return {
        ...state,
        backorderRedirectUrl: action.backorderRedirectUrl,
        customer: customer
      };
    case 'SET_RESEND_CONFIRMATION_SUCCESS':
      customer = Object.assign({}, state.customer);
      customer.resendConfirmation = true;
      return {
        ...state,
        customer: customer
      };
    case 'SET_CUSTOMER_APPOINTMENT_ERROR':
      customer = Object.assign({}, state.customer);
      customer.appointmentError = action.error;
      return {
        ...state,
        customer: customer
      };
    case 'SET_LOCATION_HEADER':
      return {
        ...state,
        locationHeader: action.location
      };
    case 'SET_TIME_SLOTS':
      return {
        ...state,
        timeslots: action.timeslots
      };
    case 'SET_RESERVED_LOCATION_AND_TIME_SLOT':
      return {
        ...state,
        reservedLocationAndTimeSlot: action.locationAndTimeSlot
      };
    case 'SET_RESET_APPOINTMENT_SUCCESS':
      customer = Object.assign({}, state.customer);
      customer.reset = true;
      return {
        ...state,
        customer: customer
      };
    case 'SET_SUBMIT_CUSTOMER_APPOINTMENT_ERROR':
      customer = Object.assign({}, state.customer);
      customer.appointmentError = action.error;
      return {
        ...state,
        customer: customer
      };
    case 'START_TIMER': {
      return {
        ...state,
        currentTime: action.currentTime,
        expireTime: action.expireTime,
        timerId: action.timerId
      };
    }
    case 'END_TIMER': {
      return {
        ...state,
        currentTime: state.expireTime,
        timerId: undefined
      };
    }
    case 'CLEAR_TIMER': {
      return {
        ...state,
        currentTime: undefined,
        expireTime: undefined,
        timerId: undefined
      };
    }
    case 'SET_USER': {
      // extend existing state.user
      user = Object.assign({}, state.user, action.user);
      return {
        ...state,
        user: user
      };
    }
    case 'SET_ALL_CAMPAIGNS_DETAILS_SUCCESS': {
      return {
        ...state,
        campaigns: action.campaigns.reduce((accum, campaign) => {
          accum[campaign.id] = campaign;
          return accum;
        }, {})
      };
    }
    case 'SET_MARKETING_CONSENT': {
      return {
        ...state,
        customerConsent: action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
