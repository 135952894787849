import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, createGlobalStyle } from 'styled-components';
import CalendarIcon from '@dls/assets/icons/Calendar';
import FormField, {
  FormFieldContext
} from '../../core/components/Forms/FormField';
import LabelText from '../../core/components/Forms/LabelText';
import FormFieldHint from '../../core/components/Forms/FormFieldHint';
import HintText from '../../core/components/Forms/HintText';
import {
  FieldWrapper,
  IconWrapper,
  LabelWrapper,
  SelectedText
} from '../DropdownInput';
import FormInput from '../../core/components/Forms/FormInput';
import { withTheme } from '../../core/ThemeProvider';
import { SingleDatePickerCalendar } from './calendarViews';
import supportedFormats from './supportedFormats';
import { formatDate } from '../../core/utils';
import Config from '../../config';

/**
 * Date picker form field component.
 * Renders a form field with a date value.
 *
 * ```js
 * <DatePicker
 *   label={"Pick a delivery date"}
 *   hintMessage={"Note that weekends and public holidays are not available."}
 * ```
 */
const DatePicker = withTheme(
  ({
    value,
    defaultValue,
    label,
    hintMessage,
    error,
    formatter,
    renderCalendar,
    onChange,
    onClear,
    onConfirm,
    onBlur,
    bgColor,
    ...rest
  }) => {
    const [dropdownVisible, setDropDownVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(value || defaultValue);
    useEffect(() => {
      value !== undefined && setSelectedDate(value);
    }, [value]);

    const onDropdownClick = () => {
      setDropDownVisible(!dropdownVisible);
    };

    const useSelectedDate = () => ({
      selectedDate,
      setSelectedDate
    });

    const useDropdownVisible = () => ({ dropdownVisible, setDropDownVisible });

    const renderDropdownCalendar = visible =>
      visible ? (
        <DropdownContainer
          onClick={e => e.preventDefault()}
          data-testid="dropdown-container"
        >
          {renderCalendar ? (
            renderCalendar({ useSelectedDate, useDropdownVisible })
          ) : (
            <SingleDatePickerCalendar
              datePickerProps={{
                onChange: onChange,
                onClear: onClear,
                onConfirm: onConfirm
              }}
              formatter={formatter}
              useDropdownVisible={useDropdownVisible}
              useSelectedDate={useSelectedDate}
            />
          )}
        </DropdownContainer>
      ) : null;

    /* TODO: Refactor.Dropdown and Datepicker use the same dropdown logic. */
    return (
      <>
        {dropdownVisible && <NoBackgroundActivity />}
        <div data-testid={rest['data-testid']}>
          <FormField
            defaultValue={serializeDateValue(selectedDate)}
            error={error}
            bgColor={bgColor}
          >
            <FormFieldContext.Consumer>
              {({ hasFocus }) => {
                return (
                  <>
                    <FieldWrapper>
                      <LabelWrapper>
                        <SelectedText
                          hasValue={!!selectedDate}
                          data-testid="datepicker-selectedText"
                        >
                          {selectedDate && formatDate(selectedDate, formatter)}
                        </SelectedText>
                      </LabelWrapper>
                      <IconWrapper>
                        <CalendarIcon
                          size={24}
                          color={rest.coreTheme.cl_sec_d1}
                        />
                      </IconWrapper>
                      <LabelText>{label}</LabelText>
                      <HiddenInput
                        value={serializeDateValue(selectedDate)}
                        onBlur={() => {
                          onBlur && onBlur();
                          setDropDownVisible(false);
                        }}
                        onClick={onDropdownClick}
                        data-testid="datepicker-input"
                        readOnly
                      />
                    </FieldWrapper>
                    {renderDropdownCalendar(dropdownVisible && hasFocus)}
                  </>
                );
              }}
            </FormFieldContext.Consumer>
          </FormField>
          {hintMessage && (
            <FormFieldHint>
              <HintText error={error} data-testid="errorId">
                {hintMessage}
              </HintText>
            </FormFieldHint>
          )}
        </div>
      </>
    );
  }
);

DatePicker.defaultProps = {
  formatter: supportedFormats['dd MMM yyyy'],
  bgColor: 'white'
};

DatePicker.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Background color of Input fields.
   * @default white
   */
  bgColor: PropTypes.oneOf(['haze', 'white']),
  calendarProps: PropTypes.shape({
    availableDays: PropTypes.shape({
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired
    }),
    defaultOptions: PropTypes.any
  }),
  defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired
    })
  ]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Format the displayed value in the field
   * formatter(date: Date | {from: Date, to: Date})
   *
   * @return {string}
   */
  formatter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  hintMessage: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onConfirm: PropTypes.func,
  /**
   * RenderProp for rendering the calendar.
   * Renders the Single Date picker by default.
   *
   * @return React.Component
   */
  renderCalendar: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired
    })
  ])
};

DatePicker.dateFormats = supportedFormats;

export default DatePicker;

const HiddenInput = styled(FormInput)`
  position: absolute;
  left: -999999px;
  height: 0;
  width: 0;
  opacity: 0;
`;

const DropdownContainer = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      margin: 0;
      text-align: center;
      background: ${coreTheme.cl_white};

      ${Config.media.sm`
        position: absolute;
        top: 100%;
        left: -1px;
        height: unset;
        width: unset;
        margin-top: ${coreTheme.space_2}px;
      `}
    `
  )
);

// Disable background scroll when fullscreen popup is open (mobile view)
const NoBackgroundActivity = withTheme(createGlobalStyle`
  body {
    height: 100vh;
    overflow: hidden;
  }

  ${Config.media.sm`
    body {
      height: unset;
      overflow: visible;
    }
  `}
`);

const serializeDateValue = selectedDate => {
  if (!selectedDate) return;
  if (selectedDate instanceof Date) {
    return selectedDate.toISOString();
  } else if (
    selectedDate.from instanceof Date ||
    selectedDate.to instanceof Date
  ) {
    return `${selectedDate.from.toISOString()} - ${selectedDate.to.toISOString()}`;
  }
};
