/**
 * Utility functions for lego component implementations.
 */

/**
 * Create a lego component
 * @param name
 * @param Component
 * @return {React.Component}
 */
export const createLegoComponent = (name, Component) => {
  const Lego = Component || (({ children }) => children);
  Lego.defaultProps = { _DLSLegoName: name };
  return Lego;
};

/**
 * Get the name of the lego component
 * @param element
 * @return {String}
 */
export const getLegoElementName = element => {
  return element && element.props && element.props._DLSLegoName;
};
