import styled from 'styled-components';
import { withTheme } from '../../ThemeProvider';

// TODO Sudhir: Add documentation and test cases.
const FormFieldHint = withTheme(
  styled.div(p => ({
    marginTop: p.coreTheme.space_2
  }))
);

export default FormFieldHint;
