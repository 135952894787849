import { getTitle, Thumbnail, TitleWrapper } from './style';
import PropTypes from 'prop-types';
import React from 'react';
import MultilineTruncateParagraph from '../../core/components/MultilineTruncateParagraph';

const CardTitle = ({
  thumbnail,
  fontWeight,
  showThumbnailBorder,
  children
}) => {
  const isHeavy = thumbnail || fontWeight === 'heavy';
  const Title = getTitle(isHeavy);

  return (
    <TitleWrapper>
      {thumbnail && (
        <Thumbnail
          src={thumbnail.url}
          alt={thumbnail.alt}
          showThumbnailBorder={showThumbnailBorder}
        />
      )}

      <MultilineTruncateParagraph Typography={Title} numOfLines={3}>
        {children}
      </MultilineTruncateParagraph>
    </TitleWrapper>
  );
};

CardTitle.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Title font weight.
   * title is always 'heavy' if thumbnail present
   * @default 'light'
   */
  fontWeight: PropTypes.oneOf(['heavy', 'light']),
  /**
   * Show border for thumbnail image
   * @default false
   */
  showThumbnailBorder: PropTypes.bool,
  /**
   * Thumbnail image source and alternative text
   */
  thumbnail: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string.isRequired
  })
};

export default CardTitle;
