
export default {
  ROI_OPEN_TITLE : `Register your interest for {{campaignName}} lineup`,
  ROI_OPEN_DESCRIPTION: `Be the first to get your hands on the new {{campaignName}} lineup with Singtel. 
        Available for purchase with selected mobile plans.
        <br/><br/> 
        Your NRIC/FIN provided here will be tied to your phone plan.`,
  ROI_CLOSED_TITLE: `Pre-registration has now closed`,
  ROI_CLOSED_DESCRIPTION: `Please fill in your details below to check if you have pre-registered successfully.`,
  PREORDER_TITLE : `Pre-order your {{campaignName}} lineup today`,
  PREORDER_DESCRIPTION_1: `Please enter the same NRIC/FIN provided when you registered your interest for the iPhone.`,
  UP_COMING_STOCKS_TITLE: `Place your pre-order for upcoming stocks of {{campaignName}} now! Limited stocks available.`,
  UP_COMING_STOCKS_DESCRIPTION: `Please note that the estimated delivery for upcoming stocks will 
    be 2 – 4 weeks and a $200 value will be held on your credit card.`,
  BACKORDER_TITLE: `Only available at a later date`,
  BACKORDER_MESSAGE: `Secure and receive your device in 6 weeks or less.`,
  NRIC_NOT_ELIGIBLE_ERROR_MSG: `Sorry, only customers who have successfully registered their interest previously 
    are able to pre-order the new {{campaignName}}.
    <br/>
    For latest updates, keep a lookout <a target="_blank" href="https://singtel.com/iphone">here.</a>`,
  SESSION_EXPIRED: `Your session has expired.`,
  APPOINTMENT_ERROR_MSG: `Kindly access the {{campaignName}} pre-order website via the
        exclusive URL sent to you in the pre-order email.`,
  PRESTIGE_NOT_ELIGIBLE: `Sorry, the {{campaignName}} pre-order is currently open for Singtel
        Prestige customers only.`,
  NRIC_NO_RECORD_FOUND_MESSAGE: `Oops, we are sorry that no record of your pre-order can be found. Stay
        tuned to <a href="//www.singtel.com/iphone">www.singtel.com/iphone</a>
        for the latest updates on {{campaignName}} islandwide sales.`,
  APPT_BOOKING_CLOSED_TITLE: `Appointment booking for {{campaignName}} is now closed`,
  APPT_BOOKING_CLOSED_MESSAGE: `You can resend your {{campaignName}} confirmation email and SMS to your registered mobile number and email.`,
  EARLY_APPT_STATUS_TITLE: `You are now 1 step closer to getting your hands on the new {{campaignName}} lineup`,
  EARLY_APPT_STATUS_MESSAGE: `For the latest updates on {{campaignName}} pre-order,
      please visit <a href="//www.singtel.com/iphone">www.singtel.com/iphone</a>.`,
  ROI_CONFIRMATION_DETAILS_TITLE: 'What’s next',
  ROI_NOTIFICATION_TITLE: '2. Get notified when pre-orders open',
  RECEIVE_CONFIRMATION_EMAIL_BODY: `1. Look out for your registration of interest details email at {{email}}.`,
  PAYINSTORE_FURTHER_STEP: '2. Pay and collect your iPhone in store at the selected date and time.',
  ROI_NOTIFICATION_BODY: '2. Wait to be notified via email and SMS when pre-orders open.',
  CUSTOMER_DETAILS_TITLE: 'Provide your personal details',
  NRIC_VALIDATION_ERROR: 'Enter a valid ID',
  CHOOSE_PAYMENT_METHOD_TITLE: 'Choose payment method',
  PAY_ONLINE_TEXT: 'Pay online at your allocated time slot',
  PAY_IN_STORE_TEXT: 'Pay in store when you collect your iPhone',
  PAY_ONLINE_CONTENT: 'Choose your preferred delivery method and timing upon payment.',
  NEXT_STEPS_NOTE_PAYONLINE_ONE: 'You’ll be able to choose your preferred delivery method and timing upon payment',
  NEXT_STEPS_NOTE_PAYONLINE_TWO: 'Your payment link is exclusive to you, so do avoid sharing this email',
  NEXT_STEPS_NOTE_PAYONLINE_THREE: 'Your phone selection is final and cannot be changed',
  NEXT_STEPS_NOTE_PAYONLINE_FOUR: 'Please clear all outstanding payments with Singtel or your current mobile service provider before purchasing your phone',
  NEXT_STEPS_NOTE_PAYINSTORE_ONE: 'Your phone selection is final and cannot be changed',
  NEXT_STEPS_NOTE_PAYINSTORE_TWO: 'Please clear all outstanding payments with Singtel or your current mobile service provider before purchasing your phone',
  FORM_NAME_EMPTY_VALIDATION: 'Enter your full name',
  FORM_NAME_VALIDATION: 'Enter a valid name',
  FORM_MOBILE_EMPTY_VALIDATION: 'Enter your mobile number',
  FORM_MOBILE_VALIDATION: 'Enter a valid mobile number',
  FORM_EMAIL_EMPTY_VALIDATION: 'Enter your email',
  FORM_EMAIL_VALIDATION: 'Enter a valid email',
  FORM_CONFIRM_EMAIL_VALIDATION: 'Check that your emails match',
  FORM_PORTIN_VALIDATION: 'Please select an option above',
  FIND_FURTHER_INSTRUCTIONS: 'You can find further instructions in your order confirmation email.'
}