import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { ArrowUp, ArrowDown } from '@dls/assets/icons';

import { noop, testIdProps } from '../../helpers';
import Divider from '../Divider';
import { useTheme } from '../../core/ThemeProvider';
import Text from '../Text';
import {
  Wrapper,
  TitleWrapper,
  TitleText,
  TextWrapper,
  IconWrapper
} from './style';
import { Fade, DURATION } from './animation';

const Accordion = props => {
  const {
    title,
    desc,
    selected,
    value,
    onClick,
    dividerNeeded = true,
    ...rest
  } = props;
  const { theme } = useTheme();
  const [height, setHeight] = useState();

  const assignHeight = node => {
    if (node && !height) {
      setHeight(node.clientHeight);
    }
  };

  return (
    <>
      <Wrapper {...testIdProps(rest)}>
        <TitleWrapper
          data-testid="titleId"
          onClick={event => onClick(event, value)}
        >
          {typeof title === 'string' ? <TitleText>{title}</TitleText> : title}
          <IconWrapper>
            {selected ? (
              <ArrowUp color={theme.cl_sec_d1} size={22} />
            ) : (
              <ArrowDown color={theme.cl_sec_d1} size={22} />
            )}
          </IconWrapper>
        </TitleWrapper>
        <Transition timeout={DURATION} in={selected} mountOnEnter unmountOnExit>
          {state => (
            <Fade
              state={state}
              height={height}
              textMargin={theme.space_4}
              data-testid="descriptionId"
            >
              <TextWrapper ref={assignHeight}>
                {typeof desc === 'string' ? (
                  <Text type="body">{desc}</Text>
                ) : (
                  desc
                )}
              </TextWrapper>
            </Fade>
          )}
        </Transition>
      </Wrapper>
      {dividerNeeded ? <Divider /> : null}
    </>
  );
};

Accordion.defaultProps = {
  onClick: noop
};

Accordion.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Accordion content
   */
  desc: PropTypes.any.isRequired,
  /**
   * Flag to show hide the divider in the Accordion.
   */
  dividerNeeded: PropTypes.bool,
  /**
   * click handler for the Accordion.
   */
  onClick: PropTypes.func,
  /**
   * Flag to show hide the `desc` in the Accordion.
   */
  selected: PropTypes.bool,
  /**
   * Accordion title
   */
  title: PropTypes.any.isRequired,
  /**
   * A unique value for the accordion.
   */
  value: PropTypes.number
};

export default Accordion;
