/**
 * Helper file for extracting values from the theme configuration.
 */

import styled, { css } from 'styled-components';
import React from 'react';
import { withTheme } from '../core/ThemeProvider';
import Text from '../components/Text';

const InteractionContainer = styled.div`
  position: relative;
  display: inline-block;
  line-height: 0;
  margin: 0;
  vertical-align: top;
  pointer-events: none;
  z-index: 0;
`;

export const Interaction = styled(
  withTheme(
    styled.div(
      ({ desktopSize, mobileSize, coreTheme }) => css`
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.1s ease-in;
        z-index: -1;

        padding: ${mobileSize || desktopSize}px;
        top: -${mobileSize || desktopSize}px;
        left: -${mobileSize || desktopSize}px;

        @media (min-width: ${coreTheme.brk_xs}) {
          padding: ${desktopSize}px;
          top: -${desktopSize}px;
          left: -${desktopSize}px;
        }
      `
    )
  )
)({});

/**
 * Wraps the component and provides touch/click interaction effect.
 * @param StyledComponent Styled component.
 * @param desktopSize size of the interaction for desktop. In px.
 * @param mobileSize size of the interaction for mobile. In px.
 * @return New component with the interaction effect
 *
 * ```jsx
 *  const YourComponent_NowWithInteractionEffects = withClearInteraction(YourComponent);
 *  <YourComponent_NowWithInteractionEffects ... />
 * ```
 */

export const withClearInteraction = (
  StyledComponent,
  desktopSize,
  mobileSize
) => {
  const StyledComponentWithInteraction = withTheme(styled(StyledComponent)`
    pointer-events: auto;

    ${p => css`
      :hover {
        + ${/* sc-selector */ Interaction} {
          background-color: ${p.enableInteraction
            ? p.coreTheme.cl_sec_l1
            : 'transparent'};
          opacity: 0.8;
        }
      }

      :active {
        + ${/* sc-selector */ Interaction} {
          background-color: ${p.enableInteraction
            ? p.coreTheme.cl_sec_l1
            : 'transparent'};
          opacity: 0.5;
        }
      }
    `};
  `);

  const NewComponent = ({ enableInteraction, ...rest }) => {
    return (
      <InteractionContainer>
        <StyledComponentWithInteraction
          {...rest}
          enableInteraction={enableInteraction}
        />
        <Interaction
          desktopSize={desktopSize}
          mobileSize={mobileSize}
          enableInteraction={enableInteraction}
        />
      </InteractionContainer>
    );
  };
  NewComponent.displayName =
    `${StyledComponent.displayName}__withClearInteraction` || null;
  return NewComponent;
};

export const getStyledText = (type, attrs) =>
  withStyledAttrs(Text, { type, ...attrs });

export const withStyledAttrs = (Component, attrs) =>
  typeof attrs === 'function'
    ? styled(Component).attrs(attrs)
    : styled(Component).attrs(() => attrs);
