import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from '../../helpers';
import Text from '../Text';
import HintText from '../../core/components/Forms/HintText';
import FormField from '../../core/components/Forms/FormField';
import FormInput from '../../core/components/Forms/FormInput';
import LabelText from '../../core/components/Forms/LabelText';
import FormFieldHint from '../../core/components/Forms/FormFieldHint';
import { withTheme } from '../../core/ThemeProvider';

class TextAreaInput extends Component {
  state = {
    charCount: 0
  };

  componentDidMount() {
    this.handleCharCount();
  }

  handleCharCount() {
    if (this.props.maxLength) {
      this.setState({
        charCount: this.textareaRef.value ? this.textareaRef.value.length : 0
      });
    }
  }

  render() {
    const {
      error,
      disabled,
      label,
      id,
      placeholder,
      hintMessage,
      maxLength,
      onChange,
      onFocus,
      onBlur,
      defaultValue,
      value,
      bgColor,
      ...rest
    } = this.props;

    return (
      <div {...rest}>
        <FormField
          htmlFor={id}
          disabled={disabled}
          error={error}
          defaultValue={defaultValue || value}
          data-testid="textarea-label-id"
          bgColor={bgColor}
        >
          <FormInput
            as="textarea"
            data-testid="inputField"
            rows="8"
            id={id}
            placeholder={placeholder || ' '}
            disabled={disabled}
            maxLength={maxLength}
            onChange={e => {
              onChange(e);
              this.handleCharCount();
            }}
            value={value}
            defaultValue={defaultValue}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={textarea => {
              this.textareaRef = textarea;
            }}
          />

          <LabelText>{label}</LabelText>
        </FormField>
        <MessageWrapper>
          {hintMessage && (
            <HintText error={error} data-testid="hintId">
              {hintMessage}
            </HintText>
          )}

          {maxLength && (
            <CharCount>
              <Text type="smallBody" data-testid="counterId">
                {this.state.charCount}/{maxLength}
              </Text>
            </CharCount>
          )}
        </MessageWrapper>
      </div>
    );
  }
}

TextAreaInput.defaultProps = {
  disabled: false,
  error: false,
  onChange: noop,
  bgColor: 'white'
};

TextAreaInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Background color of Input fields.
   * @default white
   */
  bgColor: PropTypes.oneOf(['haze', 'white']),
  /**
   * Default value set in the input element
   * when the component is rendered.
   */
  defaultValue: PropTypes.string,
  /**
   * Disable the element
   */
  disabled: PropTypes.bool,
  /**
   * Error State
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Hint message displayed under TextField
   */
  hintMessage: PropTypes.string,
  /**
   * Label
   */
  label: PropTypes.string.isRequired,
  /**
   * Maximum value length
   */
  maxLength: PropTypes.number,
  /**
   * On Change event
   */
  onChange: PropTypes.func,
  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Value of the input element.
   * Use this field when the component is used as
   * a fully controlled element
   */
  value: PropTypes.string
};

const MessageWrapper = styled(FormFieldHint)`
  display: flex;
`;

const CharCount = withTheme(styled.div`
  flex: auto;
  text-align: right;
  margin-left: ${p => p.coreTheme.space_3}px;
`);

export default TextAreaInput;
