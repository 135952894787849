import React, { useEffect, useState } from 'react';
import getComponentName from '../../helpers/getComponentName';
import debounce from '../utils/debounce';

/**
 *
 * @param debounceDuration
 * @return {{width: number, height: number}}
 */
export function useWindowSize({ debounceDuration = 0 } = {}) {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);
  let updateWindowSize = setWindowSize;

  if (debounceDuration) {
    updateWindowSize = debounce(setWindowSize, debounceDuration);
  }

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      updateWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export const withWindowSize = (Component, options) => {
  const WithWindowSize = React.forwardRef((props, ref) => {
    const windowSize = useWindowSize(options);

    return <Component {...props} ref={ref} windowSize={windowSize} />;
  });

  WithWindowSize.displayName = `WithWindowSize${getComponentName(Component)}`;

  return WithWindowSize;
};
