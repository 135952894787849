import React, { Component } from 'react';
import { Anchor, Heading, Grid, Column, Row } from '@lux/components';

import Paragraph from '../../components/Paragraph';
import Container from '../../components/Container';

class NoMatch extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Grid>
            <Row>
              <Column sm={12}>
                <Heading level={3}>Oops!</Heading>
                <Paragraph>
                  The page you requested could not be found. <br />
                  For more information, visit{' '}
                  <Anchor target="_blank" href="https://www.singtel.com/iphone">
                    www.singtel.com/iphone
                  </Anchor>
                </Paragraph>
              </Column>
            </Row>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default NoMatch;
