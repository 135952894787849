/*!
 * Group items from an array together by some criteria or value.
 *
 * @param  {Array} array      The array to group items from
 * @param  {String|Function} criteria The criteria to group by
 * @return {Object} The grouped object
 */
function groupBy(array, criteria) {
  return array.reduce(function(obj, item) {
    const key =
      typeof criteria === 'function' ? criteria(item) : item[criteria];
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = [];
    }
    obj[key].push(item);
    return obj;
  }, {});
}

export default groupBy;
