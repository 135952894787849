const allImages = {
  'iPhone 16': [
    ['Ultramarine', require('../assets/images/thumbnails/iphone16-ultramarine.png')],
    ['Teal', require('../assets/images/thumbnails/iphone16-teal.png')],
    ['Pink', require('../assets/images/thumbnails/iphone16-pink.png')],
    ['White', require('../assets/images/thumbnails/iphone16-white.png')],
    ['Black', require('../assets/images/thumbnails/iphone16-black.png')]
  ],
  'iPhone 16 Plus': [
    ['Ultramarine', require('../assets/images/thumbnails/iphone16plus-ultramarine.png')],
    ['Teal', require('../assets/images/thumbnails/iphone16plus-teal.png')],
    ['Pink', require('../assets/images/thumbnails/iphone16plus-pink.png')],
    ['White', require('../assets/images/thumbnails/iphone16plus-white.png')],
    ['Black', require('../assets/images/thumbnails/iphone16plus-black.png')]
  ],
  'iPhone 16 Pro': [
    ['Desert Titanium', require('../assets/images/thumbnails/iphone16pro-desert-titanium.png')],
    ['White Titanium', require('../assets/images/thumbnails/iphone16pro-white-titanium.png')],
    ['Natural Titanium', require('../assets/images/thumbnails/iphone16pro-natural-titanium.png')],
    ['Black Titanium', require('../assets/images/thumbnails/iphone16pro-black-titanium.png')]
  ],
  'iPhone 16 Pro Max': [
    ['Desert Titanium', require('../assets/images/thumbnails/iphone16promax-desert-titanium.png')],
    ['White Titanium', require('../assets/images/thumbnails/iphone16promax-white-titanium.png')],
    ['Natural Titanium', require('../assets/images/thumbnails/iphone16promax-natural-titanium.png')],
    ['Black Titanium', require('../assets/images/thumbnails/iphone16promax-black-titanium.png')]
  ]
};

function createDeviceImageConfig(deviceName) {
  const allDeviceImages = allImages[deviceName];
  return allDeviceImages.map(([colour, src]) => ({
    name: deviceName,
    colour,
    src
  }));
}

export const devices = {
  iphone16: {
    name: 'iPhone 16',
    description: 'iPhone 16',
    devices: {
      'iPhone 16': require('../assets/images/thumbnails/iphone16-ultramarine.png'),
      'iPhone 16 Plus': require('../assets/images/thumbnails/iphone16plus-ultramarine.png'),
      'iPhone 16 Pro': require('../assets/images/thumbnails/iphone16pro-desert-titanium.png'),
      'iPhone 16 Pro Max': require('../assets/images/thumbnails/iphone16promax-desert-titanium.png')
    },
    // List of all images for all the devices and their colors
    images: [
      ...createDeviceImageConfig('iPhone 16'),
      ...createDeviceImageConfig('iPhone 16 Plus'),
      ...createDeviceImageConfig('iPhone 16 Pro'),
      ...createDeviceImageConfig('iPhone 16 Pro Max')
    ],
    banner: require('../assets/images/banners/banner_iphone16_mass_800x400.png')
  },
  iphone16eshponline: {
    name: 'iPhone 16',
    description: 'iPhone 16',
    devices: {
      'iPhone 16': require('../assets/images/thumbnails/iphone16-ultramarine.png'),
      'iPhone 16 Plus': require('../assets/images/thumbnails/iphone16plus-ultramarine.png'),
      'iPhone 16 Pro': require('../assets/images/thumbnails/iphone16pro-desert-titanium.png'),
      'iPhone 16 Pro Max': require('../assets/images/thumbnails/iphone16promax-desert-titanium.png')
    },
    // List of all images for all the devices and their colors
    images: [
      ...createDeviceImageConfig('iPhone 16'),
      ...createDeviceImageConfig('iPhone 16 Plus'),
      ...createDeviceImageConfig('iPhone 16 Pro'),
      ...createDeviceImageConfig('iPhone 16 Pro Max')
    ],
    banner: require('../assets/images/banners/banner_iphone16_mass_800x400.png')
  },
  iphone16onlinee2ebrt: {
    name: 'iPhone 16',
    description: 'iPhone 16',
    devices: {
      'iPhone 16': require('../assets/images/thumbnails/iphone16-ultramarine.png'),
      'iPhone 16 Plus': require('../assets/images/thumbnails/iphone16plus-ultramarine.png'),
      'iPhone 16 Pro': require('../assets/images/thumbnails/iphone16pro-desert-titanium.png'),
      'iPhone 16 Pro Max': require('../assets/images/thumbnails/iphone16promax-desert-titanium.png')
    },
    // List of all images for all the devices and their colors
    images: [
      ...createDeviceImageConfig('iPhone 16'),
      ...createDeviceImageConfig('iPhone 16 Plus'),
      ...createDeviceImageConfig('iPhone 16 Pro'),
      ...createDeviceImageConfig('iPhone 16 Pro Max')
    ],
    banner: require('../assets/images/banners/banner_iphone16_mass_800x400.png')
  },
  mercurypentestbrt: {
    name: 'iPhone 16',
    description: 'iPhone 16',
    devices: {
      'iPhone 16': require('../assets/images/thumbnails/iphone16-ultramarine.png'),
      'iPhone 16 Plus': require('../assets/images/thumbnails/iphone16plus-ultramarine.png'),
      'iPhone 16 Pro': require('../assets/images/thumbnails/iphone16pro-desert-titanium.png'),
      'iPhone 16 Pro Max': require('../assets/images/thumbnails/iphone16promax-desert-titanium.png')
    },
    // List of all images for all the devices and their colors
    images: [
      ...createDeviceImageConfig('iPhone 16'),
      ...createDeviceImageConfig('iPhone 16 Plus'),
      ...createDeviceImageConfig('iPhone 16 Pro'),
      ...createDeviceImageConfig('iPhone 16 Pro Max')
    ],
    banner: require('../assets/images/banners/banner_iphone16_mass_800x400.png')
  },
  default: {
    banner: require('../assets/images/banners/banner_iphone16_mass_800x400.png')
  },
  allImages: []
};

export const defaultDevice = Object.keys(devices)[0];
