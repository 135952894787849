import PropTypes from 'prop-types';
import styled from 'styled-components';
import Config from '../../config';

const isNumber = val => {
  return val === parseInt(val, 10);
};

const getColumnWidthFromProps = props => {
  return Object.keys(props)
    .filter(propKey => ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(propKey) !== -1)
    .map(viewport => {
      // if prop is set to a fixed width (1-12)
      if (isNumber(props[viewport])) {
        return Config.media[viewport]`
            flex-basis: ${(100 / 12) * props[viewport]}%;
            max-width: ${(100 / 12) * props[viewport]}%;
            display: block;
          `;
      } else if (props[viewport]) {
        // else if prop is set to true (auto sizing)
        return Config.media[viewport]`
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
            display: block;
          `;
      } else {
        // else column is hidden
        return Config.media[viewport]`display: none`;
      }
    });
};

const getColumnOffsetFromProps = props => {
  return Object.keys(props)
    .filter(
      propKey =>
        ['xsOffset', 'smOffset', 'mdOffset', 'lgOffset', 'xlOffset'].indexOf(
          propKey
        ) !== -1
    )
    .map(
      viewport => Config.media[viewport.replace(/Offset$/, '')]`
        margin-left: ${(100 / 12) * props[viewport]}%;
      `
    );
};

const Column = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: ${Config.grid.gutterWidth / 2}rem ${Config.grid.gutterWidth / 2}rem;
  flex-direction: ${p => (p.reverse ? 'column-reverse' : 'column')};

  ${p => getColumnWidthFromProps(p)} ${p => getColumnOffsetFromProps(p)};
  ${p => p.noGutter && `padding: 0;`};
`;

Column.defaultProps = {
  xs: true
};

Column.propTypes = {
  /** Reverse flex direction */
  reverse: PropTypes.bool,
  noGutter: PropTypes.bool,
  /**
   * When integer value, it specify the column size on the grid.
   * When true, enable auto sizing column.
   * When false, hide column for the breakpoint.
   */
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number
};

export default Column;
