/**
 * Available format types.
 * extend this list to support new formats.
 * @type {{<format_name>: <mask Array>}}>}
 *
 * @see https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#mask
 */
export default {
  /* dd / mm / yyyy */
  date: [
    /\d/,
    /\d/,
    ' ',
    '/',
    ' ',
    /\d/,
    /\d/,
    ' ',
    '/',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],

  /* xxxx xxxx xxxx xxxx */
  'credit-card': [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  /* +65 9201 1234 */
  'mobile-sg': [
    '+',
    '6',
    '5',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]
};
