import styled, { css } from 'styled-components';

const Fade = styled.span`
  transition: opacity 0.4s;
  ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return css`
          opacity: 0;
        `;
      case 'entered':
      case 'exiting':
        return css`
          opacity: 1;
        `;
    }
  }}
`;

export default Fade;
