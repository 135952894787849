/* stylelint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BreadcrumbItem, { BreadcrumbText } from './BreadcrumbItem';
import { testIdProps } from '../../helpers';
import theme from '../../theme';

const renderChildren = (children, itemsBeforeTruncate, itemsAfterTruncate) => {
  const allItems = React.Children.toArray(children);
  if (itemsBeforeTruncate + itemsAfterTruncate >= allItems.length) {
    return allItems;
  }

  const beforeItems = allItems.slice(0, itemsBeforeTruncate);
  const afterItems = allItems.slice(
    allItems.length - itemsAfterTruncate,
    allItems.length
  );

  return [
    ...beforeItems,
    <BreadcrumbItem key="ellipsis" className="ellipsis">
      ...
    </BreadcrumbItem>,
    ...afterItems
  ];
};

const StyledBreadcrumb = styled.div`
  ${BreadcrumbText} {
    color: ${theme.main.colours.tertiary.l3};
  }

  > :last-child {
    ${BreadcrumbText} {
      font-weight: bold;
      color: ${theme.main.colours.tertiary.base};
    }

    .separator {
      display: none;
    }
  }

  > :not(:last-child):not(.ellipsis) {
    ${BreadcrumbText} {
      :hover {
        color: ${theme.main.colours.tertiary.base};
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
`;

const Breadcrumb = ({
  children,
  itemsBeforeTruncate,
  itemsAfterTruncate,
  ...rest
}) => {
  return (
    <StyledBreadcrumb {...testIdProps(rest)}>
      {renderChildren(children, itemsBeforeTruncate, itemsAfterTruncate)}
    </StyledBreadcrumb>
  );
};

Breadcrumb.defaultProps = {
  itemsBeforeTruncate: 1,
  itemsAfterTruncate: 3
};

Breadcrumb.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Breadcrumb items
   */
  children: PropTypes.any.isRequired,
  /**
   * If max items is exceeded, the number of items to show after the ellipsis.
   */
  itemsAfterTruncate: PropTypes.number,
  /**
   * If max items is exceeded, the number of items to show before the ellipsis.
   */
  itemsBeforeTruncate: PropTypes.number
};

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
