import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import Config from '../../config';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const CarouselWrapper = withTheme(
  styled.div(
    props => css`
      width: 100%;
      overflow: ${props.type === 'card' ? 'scroll' : 'hidden'};
      ${Config.media.sm`
        overflow: hidden;
      `};
      /* stylelint-disable property-no-unknown */
      scrollbar-width: none; /* Firefox */
      ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Edge */
      }
      border-radius: ${props.type === 'image' &&
        !props.fullWidth &&
        `${props.coreTheme.rad_xl}`};
    `
  )
);

export const CarouselContainer = styled.div`
  display: flex;
  padding: ${props => props.type === 'card' && '7px'};
  transition: transform 0.8s ease;
  transform: ${props =>
    props.type === 'card' ? 'none' : `translateX(-${props.current * 100}%)`};
  ${Config.media.sm`
    transform: ${props => `translateX(-${props.current * 100}%)`};
    `};
`;

export const CarouselSlot = styled.div`
  margin-right: ${props => props.type === 'card' && '16px'};

  &:last-child {
    padding-right: ${props => props.type === 'card' && '15px'};
    ${Config.media.sm`
      padding-right: 0;
    `}
  }
  width: ${props => (props.type === 'image' ? '100%' : '70%')};
  ${Config.media.sm`
    width: ${props =>
      props.type === 'card' &&
      `calc((100% - calc(15px * ${props.pagination - 1} )) / ${
        props.pagination
      })`};
  `}
  flex-shrink: 0;
`;

export const ArrowButton = withTheme(
  styled.div(
    props => css`
      display: none;
      ${Config.media.sm`
        display: flex;
      `};

      visibility: ${props.showArrow ? 'visible' : 'hidden'};
      position: ${props.inside && 'absolute'};
      cursor: pointer;
      padding: 9px;
      border-radius: 100%;
      background-color: ${props.coreTheme.cl_ter_l5};
      z-index: ${props.inside && '10'};
      top: ${props.inside && '50%'};
      transform: ${props.inside && 'translateY(-50%)'};
      opacity: ${props.inside && '1'};
      ${props.inside && props.left && 'left: calc(2.8% + 1px);'};
      ${props.inside && props.right && 'right: calc(2.8% + 1px);'};
    `
  )
);

export const OutsideArrowButtonWrapper = styled.div`
  display: none;
  ${Config.media.sm`
    display: flex;
    width: 40px;
    align-items: center;
    ${props => props.left && 'margin-right: 14px;'}
    ${props => props.right && 'margin-left: 14px;'}
  `};
`;

export const PaginationPanel = styled.div`
  display: ${props => (props.type === 'card' ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  ${Config.media.sm`
    display: flex;
    margin-top: 16px;
  `};
`;

export const PaginationDot = withTheme(
  styled.div(
    props => css`
      cursor: pointer;
      width: ${props.active ? '24px' : '8px'};
      height: 8px;
      ${Config.media.sm`
        width: ${props.active ? '36px' : '12px'};
        height: 12px;
      `}
      margin: 0 6px;
      border-radius: ${props.active ? '15px' : '100%'};
      background: ${props.active
        ? `${props.coreTheme.cl_ter_l3}`
        : `${props.coreTheme.cl_ter_l5}`};
      vertical-align: ${props.active ? 'text-top' : 'unset'};
    `
  )
);
