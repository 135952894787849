import merge from 'lodash/merge';
import { flattenTheme } from './utils';
import { withOpacity } from '../helpers';

export const breakpoints = ['48em', '62em', '75em', '100em', '1000em'];

breakpoints.xs = breakpoints[0]; // 768
breakpoints.sm = breakpoints[1]; // 992
breakpoints.md = breakpoints[2]; // 1200
breakpoints.lg = breakpoints[3]; // 1600
breakpoints.xl = breakpoints[4]; // 16000

export const baseTheme = {
  breakpoints,
  space: [
    0, // space_0
    4, // space_1
    8, // space_2
    12, // space_3
    16, // space_4
    20, // space_5
    24, // space_6
    28, // space_7
    32, // space_8
    36, // space_9
    40 // space_10
  ],
  borderRadius: {
    xs: `8px`,
    sm: `10px`, // Marked for removal
    md: `12px`, // Marked for removal
    lg: `14px`, // Marked for removal
    xl: `16px`,
    xxl: `32px`
  },
  main: {
    fontFamily: {
      primaryThin: `Museo-100, Georgia, Times New Roman, Times, serif`,
      primaryRegular: `Museo-300, Georgia, Times New Roman, Times, serif`,
      primaryBold: `Museo-500, Georgia, Times New Roman, Times, serif`,
      secondaryThin: `AvenirLTStd-Book, Arial, Helvetica, sans-serif`,
      secondaryRegular: `AvenirLTStd-Medium, Arial, Helvetica, sans-serif`,
      secondaryBold: `AvenirLTStd-Heavy, Arial, Helvetica, sans-serif`
    },
    fontSizes: {
      xs: `12px`,
      sm: `14px`,
      md: `16px`,
      lg: `18px`,
      xl: `20px`,
      xxl: `22px`
    },
    colours: {
      white: '#FFFFFF',
      black: '#000000',

      /** Semantic colors used for signal severity */
      // Red for error, danger, wrong.
      errorRed: '#BC1430',

      // Green for success, safe, right
      successGreen: '#0CAA8F',

      // Divider color
      divider: '#D0D0D0',

      /** Colors Palette for DLS */
      primary: {
        l3: '#FCEAED',
        l2: '#FF7C92',
        l1: '#FF5D76',
        base: '#ED1A3D',
        d1: '#E71336',
        d2: '#CA112F',
        d3: '#BC1430',
        d4: '#6D0819'
      },
      secondary: {
        // purple
        l2: '#EFEDFD',
        l1: '#DEDAFA',
        base: '#9A8EF2',
        d1: '#5C50BB',
        d2: '#5457A0',
        d3: '#151560'
      },
      tertiary: {
        // neutral
        l6: '#F7F7F7',
        l5: '#E1E1E1',
        l4: '#A8A8A8',
        l3: '#757575',
        l2: '#565656',
        l1: '#3B3B3B',
        base: '#222222',
        d1: '#121212'
      }
    },
    shadows: {
      size1: '0 3px 8px 0 rgba(0 0 0 / 0.12)',
      size2: '0 6px 16px 0 rgba(0 0 0 / 0.10)'
    }
  }
};

export const updateTheme = (baseTheme, newTheme) => {
  let colours = null;

  if (newTheme?.main?.colours) {
    colours = merge({}, baseTheme.main.colours, newTheme.main.colours);
  }

  //returning the updated theme
  return {
    ...baseTheme,
    main: {
      ...baseTheme.main,
      colours: colours ? colours : baseTheme.main.colours
    }
  };
};

export const getDefalutTheme = newTheme => {
  const flatTheme = flattenTheme(updateTheme(baseTheme, newTheme));

  const componentsTheme = {
    accordion: {
      margin: `${flatTheme.space_4}px 0`,
      mdMargin: `${flatTheme.space_4}px 0`,
      iconSize: '16px',
      mdIconSize: '22px'
    },
    button: {
      minWidth: '128px',
      minHeight: '40px',
      border: '1px solid transparent',
      borderRadius: flatTheme.rad_xl,
      padding: `${flatTheme.space_2}px ${flatTheme.space_6}px`,
      background: flatTheme.cl_pri_base,
      hoverBg: flatTheme.cl_pri_d2,
      activeBg: flatTheme.cl_pri_d4,
      mdMinHeight: '46px',
      secondary: {
        background: flatTheme.cl_sec_l2,
        borderColor: '1px solid transparent',
        color: flatTheme.cl_sec_d1,
        hoverBg: flatTheme.cl_sec_l1,
        activeBg: flatTheme.cl_sec_base
      },
      small: {
        fontSize: '16px',
        minHeight: '32px',
        minWidth: '120px',
        padding: `4px 16px`,
        borderWidth: '1px',
        borderRadius: '12px',
        md: {
          minHeight: '32px',
          minWidth: '120px',
          padding: `4px 16px`
        }
      }
    },
    pagination: {
      fontSize: '0.875rem',
      marginTop: '16px',
      button: {
        fontFamily: flatTheme.ff_sec_regular,
        color: flatTheme.cl_ter_l2,
        backgroundColor: 'transparent',
        hoverBg: flatTheme.cl_sec_l2,
        borderRadius: '16px',
        height: '32px',
        minWidth: '32px',
        margin: '0 3px',
        padding: '0 6px',
        selected: {
          color: flatTheme.cl_white,
          backgroundColor: flatTheme.cl_sec_d1
        },
        svg: {
          width: '16px',
          height: '16px'
        }
      }
    },
    tab: {
      bottomBorderColor: flatTheme.cl_ter_l5,
      // use to indicate divider position
      tabBarDividerPositionBottom: 0,
      // tab title & subtitle selected color
      tabItemContainerPadding: `${flatTheme.space_4}px ${flatTheme.space_6}px ${flatTheme.space_2}px`,
      // tab title & subtitle selected color
      selectedTabTitleColor: flatTheme.cl_sec_d1,
      // tab title & subtitle unselected color
      tabTitleColor: flatTheme.cl_ter_base,
      // tab item container hover background
      tabItemContainerHoverBg: withOpacity(flatTheme.cl_sec_l1, 0.8),
      // tab item container active background
      tabItemContainerActiveBg: withOpacity(flatTheme.cl_white, 0.5),
      // tab item container background
      tabItemContainerBg: flatTheme.cl_white,
      // tab item indicator background
      tabItemIndicatorBgColor: flatTheme.cl_sec_d1,
      // tab item divider background
      tabBarDividerBgColor: flatTheme.cl_ter_l5
    },
    listItem: {
      leftContent: {
        backgroundColor: flatTheme.cl_ter_l6,
        minHeight: '80px',
        minWidth: '80px',
        image: {
          height: '48px',
          width: '48px'
        },
        thumbnail: {
          height: '104px',
          width: '104px'
        },
        icon: {
          size: '32px',
          color: flatTheme.cl_sec_d1
        },
        tag: {
          backgroundColor: flatTheme.cl_pri_d2,
          height: '20px',
          fontSize: [12, 12, 14, 14, 14],
          color: flatTheme.cl_white,
          lineHeight: '20px',
          letterSpacing: '0.002em',
          fontFamily: flatTheme.ff_sec_bold,
          padding: '0 16px'
        }
      },
      bodyContent: {
        title: {
          color: flatTheme.cl_ter_l1,
          lineHeight: '24px',
          mdLineHeight: '30px'
        },
        subTitle: {
          color: flatTheme.cl_ter_l3,
          lineHeight: '24px',
          mdLineHeight: '28px'
        },
        points: {
          icon: {
            size: '24px',
            color: flatTheme.cl_sec_d1
          }
        }
      }
    },
    header: {
      desktopHeight: '116px',
      mobileHeight: '64px',
      borderTop: `4px solid ${flatTheme.cl_pri_base}`,
      boxShadow: flatTheme.sd_size_1,
      deskTopRHSMenuFontColor: flatTheme.cl_black,
      deskTopRHSMenuActiveFont: flatTheme.ff_sec_bold,
      deskTopRHSMenuInactiveFont: flatTheme.ff_sec_thin,
      deskTopLogoHeight: '48px',
      deskTopIconHeight: '40px',
      deskTopIconWidth: '40px',
      mobileIconHeight: '24px',
      mobileIconWidth: '24px',
      iconMenuFont: flatTheme.ff_sec_thin,
      iconMenuFontColor: flatTheme.cl_black,
      iconMenuActiveFontColor: flatTheme.cl_pri_base,
      desktopMenuFontSize: flatTheme.fs_md,
      desktopMenuActiveFontColor: flatTheme.cl_pri_base,
      desktopMenuInActiveFontColor: flatTheme.cl_black,
      desktopSubMenuFontSize: flatTheme.fs_md,
      mobileMenuFontSize: flatTheme.fs_sm,
      mobileMenuFontColor: flatTheme.cl_black,
      mobileMenuActiveFontColor: flatTheme.cl_pri_base,
      search: {
        searchInputFont: flatTheme.ff_sec_thin,
        searchInputFontColor: flatTheme.cl_black,
        searchInputPlaceholderFontColor: flatTheme.cl_ter_l4
      }
    },
    footer: {
      footerPage: {
        headingFont: flatTheme.ff_sec_bold,
        headingFontSize: flatTheme.fs_sm,
        headingFontColor: flatTheme.cl_black,
        itemFont: flatTheme.ff_sec_thin,
        itemFontSize: flatTheme.fs_sm,
        itemFontColor: flatTheme.cl_ter_l4
      },
      dividerBorder: `1px solid ${flatTheme.cl_ter_l4}`,
      linkFont: flatTheme.ff_sec_bold,
      linkFontColor: flatTheme.cl_ter_l4,
      linkFontSize: flatTheme.fs_xs,
      copyRightFont: flatTheme.ff_sec_thin,
      copyRightFontSize: flatTheme.fs_xs,
      copyRightFontColor: flatTheme.cl_ter_l4,
      footerTextFont: flatTheme.ff_sec_thin,
      footerTextFontSize: flatTheme.fs_xs,
      footerTextFontColor: flatTheme.cl_ter_l4,
      footerTextFontSpacing: '0.25px'
    },
    map: {
      mapTerrain: {
        waterGeometry: flatTheme.cl_ter_l5,
        landscapeGeometry: flatTheme.cl_ter_l6,
        roadHighwayGeometryFill: flatTheme.cl_white,
        roadHighwayGeometryStroke: flatTheme.cl_white,
        roadArterialGeometry: flatTheme.cl_white,
        roadLocalGeometry: flatTheme.cl_white,
        poiGeometry: flatTheme.cl_ter_l6,
        poiParkGeometry: flatTheme.cl_ter_l5,
        labelsTextStroke: flatTheme.cl_white,
        labelsTextFill: flatTheme.cl_ter_l4,
        transitGeometry: flatTheme.cl_ter_l6,
        administrativeGeometryFill: flatTheme.cl_ter_l6,
        administrativeGeometryStroke: flatTheme.cl_ter_l6
      },
      marker: {
        labelTextShadow: `1px 1px 1px ${flatTheme.cl_white}`,
        labelLineHeight: '16px',
        labelLetterSpacing: '0.2px',
        labelFontSize: flatTheme.fs_xs,
        labelColor: flatTheme.cl_black,
        labelFontFamily: flatTheme.ff_sec_regular
      }
    },
    table: {
      backgroundColor: {
        hover: flatTheme.cl_sec_l2,
        active: flatTheme.cl_sec_l2
      }
    }
  };

  return {
    ...flatTheme,
    ...componentsTheme
  };
};

/**
 * Flattened `coreTheme` props. To avoid prop drilling.
 * Internal object structure used in components.
 */
export const defaultTheme = getDefalutTheme();

export default baseTheme;
