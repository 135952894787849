import styled, { css } from 'styled-components';
import { Column, Grid } from '../../components';
import Config from '../../config';

/**
 * Additional prop `noGutterY` added to
 * remove vertical gutter space in the grid
 *
 * TODO: Add this enhancement in the Grid component.
 */
const NoGutterYGrid = styled(Grid)`
  ${Config.media.md`
      padding: ${p =>
        p.noGutterY
          ? `0 ${Config.grid.outerMargin / 2}rem`
          : `${Config.grid.outerMargin / 2}rem`};
    `};
  ${p =>
    p.noGutterY &&
    css`
      padding-top: 0;
      padding-bottom: 0;

      ${/* sc-selector */ Column} {
        padding-top: 0;
        padding-bottom: 0;
      }
    `}
`;

export default NoGutterYGrid;
