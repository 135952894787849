// native smooth scrolling for Chrome, Firefox & Opera
// @see: https://caniuse.com/#feat=css-scroll-behavior

const nativeSmoothScrollElement = (element, options) => {
  element.scrollTo(options);
};

// polyfilled smooth scrolling for IE, Edge & Safari
const smoothScrollElement = (element, options) => {
  const isScrollingHorizontal = options.left !== undefined;
  const duration = options.duration || 400;
  const start = isScrollingHorizontal ? element.scrollLeft : element.scrollTop,
    to = isScrollingHorizontal ? options.left : options.top,
    change = to - start,
    startDate = +new Date();

  // t = current time (ms)
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    return (c / 2) * t * t + b;
  };

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    if (isScrollingHorizontal) {
      element.scrollLeft = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
    } else {
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
    }
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      if (isScrollingHorizontal) {
        element.scrollLeft = to;
      } else {
        element.scrollTop = to;
      }
    }
  };
  animateScroll();
};

export const scrollElement = (element, options) => {
  if (!element) {
    return;
  }

  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style;

  if (supportsNativeSmoothScroll) {
    nativeSmoothScrollElement(element, options);
  } else {
    smoothScrollElement(element, options);
  }
};
