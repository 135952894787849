import { useState, useEffect, useCallback } from 'react';

/**
 * @param {object} sortModel pre-config for sorted column
 * @param {function} onSortChange on sort button click callback
 * @param {object[]} columns column config
 * @return {{
 *  onSortClick: function,
 *  getSortIconColor: function
 * }}
 */

const sortingOrder = ['asc', 'desc', null];

const useSorting = ({ sortModel, onSortChange, columns }) => {
  const [sortingModels, setSortingModels] = useState({});

  useEffect(() => {
    const result = {};
    //create internal sortingModels based on sortModel
    columns.forEach(column => {
      if (!column.sortable) return;
      const model = (sortModel || []).find(item => item.field === column.key);
      const isValidSort = sortingOrder.includes(model?.sort);
      //give default value if current sortModel is invalid
      result[column.key] = isValidSort
        ? model
        : { field: column.key, sort: null };
    });
    setSortingModels(result);
  }, [columns, sortModel]);

  //convert sortingModels object into array
  const getSortModel = modelList =>
    Object.keys(modelList).map(modelKey => modelList[modelKey]);

  const onSortClick = useCallback(
    column => {
      if (!column.sortable) return;
      const result = { ...sortingModels };
      //get current sorting status index from sortingOrder
      const sortIndex = sortingOrder.findIndex(
        item => item === sortingModels[column.key]?.sort
      );
      //get next sort status from sortingOrder
      let newSort;
      if (sortIndex < 0 || sortIndex === sortingOrder.length - 1) {
        //if sortIndex does not exist(which means this column has not been sorted yet)
        //or this is the last sorting status(null)
        //set newSort to the first sorting status(asc)
        newSort = sortingOrder[0];
      } else {
        newSort = sortingOrder[sortIndex + 1];
      }
      //update sorting status
      result[column.key].sort = newSort;
      setSortingModels(result);

      onSortChange({
        sortModel: getSortModel(result),
        currentModel: result[column.key],
        columns
      });
    },
    [sortingModels, columns]
  );

  return { onSortClick, sortingModels };
};

export default useSorting;
