import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from '../../helpers';
import { Hide as HideIcon, Show as ShowIcon } from '@dls/assets/icons';
import FormField from '../../core/components/Forms/FormField';
import FormInput from '../../core/components/Forms/FormInput';
import HintText from '../../core/components/Forms/HintText';
import FormFieldHint from '../../core/components/Forms/FormFieldHint';
import LabelText from '../../core/components/Forms/LabelText';
import { useTheme } from '../../core/ThemeProvider';

const InputPassword = props => {
  const [toggle, setToggle] = useState(true);

  const onToggleView = useCallback(() => setToggle(toggle => !toggle), []);

  const handleMouseDown = useCallback(e => {
    // TODO Test preventDefault was fired..
    /**
     * Cannot make assertions.
     * Something under the hood is stopping the mock `preventDefault`
     * function to be passed down to the callback.
     * */

    e.preventDefault();
  }, []);

  const {
    error,
    id,
    name,
    defaultValue,
    value,
    onChange,
    disabled,
    label,
    placeholder,
    hintMessage,
    maxLength,
    bgColor,
    autoComplete,
    ...rest
  } = props;

  const { theme } = useTheme();

  return (
    <div {...rest}>
      <FormField
        disabled={disabled}
        defaultValue={defaultValue || value}
        error={error}
        bgColor={bgColor}
      >
        <InputContainer>
          <FormInput
            data-testid="inputField"
            id={id}
            name={name}
            placeholder={placeholder || ' '}
            error={error}
            type={toggle ? 'password' : 'text'}
            onChange={onChange}
            value={value}
            maxLength={maxLength}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
          />
        </InputContainer>
        <LabelText>{label}</LabelText>
        <InputAdornment>
          <IconHotSpot
            onMouseDown={handleMouseDown}
            onClick={onToggleView}
            data-testid="toggleView"
          >
            {toggle ? (
              <HideIcon size={24} color={theme.cl_sec_d1} />
            ) : (
              <ShowIcon size={24} color={theme.cl_sec_d1} />
            )}
          </IconHotSpot>
        </InputAdornment>
      </FormField>
      {hintMessage && (
        <FormFieldHint>
          <HintText mt={3} error={error}>
            {hintMessage}
          </HintText>
        </FormFieldHint>
      )}
    </div>
  );
};

InputPassword.defaultProps = {
  disabled: false,
  error: false,
  onChange: noop,
  bgColor: 'white'
};

InputPassword.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Specifies whether autocomplete is applied to an editable text field.
   */
  autoComplete: PropTypes.string,
  /**
   * Background color of Input fields.
   * @default white
   */
  bgColor: PropTypes.oneOf(['haze', 'white']),
  /**
   * Default value set in the input element
   * when the component is rendered.
   */
  defaultValue: PropTypes.string,
  /**
   * Disable the element
   */
  disabled: PropTypes.bool,
  /**
   * Error State
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Hint message displayed under the
   */
  hintMessage: PropTypes.string,
  /**
   * `id` field
   */
  id: PropTypes.string,
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * Maximum value length
   */
  maxLength: PropTypes.number,
  /**
   * Change event handler
   */
  onChange: PropTypes.func,
  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Value of the input element.
   * Use this field when the component is used as
   * a fully controlled element
   */
  value: PropTypes.string
};

const InputContainer = styled.div`
  padding-right: 40px;
`;

const IconHotSpot = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 8px;
  line-height: 0;
`;

const InputAdornment = styled.div`
  position: absolute;
  right: 16px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

export default InputPassword;
