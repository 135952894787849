import styled from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { withStyledAttrs } from '../../helpers/styled';
import Text from '../Text';
import Config from '../../config';

export const Wrapper = withTheme(styled.div`
  margin: ${p => p.coreTheme.accordion.margin};

  ${Config.media.md`
    margin: ${p => p.coreTheme.accordion.mdMargin};
  `};
`);

export const TitleWrapper = withTheme(styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`);

export const IconWrapper = withTheme(styled.div`
  align-self: flex-start;
  margin-top: ${p => p.coreTheme.space_2}px;

  svg {
    width: ${p => p.coreTheme.accordion.iconSize};
    height: ${p => p.coreTheme.accordion.iconSize};
    stroke: ${p => p.coreTheme.cl_sec_d1};
  }
  ${Config.media.md`
    svg {
      width: ${p => p.coreTheme.accordion.mdIconSize};
      height: ${p => p.coreTheme.accordion.mdIconSize};
      stroke: none;
    }
  `};
`);

export const TitleText = withTheme(
  withStyledAttrs(Text, { type: 'boldBody' })`
    color: ${p => p.coreTheme.cl_ter_base};
    margin-right: ${p => p.coreTheme.space_4}px;

    ${Config.media.md`
      margin-right: ${p => p.coreTheme.space_6}px;
    `};
  `
);

export const TextWrapper = withTheme(
  styled.div`
    color: ${p => p.coreTheme.cl_ter_base};
    font-family: ${p => p.coreTheme.ff_sec_thin};
  `
);
