import styled, { css } from 'styled-components';
import Grid from '../Grid';
import Text from '../Text';
import Config from '../../config';
import { withTheme } from '../../core/ThemeProvider';
import { withStyledAttrs } from '../../helpers/styled';

export const Content = styled.div`
  width: auto;
`;

export const AnimationWrapper = styled.div`
  z-index: ${p => p.zIndex};
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const NoPaddingGrid = styled(Grid)`
  padding-top: 0;
  padding-bottom: 0;

  ${Config.media.md`
    padding: 0px;
  `};
`;

export const ToastComponent = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      pointer-events: auto;
      box-sizing: border-box;
      position: absolute;
      background-color: ${coreTheme.cl_ter_l1};
      bottom: 16px;
      border-radius: ${coreTheme.rad_xl};
      width: 100%;
      height: 80px;
      display: flex;
      padding: 16px;

      ${Config.media.sm`
        bottom: 40px;
        padding: 25px;
      `};
    `
  )
);

export const IconWrapper = styled.div`
  align-self: center;
  height: 24px;
  margin-right: 16px;

  ${Config.media.sm`
    margin-right: 24px;
  `};
`;

export const Title = withTheme(
  withStyledAttrs(Text, { type: 'body' })(
    ({ coreTheme }) => css`
      /* stylelint-disable value-no-vendor-prefix */
      display: -webkit-box;
      text-overflow: ellipsis;
      flex: 1;
      text-align: left;
      overflow: hidden;
      align-self: center;
      color: ${coreTheme.cl_ter_l6};
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      ${Config.media.sm`
        -webkit-line-clamp: 1;
      `};
    `
  )
);

export const Action = withTheme(
  withStyledAttrs(Text, { type: 'link' })(
    ({ coreTheme }) => css`
      align-items: center;
      color: ${coreTheme.cl_sec_l2};
      cursor: pointer;
      display: inline-flex;
      margin-left: 16px;

      ${Config.media.sm`
        margin-left: 24px;
      `};
    `
  )
);
