/* stylelint-disable */
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { testIdProps } from '../../../helpers';
import { scrollElement } from '../../../helpers/smoothScrollingPolyfill';
import { TabBottomBorder } from './TabBar';
import { withTheme } from '../../../core/ThemeProvider';
import Text from '../../Text';
const TabItem = ({
  title,
  subTitle,
  icon,
  tabItemWeight,
  tabContext,
  ...rest
}) => {
  const containerRef = useRef();
  const hasIcon = !!icon;
  const showSubtitle = subTitle && !hasIcon;
  const showIcon = hasIcon && !subTitle;

  /* Props from tab context */
  const {
    index,
    isSelected,
    onItemClick,
    isMobile,
    windowSize,
    indicatorEl,
    tabsContainerEl,
    secondary
  } = tabContext || {};

  const animateTabIndicator = selectedTab => {
    indicatorEl.style.width = `${selectedTab.offsetWidth}px`;
    indicatorEl.style.left = `${selectedTab.offsetLeft}px`;
    indicatorEl.style.transition = 'width 0.3s, left 0.3s';
  };

  const scrollToSelectedTabIfNeeded = selectedTab => {
    const containerWidth = tabsContainerEl.offsetWidth;
    const selectedTabLeft = selectedTab.getBoundingClientRect().left;
    const selectedTabWidth = selectedTab.offsetWidth;
    const arrowPadding = isMobile ? 0 : 20;

    if (selectedTabLeft < 0) {
      scrollElement(tabsContainerEl, {
        left: selectedTab.offsetLeft - arrowPadding,
        behavior: 'smooth'
      });
    } else if (containerWidth < selectedTabLeft + selectedTabWidth) {
      scrollElement(tabsContainerEl, {
        top: 0,
        left:
          selectedTab.offsetLeft +
          selectedTabWidth -
          containerWidth +
          arrowPadding,
        behavior: 'smooth'
      });
    }
  };

  const onClick = e => {
    onItemClick && onItemClick(e, index);
  };

  useEffect(() => {
    if (isSelected) {
      indicatorEl &&
        containerRef.current &&
        animateTabIndicator(containerRef.current);
      tabsContainerEl &&
        containerRef.current &&
        scrollToSelectedTabIfNeeded(containerRef.current);
    }
  }, [indicatorEl, isSelected, containerRef, windowSize]);

  return (
    <TabItemContainer
      isSelected={isSelected}
      onClick={onClick}
      tabItemWeight={tabItemWeight}
      ref={containerRef}
      secondary={secondary}
      {...testIdProps(rest)}
    >
      <TabItemBottomBorder secondary={secondary} />
      {showIcon && (
        <TabIconContainer data-testid={'tabItemIcon'}>
          <TabIcon isSelected={isSelected} icon={icon} />
        </TabIconContainer>
      )}
      {title && (
        <TabItemTitle
          type={isSelected ? 'boldBody' : 'body'}
          isSelected={isSelected}
          lineHeight="compact"
          data-text={title}
          data-testid={isSelected ? 'tabItemTitleSelected' : 'tabItemTitle'}
          secondary={secondary}
        >
          {title}
        </TabItemTitle>
      )}
      {showSubtitle && (
        <TabItemSubTitle
          isSelected={isSelected}
          type="smallBody"
          lineHeight="compact"
          data-testid={'tabItemSubTitle'}
          secondary={secondary}
        >
          {subTitle}
        </TabItemSubTitle>
      )}
    </TabItemContainer>
  );
};

export const TabItemPropTypes = {
  icon: PropTypes.node,
  /**
   * Depreacted: icon name
   */
  iconName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string
};

TabItem.propTypes = TabItemPropTypes;

export default TabItem;

const TabItemBottomBorder = withTheme(
  styled(TabBottomBorder)(
    ({ coreTheme, secondary }) =>
      css`
        background-color: ${coreTheme.tab.bottomBorderColor};
        ${secondary &&
          css`
            background-color: ${coreTheme.tab.secondary?.bottomBorderColor};
          `}
      `
  )
);

const TabItemTitle = withTheme(
  styled(Text)(
    ({ coreTheme, isSelected, secondary }) => css`
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: ${coreTheme.tab.fontSize};
      font-weight: ${coreTheme.tab.fontWeight};
      font-family: ${coreTheme.tab.fontFamily};
      color: ${isSelected
        ? coreTheme.tab.selectedTabTitleColor
        : coreTheme.tab.tabTitleColor};

      ::after {
        display: block;
        height: 1px;
        content: attr(data-text);
        visibility: hidden;
        overflow: hidden;
      }
      ${secondary &&
        css`
          color: ${isSelected
            ? coreTheme.tab.secondary?.selectedTabTitleColor
            : coreTheme.tab.secondary?.tabTitleColor};
        `}
    `
  )
);

const TabItemSubTitle = withTheme(
  styled(Text)(
    ({ coreTheme, isSelected, secondary }) => css`
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: ${coreTheme.tab.fontSize};
      font-weight: ${coreTheme.tab.fontWeight};
      font-family: ${coreTheme.tab.fontFamily};
      margin-top: 2px;
      color: ${isSelected
        ? coreTheme.tab.selectedTabTitleColor
        : coreTheme.tab.tabTitleColor};
      ${secondary &&
        coreTheme.tab.secondary &&
        css`
          color: ${isSelected
            ? coreTheme.tab.secondary?.selectedTabTitleColor
            : coreTheme.tab.secondary?.tabTitleColor};
        `}
    `
  )
);

const TabItemContainer = withTheme(
  styled.div(
    ({ coreTheme, isSelected, tabItemWeight, secondary }) => css`
			position: relative;
			min-height: ${
        coreTheme.tab.tabItemMinHeight ? coreTheme.tab.tabItemMinHeight : '48px'
      };
      min-width: ${coreTheme.tab.tabItemMinWidth};
			box-sizing: border-box;
			padding: ${coreTheme.tab.tabItemContainerPadding};
			white-space: nowrap;
			display: table-cell;
			transition: background 0.2s ease-out;
			cursor: pointer;
			user-select: none;
      width: '${tabItemWeight}%';
      background-color: ${
        isSelected
          ? coreTheme.tab.tabItemContainerActiveBg
          : coreTheme.tab.tabItemContainerBg
      };

      ${secondary &&
        css`
          background-color: ${coreTheme.tab.secondary?.tabItemContainerBg};
        `}

			&:hover {
				background-color: ${coreTheme.tab.tabItemContainerHoverBg};
        ${secondary &&
          css`
            background-color: ${coreTheme.tab.secondary
              ?.tabItemContainerHoverBg};
          `}
			}
			:first-child {
				border-top-left-radius: ${coreTheme.tab.borderRadius};
				border-bottom-left-radius: ${coreTheme.tab.borderRadius};
			}
			:last-child {
				border-top-right-radius: ${coreTheme.tab.borderRadius};
				border-bottom-right-radius: ${coreTheme.tab.borderRadius};
			}
      ${isSelected === true &&
        coreTheme.tab.afterTriangleColor &&
        css`
          ::after {
            position: absolute;
            content: '';
            width: 0px;
            height: 0px;
            border-top: 15px solid ${coreTheme.tab.afterTriangleColor};
            border-right: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid transparent;
            left: 50%;
            margin-left: -15px;
            top: 95%;
            z-index: 9;
          }
        `}; 
      ${secondary &&
        coreTheme.tab.secondary &&
        css`
          background-color: ${isSelected
            ? coreTheme.tab.secondary?.tabItemContainerActiveBg
            : coreTheme.tab.secondary?.tabItemContainerBg};
        `}
      ${secondary &&
        isSelected === true &&
        coreTheme.tab.secondary &&
        css`
          ::after {
            border-top: 15px solid
              ${coreTheme.tab.secondary?.afterTriangleColor};
          }
        `}
		`
  )
);

const TabIcon = withTheme(({ isSelected, icon: Icon, coreTheme }) => (
  <Icon
    size={24}
    color={isSelected ? coreTheme.cl_sec_d1 : coreTheme.cl_ter_base}
  />
));

const TabIconContainer = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;
