import { BodyText, BodyWrapper, ViewMoreText, ViewMoreWrapper } from './style';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MultilineTruncateParagraph from '../../core/components/MultilineTruncateParagraph';
import { useTheme } from '../../core/ThemeProvider';
import { ArrowDown, ArrowUp } from '@dls/assets/icons';

const CardBody = ({ children }) => {
  const [truncated, setTruncated] = useState(true);
  const [isOverFlow, setOverflow] = useState(false);
  const { theme } = useTheme();

  return (
    <BodyWrapper>
      <MultilineTruncateParagraph
        Typography={BodyText}
        numOfLines={3}
        truncated={truncated}
        onChangeOverflow={setOverflow}
      >
        {children}
      </MultilineTruncateParagraph>
      {isOverFlow && (
        <ViewMoreWrapper onClick={() => setTruncated(!truncated)}>
          <ViewMoreText>View {truncated ? 'More' : 'Less'}</ViewMoreText>
          {truncated ? (
            <ArrowDown size={16} color={theme.cl_sec_d1} />
          ) : (
            <ArrowUp size={16} color={theme.cl_sec_d1} />
          )}
        </ViewMoreWrapper>
      )}
    </BodyWrapper>
  );
};

CardBody.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Body text
   */
  children: PropTypes.node
};

export default CardBody;
