import React from 'react';
import styled from 'styled-components';
import { Theme } from '@lux/components';

const StyledHeroBanner = styled.section`
  background-color: ${Theme.colours.white};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;

  img {
    width: 100%;
  }
`;

const HeroBanner = props => (
  <StyledHeroBanner>
    <img src={props.imagePath} alt="" />
  </StyledHeroBanner>
);

export default HeroBanner;
