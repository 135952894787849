export { default as BinarySelector } from './components/BinarySelector';
export { default as Button } from './components/Button';
export { default as Card } from './components/Card';
export { default as Checkbox } from './components/Checkbox';
export { default as Chip } from './components/Chip';
export { default as Column } from './components/Column';
export { default as DatePicker } from './components/DatePicker';
export { default as Divider } from './components/Divider';
export { default as DropdownInput } from './components/DropdownInput';
export { default as FileInput } from './components/FileInput';
export { default as Grid } from './components/Grid';
export { default as InputPassword } from './components/InputPassword';
export { default as InputText } from './components/InputText';
export { default as ListItem } from './components/ListItem';
export { default as Modal } from './components/Modal';
export { default as Notification } from './components/Notification';
export { default as PinInput } from './components/PinInput';
export { default as PromotionalBanner } from './components/PromotionalBanner';
export { default as QuantityInput } from './components/QuantityInput';
export { default as Radio } from './components/Radio';
export { default as Rating } from './components/Rating';
export { default as Ribbon } from './components/Ribbon';
export { default as Row } from './components/Row';
export { default as SkeletonLoader } from './components/SkeletonLoader';
export { default as Tabs } from './components/Tabs';
export { default as Text } from './components/Text';
export { default as TextAreaInput } from './components/TextAreaInput';
export { default as TextLink } from './components/TextLink';
export { default as Toggle } from './components/Toggle';
export { default as BulletList } from './components/BulletList';
export { default as Stepper } from './components/Stepper';
export { default as Accordion } from './components/Accordion';
export { default as CirclePattern } from './components/CirclePattern';
export { default as Animation } from './components/Animation';
export { default as Table } from './components/Table';
export { default as Toast } from './components/Toast';
export { default as Pagination } from './components/Pagination';
export { default as Voucher } from './components/Voucher';
export { default as Selector } from './components/Selector';
export { default as Carousel } from './components/Carousel';
export { default as Spacing } from './components/Spacing';
export { default as Header } from './components/Header';
export { default as Footer } from './components/Footer';
export { default as Map } from './components/Map';
export { default as Breadcrumb } from './components/Breadcrumb';
export { default as Banner } from './components/Banner';
import InlineBase from './components/Inline';
import StackBase from './components/Stack';

export const Layout = { Inline: InlineBase, Stack: StackBase };

// Deprecated components
import withWarningMessage from './core/utils/withWarningMessage';

export const Inline = withWarningMessage(InlineBase, {
  message: `This 'Inline' reference is deprecated. We've replaced it with 'Layout.Inline', please update your code accordingly.`
});

export const Stack = withWarningMessage(StackBase, {
  message: `This 'Stack' reference is deprecated. We've replaced it with 'Layout.Stack', please update your code accordingly.`
});
