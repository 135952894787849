import styled, { css } from 'styled-components';
import Config from '../../config';
import { withTheme } from '../../core/ThemeProvider';
import { withStyledAttrs } from '../../helpers/styled';
import Text from '../Text';

const getStyledText = (type, tag) => withStyledAttrs(Text, { type, tag });

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const WrapperVariant1 = withTheme(
  styled(Wrapper)(
    ({ coreTheme }) => css`
      flex-direction: column;
      padding: ${coreTheme.space_3}px ${coreTheme.space_6}px
        ${coreTheme.space_3}px ${coreTheme.space_6}px;

      @media (max-width: 375px) {
        /* An exception breakpoint for this component only */
        padding-left: 0;
        padding-right: 0;
      }
    `
  )
);

export const WrapperVariant2 = withTheme(
  styled(Wrapper)(
    ({ flush, coreTheme }) => css`
      flex-direction: row;
      padding-top: ${coreTheme.space_1}px;
      padding-bottom: ${coreTheme.space_1}px;

      ${Config.media.sm`
        padding: ${coreTheme.space_2}px;
      `};

      ${Config.media.md`
        padding-top: ${coreTheme.space_2}px;
        padding-bottom: ${coreTheme.space_2}px;
        padding-left: ${flush === 'left' ? coreTheme.space_6 : 0}px
        padding-right: ${flush === 'right' ? coreTheme.space_6 : 0}px;
      `};
    `
  )
);

export const ThumbnailVariant1 = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  max-width: 254px;

  ${Config.media.sm`
    padding: 0;
  `};
`;

export const ThumbnailVariant2 = styled.div`
  align-self: flex-start;

  ${Config.media.sm`
    width: auto;
    flex: 2;
  `};
`;

export const Image = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 50%;

  ${Config.media.sm`
  width: 100%;
  height: 100%;
  `};
`;

export const ContentVariant1 = withTheme(styled.div`
  padding-top: ${p => p.coreTheme.space_4}px;
`);

export const ContentVariant2 = withTheme(styled.div`
  flex: 2;
  padding-left: ${p => p.coreTheme.space_4}px;

  ${Config.media.sm`
    flex: 3;
  `};
`);

export const getBodyVariant1 = isMobile =>
  withTheme(getStyledText(isMobile ? 'sectionTitle' : 'header')`
    text-align: center;
    font-family: ${p => p.coreTheme.ff_sec_regular};
    color: ${p => p.coreTheme.cl_ter_base};
  `);

export const Title = withTheme(getStyledText('boldBody')`
  color: ${p => p.coreTheme.cl_ter_base};
`);

export const BodyVariant2 = withTheme(getStyledText('body', 'span')`
  color: ${p => p.coreTheme.cl_ter_base};
`);
