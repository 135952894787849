import React, { useState, useEffect, useMemo } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Fade from './fade';
import Lottie from '../../core/components/Lottie';
import { testIdProps } from '../../helpers';
import { Wrapper } from './style';

const DEFAULT_SIZE = '40px';
const Action = {
  PLAY: 'play',
  PAUSE: 'pause',
  STOP: 'stop'
};

const coloredSource = (source, color) => {
  const r = /^#?(..)(..)(..)(..)?$/.exec(color);
  const arrColor = [r[1], r[2], r[3], r[4] || 'FF'].map(
    c => parseInt(c, 16) / 255
  );

  return JSON.parse(
    JSON.stringify(source).replace(
      /"k":\[[0.\d]+,[0.\d]+,[0.\d]+,1\]/g,
      `"k":[${arrColor}]`
    )
  );
};

const Animation = props => {
  const [fadeIn, setFadeIn] = useState(false);
  const {
    color,
    loop,
    action,
    size,
    source,
    segments,
    onComplete,
    ...rest
  } = props;

  useEffect(() => {
    setFadeIn(action !== Action.STOP);
  }, [action]);

  const animationData = useMemo(
    () => (color ? coloredSource(source, color) : source),
    [color, source]
  );

  useEffect(() => {
    // Reset fade transition if animation source change
    setFadeIn(false);
    setTimeout(() => setFadeIn(true), 0);
  }, [source]);

  return (
    <Wrapper size={size || DEFAULT_SIZE} {...testIdProps(rest)}>
      <Transition in={fadeIn} mountOnEnter unmountOnExit timeout={200}>
        {state => (
          <Fade state={state}>
            <Lottie
              data-testid="lottieId"
              animationData={animationData}
              config={{ loop }}
              play={action !== Action.STOP}
              pause={action === Action.PAUSE}
              segments={segments}
              height={size || DEFAULT_SIZE}
              width={size || DEFAULT_SIZE}
              onComplete={onComplete}
            />
          </Fade>
        )}
      </Transition>
    </Wrapper>
  );
};

Animation.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Animation actions, default is 'play'
   */
  action: PropTypes.oneOf(['pause', 'play', 'stop']),
  /**
   * Animation color
   */
  color: PropTypes.string,
  loop: PropTypes.bool,
  /**
   * Complete animation event when loop === false
   */
  onComplete: PropTypes.func,
  /**
   * Contain 2 numeric values that will be used as first and last frame of the animation.
   */
  segments: PropTypes.arrayOf(PropTypes.number),
  /**
   * Animation size
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Animation source file
   */
  source: PropTypes.object.isRequired
};

Animation.defaultProps = {
  action: Action.PLAY,
  loop: true
};

export default Animation;
