/**
 * Get the translate y position for the view less link. Translate y position returned in this function
 * indicates how many pixels the element should be moved from it's current position in y axis.
 * We need to place view less link below the selector with maximum height to prevent overlapping when
 * animation is happening.
 *
 * @param { Array } locationsList - List of locations which has a property for the selector node ref
 * @param { RefObject } viewLessLinkNodeRef - View less link node ref
 * @param { number } initialSearchResultCount - Number of search results initially displayed after the search
 * @returns translate y position for the view less link
 */
export const getViewLessLinkTransitionStartYPosition = (
  locationsList,
  viewLessLinkNodeRef,
  initialSearchResultCount
) => {
  // Calculate the max selector element height from the view more result set.
  let maxSelectorElemHeight = 0;
  locationsList.slice(initialSearchResultCount).forEach(location => {
    if (location.nodeRef.current?.offsetHeight > maxSelectorElemHeight) {
      maxSelectorElemHeight = location.nodeRef.current?.offsetHeight;
    }
  });

  // Get the offset top of last selector from the initially displayed result set
  const initiallyDisplayedLastSelectorOffsetTop =
    locationsList[initialSearchResultCount - 1].nodeRef.current?.offsetTop;

  // Get the view less link transition start offset y position
  const viewLessLinkYOffset =
    initiallyDisplayedLastSelectorOffsetTop + maxSelectorElemHeight;

  // Get the translate y position for the view less link
  const translateYPos =
    viewLessLinkYOffset - viewLessLinkNodeRef.current?.offsetTop;

  return translateYPos || 0;
};

/**
 * Get the translate y position for the given location selector. Translate y position returned in this function
 * indicates how many pixels the element should be moved from it's current position in y axis
 *
 * @param { Array } locationsList - List of locations which has a property for the selector node ref
 * @param { RefObject } locationSelectorNodeRef - Location selector node ref
 * @param { number } initialSearchResultCount - Number of search results initially displayed after the search
 * @returns translate y position for the given location selector
 */
export const getLocationSelectorsTransitionStartYPosition = (
  locationsList,
  locationSelectorNodeRef,
  initialSearchResultCount
) => {
  // Get the offset top of last selector from the initially displayed result set
  const initiallyDisplayedLastSelectorOffsetTop =
    locationsList[initialSearchResultCount - 1].nodeRef.current?.offsetTop;

  // Get the translate y position for the given location selector
  const translateYPos =
    initiallyDisplayedLastSelectorOffsetTop -
    locationSelectorNodeRef.current?.offsetTop;

  return translateYPos || 0;
};
