import React from 'react';
import PropTypes from 'prop-types';
import { Marker as GoogleMapMarker } from '@react-google-maps/api';
import { useTheme } from '../../../core/ThemeProvider';
import { defaultPin } from '../assets/defaultPin';
import { selectedPin } from '../assets/selectedPin';

const Marker = ({ markerData, onClick, showLabel, markerLabelClassName }) => {
  const { theme } = useTheme();

  let label;
  if (showLabel) {
    label = {
      className: markerLabelClassName,
      text: markerData.name,
      fontSize: theme.map.marker.labelFontSize,
      color: theme.map.marker.labelColor,
      fontFamily: theme.map.marker.labelFontFamily
    };
  }

  const markerIconUrl =
    'data:image/svg+xml;charset=utf-8,' +
    encodeURIComponent(markerData.isSelected ? selectedPin : defaultPin);

  const markerLabelOrigin = markerData.isSelected
    ? new window.google.maps.Point(18, 63)
    : new window.google.maps.Point(12, 48);

  return (
    <GoogleMapMarker
      data-testid="google-map-marker"
      onClick={onClick}
      icon={{
        url: markerIconUrl,
        labelOrigin: markerLabelOrigin
      }}
      position={markerData}
      label={label}
    ></GoogleMapMarker>
  );
};

Marker.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Marker data used to draw the marker on the map
   */
  markerData: PropTypes.shape({
    /**
     * Flag to indicate whether marker should show selected state
     */
    isSelected: PropTypes.bool.isRequired,
    /**
     * Location latitude
     */
    lat: PropTypes.number.isRequired,
    /**
     * Location longitude
     */
    lng: PropTypes.number.isRequired,
    /**
     * marker label name
     */
    name: PropTypes.string.isRequired
  }).isRequired,
  /**
   * An external css class name to be appended to the marker label
   * for custom styling
   */
  markerLabelClassName: PropTypes.string.isRequired,
  /**
   * Handler to get notified when clicked on the marker
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Flag to indicate whether to show the label for the marker icon
   */
  showLabel: PropTypes.bool.isRequired
};

export default Marker;
