import merge from 'lodash/merge';
import { baseTheme, updateTheme } from './index';
import { flattenTheme } from './utils';
import gomoTheme from './advance/index';
import { remCalc } from '@lux/helpers';
import { withOpacity } from '../helpers';

const advanceThemeProps = merge({}, baseTheme, {
  main: {
    fontFamily: {
      primaryThin: `Poppins-Bold, arial, helvetica, sans-serif`,
      primaryRegular: `Poppins-Bold, arial, helvetica, sans-serif`,
      primaryBold: `Poppins-Bold, arial, helvetica, sans-serif`,
      secondaryThin: `Roboto, arial, helvetica, sans-serif`,
      secondaryRegular: `Roboto-Bold, arial, helvetica, sans-serif`,
      secondaryBold: `Roboto-Bold, arial, helvetica, sans-serif`
    },
    colours: {
      primary: {
        d1: gomoTheme.colours.gomo_cerise,
        d2: gomoTheme.colours.gomo_cerise,
        d3: gomoTheme.colours.gomo_cerise,
        d4: gomoTheme.colours.gomo_cerise_fc
      },
      secondary: {
        l1: gomoTheme.colours.gomo_cerise,
        l2: gomoTheme.colours.gomo_cerise,
        d1: gomoTheme.colours.gomo_grey_e6,
        d2: gomoTheme.colours.gomo_grey_be,
        d3: gomoTheme.colours.gomo_grey_bb
      },
      tertiary: {
        l3: gomoTheme.colours.gomo_cyan,
        l4: gomoTheme.colours.gomo_ocean_blue
      }
    },
    shadows: {
      size1: '0 3px 8px 0 rgba(0 0 0 / 0.12)',
      size2: '0 6px 16px 0 rgba(0 0 0 / 0.10)'
    }
  }
});

export const getAdvanceTheme = theme => {
  const flatTheme = flattenTheme(updateTheme(advanceThemeProps, theme));

  const advanceComponentsProps = {
    text: {
      header: {
        fontFamily: flatTheme.ff_pri_bold
      },
      body: {
        letterSpacing: '0px'
      },
      boldBody: {
        letterSpacing: 0.3
      },
      smallBody: {
        letterSpacing: '0px'
      }
    },
    accordion: {
      margin: `${flatTheme.space_4}px 0`,
      mdMargin: `${flatTheme.space_4}px 0`,
      iconSize: '16px',
      mdIconSize: '22px'
    },
    button: {
      background: flatTheme.cl_pri_base,
      hoverBg: flatTheme.cl_pri_d2,
      activeBg: flatTheme.cl_pri_d4,
      border: `1px solid transparent`,
      borderRadius: `${remCalc(20)}`,
      padding: `0 ${remCalc(20)}`,
      mdPadding: `0 ${remCalc(40)}`,
      minWidth: remCalc(180),
      maxHeight: remCalc(40),
      minHeight: remCalc(40),
      fontSize: flatTheme.fs_md,
      fontWeight: 600,
      fontFamily: flatTheme.ff_pri_regular,
      secondary: {
        color: flatTheme.cl_sec_d1,
        background: flatTheme.cl_sec_l2,
        borderColor: 'transparent',
        hoverBg: flatTheme.cl_sec_l1,
        activeBg: flatTheme.cl_sec_base
      },
      customStyle: {}
    },
    tab: {
      tabBarDividerPositionBottom: '15px',
      tabBarContainerPaddingBottom: '15px',
      tabItemContainerPadding: '14px',
      selectedTabTitleColor: flatTheme.cl_sec_d1,
      tabTitleColor: flatTheme.cl_ter_base,
      tabItemContainerHoverBg: withOpacity(flatTheme.cl_sec_l1, 0.8),
      tabItemContainerActiveBg: withOpacity(flatTheme.cl_white, 0.5),
      tabItemContainerBg: flatTheme.cl_white,
      tabItemIndicatorBgColor: flatTheme.cl_sec_d1,
      tabBarDividerBgColor: flatTheme.cl_ter_l5,
      bottomBorderColor: flatTheme.cl_ter_l5
    },
    pagination: {
      fontSize: '0.875rem',
      marginTop: '16px',
      button: {
        fontFamily: flatTheme.ff_sec_regular,
        color: flatTheme.cl_ter_l2,
        backgroundColor: 'transparent',
        hoverBg: flatTheme.cl_sec_l2,
        borderRadius: '16px',
        height: '32px',
        minWidth: '32px',
        margin: '0 3px',
        padding: '0 6px',
        selected: {
          color: flatTheme.cl_white,
          backgroundColor: flatTheme.cl_sec_d1
        },
        svg: {
          width: '16px',
          height: '16px'
        }
      }
    },
    header: {
      desktopHeight: '72px',
      mobileHeight: '64px',
      desktopMenuActiveFontColor: flatTheme.cl_ter_l4,
      desktopMenuInActiveFontColor: flatTheme.cl_black,
      deskTopLogoHeight: '48px',
      logoAlignment: 'center',
      desktopSubMenuFontColor: flatTheme.cl_ter_base,
      desktopSubMenuActiveFontColor: flatTheme.cl_ter_l4,
      desktopSubMenuFontSize: flatTheme.fs_md,
      mobileMenuFontColor: flatTheme.cl_ter_base,
      mobileMenuActiveFontColor: flatTheme.cl_ter_l4
    },
    footer: {
      backgroundColor: flatTheme.cl_ter_l6,
      linkFont: flatTheme.ff_sec_regular,
      linkFontColor: flatTheme.cl_ter_l4,
      linkFontSize: flatTheme.fs_md
    },
    selector: {
      border: `1px solid ${flatTheme.cl_sec_d3}`,
      selectedBorder: `2px solid ${flatTheme.cl_ter_l4}`
    },
    listItem: {
      leftContent: {
        backgroundColor: flatTheme.cl_ter_l6,
        minHeight: '80px',
        minWidth: '80px',
        image: {
          height: '48px',
          width: '48px'
        },
        thumbnail: {
          height: '104px',
          width: '104px'
        },
        icon: {
          size: '32px',
          color: flatTheme.cl_sec_d1
        },
        tag: {
          backgroundColor: flatTheme.cl_pri_d2,
          height: '20px',
          fontSize: [12, 12, 14, 14, 14],
          color: flatTheme.cl_white,
          lineHeight: '20px',
          letterSpacing: '0.002em',
          fontFamily: flatTheme.ff_sec_bold,
          padding: '0 16px'
        }
      },
      bodyContent: {
        title: {
          color: flatTheme.cl_ter_l1,
          lineHeight: '24px',
          mdLineHeight: '30px'
        },
        subTitle: {
          color: flatTheme.cl_ter_l3,
          lineHeight: '24px',
          mdLineHeight: '28px'
        },
        points: {
          icon: {
            size: '24px',
            color: flatTheme.cl_sec_d1
          }
        }
      }
    },
    map: {
      mapTerrain: {
        waterGeometry: flatTheme.cl_ter_l5,
        landscapeGeometry: flatTheme.cl_ter_l6,
        roadHighwayGeometryFill: flatTheme.cl_white,
        roadHighwayGeometryStroke: flatTheme.cl_white,
        roadArterialGeometry: flatTheme.cl_white,
        roadLocalGeometry: flatTheme.cl_white,
        poiGeometry: flatTheme.cl_ter_l6,
        poiParkGeometry: flatTheme.cl_ter_l5,
        labelsTextStroke: flatTheme.cl_white,
        labelsTextFill: flatTheme.cl_ter_l4,
        transitGeometry: flatTheme.cl_ter_l6,
        administrativeGeometryFill: flatTheme.cl_ter_l6,
        administrativeGeometryStroke: flatTheme.cl_ter_l6
      },
      marker: {
        labelTextShadow: `1px 1px 1px ${flatTheme.cl_white}`,
        labelLineHeight: '16px',
        labelLetterSpacing: '0.2px',
        labelFontSize: flatTheme.fs_xs,
        labelColor: flatTheme.cl_black,
        labelFontFamily: flatTheme.ff_sec_regular
      }
    },
    table: {
      backgroundColor: {
        hover: gomoTheme.colours.gomo_yellow_13,
        active: gomoTheme.colours.gomo_yellow_13
      }
    }
  };

  return {
    ...flatTheme,
    ...advanceComponentsProps
  };
};

export const advanceTheme = getAdvanceTheme();

export default advanceTheme;
