export default {
  gomo_red: '#ED1A3D',
  pupil_black: '#000000',
  iris_white: '#FFFFFF',
  gomo_blue: '#85DDDA',
  gomo_lime: '#EBE013',
  gomo_ocean_blue: '#006F91',
  gomo_cerise: '#E32490',
  gomo_cerise_fc: '#FCE9F3',
  gomo_yellow: '#FFB824',
  gomo_yellow_13: '#EBE013',
  gomo_cyan: '#19C5FF',
  gomo_grey_bb: '#BBBBBB',
  gomo_grey_f3: '#F3F3F3',
  gomo_grey_be: '#BEBEBE',
  gomo_grey_99: '#999999',
  gomo_grey_e6: '#e6e6e6',
  gomo_grey_65: '#656565',
  gomo_black_33: '#333333',
  gomo_grey_d8: '#D8D8D8',
  gomo_anchor_blue: '#009FD4',
  gomo_light_blue: '#E8F9FF'
};
