import styled from 'styled-components';
import Text from '../../Text';
import config from '../../../config';
import { withTheme } from '../../../core/ThemeProvider';

export const StyledFilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFilterIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
`;

export const StyledSearchResultsHeading = withTheme(styled(Text)`
  flex: 1;
  padding-right: ${p => p.coreTheme.space_4}px;
`);

export const StyledArrowIconContainer = withTheme(styled.div`
  margin-top: 5px;
  margin-left: 10px;
  stroke: ${p => p.coreTheme.cl_sec_d1};

  ${config.media.md`
    svg {
      width: 22px;
      height: 22px;
      stroke: none;
    }
  `};
`);

export const StyledViewMoreLessLinkContainer = withTheme(styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding-top: ${p => p.coreTheme.space_4}px;
`);

// This style is used to start the slide animation of view more selectors from
// behind the initial result selectors.
// z-index only works on positioned elements. https://stackoverflow.com/a/32816773
export const StyledInitialResultSelectorContainer = styled.div`
  position: relative;
  z-index: 1;
`;
