import { useState, useEffect } from 'react';
import useDevice from '../../../core/hooks/useDevice';

const VIEW_TYPE = {
  mobile: {
    limit: 3,
    threshold: 1
  },
  desktop: {
    limit: 5,
    threshold: 3
  }
};

/* Determine if we should show the the ellipsis */
export const getPaginationParams = (current, numberOfPages, isMobile) => {
  let showFirstDots = false;
  let showLastDots = false;
  let LIMIT, ELLIPSIS_THRESHOLD;

  if (isMobile) {
    LIMIT = VIEW_TYPE['mobile'].limit;
    ELLIPSIS_THRESHOLD = VIEW_TYPE['mobile'].threshold;
  } else {
    LIMIT = VIEW_TYPE['desktop'].limit;
    ELLIPSIS_THRESHOLD = VIEW_TYPE['desktop'].threshold;
  }

  /* 
    number that links together
    [-1-][2][3][4][5][...][10] = 1,2,3,4,5 = 5
    [1][...][4][-5-][6][...][10] = 4,5,6 = 3
    [1][...][6][7][8][9][-10-] = 6,7,8,9,10 = 5
    */
  let numberOfLinks = LIMIT;
  let startNumber = 1;

  if (numberOfPages <= LIMIT) {
    // Special case: Less pages available than the limit of displayed pages
    numberOfLinks = numberOfPages;
  } else if (current < LIMIT - 1 && LIMIT > ELLIPSIS_THRESHOLD) {
    // set showLastDots = true
    showLastDots = true;
    numberOfLinks = LIMIT;

    numberOfLinks = Math.min(numberOfLinks, LIMIT);
  } else if (
    numberOfPages - current + 2 < LIMIT &&
    LIMIT > ELLIPSIS_THRESHOLD
  ) {
    // set showFirstDots = true
    showFirstDots = true;
    numberOfLinks = LIMIT;

    startNumber = numberOfPages - numberOfLinks + 1;
  } else {
    // We are somewhere in the middle of the page list
    if (LIMIT > ELLIPSIS_THRESHOLD) {
      numberOfLinks = LIMIT - 2;
      showFirstDots = true;
      showLastDots = true;
    }

    startNumber = current - Math.floor(numberOfLinks / 2);
  }

  // Sanity checks
  if (startNumber < 1) {
    startNumber = 1;
    showFirstDots = false;
  } else if (startNumber > numberOfPages - numberOfLinks) {
    startNumber = numberOfPages - numberOfLinks + 1;
    showLastDots = false;
  }
  if (showFirstDots && startNumber < 4) {
    numberOfLinks = numberOfLinks + 2;
    startNumber = 1;
    showFirstDots = false;
  }

  const lastPageNumber = startNumber + numberOfLinks - 1;
  if (showLastDots && lastPageNumber > numberOfPages - 3) {
    numberOfLinks =
      numberOfLinks + (lastPageNumber === numberOfPages - 2 ? 2 : 3);
    showLastDots = false;
  }

  numberOfLinks = Math.min(numberOfLinks, numberOfPages - startNumber + 1);

  return {
    showFirstDots,
    showLastDots,
    numberOfLinks,
    startNumber,
    currentPage: current
  };
};

/* Generate list of page numbers */
export const getPageList = (numberOfLinks, startNumber) => {
  const pages = Array.from(Array(numberOfLinks).keys()).map(
    (item, index) => startNumber + index
  );

  return pages;
};

/* Generate pagination list items */
export const getPaginationArr = ({
  linkedNumbers,
  showFirstDots,
  showLastDots,
  currentPage,
  numberOfPages
}) => {
  // Helper function and flag
  const isActivePage = pageNumber => pageNumber === currentPage;
  const noCurrentPage = currentPage < 1;

  let buttons = [];

  // Factory function for prev/next/first/last buttons
  const makeEndBtn = (linkTo, pageTest, btnText) => {
    const isDisabled =
      isActivePage(pageTest) ||
      noCurrentPage ||
      linkTo < 1 ||
      linkTo > numberOfPages;
    const pageNumber =
      linkTo < 1 ? 1 : linkTo > numberOfPages ? numberOfPages : linkTo;

    return {
      linkToPage: pageNumber,
      index: pageNumber - 1,
      content: btnText,
      disabled: isDisabled
    };
  };

  const makePageButton = pageNumber => {
    const active = isActivePage(pageNumber) && !noCurrentPage;

    return {
      linkToPage: pageNumber,
      index: pageNumber - 1,
      content: pageNumber,
      active
    };
  };

  const makeEllipsis = () => {
    return {
      content: '…'
    };
  };

  /* Goto previous page button */
  buttons.push(makeEndBtn(currentPage - 1, 1, 'prev'));

  /* Show first (1) button? */
  if (linkedNumbers[0] !== 1) {
    buttons.push(makePageButton(1));
  }

  /* First ellipsis */
  if (showFirstDots) {
    buttons.push(makeEllipsis());
  }

  /* Individual page links */
  linkedNumbers.forEach(page => {
    buttons.push(makePageButton(page));
  });

  /* Last ellipsis */
  if (showLastDots) {
    buttons.push(makeEllipsis());
  }

  /* Show last page button? */
  if (linkedNumbers[linkedNumbers.length - 1] !== numberOfPages) {
    buttons.push(makePageButton(numberOfPages));
  }

  /* Goto next page button */
  buttons.push(makeEndBtn(currentPage + 1, numberOfPages, 'next'));

  return buttons;
};

const usePagination = ({ current, count, onChange }) => {
  const { isMobile } = useDevice();
  const [page, setPage] = useState(current);
  const [paginationArr, setPaginationArr] = useState([]);

  useEffect(() => {
    if (current !== page) {
      setPage(current);
    }
  }, [current]);

  useEffect(() => {
    /* first, Determine if we should show the the ellipsis */
    /* second, Generate list of page numbers */
    /* lastly, Generate pagination list items */

    const {
      showFirstDots,
      showLastDots,
      numberOfLinks,
      startNumber
    } = getPaginationParams(page, count, isMobile);

    const linkedNumbers = getPageList(numberOfLinks, startNumber);

    const result = getPaginationArr({
      linkedNumbers,
      showFirstDots,
      showLastDots,
      currentPage: page,
      numberOfPages: count
    });

    setPaginationArr(result);
  }, [page, count, isMobile]);

  const handleChange = value => {
    let targetValue;

    if (value === page) {
      return false;
    } else if (value < 1) {
      targetValue = 1;
    } else if (value > count) {
      targetValue = count;
    } else {
      targetValue = value;
    }

    setPage(targetValue);
    onChange(targetValue);
  };

  return { paginationArr, onPageChange: handleChange };
};

export default usePagination;
