import grid from '../config/grid';

/**
 * Returns true if we are in a xsmall viewport
 */
export function isNarrowViewport(width = window.innerWidth) {
  return width < grid.breakpoints.sm * 16;
}

/**
 * Converts Hex Color into Color with Opacity
 */
export function withOpacity(color, opacity) {
  return color + Math.round(opacity * 255).toString(16);
}

/**
 * @param {Object} props all props available in the component.
 * @param {String?} defaultTestId use this testId, if no testId is provided.
 * @return {*}
 */
export function testIdProps(props, defaultTestId) {
  const testId = props['data-testid'] || defaultTestId;
  return testId ? { 'data-testid': testId } : {};
}

export function noop() {}

export const isDefined = val => {
  return typeof val !== 'undefined';
};

export const isNumber = val => {
  return val === parseInt(val, 10);
};
