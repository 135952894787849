import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../../helpers';
import { useWindowSize } from '../../hooks/windowSize';

const MultilineTruncateParagraph = ({
  Typography,
  numOfLines,
  truncated,
  onChangeOverflow,
  timeout,
  children,
  ...rest
}) => {
  const [isOverFlow, setOverflow] = useState(false);
  const textRef = useRef(null);
  const windowSize = useWindowSize();

  const processTruncateText = useCallback(
    node => {
      const words = children.split(' ');
      node.textContent = words[0];
      const maxHeight = node.clientHeight * numOfLines; // get the the paragraph max height when truncated
      let truncatedStr = '';

      const overflow = words.some(word => {
        const prevStr = truncatedStr;
        truncatedStr = truncatedStr ? `${truncatedStr} ${word}` : word; // concat the paragraph word by word
        node.textContent = truncatedStr;

        if (node.clientHeight > maxHeight) {
          // when the truncated paragraph exceeds the max height
          node.textContent = `${prevStr}...`;
          if (node.clientHeight > maxHeight) {
            // replace the last 4 letters by '...' to make sure it's not going to the next line
            node.textContent = `${prevStr.substring(0, prevStr.length - 4)}...`;
          }
          return true;
        }
        return false;
      });

      setOverflow(prevState => (prevState ? prevState : overflow));
    },
    [children, numOfLines]
  );

  useEffect(() => {
    onChangeOverflow(isOverFlow);
  }, [isOverFlow]);

  useEffect(() => {
    let transitionTimeout;
    if (textRef.current && truncated) {
      transitionTimeout = setTimeout(() => {
        processTruncateText(textRef.current);
      }, timeout);
    }

    return () => clearTimeout(transitionTimeout);
  }, [truncated, windowSize, numOfLines]);

  return (
    <Typography tag="span" {...rest}>
      {truncated && (
        <div ref={textRef} data-testid="truncatedTextId">
          {children}
        </div>
      )}
      {!truncated && <div>{children}</div>}
    </Typography>
  );
};

MultilineTruncateParagraph.defaultProps = {
  onChangeOverflow: noop,
  truncated: true,
  timeout: 0,
  children: ''
};

MultilineTruncateParagraph.propTypes = {
  /* Text typography used in the paragraph */
  Typography: PropTypes.object,
  /* Number of lines when truncated */
  numOfLines: PropTypes.number,
  /* Flag to trigger collapse/expand paragraph */
  truncated: PropTypes.bool,
  /* Return value if the text is overflow or not */
  onChangeOverflow: PropTypes.func,
  /* The time delay for each transition */
  timeout: PropTypes.number
};

export default MultilineTruncateParagraph;
