import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SearchIcon from '../icons/searchIcon.svg';
import { withTheme } from '../../../../core/ThemeProvider';

const SCSearchBox = withTheme(
  styled.div(
    props => css`
      position: relative;
      font-family: ${props.coreTheme.header.search.searchInputFont};
      display: flex;
    `
  )
);

const SCSearchInput = withTheme(
  styled.input(
    props => css`
      color: ${props.coreTheme.header.search.searchInputFontColor};
      font-family: ${props.coreTheme.header.search.searchInputFont};
      font-size: 1rem;
      border-radius: 7px;
      padding: 10px;

      &::placeholder {
        color: ${props.coreTheme.header.search.searchInputPlaceholderFontColor};
      }

      @media (max-width: ${props.coreTheme.brk_md}) {
        width: 100%;
      }
    `
  )
);

const SCSearchIcon = withTheme(
  styled.div`
    width: 1rem;
    height: 100%;
    position: absolute;
    padding: 0 10px;
    cursor: pointer;
    top: 0;
    right: 0;

    svg {
      width: 1rem;
      height: 100%;
    }
  `
);

const SearchInput = ({
  onSearchIconClick,
  onSearchInputActivated,
  searchInputClassName,
  placeholder
}) => {
  useEffect(() => {
    // Notify the consumer of this component when the search input is mounted on the DOM.
    onSearchInputActivated && onSearchInputActivated();
  }, []);

  return (
    <SCSearchBox>
      <SCSearchInput
        className={searchInputClassName}
        type="text"
        placeholder={placeholder}
        data-testid="search-input"
      />
      <SCSearchIcon data-testid="search-input-icon" onClick={onSearchIconClick}>
        <SearchIcon />
      </SCSearchIcon>
    </SCSearchBox>
  );
};

SearchInput.propTypes = {
  /**
   * Callback to be notified when clicked on search icon
   */
  onSearchIconClick: PropTypes.func,

  /**
   * Callback to get notified when search input is activated and mounted on DOM.
   */
  onSearchInputActivated: PropTypes.func,

  /**
   * className to set on search input
   */
  searchInputClassName: PropTypes.string,

  /**
   * Search input placeholder
   */
  placeholder: PropTypes.string
};

export default SearchInput;
