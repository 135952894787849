import React, { useState, useEffect, useImperativeHandle } from 'react';
import { ArrowLeft, ArrowRight } from '@dls/assets/icons';
import {
  Wrapper,
  CarouselWrapper,
  CarouselContainer,
  CarouselSlot,
  ArrowButton,
  OutsideArrowButtonWrapper,
  PaginationPanel,
  PaginationDot
} from './style';
import useDevice from '../../core/hooks/useDevice';
import { testIdProps } from '../../helpers';
import PropTypes from 'prop-types';

const Carousel = React.forwardRef(
  (
    {
      children,
      type,
      fullWidth,
      autoplay,
      hidePagination,
      pagination,
      outsideButtons,
      lazyLoad,
      lazyLoadOffset,
      infiniteLoop = true,
      onSlideIndexChanged = () => {},
      shouldHandlePointerMove = true,
      ...rest
    },
    ref
  ) => {
    const { isMobile } = useDevice();
    useImperativeHandle(ref, () => ({
      resetCarousel
    }));
    const numOfPage =
      children && !isMobile && type === 'card'
        ? Math.ceil(children.length / pagination)
        : children && children.length;
    const [active, setActive] = useState(0);
    const [scrolledSlides, setScolledSlides] = useState(0);
    const [pointerPosition, setPointerPosition] = useState(null);

    const showLeftArrow = infiniteLoop || active > 0;
    const showRightArrow = infiniteLoop || active < numOfPage - 1;

    const resetCarousel = () => {
      moveToSlide(0);
    };
    const handlePointerDown = e => {
      if (!shouldHandlePointerMove) return;
      const pointerDown = e.clientX;
      setPointerPosition(pointerDown);
    };

    const handlePointerMove = e => {
      if (!shouldHandlePointerMove) return;
      const pointerDown = pointerPosition;
      if (pointerDown === null) {
        return;
      }
      const currentPointer = e.clientX;
      const diff = pointerDown - currentPointer;
      if (diff > 0.1) {
        moveToSlide(active + 1);
      }
      if (diff < -0.1) {
        moveToSlide(active - 1);
      }
      setPointerPosition(null);
    };

    useEffect(() => {
      if (autoplay) {
        const interval = setInterval(() => {
          moveToSlide(active + 1);
        }, autoplay * 1000);
        return () => clearInterval(interval);
      }
    }, [active]);

    useEffect(() => {
      if (active > scrolledSlides) {
        setScolledSlides(active);
      }
    }, [active]);

    const moveToSlide = index => {
      let slideIndex = index;
      if (index > numOfPage - 1) slideIndex = 0;
      if (index < 0) slideIndex = numOfPage - 1;
      setActive(slideIndex);
      onSlideIndexChanged(index);
    };

    const renderChildern = (pagination, type) => {
      const carouselChildren = React.Children.toArray(children);
      let renderedChildern;
      if (carouselChildren.length) {
        if (type === 'image' && lazyLoad) {
          const visibleItemsCount = scrolledSlides + lazyLoadOffset + 1;
          const notVisibleItemsCount =
            carouselChildren.length - visibleItemsCount;
          const visibleComponents = carouselChildren.slice(
            0,
            visibleItemsCount
          );
          const virtualizedItems = Array(
            notVisibleItemsCount > 0 ? notVisibleItemsCount : 0
          ).fill(null);
          if (notVisibleItemsCount > 0) {
            renderedChildern = visibleComponents.concat(virtualizedItems);
          } else {
            renderedChildern = visibleComponents;
          }
        } else {
          renderedChildern = carouselChildren;
        }
        return renderedChildern.map((child, index) => (
          <CarouselSlot key={index} pagination={pagination} type={type}>
            {child}
          </CarouselSlot>
        ));
      }
      return null;
    };

    return (
      <div {...testIdProps(rest)}>
        <Wrapper>
          {!outsideButtons && numOfPage > 1 && (
            <ArrowButton
              data-testid="arrow-left"
              onClick={() => moveToSlide(active - 1)}
              left
              inside
              showArrow={showLeftArrow}
            >
              <ArrowLeft size={25} />
            </ArrowButton>
          )}

          {outsideButtons && numOfPage > 1 && (
            <OutsideArrowButtonWrapper left>
              <ArrowButton
                data-testid="outside-arrow-left"
                onClick={() => moveToSlide(active - 1)}
                left
                showArrow={showLeftArrow}
              >
                <ArrowLeft size={25} />
              </ArrowButton>
            </OutsideArrowButtonWrapper>
          )}

          <CarouselWrapper fullWidth={fullWidth} type={type}>
            <CarouselContainer
              data-testid="carousel-container"
              current={active}
              onPointerDown={handlePointerDown}
              onPointerMove={handlePointerMove}
              type={type}
            >
              {renderChildern(pagination, type)}
            </CarouselContainer>
          </CarouselWrapper>

          {!outsideButtons && numOfPage > 1 && (
            <ArrowButton
              data-testid="arrow-right"
              onClick={() => moveToSlide(active + 1)}
              right
              inside
              showArrow={showRightArrow}
            >
              <ArrowRight size={25} />
            </ArrowButton>
          )}

          {outsideButtons && numOfPage > 1 && (
            <OutsideArrowButtonWrapper right>
              <ArrowButton
                data-testid="outside-arrow-right"
                onClick={() => moveToSlide(active + 1)}
                right
                showArrow={showRightArrow}
              >
                <ArrowRight size={25} />
              </ArrowButton>
            </OutsideArrowButtonWrapper>
          )}
        </Wrapper>

        {!hidePagination && (
          <PaginationPanel type={type}>
            {numOfPage > 1 &&
              [...Array(numOfPage).keys()].map(page => (
                <PaginationDot
                  data-testid={`Pagination${page + 1}`}
                  key={page}
                  active={page === active}
                  onClick={() => moveToSlide(page)}
                />
              ))}
          </PaginationPanel>
        )}
      </div>
    );
  }
);

Carousel.defaultProps = {
  autoplay: 0,
  fullWidth: false,
  hidePagination: false,
  outsideButtons: false,
  pagination: 1,
  lazyLoad: false,
  lazyLoadOffset: 1
};
Carousel.displayName = 'Carousel';
Carousel.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * autoplay value in seconds
   * @default 0
   */
  autoplay: PropTypes.number,
  /**
   * carousel items
   */
  children: PropTypes.node,
  /**
   * flag when set to true, make the carousel full width with no border radius
   */
  fullWidth: PropTypes.bool,
  /**
   * flag when set to true, hide the default pagination panel
   * @default false
   */
  hidePagination: PropTypes.bool,
  /**
   * This flag determines whether the carousel should cycle continuously.
   * Flag is default set to true.
   * @default true
   */
  infiniteLoop: PropTypes.bool,
  /**
   * only work when carousel type is image.
   * flag when set to true, enable lazy loading
   * @default false
   */
  lazyLoad: PropTypes.bool,
  /**
   * configure the number of images to be preloaded when lazyLoad is set to true
   * @default 1
   */
  lazyLoadOffset: PropTypes.number,
  /**
   * This is callback when slide index gets changed.
   */
  onSlideIndexChanged: PropTypes.func,
  /**
   * flag when set to true, show the buttons outside of the container
   */
  outsideButtons: PropTypes.bool,
  /**
   * only work when carousel type is card and not in mobile view.
   * allow to define the number of items showed per carousel slide.
   * @default 1
   */
  pagination: PropTypes.number,
  /**
   * This flag determines whether or not carousel component handles pointer move on child content.
   * Flag is default set to true.
   * @default true
   */
  shouldHandlePointerMove: PropTypes.bool,
  /**
   * type of carousel variant
   */
  type: PropTypes.oneOf(['card', 'image']).isRequired
};

export default Carousel;
