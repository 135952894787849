import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import ErrorPage from '../containers/ErrorPage';
import NoMatch from '../containers/NoMatch';
import StaticEarly from '../containers/StaticEarly';
import AppointmentStaticEarly from '../containers/AppointmentStaticEarly';
import { isGalileoGalilei, isCuriosityRover } from '../configs';
import { defaultDevice } from '../configs/devices';
import PaymentFailurePage from '../containers/PaymentFailurePage';
import PaymentSuccessPage from '../containers/PaymentSuccessPage';

function Loading() {
  return <div />;
}

const CcoLogin = Loadable({
  loader: () => import(/* webpackChunkName: "cco" */'../containers/CcoLogin'),
  loading: Loading
});

const CcoLanding = Loadable({
  loader: () =>
    import(/* webpackChunkName: "cco" */ '../containers/CcoLanding'),
  loading: Loading
});

const CcoProducts = Loadable({
  loader: () =>
    import(/* webpackChunkName: "cco" */ '../containers/CcoProducts'),
  loading: Loading
});

const CcoTimeSlots = Loadable({
  loader: () =>
    import(/* webpackChunkName: "cco" */ '../containers/CcoTimeSlots'),
  loading: Loading
});

const CcoCustomer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "cco" */ '../containers/CcoCustomer'),
  loading: Loading
});

const ThomasHarriot = Loadable({
  loader: () => import('../containers/ThomasHarriot'),
  loading: Loading
});

const AppointmentCustomerDetails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentCustomerDetails'),
  loading: Loading
});

const AppointmentLanding = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentLanding'),
  loading: Loading
});

const AppointmentSelectDevice = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentSelectDevice'),
  loading: Loading
});

const AppointmentLocations = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentLocations'),
  loading: Loading
});

const AppointmentReview = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentReview'),
  loading: Loading
});

const AppointmentConfirmation = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentConfirmation'),
  loading: Loading
});

const AppointmentFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentFound'),
  loading: Loading
});

const RoutedAppointmentPlan = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/AppointmentPlan/AppointmentPlan'),
  loading: Loading
});

const RoutedRoiRegistrationPlan = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/RoiRegistrationPlan/index'),
  loading: Loading
});

const CampaignClosed = Loadable({
  loader: () =>
    import(/* webpackChunkName: "appointment" */ '../containers/CampaignClosed'),
  loading: Loading
});

const RoiLanding = Loadable({
  loader: () =>
    import(/* webpackChunkName: "roi" */ '../containers/RoiLanding'),
  loading: Loading
});

const RoiSelectDevice = Loadable({
  loader: () =>
    import(/* webpackChunkName: "roi" */ '../containers/RoiSelectDevice'),
  loading: Loading
});

const RoiCustomerDetailsForm = Loadable({
  loader: () =>
    import(/* webpackChunkName: "roi" */ '../containers/RoiCustomerDetailsForm'),
  loading: Loading
});

const RoiReview = Loadable({
  loader: () => import(/* webpackChunkName: "roi" */ '../containers/RoiReview'),
  loading: Loading
});

const RoiConfirmation = Loadable({
  loader: () =>
    import(/* webpackChunkName: "roi" */ '../containers/RoiConfirmation'),
  loading: Loading
});

const RoiRegistrationFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "roi" */ '../containers/RoiRegistrationFound'),
  loading: Loading
});

const routes = store => {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        // TODO: Test private route condition
        const { customer } = store.getState();
        const isAuthorised = Boolean(
          customer.presale || customer.nric || customer.email
        );
        if (isAuthorised) {
          return <Component {...props} />;
        } else {
          let appType = props.location.pathname.match(/^\/[^/]+/)[0];
          const tokenQueryString = props.location.search;
          const campaign = props.match.params.campaign;
          return (
            <Redirect
              to={{
                pathname: `${appType}/${campaign}${tokenQueryString}`,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );

  const CcoRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        if (store.getState().user.role) {
          return <Component {...props} />;
        } else {
          const campaign = props.match.params.campaign;
          return (
            <Redirect
              to={{
                pathname: `/cco/${campaign}`,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );

  const AppointmentRoutes = () => {
    return (
      <React.Fragment>
        <Route
          path="/appointment"
          exact
          render={props => {
            if (isCuriosityRover) {
              return <AppointmentStaticEarly />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: `/appointment/${defaultDevice}/plan`,
                    state: { from: props.location },
                    search: props.location.search
                  }}
                />
              );
            }
          }}
        />

        <Route
          path="/appointment/:campaign/plan"
          exact
          component={isGalileoGalilei ? StaticEarly : RoutedAppointmentPlan}
        />

        <Route
          path="/appointment/:campaign"
          exact
          component={
            isCuriosityRover ? AppointmentStaticEarly : AppointmentLanding
          }
        />

        {isCuriosityRover && (
          <Route
            path={`/appointment/:campaign/curiosity-rover`}
            exact
            strict
            component={AppointmentLanding}
          />
        )}

        <PrivateRoute
          path="/appointment/:campaign/device/:productIndex/:isEdit?"
          component={AppointmentSelectDevice}
        />

        <PrivateRoute
          path="/appointment/:campaign/booking"
          component={AppointmentLocations}
        />

        <PrivateRoute
          path="/appointment/:campaign/review"
          component={AppointmentReview}
        />

        <PrivateRoute
          path="/appointment/:campaign/confirmation"
          component={AppointmentConfirmation}
        />

        <PrivateRoute
          path="/appointment/:campaign/registration"
          component={AppointmentFound}
        />

        <PrivateRoute
          path="/appointment/:campaign/details"
          component={AppointmentCustomerDetails}
        />
      </React.Fragment>
    );
  };

  const RoiRoutes = () => (
    <React.Fragment>
      <Route
        path="/register"
        exact
        render={props => {
          if (isGalileoGalilei) {
            return <StaticEarly />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: `/register/${defaultDevice}`,
                  state: { from: props.location }
                }}
              />
            );
          }
        }}
      />

      <Route
        path="/register/:campaign"
        exact
        strict
        component={isGalileoGalilei ? StaticEarly : RoiLanding}
      />

      <Route
        path="/register/:campaign/plan"
        exact
        component={isGalileoGalilei ? StaticEarly : RoutedRoiRegistrationPlan}
      />

      {isGalileoGalilei && (
        <Route
          path={`/register/:campaign/galileo-galilei`}
          exact
          strict
          component={RoiLanding}
        />
      )}

      <PrivateRoute
        path="/register/:campaign/device/:productIndex/:isEdit?"
        component={RoiSelectDevice}
      />
      <PrivateRoute
        path="/register/:campaign/details"
        component={RoiCustomerDetailsForm}
      />
      <PrivateRoute path="/register/:campaign/review" component={RoiReview} />
      <PrivateRoute
        path="/register/:campaign/confirmation"
        component={RoiConfirmation}
      />
      <PrivateRoute
        path="/register/:campaign/registration"
        component={RoiRegistrationFound}
      />
    </React.Fragment>
  );

  const CcoRoutes = () => (
    <React.Fragment>
      <Route path="/cco/:campaign" exact strict component={CcoLogin} />
      <CcoRoute path="/cco/:campaign/landing" component={CcoLanding} />
      <CcoRoute path="/cco/:campaign/customer" component={CcoCustomer} />
      <CcoRoute path="/cco/:campaign/products" component={CcoProducts} />
      <CcoRoute path="/cco/:campaign/timeslots" component={CcoTimeSlots} />
    </React.Fragment>
  );

  return (
    <Switch>
      {/* Book An Appointment */}
      <Route path="/appointment" component={AppointmentRoutes} />
      {/* Register Of Interest */}
      <Route path="/register" component={RoiRoutes} />
      {/* Do not allow viewing of root path */}
      <Route
        path="/"
        exact
        render={() => {
          window.location.href = 'https://www.singtel.com/iphone';
          return null;
        }}
      />
      {/* Error page for technical issues */}
      <Route path="/error" exact component={ErrorPage} />
      <Route
        path="/payment-success/:campaign"
        exact
        component={PaymentSuccessPage}
      />
      <Route
        path="/payment-failure/:campaign"
        exact
        component={PaymentFailurePage}
      />
      <Route path="/closed/:campaign" component={CampaignClosed} />
      {/* CCO */}
      <Route path="/cco" component={CcoRoutes} />
      <Route path="/thomas-harriot" exact component={ThomasHarriot} />
      {/* 404 */}
      <Route component={NoMatch} />
    </Switch>
  );
};

export default routes;
