/* stylelint-disable */
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { withStyledAttrs } from '../../helpers/styled';
import Text from '../Text';
import theme from '../../theme';

const CutOut = withTheme(
  styled.div(
    p => `
  width: 2px;
  height: calc(100% - 16px);
  margin-top: 8px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  left: 103px;
  @media (min-width: ${p.coreTheme.brk_sm}) {
    left: 141px;
  }

  background: radial-gradient(ellipse at center, ${p.backgroundColor ||
    p.coreTheme.cl_white} 0%, ${p.backgroundColor ||
      p.coreTheme.cl_white} 30%, transparent 30%);
  background-size: 5px 5px;
  background-repeat: repeat-y;
  background-position: center;
`
  )
);

const TagText = withStyledAttrs(Text, {
  type: 'boldBody',
  lineHeight: 'compact',
  fontSize: [12, 12, 13, 14, 14]
})`
color: ${theme.main.colours.white} !important;
`;

const Tag = styled.div(
  p => `
  color: ${theme.main.colours.white};
  background-color: ${p.backgroundColor};
  width: 100%;
  text-align: center;
`
);

const CouponCover = withTheme(styled.div`
  width: 104px;
  height: inherit;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 142px;
  }

  ${Tag} {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 20px;
  }

  & > img {
    width: 100%;
    height: auto;
  }
`);

const CouponBody = styled.div`
  width: 223px;
  overflow: hidden;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 250px;
    padding: 20.5px 16px;
  }
`;

const Title = withTheme(
  withStyledAttrs(Text, {
    type: 'boldBody',
    lineHeight: 'compact',
    fontSize: [14, 14, 15, 16, 16]
  })(
    p => `
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.maxLines};
  `
  )
);

const SubTitle = withTheme(
  withStyledAttrs(Text, {
    type: 'smallBody',
    lineHeight: 'compact',
    fontSize: [14, 14, 14, 14, 14]
  })(
    p => `
  color: ${p.expiring ? p.coreTheme.cl_pri_d3 : p.coreTheme.cl_ter_l2};
`
  )
);

const Value = withTheme(
  withStyledAttrs(Text, {
    type: 'smallBody',
    lineHeight: 'compact',
    fontSize: [14, 14, 15, 16, 16]
  })(
    p => `
  color: ${p.expiring ? p.coreTheme.cl_pri_d3 : p.coreTheme.cl_ter_l2};
`
  )
);

const ValueWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
    width: 16px;
    height: 16px;

    @media (min-width: ${theme.breakpoints.sm}) {
      width: 24px;
      height: 24px;
    }
  }
`;

const Container = withTheme(
  styled.div(
    p => css`
      background-color: inherit;
      display: flex;
      width: max-content;
      height: 104px;
      box-shadow: ${p.coreTheme.sd_size_1};
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;

      @media (min-width: ${p.coreTheme.brk_sm}) {
        height: 142px;
      }

      &::before,
      ::after {
        content: '';
        position: absolute;
        left: 96px;
        width: 16px;
        height: 16px;
        background-color: ${p.coreTheme.cl_white};
        z-index: 1;

        @media (min-width: ${p.coreTheme.brk_sm}) {
          left: 134px;
        }

        ${p.curveStyle && p.curveStyle}
      }

      &::before {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        top: -8px;
        box-shadow: 0 -1px 2px -1px rgba(0 0 0 / 20%) inset;
      }

      &::after {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        bottom: -8px;
        /* box-shadow: 0 1px 2px -1px rgba(0 0 0 / 20%) inset; */
        box-shadow: -2px 6px 2px -5px rgba(0 0 0 / 20%) inset;
      }

      ${p.disabled &&
        `
    box-shadow: none;

    &::before,::after {
      box-shadow: none;
    }

    svg {
      path {
        fill: ${p.coreTheme.cl_ter_l2}
      }
    }

    ${CouponBody} {
      background-color: ${p.coreTheme.cl_ter_l5};
    }

    ${Tag} {
      background-color: ${p.coreTheme.cl_ter_l1};
    }
  `}
    `
  )
);

export {
  Container,
  CouponCover,
  CouponBody,
  Tag,
  TagText,
  Title,
  SubTitle,
  ValueWrapper,
  Value,
  CutOut
};
