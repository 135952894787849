import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from './MaskedInput';
import styled, { css } from 'styled-components';
import { noop, testIdProps } from '../../helpers';
import FormField from '../../core/components/Forms/FormField';
import FormInput from '../../core/components/Forms/FormInput';
import LabelText from '../../core/components/Forms/LabelText';
import HintText from '../../core/components/Forms/HintText';
import FormFieldHint from '../../core/components/Forms/FormFieldHint';
import { withTheme, useTheme } from '../../core/ThemeProvider';
import loadingAnimation from '@dls/assets/lottiefiles/loading';
import { Fade } from '../../core/animation';
import Animation from '../Animation';

const LoadingOverlay = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/* eslint-disable-next-line react/display-name */
const InputText = React.forwardRef(function InputText(props, ref) {
  /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
  const {
    error,
    defaultValue,
    displayFormat,
    disabled,
    label,
    hintMessage,
    suffix,
    loading,
    icon,
    rightTextLink,
    bgColor,
    alwaysShowAsHint,
    onChange,
    ...rest
  } = props;

  const { theme } = useTheme();
  const value = useRef(defaultValue);

  const handleRightTextLinkClick = e => {
    blockFieldInteraction(e);
    rightTextLink.onClick && rightTextLink.onClick(e, value.current);
  };

  const _onChange = (e, rawValue) => {
    value.current = e.target.value;
    if (typeof onChange === 'function') {
      onChange(e, rawValue);
    }
  };

  const renderInputSuffix = () => {
    if (suffix) return suffix;

    /* keep it for now, as it might be used in other projects */
    if (icon) return <AdornmentImage src={icon.src} alt={icon.alt} />;

    /* keep it for now, as it might be used in other projects */
    if (rightTextLink)
      return (
        <AdornmentText
          onClick={handleRightTextLinkClick}
          data-testid="textLinkId"
        >
          {rightTextLink.text}
        </AdornmentText>
      );

    return null;
  };

  return (
    <div {...testIdProps(props)}>
      <FormField
        disabled={disabled}
        defaultValue={defaultValue}
        error={error}
        bgColor={bgColor}
      >
        <InputContainer loading={loading}>
          {displayFormat ? (
            <MaskedInput
              {...rest}
              {...testIdProps({}, 'inputField')}
              onChange={_onChange}
              ref={ref}
              disabled={disabled}
              displayFormat={displayFormat}
              defaultValue={defaultValue}
            />
          ) : (
            <FormInput
              {...rest}
              {...testIdProps({}, 'inputField')}
              onChange={_onChange}
              disabled={disabled}
              defaultValue={defaultValue}
              ref={ref}
              alwaysShowAsHint={alwaysShowAsHint}
              as="input"
              error={props.error}
            />
          )}

          {(suffix || icon || rightTextLink) && (
            <InputAdornment
              onClick={blockFieldInteraction}
              onMouseDown={blockFieldInteraction}
            >
              {renderInputSuffix()}
            </InputAdornment>
          )}

          <LabelText
            data-testid="input-label"
            alwaysShowAsHint={alwaysShowAsHint}
          >
            {label}
          </LabelText>
        </InputContainer>
        <Fade
          visible={loading}
          css={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0
          }}
        >
          <LoadingOverlay>
            <Animation
              data-testid="loading-animation"
              source={loadingAnimation}
              size="22px"
              color={theme.cl_sec_d1}
              action="play"
            />
          </LoadingOverlay>
        </Fade>
      </FormField>
      {hintMessage && (
        <FormFieldHint>
          <HintText error={error} data-testid="hintId">
            {hintMessage}
          </HintText>
        </FormFieldHint>
      )}
    </div>
  );
});

InputText.defaultProps = {
  disabled: false,
  error: false,
  onChange: noop,
  type: 'text',
  bgColor: 'white'
};

InputText.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Background color of Input fields.
   * @default white
   */
  bgColor: PropTypes.oneOf(['haze', 'white']),
  /**
   * A default value to prefill the component.
   */
  defaultValue: PropTypes.string,
  /**
   * Disable the element
   */
  disabled: PropTypes.bool,
  /**
   * Text displayed in the text field is formatted as the user types.
   */
  displayFormat: PropTypes.oneOf(['credit-card', 'date', 'mobile-sg']),
  /**
   * Error flag
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Hint message displayed under TextField
   */
  hintMessage: PropTypes.string,
  /**
   * Icon name for icon button.
   */
  icon: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string.isRequired
  }),
  /**
   * Label
   */
  label: PropTypes.string.isRequired,
  /**
   * loading overlay
   * @default false
   */
  loading: PropTypes.bool,
  /**
   * Maximum value length
   */
  maxLength: PropTypes.number,
  /**
   * Change event handler
   */
  onChange: PropTypes.func,
  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Right text link
   */
  rightTextLink: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired
  }),
  /**
   * suffix in input
   */
  suffix: PropTypes.any,
  /**
   * Type of the input field
   */
  type: PropTypes.string,
  /**
   * value prop for input field.
   * When set, the component becomes a controller component.
   */
  value: PropTypes.string
};

// Prevent click and mouse events to propagate down to the input field.
const blockFieldInteraction = e => {
  e.preventDefault();
  e.stopPropagation();
};

/**
 * Container for placing UI on the left or the right side of the input field.
 * @param {'end'|'begin'} position Where to place the UI.
 */
const InputAdornment = styled.div(
  p => css`
    position: relative;
    bottom: 10px;
    display: flex;
    padding: 0 0 0 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${p.position === 'end' ? { left: 0 } : { right: 0 }}
  `
);

const AdornmentImage = styled.img`
  width: 40px;
`;

const AdornmentText = withTheme(styled.span`
  padding: 4px 0;
  font-family: ${p => p.coreTheme.ff_sec_bold};
  color: ${p => p.coreTheme.cl_sec_d1};
  direction: rtl;
  ${p => p.onClick && { cursor: 'pointer' }}
`);

const InputContainer = styled.div`
  display: flex;
  height: 30px;

  ${p =>
    p.loading &&
    `
 opacity: 0.5
  `}
`;

export default InputText;
