export default {
  gutterWidth: 1,
  outerMargin: 1,
  container: {
    lg: 77.5, //1240px
    xl: 90 //1440px
  },
  breakpoints: {
    xs: 0,
    sm: 48, //768px
    md: 62, //992px
    lg: 75, //1200px
    xl: 100 //1600px
  },
  minWidth: 22.5, //360px
  maxWidth: 120 //1920px
};
