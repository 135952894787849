import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  StyledVoucher,
  StyledLeftCorner,
  StyledVoucherLeftCorner,
  StyledVoucherContentCorner,
  StyledVoucherContent,
  StyledText,
  StyledAmount,
  StyledRightCorner,
  StyledRightCornerItem,
  StyledVoucherRightCorner
} from './styles';

export const VOUCHER_STATUS = {
  DEFAULT: 'DEFAULT',
  SELECTED: 'SELECTED',
  NOT_AVAILABLE: 'DISABLED'
};

const VOUCHER_STATUS_TO_TEXT = {
  APPLY: 'Apply',
  REMOVE: 'Remove',
  NOT_AVAILABLE: 'Not available'
};

const VoucherItem = ({
  id,
  name,
  selected,
  disabled,
  voucherAmountText,
  voucherExpiryText,
  onChange,
  ...rest
}) => {
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (selected) {
      setStatus(VOUCHER_STATUS.SELECTED);
    } else if (disabled) {
      setStatus(VOUCHER_STATUS.NOT_AVAILABLE);
    } else {
      setStatus(VOUCHER_STATUS.DEFAULT);
    }
  }, [selected, disabled]);

  const handleChangeStatus = () => {
    if (!disabled) {
      onChange(id);
    }
  };

  const renderItem = () => {
    let actionText;
    if (selected) {
      actionText = VOUCHER_STATUS_TO_TEXT.REMOVE;
    } else if (disabled) {
      actionText = VOUCHER_STATUS_TO_TEXT.NOT_AVAILABLE;
    } else {
      actionText = VOUCHER_STATUS_TO_TEXT.APPLY;
    }

    return <StyledText type="link">{actionText}</StyledText>;
  };

  return (
    <StyledVoucher
      type={status}
      onClick={handleChangeStatus}
      data-testid={rest['data-testid']}
    >
      <StyledLeftCorner>
        <StyledVoucherLeftCorner />
      </StyledLeftCorner>

      <StyledVoucherContentCorner>
        <StyledVoucherContent>
          <StyledText type="boldBody">{name}</StyledText>
          <StyledAmount type="sectionTitle">{voucherAmountText}</StyledAmount>
          <StyledText type="smallBody">{voucherExpiryText}</StyledText>
        </StyledVoucherContent>
      </StyledVoucherContentCorner>

      <StyledRightCorner>
        <StyledRightCornerItem>{renderItem()}</StyledRightCornerItem>
        <StyledVoucherRightCorner />
      </StyledRightCorner>
    </StyledVoucher>
  );
};

VoucherItem.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * if disabled
   */
  disabled: PropTypes.bool.isRequired,
  /**
   * voucher id
   */
  id: PropTypes.string.isRequired,
  /**
   * voucher name
   */
  name: PropTypes.string.isRequired,
  /**
   * On Change event
   */
  onChange: PropTypes.func.isRequired,
  /**
   * if selected
   */
  selected: PropTypes.bool.isRequired,
  /**
   * voucher amount text
   */
  voucherAmountText: PropTypes.string.isRequired,
  /**
   * voucher expiry text
   */
  voucherExpiryText: PropTypes.string.isRequired
};

VoucherItem.defaultProps = {
  selected: false,
  disabled: false,
  'data-testid': 'voucher-item'
};

export default VoucherItem;
