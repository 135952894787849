import React from 'react';
import PropTypes from 'prop-types';
import RadioInput from './RadioInput';
import FormFieldHint from '../../core/components/Forms/FormFieldHint';
import HintText from '../../core/components/Forms/HintText';

const RadioGroup = ({ options, value, name, onChange, error }) => {
  const handleChange = (e, radioItem, index) => {
    onChange && onChange(e, radioItem, index);
  };

  return (
    <div>
      {options.map((radioOption, index) => {
        return (
          <RadioInput
            key={index}
            onChange={e => handleChange(e, radioOption, index)}
            value={radioOption.value}
            checked={radioOption.value === value}
            error={radioOption.error}
            name={name}
            id={radioOption.id}
          >
            {radioOption.label}
          </RadioInput>
        );
      })}
      {error && (
        <FormFieldHint>
          <HintText error={true}>{error}</HintText>
        </FormFieldHint>
      )}
    </div>
  );
};

RadioGroup.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Error message for the `RadioGroup`
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Name for the radio group
   */
  name: PropTypes.string.isRequired,
  /**
   * Change handler
   */
  onChange: PropTypes.func,
  /**
   * props list of radio items to be rendered in the `RadioGroup`
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired
    })
  ),
  /**
   * Value for the Radio group
   */
  value: PropTypes.string
};

export default RadioGroup;
