import React from 'react';
import PropTypes from 'prop-types';
import { Column, Spacing } from '../../index';
import {
  StyledEmptyContentContainer,
  StyledBodyText,
  StyledGrid,
  StyledHeaderText,
  StyledIconImage,
  StyledRow,
  StyledTextContainer,
  Wrapper
} from './style';
import { testIdProps } from '../../helpers';

const renderIcon = (icon, iconAlt) => {
  const MobileIcon = icon;
  if (typeof MobileIcon === 'string') {
    return (
      <StyledIconImage src={icon} alt={iconAlt} data-testid="bannerIconImage" />
    );
  }
  return <MobileIcon />;
};

const Banner = ({
  headerText,
  bodyText,
  desktopImage,
  mobileImage,
  textColor,
  textDesktopAlignment = 'left',
  icon,
  iconAlt,
  ...rest
}) => {
  return (
    <Wrapper
      desktopImage={desktopImage}
      mobileImage={mobileImage}
      {...testIdProps(rest)}
    >
      <StyledGrid>
        <StyledRow reverse={textDesktopAlignment === 'left'}>
          <Column xs={12} sm={6} noGutter>
            <StyledEmptyContentContainer />
          </Column>
          <Column xs={12} sm={6} noGutter>
            <StyledTextContainer>
              {icon && (
                <>
                  {renderIcon(icon, iconAlt)}
                  <Spacing top={4} />
                </>
              )}

              {headerText && (
                <StyledHeaderText
                  type="bannerTitle"
                  data-testid="bannerHeaderText"
                  color={textColor}
                >
                  {headerText}
                </StyledHeaderText>
              )}

              {bodyText && (
                <StyledBodyText
                  type="body"
                  data-testid="bannerBodyText"
                  color={textColor}
                >
                  {bodyText}
                </StyledBodyText>
              )}
            </StyledTextContainer>
          </Column>
        </StyledRow>
      </StyledGrid>
    </Wrapper>
  );
};

Banner.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Banner description
   */
  bodyText: PropTypes.string,
  /**
   * Desktop image src
   */
  desktopImage: PropTypes.string.isRequired,
  /**
   * Banner title
   */
  headerText: PropTypes.string,
  /**
   * Icon image
   */
  icon: PropTypes.any,
  /**
   * Icon image alternative text
   */
  iconAlt: PropTypes.string,
  /**
   * Mobile image src
   */
  mobileImage: PropTypes.string.isRequired,
  /**
   * Banner content text color hex code
   *  @default "black"
   */
  textColor: PropTypes.string,
  /**
   * Desktop text alignment
   */
  textDesktopAlignment: PropTypes.oneOf(['left', 'right'])
};

export default Banner;
