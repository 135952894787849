import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { isAfter } from 'date-fns';
import DatePickerCalendarContainer from './DatePickerCalendarContainer';
import Calendar from '../Calendar';
import { formatDate } from '../../core/utils';

export const SingleDatePickerCalendar = ({
  datePickerProps = {},
  calendarProps: { availableDays, defaultOptions } = {},
  useSelectedDate,
  useDropdownVisible,
  formatter,
  disabledDays
}) => {
  const { setDropDownVisible } = useDropdownVisible();
  const { selectedDate, setSelectedDate } = useSelectedDate();
  const [currentMonth, setCurrentMonth] = useState(
    selectedDate || defaultOptions.month
  );

  const originalSelectedDate = useMemo(() => selectedDate, []);

  const Container = useCallback(
    ({ children, selectedDays }) => (
      <DatePickerCalendarContainer
        onClear={() => {
          setSelectedDate();
          datePickerProps.onChange();
          datePickerProps.onClear();
        }}
        onConfirm={() => {
          datePickerProps.onConfirm(selectedDays);
          setDropDownVisible(false);
        }}
        goBack={() => {
          setSelectedDate(originalSelectedDate);
          setDropDownVisible(false);
        }}
        selectedDateText={
          selectedDays && selectedDays.length !== 0
            ? formatDate(selectedDays, formatter)
            : ''
        }
      >
        {children}
      </DatePickerCalendarContainer>
    ),
    []
  );

  return (
    <Calendar
      selectedDays={selectedDate}
      availableDays={availableDays}
      defaultOptions={{
        month: currentMonth
      }}
      onDayClick={(day /*, modifiers, e*/) => {
        setSelectedDate(day);
        datePickerProps.onChange(day);
      }}
      onMonthChange={date => setCurrentMonth(date)}
      Container={Container}
      data-testid={'calendar-singleDatePicker'}
      disabledDays={disabledDays}
    />
  );
};

export const DateRangeCalendar = ({
  datePickerProps,
  calendarProps: { availableDays, defaultOptions = {} } = {},
  useSelectedDate,
  useDropdownVisible,
  formatter,
  disabledDays
}) => {
  const { setDropDownVisible } = useDropdownVisible();
  const { selectedDate, setSelectedDate } = useSelectedDate();
  const [range, setRange] = useState(selectedDate || {});
  const [currentMonth, setCurrentMonth] = useState(
    (selectedDate && selectedDate.from) || defaultOptions.month
  );

  const { from, to } = range;

  const originalSelectedDate = useMemo(() => selectedDate, []);

  useEffect(() => {
    // fire on change when new date(s) picked
    if (Object.keys(range).length !== 0) {
      datePickerProps.onChange(range);
    }
  }, [range]);

  const Container = useCallback(
    ({ children, selectedDays }) => (
      <DatePickerCalendarContainer
        onClear={() => {
          setSelectedDate();
          datePickerProps.onChange();
          datePickerProps.onClear();
        }}
        onConfirm={() => {
          datePickerProps.onConfirm(selectedDays);
          setDropDownVisible(false);
        }}
        goBack={() => {
          setSelectedDate(originalSelectedDate);
          setDropDownVisible(false);
        }}
        selectedDateText={
          selectedDays && selectedDays.length !== 0
            ? formatDate(selectedDays, formatter)
            : ''
        }
      >
        {children}
      </DatePickerCalendarContainer>
    ),
    []
  );

  // TODO tests date range select api.
  return (
    <Calendar
      selectedDays={selectedDate}
      showSelectedDaysAsRange={!!(from && to)}
      onDayClick={(day /*, modifiers, e*/) => {
        if (!from) {
          setSelectedDate(day);
          setRange({ from: day });
          return;
        }
        if (!to) {
          const dateObj = isAfter(from, day)
            ? { from: day, to: from }
            : { from, to: day };
          setSelectedDate(dateObj);
          setRange(dateObj);
          return;
        }
        if (from && to) {
          setSelectedDate(day);
          setRange({ from: day, to: null });
        }
      }}
      availableDays={availableDays}
      onMonthChange={date => setCurrentMonth(date)}
      defaultOptions={{
        month: currentMonth
      }}
      Container={Container}
      data-testid={'calendar-dateRangePicker'}
      disabledDays={disabledDays}
    />
  );
};
