import React from 'react';
import ReactGA from 'react-ga';
import {colours as COLOURS, gtmContainerId} from '../configs';
import { Provider } from 'react-redux';
import createStore from '../store';
import {isValidAlphaWithSpace, isValidEmail, isValidMobileNumber} from "@lux/helpers";
import constants from "../configs/constants";

const validation = {
  name: {
    MAX_LENGTH: 255
  },
  mobile: {
    MAX_LENGTH: 255
  },
  email: {
    MAX_LENGTH: 255
  }
};

export const normalizedProductItem = product => {
  return {
    id: product.id,
    sku: product.sku,
    description: product.description,
    model: {
      name: product.values.find(v => v.option.name === 'model').name,
      id: product.values.find(v => v.option.name === 'model').id
    },
    colour: {
      name: product.values.find(v => v.option.name === 'color').name,
      id: product.values.find(v => v.option.name === 'color').id
    },
    capacity: {
      name: product.values.find(v => v.option.name === 'capacity').name,
      id: product.values.find(v => v.option.name === 'capacity').id
    },
    available: product.available,
    backorder: product.backorder
  };
};

export const sendGaEvent = (category, action, label) => {
  ReactGA.event({
    category: category + '',
    action: action + '',
    label: label + '',
    nonInteraction: true
  });
};

export const sendApiToGaEvent = (apiName, status) => {
  sendGaEvent('API Status Codes', apiName, status);
};

export const resetGTMDataLayer = () => {
  if(gtmContainerId && window.google_tag_manager &&
    window.google_tag_manager[gtmContainerId]) {
    window.google_tag_manager[gtmContainerId].dataLayer.reset();
  }
}

export const getIndividualProduct = (products) => {
  let dataLayerProducts = [];
  Object.keys(products).map(function(key) {
    const product = products[key];
    const capacity = product.capacity.name;
    const colour = product.colour.name;
    const model = product.model.name;
    const backorder = product.backorder;
    dataLayerProducts.push({
      model: model,
      colour: colour,
      capacity: capacity,
      backorder: backorder
    })
  });

  return dataLayerProducts;
}

export const sendDataLayerEvent = (props) => {
  const { reservedProducts, reservedLocationAndTimeSlot, customer} = props;
  const dataLayerProducts = getIndividualProduct(reservedProducts);
  const reservedLocation = reservedLocationAndTimeSlot && reservedLocationAndTimeSlot.location;
  const location = reservedLocation && reservedLocation.name;
  const isPayOnline = reservedLocation && reservedLocation.payonline;
  const paymentDetails = reservedLocationAndTimeSlot && reservedLocationAndTimeSlot.timeSlot;
  const appointmentOrder =  {
      productsModel: dataLayerProducts,
      switchingTelco: customer && !!customer.portIn,
      paymentLocation: location,
      paymentMethod: isPayOnline ? 'Pay online' : 'Pay in store',
      paymentDetails: paymentDetails
  };

  if(window.dataLayer) {
    window.dataLayer.push({
      event: 'AppointmentOrder',
      eventProps: appointmentOrder
    });
  }
}

export const sendRoiDataLayer = (props) => {
  const { selectedProducts, customer} = props;
  const dataLayerProducts = getIndividualProduct(selectedProducts);
  const roiOrder =  {
    productsModel: dataLayerProducts,
    switchingTelco: customer && !!customer.portIn
  };

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'RoiOrder',
      eventProps: roiOrder
    });
  }
}

export const ignoreQueryString = url => {
  return url.split(/[?#]/)[0];
};

export const formatDate = input => {
  const pad = s => {
    return s < 10 ? '0' + s : s;
  };
  const d = new Date(input);
  return [pad(d.getDate()), getMonthName(pad(d.getMonth() + 1)), d.getFullYear()].join(' ');
};

export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber-1);
  return date.toLocaleString('en-US', { month: 'short' });
}

export const getDay = input => {
  const d = new Date(input);
  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];
  return days[d.getDay()];
};

export const extractDateTime = datetime => {
  const dateWithTime = datetime && datetime.split(' ');
  return {
    time: `${dateWithTime[1]} ${dateWithTime[2]}`,
    date: dateWithTime[0]
  };
};

export const getColourValueByName = name => {
  const colourByName = COLOURS.find(colour => colour.name === name);
  if (colourByName) {
    return colourByName.value;
  }
};

export const findProduct = (products, modelId, colourId, capacityId) => {
  const modelObject = products[modelId];
  const colorObject = modelObject && modelObject[colourId];
  const capacityObject = colorObject && colorObject[capacityId];

  if(modelObject && colorObject && capacityObject) {
    return {
      model: {
        name: modelObject.name,
        id: modelId
      },
      colour: {
        name: colorObject.name,
        id: colourId
      },
      capacity: {
        name: capacityObject.name,
        id: capacityId
      },
      id: capacityObject.id,
      sku: capacityObject.sku,
      description: capacityObject.description,
      available: capacityObject.available,
      backorder: capacityObject.backorder
    };
  } else
    return undefined;
}

export const getPreSelectedProduct = (products) => {
  const {
    uniqueModels,
    uniqueColours,
    uniqueCapacities
  } = availProducts(products);

  // Filter out the available models from the given products
  const availableModelIds = uniqueModels.filter(uniqueModel => {
    const model = products && products[uniqueModel.id];
    return model && model.available;
  });
  const preSelectModel = availableModelIds[0];

  // Filter out the available colour ids from the pre-selected model
  const availableColourIds = uniqueColours.filter(uniqueColour => {
    const {id} = uniqueColour;
    const colour = preSelectModel && products[preSelectModel.id][id];
    return colour && colour.available;
  });
  const preSelectColor = availableColourIds[0];

  // Filter out the available size ids from the pre-selected model and pre-selected colour
  const availableSizeIds = uniqueCapacities.filter(uniqueCapacity => {
    const { id } = uniqueCapacity;
    const capacity = preSelectColor && products[preSelectModel.id][preSelectColor.id][id];
    return capacity && capacity.available;
  });

  const preSelectSize = sortCapacity(availableSizeIds)[0];

  return {
    preSelectModelId: preSelectModel && preSelectModel.id,
    preSelectColorId: preSelectColor && preSelectColor.id,
    preSelectCapacityId: preSelectSize && preSelectSize.id
  }
}

export const getPreselectedColorNSize = (
  selectedModelId,
  normalizedProducts,
  uniqueColours,
  uniqueCapacities) => {
  // Filter out the available colour ids from the pre-selected model
  const availableColourIds = uniqueColours.filter(uniqueColour => {
    const {id} = uniqueColour;
    const colour = selectedModelId && normalizedProducts[selectedModelId][id];
    return colour && colour.available;
  });
  const preSelectColor = availableColourIds[0];

  // Filter out the available size ids from the pre-selected model and pre-selected colour
  const availableSizeIds = uniqueCapacities.filter(uniqueCapacity => {
    const { id } = uniqueCapacity;
    const capacity = preSelectColor && normalizedProducts[selectedModelId][preSelectColor.id][id];
    return capacity && capacity.available;
  });

  const preSelectSize = sortCapacity(availableSizeIds)[0];

  return {
    preSelectColorId: preSelectColor && preSelectColor.id,
    preSelectCapacityId: preSelectSize && preSelectSize.id
  }
}

export const availProducts = (products) => {

  const modelsList = [];
  const colorsList = [];
  const sizesList = [];
  const availableProducts = [];
  const productKeys = Object.keys(products);
  productKeys.forEach(function (modelKey) {
    const modelObject = products[modelKey];
    const deviceModel = {
      id: modelKey,
      name: modelObject.name
    };

    modelsList.push(deviceModel);
    const modelKeys = Object.keys(modelObject);
    modelKeys.forEach(function (colorKey) {
      const colorObj = modelObject[colorKey];

      if (isObject(colorObj)) {
        const colorModel = {
          id: colorKey,
          name: colorObj.name
        };

        colorsList.push(colorModel);
        const sizeKeys = Object.keys(colorObj);
        sizeKeys.forEach(function (sizeKey) {
          const sizeObj = colorObj[sizeKey];
          if (isObject(sizeObj)) {
            const sizeModel = {
              id: sizeKey,
              name: sizeObj.name
            };
            sizesList.push(sizeModel);
            if (
              modelObject.available &&
              colorObj.available &&
              sizeObj.available
            ) {
              const availableProduct = {
                model: deviceModel,
                colour: colorModel,
                capacity: sizeModel,
                id: sizeObj.id,
                description: sizeObj.description,
                sku:  sizeObj.sku,
                available: sizeObj.available,
                backorder: sizeObj.backorder
              };
              availableProducts.push(availableProduct);
            }
          }
        });
      }
    });
  });

  const uniqueModels = filterUniqueById(modelsList);
  const uniqueColours = filterUniqueById(colorsList);
  const uniqueCapacities = filterUniqueById(sizesList);

  return {
    uniqueModels,
    uniqueColours,
    uniqueCapacities,
    availableProducts
  };
}

export const isReservedProductPreOrderWithPaylater = (reservedLocationAndTimeSlot, reservedProducts) => {
  const isPayOnline = reservedLocationAndTimeSlot && reservedLocationAndTimeSlot.location
    && reservedLocationAndTimeSlot.location.payonline;

  let isReservedOnePreOrderModel, isPayLater;
  let isReservedOneBackOrderModel;
  if(reservedProducts && Object.keys(reservedProducts).length > 0) {
    isReservedOneBackOrderModel =  reservedProducts[0].backorder;
  }

  if(isPayOnline === undefined) {
    isPayLater = false;
  } else {
    isPayLater = !isPayOnline;
  }

  if (isReservedOneBackOrderModel === undefined) {
    isReservedOnePreOrderModel = false;
  } else {
    isReservedOnePreOrderModel = !isReservedOneBackOrderModel;
  }

  return isPayLater && isReservedOnePreOrderModel;
};

export const getAppointmentOrderDetails = order => {
  const oneOrderItem = order && order.orderItems;
  const twoOrderItems = order && order.orderItems && order.orderItems.length === 2;

  const firstOrderItem = oneOrderItem && order.orderItems[0];
  const secondOrderItem = twoOrderItems && order.orderItems[1];

  const isOnlinePayment = order.paymentType === 'PAY_ONLINE' &&
    ((firstOrderItem && firstOrderItem.paymentTimeSlotAssigned) ||
    (secondOrderItem && secondOrderItem.paymentTimeSlotAssigned));

  let firstOrderItemsStatus,
      createdDateTime,
      firstItemAllocatedDateTime,
      secondItemAllocatedDateTime;

  if(isOnlinePayment) {
    firstOrderItemsStatus = firstOrderItem && firstOrderItem.status;

    const createdDate = extractDateTime(order.appointments[0].createdDate).date;
    const createdTime = extractDateTime(order.appointments[0].createdDate).time;
    createdDateTime = createdDate+" "+createdTime;

    const firstOrderItemTimeSlot = firstOrderItem &&
      extractDateTime(firstOrderItem.paymentTimeSlot.startTimeSGT).date;
    firstItemAllocatedDateTime = firstOrderItem &&
      firstOrderItemTimeSlot+" "+
      extractDateTime(firstOrderItem.paymentTimeSlot.startTimeSGT).time;

    const secondOrderItemTimeSlot = secondOrderItem &&
      extractDateTime(secondOrderItem.paymentTimeSlot.startTimeSGT).date;
    secondItemAllocatedDateTime = secondOrderItem ?
      (secondOrderItemTimeSlot+" "+ extractDateTime(secondOrderItem.paymentTimeSlot.startTimeSGT).time)
      : null;
  }

  return {
    isOnlinePayment,
    createdDateTime,
    firstItemAllocatedDateTime,
    secondItemAllocatedDateTime,
    firstOrderItemsStatus
  };
};

export const isObject = (obj) => {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}

export const filterUniqueById = (arr) => {
  const ids = arr.map(o => o.id);
  return arr.filter(({id}, index) => !ids.includes(id, index + 1));
}

export const sortCapacity = capacities => {
  return capacities.sort((a, b) => {
    return (
      parseInt(a.name.split('GB')[0], 10) - parseInt(b.name.split('GB')[0], 10)
    );
  });
};

export const sortByNames = uniqueModels => {
  return uniqueModels.sort((a, b) => {
    return (
      (a.name && b.name) && a.name.localeCompare(b.name));
  });
}

export const timeFormatter = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  return str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
};

const { store } = createStore();

/**
 * Wrap the component with a Provider from the Redux store
 *
 * @param {HTMLElement} component
 * @returns {*}
 */
export const wrapWithProvider = component => {
  return <Provider store={store}>{component}</Provider>;
};

/**
 *
 * @param {*} shortcode
 * @param {*} rawString String to find the needle
 * @param {*} replaceStr String replacement for the shortcode
 */
export const replaceShortcode = (shortcode, rawString, replaceStr) => {
  return rawString.replace(new RegExp(shortcode, 'g'), replaceStr);
};

export const appointmentTimerInMins = durationInMilliseconds => {
  const MILLISECOND = 1000,
    MINUTE = 60;
  return durationInMilliseconds / MILLISECOND / MINUTE;
};

export function makeSecure(locationUrl) {
  return (locationUrl || '').replace(/^(http|https)(:)(\/\/)/im, 'https:$3');
}

export function compileTemplate(templateStr, data = {}) {
  return Object.entries(data).reduce((str, [key, val]) => {
    str = replaceShortcode(`{{${key}}}`, str, val);
    return str;
  }, templateStr);
}

export function checkEmail(email) {
  let error = '';
  if (!email || email.trim() === '') {
    error = constants['FORM_EMAIL_EMPTY_VALIDATION'];
  } else if (!isValidEmail(email)  || !!email.includes('.con') ||
    email.length > validation.email.MAX_LENGTH) {
    error = constants['FORM_EMAIL_VALIDATION'];
  }
  return error;
}

export function checkMobileNumber(mobile) {
  let error = '';
  if (!mobile || mobile.trim() === '') {
    error = constants['FORM_MOBILE_EMPTY_VALIDATION'];
  } else if (!isValidMobileNumber(mobile) ||
    mobile.length > validation.mobile.MAX_LENGTH) {
    error = constants['FORM_MOBILE_VALIDATION'];
  }
  return error;
}

export function checkName(name) {
  let error = '';
  if (!name || name.trim() === '' || name.length < 3) {
    error = constants['FORM_NAME_EMPTY_VALIDATION'];
  } else if (
    !isValidAlphaWithSpace(name) ||
    name.length > validation.name.MAX_LENGTH
  ) {
    error = constants['FORM_NAME_VALIDATION'];
  }
  return error;
}

export function checkEmailConfirm(email, confirmEmail) {
  let error = '';
  if (email !== confirmEmail) error = constants['FORM_CONFIRM_EMAIL_VALIDATION'];
  return error;
}

export function checkPortIn(portIn) {
  let error = '';
  if (portIn === '') {
    error = constants['FORM_PORTIN_VALIDATION'];
  }
  return error;
}

export const toYorN = value => {
  return value ? 'Yes' : 'No';
};
