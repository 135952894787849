import React from 'react';
import { Header as BaseHeader } from '@lux/components';

const Header = () => (
  <BaseHeader
    logo={{ link: '#', alt: 'Singtel' }}
    menuItems={[{ item: { active: true, title: '', src: '#' }, subMenu: [] }]}
  />
);

export default Header;
