import React from 'react';
import PropTypes from 'prop-types';
import { noop, testIdProps } from '../../helpers';
import { withTheme } from '../../core/ThemeProvider';
import MultilineTruncateParagraph from '../../core/components/MultilineTruncateParagraph';
import {
  BannerContainerVariant1,
  BannerContainerVariant2,
  ContentWrapper,
  ContentText,
  TitleVariant1,
  TitleVariant2,
  ContentBody,
  IconWrapper
} from './style';

const bannerTypes = {
  VARIANT_1: 'variant1',
  VARIANT_2: 'variant2'
};

const Variant1 = ({ onClick, title, icon: Icon, ...rest }) => (
  <BannerContainerVariant1 onClick={onClick} {...testIdProps(rest)}>
    <ContentWrapper>
      <IconWrapper>
        <Icon size={24} color={rest.coreTheme.cl_white} />
      </IconWrapper>
      <ContentText>
        {/* TODO: Utility Class for Typography and Colours */}
        <TitleVariant1 dangerouslySetInnerHTML={{ __html: title }} />
      </ContentText>
    </ContentWrapper>
  </BannerContainerVariant1>
);

const Variant2 = ({ title, icon: Icon, content, ...rest }) => (
  <BannerContainerVariant2 {...testIdProps(rest)}>
    <ContentWrapper>
      <IconWrapper>
        <Icon size={24} color={rest.coreTheme.cl_white} />
      </IconWrapper>
      <ContentText>
        {/* TODO: Utility Class for Typography and Colours */}
        <TitleVariant2 dangerouslySetInnerHTML={{ __html: title }} />
        <MultilineTruncateParagraph numOfLines={2} Typography={ContentBody}>
          {content}
        </MultilineTruncateParagraph>
      </ContentText>
    </ContentWrapper>
  </BannerContainerVariant2>
);

const PromotionalBanner = props => {
  const { type, onClick, title, icon, content, ...rest } = props;

  return type === bannerTypes.VARIANT_1 ? (
    <Variant1 {...rest} title={title} icon={icon} onClick={onClick} />
  ) : (
    <Variant2 {...rest} title={title} icon={icon} content={content} />
  );
};

PromotionalBanner.defaultProps = {
  type: bannerTypes.VARIANT_1,
  onClick: noop
};

PromotionalBanner.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Content for variant 2
   */
  content: PropTypes.string,
  /**
   * Icon component from DLS Assets
   */
  icon: PropTypes.node,
  /**
   * Click Handler
   */
  onClick: PropTypes.func,
  /**
   * Title
   */
  title: PropTypes.string,
  /**
   * Banner type
   */
  type: PropTypes.oneOf(['variant1', 'variant2']).isRequired
};

export default withTheme(PromotionalBanner);
