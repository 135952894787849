import React, { useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import CloseIcon from '@dls/assets/icons/Close';
import { noop, testIdProps } from '../../helpers';
import { withTheme, useTheme } from '../../core/ThemeProvider';
import getRandomId from '../../core/utils/getRandomId';
import { Fade, FADE_OUT_DURATION, FADE_IN_DURATION } from './animation';
import { Grid, Row, Column } from '../..';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import {
  AnimationWrapper,
  CloseWrapper,
  ModalContainer,
  NoBackgroundActivity,
  Wrapper
} from './styles';

export const ModalContext = React.createContext();

const CloseButton = withTheme(({ onClick, coreTheme }) => (
  <CloseWrapper onClick={onClick} data-testid="modal-closeBtn">
    <CloseIcon
      color={coreTheme.modal?.iconColor || coreTheme.cl_sec_d1}
      size={22}
    />
  </CloseWrapper>
));

const Modal = props => {
  const {
    visible,
    title,
    onClose,
    bgColor,
    children,
    zIndex,
    wide,
    closable,
    backdropClosable,
    alignCenter,
    scrollable,
    ...rest
  } = props;
  const { theme } = useTheme();
  const modalRef = useRef(null);
  const [exited, setExited] = React.useState(true);
  const [hasStickyFooter, setHasStickyFooter] = React.useState(null);

  if (typeof document === 'undefined') {
    return null;
  }

  const body = document.getElementsByTagName('body')[0];

  const modalNode = useMemo(() => {
    let node = document.querySelector('[id^="modal-root-"]');

    if (!node && visible) {
      node = document.createElement('div');
      node.setAttribute('id', `modal-root-${getRandomId()}`);
      body.appendChild(node);
    }

    return node;
  }, [visible]);

  useEffect(() => {
    !visible &&
      exited &&
      body.contains(modalNode) &&
      body.removeChild(modalNode);
  }, [visible, exited]);

  useEffect(() => {
    for (let child in children) {
      if (children[child]?.type === ModalFooter) {
        setHasStickyFooter(true);
      }
    }
  }, []);

  // Close modal if clicking outside of modal content
  const handleClickOverlay = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose(event);
    }
  };

  const backdropClickable = () => {
    return backdropClosable && { onClick: handleClickOverlay };
  };

  //returns null when animation exited
  if (!visible && exited) return null;

  const modalElement = (
    <AnimationWrapper zIndex={zIndex}>
      <Transition
        appear
        timeout={visible ? FADE_IN_DURATION : FADE_OUT_DURATION}
        in={visible}
        mountOnEnter
        unmountOnExit
        onEnter={() => {
          setExited(false);
        }}
        onExited={() => {
          setExited(true);
        }}
      >
        {state => (
          <>
            {visible && <NoBackgroundActivity />}
            <Fade state={state}>
              <ModalContainer
                alignCenter={alignCenter}
                {...testIdProps(rest)}
                {...backdropClickable()}
                scrollable={scrollable}
              >
                <Grid>
                  <Row center="xs">
                    <Column
                      xs={12}
                      sm={12}
                      md={wide ? theme.modal?.maxWide || 8 : 6}
                    >
                      <Wrapper
                        data-testid="modalWrapperId"
                        ref={modalRef}
                        hasTitle={!!title}
                        bgColor={bgColor}
                      >
                        <ModalContext.Provider
                          value={{
                            scrollable,
                            visible,
                            title,
                            hasStickyFooter
                          }}
                        >
                          {children}
                        </ModalContext.Provider>
                        {closable && <CloseButton onClick={onClose} />}
                      </Wrapper>
                    </Column>
                  </Row>
                </Grid>
              </ModalContainer>
            </Fade>
          </>
        )}
      </Transition>
    </AnimationWrapper>
  );

  return createPortal(modalElement, modalNode);
};

Modal.defaultProps = {
  onClose: noop,
  bgColor: 'white',
  zIndex: 1000,
  backdropClosable: true,
  alignCenter: false,
  closable: true
};

Modal.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * if true, the modal will be align center by vertically and horizontally
   */
  alignCenter: PropTypes.bool,
  /**
   * if true, the modal will be closed when the mouse click occurs outside the modal dialog box
   */
  backdropClosable: PropTypes.bool,
  /**
   * Modal background color
   * @default white
   */
  bgColor: PropTypes.oneOf(['haze', 'white']),
  /**
   * Whether a close (x) button is visible on top right of the modal dialog or not
   */
  closable: PropTypes.bool,
  /**
   * close click event handler
   * Triggered when user clicks on the close button or the backdrop
   */
  onClose: PropTypes.func,
  /**
   * Allows scrolling the modal content instead of the entire modal when overflowing
   */
  scrollable: PropTypes.bool,
  /**
   * The modal's title
   */
  title: PropTypes.string,
  /**
   * Whether the modal is visible or not
   */
  visible: PropTypes.bool,
  /**
   * if true modal will take up 8 columns width on Desktop
   * Note: this option is for Desktop view only
   */
  wide: PropTypes.bool,
  /**
   * Modal z-index value
   */
  zIndex: PropTypes.number
};

Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
