// TODO move this to env config
const devApiDomain = 'http://localhost:3000';
const apiVersion = '/api/v1';
export const backorderCampaignId = 'iphone13presale-backorder';

export const isDevelopment = process.env.NODE_ENV === 'development';
export const isTest = process.env.NODE_ENV === 'test';
export const isProduction = !isDevelopment && !isTest;

const apiHost = {
  development: devApiDomain,
  production: window.location.protocol + '//' + window.location.host
};

const host =
  apiHost[
    window.location.hostname === 'localhost'
      ? 'development'
      : process.env.NODE_ENV
  ];

import faqsPP2 from './faqs.pp2.json';
import faqsDefault from './faqs.default.json';

export const api = {
  roi: {
    campaignStatus: host + apiVersion + '/campaign',
    registration: host + apiVersion + '/campaign/:campaignId/registration',
    searchRegistration:
      host + apiVersion + '/campaign/:campaignId/search/registration',
    submitRegistration:
      host + apiVersion + '/campaign/:campaignId/registration',
    productCatalog: host + apiVersion + '/campaign/:campaignId/products',
    resendConfirmation:
      host + apiVersion + '/registration/:registrationId/resendconfirmation'
  },
  appointment: {
    registration: host + apiVersion + '/campaign/:campaignId/validate',
    productOrder: host + apiVersion + '/registration/:registrationId/orders',
    timeslots: host + apiVersion + '/campaign/:campaignId/timeslots',
    resendConfirmation:
      host + apiVersion + '/order/:orderId/resendconfirmation',
    resetSession: host + apiVersion + '/campaign/:campaignId/validate',
    // location header is prepended to these below urls
    reserveProduct: '/items?product_id=:productId&quantity=1',
    reserveTimeSlot: '/timeslot/:timeSlotId',
    submit: '/confirm',
    resetAppointment: '/reset'
  },
  cco: {
    login: host + apiVersion + '/login',
    campaignStatus: host + apiVersion + '/cco/campaign',
    productCatalog:
      host + apiVersion + '/cco/campaign/:campaignId/productcatalog',
    timeSlots: host + apiVersion + '/cco/campaign/:campaignId/timeslots',
    downloadReport: apiVersion + '/cco/campaign/:campaignId/listall.{EXT}',
    downloadLogFiles: apiVersion + '/cco/logFiles',
    updateCustomerDetails: host + apiVersion + '/cco/registration/:customerId',
    getCustomerCorrespondence:
      host + apiVersion + '/cco/registration/:customerId/correspondence',
    searchCustomers: host + apiVersion + '/cco/campaign/:campaignId/search',
    resend: host + apiVersion + '/cco/registration/:customerId/resend'
  },
  thomasHarriot: { campaign: host + apiVersion + '/a' + 'dm' + 'in/campaign' },
  common: {
    location: isDevelopment ? devApiDomain : ''
  }
};

export const colours = [
  { name: 'Ultramarine', value: '#9AADF6' },
  { name: 'Teal', value: '#B0D4D2' },
  { name: 'Pink', value: '#F2ADDA' },
  { name: 'White', value: '#FAFAFA' },
  { name: 'Black', value: '#3C4042' },
  { name: 'Desert Titanium', value: '#BFA48F' },
  { name: 'White Titanium', value: '#F2F1ED' },
  { name: 'Natural Titanium', value: '#C2BCB2' },
  { name: 'Black Titanium', value: '#3C3C3D' },
  // TODO: Remove unused colors.
  // Tests fail if removed
  { name: 'Blue', value: '#ADB4C7' },
  { name: 'Red', value: '#FC0324' },
  { name: 'Purple', value: '#E6DDEB' },
  { name: 'Starlight', value: '#FAF6F2' },
  { name: 'Midnight', value: '#222930' },
  { name: 'Deep Purple', value: '#594F63' },
  { name: 'Gold', value: '#FAE8CE' },
  { name: 'Silver', value: '#F0F2F2' },
  { name: 'Space Black', value: '#403E3D' },
  { name: 'Space Grey', value: '#111111' },
  { name: 'Black', value: '#25212b' },
  { name: 'White', value: '#f6f2ef' },
  { name: 'Coral', value: '#ff6250' },
  { name: 'Yellow', value: '#f1e05b' },
  { name: 'Topaz Blue', value: '#426f7e' },
  { name: 'Coral Red', value: '#ff4040' },
  { name: 'Green', value: '#d8efd5' },
  { name: 'Midnight Green', value: '#4f5853' },
  { name: 'Purple', value: '#ccb9e0' },
  { name: 'Pacific Blue', value: '#2d4e5c' }
];

const DEFAULT_APPOINTMENT_TIMER_VALUE = 300000;
export const DEFAULT_RECAPTCHA_SITEKEY =
  '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
export const timerValueInMilliseconds = process.env
  .LUX_APPOINTMENT_TIMER_IN_MILLISECONDS
  ? parseInt(process.env.LUX_APPOINTMENT_TIMER_IN_MILLISECONDS)
  : DEFAULT_APPOINTMENT_TIMER_VALUE;
export const gReCaptchaSitekey =
  isDevelopment || isTest
    ? DEFAULT_RECAPTCHA_SITEKEY
    : process.env.LUX_RECAPTCHA_SITEKEY;
// export const useBackorder = () =>
//   process.env.LUX_FEATURE_FLAG_USE_BACKORDER === 'false' ? false : true;
export const isGalileoGalilei = process.env.LUX_BUSINESS_TESTING;
export const isCuriosityRover = process.env.LUX_BUSINESS_APPOINTMENT_TESTING;
export const brnROIFormUrl =
  'https://www.singtel.com/business/info/apple/iphone-roi';

export const faqs =
  process.env.LUX_FAQ_ENABLE_PP2 === 'true' ? faqsPP2 : faqsDefault;

// Google Tag Manager Container ID
export const gtmContainerId = process.env.LUX_GTM_CONTAINER_ID;

// Google Analytics
export const GoogleAnalyticsId =
  window.location.hostname.indexOf('mercury') === -1 &&
  process.env.NODE_ENV === 'production'
    ? // production
      'UA-68695870-5'
    : // development
      'UA-68695870-4';
