export const shadowTypes = { sm: 'sm', lg: 'lg' };

export const radiusTypes = { sm: 'sm', md: 'md', lg: 'lg' };

export const paddingTypes = { sm: 'sm', md: 'md', lg: 'lg' };

const shadowMapValues = theme => ({
  [shadowTypes.sm]: theme.sd_size_1,
  [shadowTypes.lg]: theme.sd_size_2
});

const radiusMapValues = theme => ({
  [radiusTypes.sm]: theme.rad_xs,
  [radiusTypes.md]: theme.rad_xl,
  [radiusTypes.lg]: theme.rad_xxl
});

const paddingMapValues = theme => ({
  [paddingTypes.sm]: `${theme.space_4}px`,
  [paddingTypes.md]: `${theme.space_6}px`,
  [paddingTypes.lg]: `${theme.space_8}px`
});

export const getShadow = (shadow, theme) => shadowMapValues(theme)[shadow];

export const getRadius = (radius, theme) => radiusMapValues(theme)[radius];

export const getPadding = (padding, theme) => paddingMapValues(theme)[padding];
