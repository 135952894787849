import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { noop, testIdProps } from '../../helpers';
import { useTheme } from '../../core/ThemeProvider';
import {
  shadowTypes,
  radiusTypes,
  paddingTypes,
  getShadow,
  getRadius,
  getPadding
} from './mapValues';
import { CardContainer } from './style';
import CardContent from './CardContent';
import CardImage from './CardImage';
import CardTitle from './CardTitle';
import CardInfo from './CardInfo';
import CardBody from './CardBody';
import CardFooter from './CardFooter';

const CardComponent = (props, ref) => {
  const {
    shadow,
    radius,
    padding,
    autoHeight,
    onClick,
    children,
    ...rest
  } = props;
  const { theme } = useTheme();

  return (
    <CardContainer
      {...testIdProps(rest)}
      ref={ref}
      onClick={onClick}
      shadow={getShadow(shadow, theme)}
      radius={getRadius(radius, theme)}
      padding={getPadding(padding, theme)}
      autoHeight={autoHeight}
    >
      {children}
    </CardContainer>
  );
};

const Card = forwardRef(CardComponent);

Card.defaultProps = {
  onClick: noop,
  shadow: shadowTypes.sm,
  radius: radiusTypes.sm,
  padding: paddingTypes.sm,
  autoHeight: false
};

Card.propTypes = {
  shadow: PropTypes.oneOf([shadowTypes.sm, shadowTypes.lg]),
  radius: PropTypes.oneOf([radiusTypes.sm, radiusTypes.md, radiusTypes.lg]),
  padding: PropTypes.oneOf([paddingTypes.sm, paddingTypes.md, paddingTypes.lg]),
  autoHeight: PropTypes.bool,
  onClick: PropTypes.func
};

Card.Content = CardContent;
Card.Image = CardImage;
Card.Title = CardTitle;
Card.Info = CardInfo;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;
