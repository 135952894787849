import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from '@lux/helpers';
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';

const SwitchEffect = styled(
  withTheme(styled.div`
    display: inline-block;
    position: absolute;
    box-sizing: border-box;
    transition: 0.2s all ease-in;

    ${({ coreTheme }) => css`
      top: -${coreTheme.space_2}px;
      left: -${coreTheme.space_2}px;
      height: 48px;
      width: 48px;
      border-radius: 48px;
      border: ${coreTheme.space_2}px solid transparent;
      border-color: ${coreTheme.cl_sec_l1};
      opacity: 0;
    `}
  `)
)({});

const Label = styled.label`
  display: block;
`;

const ToggleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const Switch = withTheme(styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: -4px;
  height: ${p => p.coreTheme.space_8}px;
  width: ${p => p.coreTheme.space_8}px;
  border-radius: ${p => p.coreTheme.rad_xl};
  transition: 0.2s all ease-in;

  ${p => css`
    transform: ${p.value ? 'translate(24px)' : 0};
    box-shadow: 0 3px 8px 0 rgba(0 0 0 / 0.12);
    background-color: ${p.value
      ? p.coreTheme.cl_sec_d1
      : p.coreTheme.cl_ter_l4};
  `}

  :hover {
    ${/* sc-selector */ SwitchEffect} {
      opacity: 0.8;
    }
  }

  :active {
    ${/* sc-selector */ SwitchEffect} {
      opacity: 0.5;
    }
  }
`);

const Path = withTheme(styled.span`
  display: block;
  width: 56px;
  height: 24px;
  background-color: ${p =>
    p.active ? p.coreTheme.cl_sec_l1 : p.coreTheme.cl_ter_l5};
  border-radius: ${p => p.coreTheme.rad_xl};
  transition: 0.4s all ease;
`);

const Checkbox = styled.input`
  display: none;
`;

const Toggle = props => {
  const { name, defaultValue, onChange, ...rest } = props;

  const [currentValue, setValue] = useState(defaultValue);
  const toggleHandler = () => {
    setValue(!currentValue);
  };

  return (
    <Label>
      <ToggleWrapper onClick={() => toggleHandler()}>
        <Switch value={currentValue}>
          <SwitchEffect />
        </Switch>
        <Path active={currentValue} />
      </ToggleWrapper>
      <Checkbox
        type="checkbox"
        name={name}
        value={currentValue}
        onChange={onChange}
        {...rest}
      />
    </Label>
  );
};

Toggle.defaultProps = {
  defaultValue: false,
  onChange: noop
};

Toggle.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * default value
   */
  defaultValue: PropTypes.bool,
  /**
   * change event handler
   */
  onChange: PropTypes.func,
  /**
   * field value, used for controlled component
   */
  value: PropTypes.bool
};

export default Toggle;
