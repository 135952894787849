import { Content } from './style';
import PropTypes from 'prop-types';
import React from 'react';

const CardContent = ({ children }) => <Content>{children}</Content>;

CardContent.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Anything that can be rendered
   */
  children: PropTypes.node
};

export default CardContent;
