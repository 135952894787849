import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withOpacity } from '../../helpers';
import { noop } from '@lux/helpers';
import { withTheme } from '../../core/ThemeProvider';

const RadioInput = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    checked,
    value,
    error,
    defaultValue,
    children,
    onChange,
    ...rest
  } = props;

  return (
    <Label>
      <RadioButton error={Boolean(error)}>
        <input
          {...rest}
          id={id}
          type="radio"
          name={name}
          checked={checked}
          value={value}
          defaultValue={defaultValue}
          onChange={e => onChange(e, !checked)}
          ref={ref}
          data-testid={rest['data-testid']}
        />

        <RadioButtonEffect />
      </RadioButton>
      {children && <LabelText>{children}</LabelText>}
    </Label>
  );
});

RadioInput.displayName = 'RadioInput';

RadioInput.defaultProps = {
  value: 0,
  onChange: noop
};

RadioInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  checked: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

/* stylelint-disable no-descending-specificity */
const LabelText = withTheme(styled.span`
  ${p => css`
    color: ${p.coreTheme.cl_ter_base};
    font-family: ${p.coreTheme.ff_sec_thin};
    font-size: ${p.coreTheme.fs_xl};
    width: calc(100% - 50px); /* fullwidth minus radio button and padding */
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    margin-left: ${p.coreTheme.space_4}px;

    @media (min-width: ${p.coreTheme.brk_xs}) {
      margin: 0;
    }
  `}
`);

const RadioButtonEffect = styled(
  withTheme(
    styled.div(
      ({ coreTheme }) => css`
        display: inline-block;
        position: absolute;
        box-sizing: border-box;
        transition: all 0.1s ease-in;

        top: 0;
        left: 0;
        height: ${coreTheme.space_6}px;
        width: ${coreTheme.space_6}px;
        border: 6px solid transparent;
        border-radius: ${coreTheme.space_5}px;

        @media (min-width: ${coreTheme.brk_xs}) {
          top: ${coreTheme.space_3}px;
          left: ${coreTheme.space_3}px;
          height: ${coreTheme.space_8}px;
          width: ${coreTheme.space_8}px;
          border: ${coreTheme.space_2}px solid transparent;
        }
      `
    )
  )
)({});

const RadioButton = styled(
  withTheme(
    styled.div(
      ({ coreTheme, error }) => css`
        display: inline-block;
        height: 24px;
        width: 24px;
        min-width: 24px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        transition: all 0.1s ease-in;
        background-color: transparent;
        box-sizing: border-box;

        @media (min-width: ${coreTheme.brk_xs}) {
          height: 56px;
          width: 56px;
          min-width: 56px;
          margin-right: ${coreTheme.space_1}px;
          margin-left: -${coreTheme.space_3}px;
          padding: 12px;
        }

        input[type='radio'] {
          transition: all 0.1s ease-in;
          display: inline-block;
          border-radius: 18px;
          margin: 0;
          appearance: none;
          background-color: ${coreTheme.cl_white};
          border: 1px solid ${error ? coreTheme.cl_pri_d3 : coreTheme.cl_ter_l4};

          height: 24px;
          width: 24px;

          @media (min-width: ${coreTheme.brk_xs}) {
            height: 32px;
            width: 32px;
          }

          &:focus {
            outline: none;
          }

          &:checked {
            border-color: ${coreTheme.cl_ter_l5};
          }

          &:checked + ${/* sc-selector */ RadioButtonEffect} {
            border-color: ${coreTheme.cl_sec_d1};
          }

          &::-ms-check {
            color: transparent;
          }
        }

        &:hover {
          background-color: ${withOpacity(coreTheme.cl_sec_l1, 0.8)};
        }
      `
    )
  )
)({});

const Label = withTheme(
  styled.label(
    ({ coreTheme }) => css`
      display: flex;
      align-items: center;
      cursor: pointer;

      @media (min-width: ${coreTheme.brk_xs}) {
        margin: 12px 0;
      }

      :active {
        ${/* sc-selector */ RadioButtonEffect} {
          opacity: 0.5;
        }
      }

      :hover {
        ${/* sc-selector */ RadioButton} {
          background-color: ${p => withOpacity(p.coreTheme.cl_sec_l1, 0.8)};
        }
      }
    `
  )
);

export default RadioInput;
