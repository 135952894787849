import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme, withTheme } from '../../../core/ThemeProvider';
import config from '../../../config';
import { withStyledAttrs } from '../../../helpers/styled';
import Row from '../../Row';
import Column from '../../Column';
import Card from '../../Card';
import Text from '../../Text';
import Spacing from '../../Spacing';
import { googleSearchApiBaseUrl } from '../config';
import DirectionsPinIcon from '../assets/directionsPin.svg';

export const StyledContainer = styled.div`
  width: calc(100% - 32px);
  position: absolute;
  bottom: 24px;
  display: block;
  z-index: 2;
  margin: 0 16px;

  ${config.media.sm`
    width: calc(100% - 200px);
    margin: 0px 100px;
  `}
`;

export const StyledPinIconWrapper = styled.div`
  padding-top: 2px;
  padding-right: 10px;
`;

const StyledContentText = styled(Text)`
  text-align: left;
`;

export const StyledDirectionsLinkContainer = styled(Spacing)`
  display: flex;
  cursor: pointer;
  user-select: none;
`;

export const StyledDirectionsLink = withTheme(
  withStyledAttrs(Text, { type: 'smallBody', fontWeight: 'bold' })`
    color: ${p => p.coreTheme.cl_sec_d1};
  `
);

const LocationDetailsCard = ({
  name,
  descriptionLiner,
  subDescriptionLiner,
  postalCode,
  directionsLinkText
}) => {
  const { theme } = useTheme();

  const onDirectionsLinkClick = () => {
    const query = `${name},${postalCode},Singapore`;
    window.open(`${googleSearchApiBaseUrl}?api=1&query=${query}`, '_blank');
  };

  return (
    <StyledContainer>
      <Row center="xs">
        <Column xs={12} sm={6} md={5}>
          <Card shadow="sm" radius="sm" padding="sm">
            <Card.Content>
              <StyledContentText type="body" data-testid="location-name">
                {name}
              </StyledContentText>
              {descriptionLiner && (
                <StyledContentText
                  type="smallBody"
                  color={theme.cl_ter_l2}
                  data-testid="location-description-liner"
                >
                  {`${descriptionLiner}`}
                </StyledContentText>
              )}

              {subDescriptionLiner && (
                <StyledContentText
                  type="smallBody"
                  color={theme.cl_ter_l2}
                  data-testid="location-sub-description-liner"
                >
                  {`${subDescriptionLiner}`}
                </StyledContentText>
              )}

              {directionsLinkText && (
                <StyledDirectionsLinkContainer
                  top={1}
                  onClick={onDirectionsLinkClick}
                  data-testid="location-directions-link"
                >
                  <StyledPinIconWrapper>
                    <DirectionsPinIcon color={theme.cl_sec_d1} size={22} />
                  </StyledPinIconWrapper>
                  <StyledDirectionsLink>
                    {directionsLinkText}
                  </StyledDirectionsLink>
                </StyledDirectionsLinkContainer>
              )}
            </Card.Content>
          </Card>
        </Column>
      </Row>
    </StyledContainer>
  );
};

LocationDetailsCard.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Description liner to be displayed in the location details card
   */
  descriptionLiner: PropTypes.string.isRequired,
  /**
   * Text label for directions link in Location details card component
   */
  directionsLinkText: PropTypes.string,
  /**
   * Location name
   */
  name: PropTypes.string.isRequired,
  /**
   * Location postal code
   */
  postalCode: PropTypes.string.isRequired,
  /**
   * Sub description liner to be displayed in the location details card
   */
  subDescriptionLiner: PropTypes.string.isRequired
};

export default LocationDetailsCard;
