import React from 'react';
import Grid from '../Grid';
import Row from '../Row';
import Column from '../Column';
import Text from '../Text';
import Logo from './Logo';
import IconMenu from './IconMenu';
import { Filter as IconHamburger } from '@dls/assets/icons';
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { remCalc } from '@lux/helpers';

const SCHeaderMobile = withTheme(
  styled.div(
    props => css`
      display: block;
      width: 100%;

      @media (min-width: ${props.coreTheme.brk_md}) {
        display: none;
      }
    `
  )
);

const SCLogoColumn = styled(Column)`
  display: flex !important;
  align-items: ${props => (props.showHamburgerRight ? 'flex-start' : 'center')};
`;

const SCRightHandColumn = styled.div`
  display: flex;
  align-items: center;
  float: right;
`;

const SCHamburgerAnchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      height: ${remCalc(22)};
      width: ${remCalc(22)};
      float: ${props.iconRight ? 'right' : 'left'};

      svg {
        height: ${props.coreTheme.header.mobileIconHeight};
        width: ${props.coreTheme.header.mobileIconWidth};
      }
    `
  )
);

const SCIconHamburger = styled.img`
  height: 100%;
  width: 100%;
`;

const SCHamburgerIcon = styled(IconHamburger)`
  cursor: pointer;
`;

const HamburgerMenu = ({ src: Src, onClick, iconRight = false }) => (
  <SCHamburgerAnchor
    type="link"
    iconRight={iconRight}
    onClick={onClick}
    data-testid="hamburger-menu"
  >
    {Src ? (
      typeof Src === 'string' ? (
        <SCIconHamburger src={Src} />
      ) : (
        <Src />
      )
    ) : (
      <SCHamburgerIcon size={22} />
    )}
  </SCHamburgerAnchor>
);

const MobileRHSControls = ({ baseUrl, menuIcons }) => (
  <>
    {menuIcons.map((menuIcon, key) => {
      return (
        <IconMenu
          baseUrl={baseUrl}
          icon={menuIcon.icon}
          subMenu={menuIcon.subMenu}
          key={key}
        />
      );
    })}
  </>
);

const MobileHeader = ({
  baseUrl,
  logo,
  menuIcons,
  onHamburgerClick,
  showHamburger,
  showHamburgerRight,
  hamburgerOpenIcon
}) => (
  <SCHeaderMobile data-testid="mobile-header">
    <Grid>
      <Row middle="xs">
        {/* Hamburger Icon default in top left corner */}
        {showHamburger && !showHamburgerRight && (
          <Column xs={3}>
            <HamburgerMenu src={hamburgerOpenIcon} onClick={onHamburgerClick} />
          </Column>
        )}

        {/* Logo in the middle */}
        <SCLogoColumn showHamburgerRight={showHamburgerRight} xs={6}>
          {logo && (
            <Logo
              baseUrl={baseUrl}
              href={logo.href}
              alt={logo.alt}
              src={logo.src}
              mode="mobile"
            />
          )}
        </SCLogoColumn>

        {/* RHS controls */}
        <Column xs={3}>
          <SCRightHandColumn>
            {menuIcons && (
              <MobileRHSControls baseUrl={baseUrl} menuIcons={menuIcons} />
            )}
          </SCRightHandColumn>
        </Column>

        {/* Hamburger Icon in top right corner when showHamburgerRight */}
        {showHamburger && showHamburgerRight && (
          <Column>
            <HamburgerMenu
              xs={3}
              src={hamburgerOpenIcon}
              onClick={onHamburgerClick}
              iconRight={showHamburgerRight}
            />
          </Column>
        )}
      </Row>
    </Grid>
  </SCHeaderMobile>
);

export default MobileHeader;
