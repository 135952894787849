import React from 'react';
import DocumentTitle from 'react-document-title';
import Container from '../../components/Container';
import Landing from '../../components/Landing';

import { devices } from '../../configs/devices';
import { ignoreQueryString } from '../../helpers';
import useCampaign from '../../helpers/useCampaign';
import { withRouter } from 'react-router-dom';

const heroImage = devices.default.banner;

export const PaymentFailurePage = props => {
  const campaignName = ignoreQueryString(props.match.params.campaign);
  const campaign = useCampaign(campaignName);

  const content = {
    title:
      campaign.name &&
      `Unfortunately, your pre-order for upcoming stocks of ${
        campaign.description
      } is unsuccessful`,
    description: campaign.name && (
      <span>
        $200 has not been held on your credit card.
        <br />
        Please stay tuned to www.singtel.com/iphone for the latest updates.
      </span>
    )
  };

  return (
    <DocumentTitle title="Dashboard">
      <Container>
        <Landing
          image={heroImage}
          title={content.title}
          description={content.description}
          showNric={false}
        />
      </Container>
    </DocumentTitle>
  );
};

export default withRouter(PaymentFailurePage);
