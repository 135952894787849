import { withTheme } from '../../core/ThemeProvider';
import PropTypes from 'prop-types';
import React from 'react';
import {
  BadgeIconContainer,
  Container,
  Content,
  Ribbon,
  TextVariant2
} from './styles';

const Variant2 = withTheme(function Variant2({
  children,
  icon: Icon,
  color,
  ...rest
}) {
  const iconColor =
    color === 'secondary' ? rest.coreTheme.cl_sec_d2 : rest.coreTheme.cl_white;
  return (
    <Ribbon>
      <Container color={color}>
        {Icon && (
          <BadgeIconContainer>
            <Icon color={iconColor} size={24} />
          </BadgeIconContainer>
        )}

        <Content>
          <TextVariant2>{children}</TextVariant2>
        </Content>
      </Container>
    </Ribbon>
  );
});

Variant2.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Ribbon text
   */
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Color scheme
   */
  color: PropTypes.oneOf(['primary', 'secondary']),
  icon: PropTypes.node
};

export default Variant2;
