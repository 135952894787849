import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

const configureStore = preloadedState => {
  let middleware;
  if (process.env.NODE_ENV !== 'production') {
    middleware = compose(applyMiddleware(thunk, createLogger()));
  } else {
    middleware = applyMiddleware(thunk);
  }

  const store = createStore(rootReducer, preloadedState, middleware);

  return store;
};

export default configureStore;
