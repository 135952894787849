import { setUrlParams } from '@lux/helpers';
import { api } from '../configs';
import { isLoading } from './index';
import { sendApiToGaEvent, sendGaEvent } from '../helpers';

const setTimeslots = timeslots => ({
  type: 'SET_TIME_SLOTS',
  timeslots
});

export const setReservedLocationAndTimeSlot = locationAndTimeSlot => ({
  type: 'SET_RESERVED_LOCATION_AND_TIME_SLOT',
  locationAndTimeSlot
});

export function getTimeSlots(campaignId) {
  const url = setUrlParams(
    api.appointment.timeslots.replace(':campaignId', campaignId),
    {
      _: new Date().getTime()
    }
  );

  return dispatch => {
    dispatch(isLoading(true));
    return fetch(url)
      .then(response => {
        sendApiToGaEvent('getTimeSlots', response.status);
        return response;
      })
      .then(response => response.json())
      .then(response => {
        dispatch(setTimeslots(response));
        dispatch(isLoading(false));
        return response;
      })
      .catch(() => {});
  };
}

export function reserveTimeSlot(
  locationHeader,
  authToken,
  locationAndTimeSlot
) {
  const url =
    locationHeader +
    api.appointment.reserveTimeSlot.replace(
      ':timeSlotId',
      locationAndTimeSlot.timeSlot.id
    );

  const options = {
    method: 'PUT',
    headers: {
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));
    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('reserveTimeSlot', response.status);
        sendGaEvent('Location', locationAndTimeSlot.location.name);
        let status = '';
        switch (response.status) {
          case 201:
            status = 'SUCCESS';
            break;
          case 204:
            status = 'SUCCESS';
            break;
          case 401:
            status = 'SESSION_NOT_FOUND';
            break;
          case 422:
            status = 'OUT_OF_STOCK';
            break;
        }
        dispatch(
          setReservedLocationAndTimeSlot({ ...locationAndTimeSlot, status })
        );
        dispatch(isLoading(false));
        return response;
      })
      .catch(() => {});
  };
}
