import React from 'react';
import skeletonPresets from './preset';
import PropTypes from 'prop-types';
import { testIdProps } from '../../helpers';

const presetTypes = Object.keys(skeletonPresets);

const SkeletonLoader = ({ preset, loading, ...rest }) => {
  const Skeleton = skeletonPresets[preset];
  if (!Skeleton) {
    throw '`preset` prop missing or incorrect. It must be one of ' +
      presetTypes.join(', ');
  }
  if (loading) {
    return <Skeleton {...testIdProps(rest)} />;
  } else {
    return rest.children;
  }
};

SkeletonLoader.propTypes = {
  preset: PropTypes.oneOf(presetTypes).isRequired,
  loading: PropTypes.bool
};

export default SkeletonLoader;
