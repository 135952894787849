import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TabBar from './src/TabBar';
import TabItem from './src/TabItem';
import withTabContext from './src/withTabContext';
import { noop } from '../../helpers';
import { useWindowSize } from '../../core/hooks/windowSize';

const TabItemWithContext = withTabContext(TabItem);

/**
 * Due to an iOS Safari bug, TabBar should have a defined max-width value
 * https://jira.dev.aws.singtel.com/browse/DLS-772
 */
const Wrapper = styled.div`
  max-width: ${p => p.maxWidth}px;
`;

const Tabs = ({ tabs, selected, onTabItemClick, secondary }) => {
  const windowSize = useWindowSize();

  return (
    <Wrapper maxWidth={windowSize.width}>
      <TabBar
        selected={selected}
        secondary={secondary}
        onTabItemClick={onTabItemClick}
      >
        {tabs.map((tabProps, index) => (
          <TabItemWithContext
            {...tabProps}
            key={index}
            tabItemWeight={tabs.length}
            secondary={secondary}
          />
        ))}
      </TabBar>
    </Wrapper>
  );
};

Tabs.defaultProps = {
  onTabItemClick: noop,
  selected: null,
  tabs: []
};

Tabs.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * handle click events on each the tab item.
   * @param e Click event
   * @param index Index of item
   */
  onTabItemClick: PropTypes.func,
  /**
   * Index of the selected tab.
   */
  selected: PropTypes.number,
  /**
   * Data model for list of tabs.
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Icon component from DLS Assets
       */
      icon: PropTypes.node,
      subTitle: PropTypes.string,
      title: PropTypes.string
    })
  ).isRequired
};

export default Tabs;
