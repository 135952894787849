import React, { useContext, useState, useEffect, useRef } from 'react';
import { ModalContext } from './Modal';
import PropTypes from 'prop-types';
import { Content, ContentWrapper, ScrollBottomFade } from './styles';
import { withTheme, useTheme } from '../../core/ThemeProvider';
import { withStyledAttrs } from '../../helpers/styled';
import Text from '../Text';
import Spacing from '../Spacing';

const ua = typeof navigator !== 'undefined' ? navigator.userAgent : '';
const firefox = ua.match(/firefox/i);
const safariiOS =
  (ua.match(/iPad/i) || ua.match(/iPhone/i)) &&
  ua.match(/WebKit/i) &&
  !ua.match(/CriOS/i);

const Title = withTheme(
  withStyledAttrs(Text, { type: 'sectionTitle' })`
    max-width: 100%;
    padding: ${p => p.coreTheme.modal?.titlePadding};
  `
);

const ModalContent = ({ children }) => {
  const [isScrollable, setIsScrollable] = useState(null);
  const [scrolledBottom, setScrolledBottom] = useState(false);
  const { theme } = useTheme();
  const contentRef = useRef(null);
  const { scrollable, visible, title, hasStickyFooter } = useContext(
    ModalContext
  );

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    if (Math.round(scrollTop + clientHeight) >= scrollHeight) {
      setScrolledBottom(true);
    } else {
      setScrolledBottom(false);
    }
  };

  const checkIsScrollable = () => {
    const { scrollHeight, clientHeight } = contentRef.current;
    scrollHeight > clientHeight
      ? setIsScrollable(true)
      : setIsScrollable(false);
  };

  useEffect(() => {
    if (contentRef.current && visible) {
      setScrolledBottom(false);
      checkIsScrollable();
      contentRef.current.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', checkIsScrollable);
      return () => {
        contentRef.current.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', checkIsScrollable);
      };
    }
  }, [visible]);

  const renderModalTitle = title => {
    if (!title) return null;
    if (theme.modal?.titlePadding) {
      return <Title>{title}</Title>;
    }
    return (
      <Spacing right={'80px'} bottom={`${theme.space_4}px`} left={3}>
        <Title>{title}</Title>
      </Spacing>
    );
  };

  const renderModalContent = (content, title) => (
    <Content>
      <Spacing
        top={title ? 0 : 3}
        /* scrollbar width customisation is not supported in firefox and safari ios, so padding is needed */
        right={isScrollable && !firefox && !safariiOS ? 0 : 3}
        left={3}
      >
        {content}
      </Spacing>
    </Content>
  );

  return (
    <>
      {scrollable ? (
        <Spacing top={3} bottom={hasStickyFooter ? 0 : 3}>
          <ContentWrapper ref={contentRef} hasStickyFooter={hasStickyFooter}>
            {renderModalTitle(title)}
            {renderModalContent(children, title)}
            {!hasStickyFooter && (
              <ScrollBottomFade
                isScrollable={isScrollable}
                scrolledBottom={scrolledBottom}
              />
            )}
          </ContentWrapper>
        </Spacing>
      ) : (
        <Spacing top={3} bottom={hasStickyFooter ? 0 : 3}>
          {renderModalTitle(title)}
          {renderModalContent(children, title)}
        </Spacing>
      )}
    </>
  );
};

ModalContent.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Modal content
   */
  children: PropTypes.node
};

export default ModalContent;
