import React from 'react';
import { Paragraph as LuxParagraph } from '@lux/components/src/components/baseElements';
import { Theme } from '@lux/components';
import styled from 'styled-components';

const StyledParagaph = styled(LuxParagraph)`
  ${Theme.media.sm`
    margin: 4px 0;
    font-size: ${Theme.fonts.sizes.small};
  `};

  ${Theme.media.md`
    margin: 8px 0;
    font-size: ${Theme.fonts.sizes.regular};
  `};

  b,
  strong {
    font-family: ${Theme.fonts.families.AvenirHeavy};
  }
`;

const Paragraph = props => (
  <StyledParagaph {...props}>{props.children}</StyledParagaph>
);

export default Paragraph;
