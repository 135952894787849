import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SearchInput from './SearchInput';
import { withTheme } from '../../../core/ThemeProvider';
import SearchIcon from './icons/searchCircleOutlineIcon.svg';
import SearchIconHover from './icons/searchCircleOutlineIconHover.svg';

const SCSearchIconContainer = withTheme(
  styled.div(
    props => css`
      display: flex;

      svg {
        cursor: pointer;
        width: ${props.coreTheme.header.deskTopIconWidth};
        height: ${props.coreTheme.header.deskTopIconHeight};

        &.hover {
          display: none;
        }
      }
      ${props.isIconHoverRequired &&
        css`
          &:hover {
            svg {
              display: none;
            }

            .hover {
              display: block;
            }
          }
        `}
    `
  )
);

const Search = ({
  isActive = true,
  onSearchIconClick,
  onSearchInputActivated,
  searchInputClassName,
  placeholder,
  isIconHoverRequired
}) => {
  return isActive ? (
    <SearchInput
      onSearchIconClick={onSearchIconClick}
      onSearchInputActivated={onSearchInputActivated}
      searchInputClassName={searchInputClassName}
      placeholder={placeholder}
    />
  ) : (
    <SCSearchIconContainer isIconHoverRequired={isIconHoverRequired}>
      <SearchIcon onClick={onSearchIconClick} data-testid="search-icon" />
      {isIconHoverRequired && (
        <SearchIconHover
          onClick={onSearchIconClick}
          data-testid="search-icon-hover"
          className="hover"
        />
      )}
    </SCSearchIconContainer>
  );
};

Search.propTypes = {
  /**
   * Indicates whether the search input is active. When set to true, it will display the
   * search input box. When set to false, it will display only the search icon.
   */
  isActive: PropTypes.bool,

  /**
   * Callback to be notified when clicked on search icon
   */
  onSearchIconClick: PropTypes.func,

  /**
   * Callback to get notified when search input is activated and mounted on DOM.
   */
  onSearchInputActivated: PropTypes.func,

  /**
   * className to set on search input
   */
  searchInputClassName: PropTypes.string,

  /**
   * Search input placeholder
   */
  placeholder: PropTypes.string
};

export default Search;
