import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon
} from '@dls/assets/icons';
import {
  StyledPaginationRoot,
  StyledPaginationUl,
  StyledPaginationButton,
  StyledPaginationItemEllipsis
} from './styles';

import usePagination from './hooks/usePagination';

const Pagination = ({ current, count, onChange, ...rest }) => {
  const { paginationArr, onPageChange } = usePagination({
    current,
    count,
    onChange
  });

  const getIcons = content => {
    switch (content) {
      case 'prev':
        return <ArrowLeftIcon />;

      case 'next':
        return <ArrowRightIcon />;

      default:
        return content;
    }
  };

  return (
    <StyledPaginationRoot data-testid={rest['data-testid']}>
      <StyledPaginationUl data-testid="pagination-list">
        {paginationArr.map((item, index) => {
          if (item.linkToPage) {
            return (
              <li key={index}>
                <StyledPaginationButton
                  data-testid={
                    item.active ? 'selected' : `page-${item.content}`
                  }
                  disabled={item.disabled}
                  selected={item.active}
                  onClick={() => onPageChange(item.linkToPage)}
                >
                  {getIcons(item.content)}
                </StyledPaginationButton>
              </li>
            );
          }
          return (
            <StyledPaginationItemEllipsis key={index}>
              {item.content}
            </StyledPaginationItemEllipsis>
          );
        })}
      </StyledPaginationUl>
    </StyledPaginationRoot>
  );
};

Pagination.defaultProps = {
  current: 1,
  count: 10,
  onChange: () => {},
  'data-testid': 'pagination'
};

Pagination.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * total number of pages
   */
  count: PropTypes.number,
  /**
   * current page
   */
  current: PropTypes.number,
  /**
   * change event handler
   */
  onChange: PropTypes.func
};

export default Pagination;
