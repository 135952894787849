import React from 'react';
import PropTypes from 'prop-types';
import { typeComponents } from './style';

const Text = ({ type, tag, ...rest }) => {
  const TypeComponent = typeComponents[type];
  let props = {
    ...rest,
    as: tag
  };

  return React.createElement(TypeComponent, props);
};

Text.defaultProps = {
  type: 'body',
  lineHeight: 'default'
};

Text.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Typography Color, defalut will be taken from theme
   * i.e. 'black' or '#000000' or rgb(0 0 0)
   */
  color: PropTypes.string,
  /**
   * Typography Font Size, default will be taken from theme
   * i.e. [16,16,15,14,14]
   */
  fontSize: PropTypes.arrayOf(PropTypes.number),
  /**
   * Typography Font Weight, defalut will be taken from theme
   * i.e. 'thin' or 'regular' or 'bold'
   */
  fontWeight: PropTypes.oneOf(['bold', 'regular', 'thin']),
  /**
   * Typography line height, applicable for 'boldBody', 'Body' and 'smallBody' only
   * - default: 1.75
   * - compact: 1.5
   * - medium: 1.3
   */
  lineHeight: PropTypes.oneOf(['compact', 'default', 'medium']),
  /**
   * The default tag for each `type` can be reconfigured.
   * <br><br>
   *
   * i.e.<br>
   * A `bannerTitle` can be rendered using a `<h2>` instead of the default `<h1>` tag.
   */
  tag: PropTypes.oneOf([
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'li',
    'p',
    'span'
  ]),
  /**
   * The style of the text rendered.
   * Each type uses a default html tag to render the text
   *
   * - bannerTitle: `<h1>`
   * - pageTitle: `<h2>`
   * - sectionTitle: `<h3>`
   * - sectionTitle02: `<h3>`
   * - header: `<h4>`
   * - body: `<p>`
   * - boldBody: `<p>`
   * - smallBody: `<p>`
   */
  type: PropTypes.oneOf([
    'bannerTitle',
    'body',
    'boldBody',
    'header',
    'link',
    'pageTitle',
    'sectionTitle',
    'sectionTitle02',
    'smallBody'
  ])
};

export default Text;
