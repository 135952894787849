import React, { forwardRef, useEffect } from 'react';
import getComponentName from '../../helpers/getComponentName';

/**
 *
 * @param {React.Component} Component The component to be deprecated
 * @param {Object} metadata
 * @param {String} metadata.message Deprecation message
 * @return {React.Component}
 */
const withWarningMessage = (Component, metadata = {}) => {
  const componentDisplayName = getComponentName(Component);
  const { message } = metadata;
  const WithWarningMessage = (props, ref) => {
    useEffect(() => {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'test'
      ) {
        console.warn(message);
      }
    }, []);

    return <Component {...props} ref={ref} />;
  };

  WithWarningMessage.displayName = componentDisplayName;

  return forwardRef(WithWarningMessage);
};

export default withWarningMessage;
