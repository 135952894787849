import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';

import loadingAnimation from '@dls/assets/lottiefiles/loading';
import tickAnimation from '@dls/assets/lottiefiles/tick';
import { withTheme, useTheme } from '../../core/ThemeProvider';
import Animation from '../Animation';
import Text from '../Text';
import { Fade } from './animation';
import Config from '../../config';
import { responsiveStyles } from '../../core/utils/responsiveStyles';

const buttonStatus = {
  FAILURE: 'failure',
  SUCCESS: 'success',
  IN_PROGRESS: 'inProgress'
};

const renderContent = (loading, text) => {
  const { theme } = useTheme();

  return (
    <>
      {/* Button Text */}
      <Transition
        timeout={FADE_DURATION}
        in={!loading}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {state => (
          <Fade state={state}>
            <Text type="boldBody" lineHeight="compact">
              {text}
            </Text>
          </Fade>
        )}
      </Transition>
      <Transition
        timeout={FADE_DURATION}
        in={loading === buttonStatus.FAILURE}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {state => (
          <Overlay>
            <Fade state={state}>
              <Text type="boldBody" lineHeight="compact">
                {text}
              </Text>
            </Fade>
          </Overlay>
        )}
      </Transition>
      <Transition
        timeout={0}
        in={loading === buttonStatus.SUCCESS}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {state => (
          <Overlay>
            <Fade state={state}>
              <Animation
                source={tickAnimation}
                size="24px"
                color={theme.cl_white}
                action="play"
                loop={false}
                segments={[28, 60]}
              />
            </Fade>
          </Overlay>
        )}
      </Transition>
      <Transition
        timeout={FADE_DURATION}
        in={loading === buttonStatus.IN_PROGRESS}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {state => (
          <Overlay>
            <Fade state={state}>
              <Animation
                source={loadingAnimation}
                size="24px"
                color={theme.cl_white}
                action="play"
                loop
              />
            </Fade>
          </Overlay>
        )}
      </Transition>
    </>
  );
};

const Button = props => {
  const {
    text,
    type,
    id,
    href,
    target,
    small,
    large,
    title,
    secondary,
    link,
    outline,
    disabled,
    loading,
    fullWidth,
    onClick,
    children,
    customStyle,
    ...rest
  } = props;

  const handleClick = e => {
    if (!disabled && onClick) {
      return onClick(e);
    }
  };

  const loadingStatus = !secondary && loading;
  const buttonType = secondary ? 'secondary' : link ? 'link' : 'primary';
  const buttonSize = small ? 'small' : large ? 'large' : 'medium';

  return (
    <ButtonWrapper
      loadingStatus={loadingStatus}
      type={type}
      id={id}
      href={href}
      target={target}
      title={title}
      buttonType={buttonType}
      buttonSize={buttonSize}
      outline={outline}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={handleClick}
      data-testid={rest['data-testid']}
      customStyle={customStyle}
    >
      {renderContent(loadingStatus, text || children)}
    </ButtonWrapper>
  );
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  secondary: false,
  link: false,
  loading: false,
  small: false,
  large: false,
  customStyle: {}
};

Button.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Disable button
   * default: false
   */
  disabled: PropTypes.bool,
  /**
   * Take the full width of parent
   */
  fullWidth: PropTypes.bool,
  /**
   * The link for the button tag
   */
  href: PropTypes.string,
  /**
   * `id` of button
   */
  id: PropTypes.string,
  /**
   * Loading state of the button
   * default false
   */
  loading: PropTypes.oneOf(['failure', 'inProgress', 'success', false]),
  /**
   * Click event handler
   */
  onClick: PropTypes.func,
  /**
   * Secondary flag
   * default false
   */
  secondary: PropTypes.bool,
  /**
   * Set the target of the link
   */
  target: PropTypes.string,
  /**
   * text of the button.
   * If not provided, renders `props.children`.
   */
  text: PropTypes.string,
  /**
   * Add a title to the link
   */
  title: PropTypes.string,
  /**
   * button `type` attribute
   */
  type: PropTypes.string
};

const FADE_DURATION = 200;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = withTheme(
  styled.button(
    ({
      coreTheme,
      fullWidth,
      buttonType,
      buttonSize,
      outline,
      loadingStatus,
      customStyle
    }) => {
      return css`
        &&& {
          background-color: ${coreTheme.button.background};
          box-sizing: border-box;
          cursor: pointer;
          display: inline-block;
          height: auto;
          outline: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border: ${coreTheme.button.border};
          min-width: ${coreTheme.button.minWidth};
          min-height: ${coreTheme.button.minHeight};
          border-radius: ${coreTheme.button.borderRadius};
          padding: ${coreTheme.button.padding};
          margin: 0;
          position: relative;
          text-align: center;
          text-decoration: none;
          transition: background-color 0.1s ease-in;
          width: ${fullWidth ? '100%' : 'auto'};
          transition-delay: 0s;

          p {
            color: ${coreTheme.cl_white};
            font-family: ${coreTheme.button.fontFamily};
            ${coreTheme.button.fontSize && {
              ...responsiveStyles(
                {
                  fontSize: [coreTheme.button.fontSize]
                },
                coreTheme
              )
            }};
            font-weight: ${coreTheme.button.fontWeight};
            line-height: normal;
          }

          &:hover {
            background-color: ${coreTheme.button.hoverBg};
            p {
              color: ${coreTheme.button.hoverColor};
            }
          }

          &:active {
            background-color: ${coreTheme.button.activeBg};
          }

          ${coreTheme.button[buttonSize] &&
            css`
              min-width: ${coreTheme.button[buttonSize].minWidth};
              min-height: ${coreTheme.button[buttonSize].minHeight};
              padding: ${coreTheme.button[buttonSize].padding};
              border-width: ${coreTheme.button[buttonSize].borderWidth};
              border-radius: ${coreTheme.button[buttonSize].borderRadius};
              p {
                ${coreTheme.button[buttonSize].fontSize && {
                  ...responsiveStyles(
                    {
                      fontSize: [coreTheme.button[buttonSize].fontSize]
                    },
                    coreTheme
                  )
                }};
              }
            `};

          ${buttonType === 'secondary' &&
            coreTheme.button.secondary &&
            css`
              background-color: ${coreTheme.button.secondary?.background};
              border-color: ${coreTheme.button.secondary?.borderColor};
              p {
                color: ${coreTheme.button.secondary?.color};
              }
              &:hover {
                background-color: ${coreTheme.button.secondary?.hoverBg};
                border-color: ${coreTheme.button.secondary?.hoverBorderColor};
                p {
                  color: ${coreTheme.button.secondary?.hoverColor};
                }
              }
              &:active {
                background-color: ${coreTheme.button.secondary.activeBg};
              }
            `};

          ${buttonType === 'link' &&
            coreTheme.button.link &&
            css`
              background: transparent;
              border: none;
              min-width: auto;
              min-height: auto;
              align-self: start;
              padding: 0;
              p {
                color: ${coreTheme.button.link?.color};
                font-family: ${coreTheme.button.link?.fontFamily};
              }
              &:hover {
                p {
                  color: ${coreTheme.button.link?.hoverColor};
                }
              }
            `};

          ${outline &&
            coreTheme.button.outline &&
            css`
              &,
              &:hover {
                background-color: transparent;
                border-color: ${buttonType === 'secondary'
                  ? coreTheme.button.secondary?.background
                  : coreTheme.button.background};
                p {
                  color: ${buttonType === 'secondary'
                    ? coreTheme.button.secondary?.background
                    : coreTheme.button.background};
                }
              }
            `};

          ${customStyle &&
            coreTheme.button.customStyle &&
            css`
              background-color: ${customStyle.background};
              border-color: ${customStyle.borderColor};
              p {
                color: ${customStyle.color};
              }
              &:hover {
                background-color: ${customStyle.hoverBg};
                border-color: ${customStyle.hoverBorderColor};
                p {
                  color: ${customStyle.hoverColor};
                }
              }
            `};

          &[disabled] {
            cursor: default;
            pointer-events: none;
            background-color: ${!outline & (buttonType !== 'link') &&
              coreTheme.button.disabled?.background};
            border-color: ${coreTheme.button.disabled?.borderColor};
            p {
              color: ${coreTheme.button.disabled?.color};
            }
          }

          ${Config.media.md`
          min-width: ${coreTheme.button.mdMinWidth};
          min-height: ${coreTheme.button.mdMinHeight};
          padding:${coreTheme.button.mdPadding};
            ${coreTheme.button[buttonSize] &&
              css`
                min-width: ${coreTheme.button[buttonSize].md?.minWidth};
                min-height: ${coreTheme.button[buttonSize].md?.minHeight};
                padding: ${coreTheme.button[buttonSize].md?.padding};
              `};
        `};

          ${loadingStatus === buttonStatus.SUCCESS &&
            css`
              background-color: ${coreTheme.cl_successGreen};
            `};

          ${(loadingStatus === buttonStatus.IN_PROGRESS ||
            loadingStatus === buttonStatus.SUCCESS) &&
            css`
              pointer-events: none;
            `};
        }
      `;
    }
  )
);

export default Button;
