import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Radio from '../Radio';
import { noop, testIdProps } from '../../helpers';
import { withTheme } from '../../core/ThemeProvider';

const BinarySelector = props => {
  const {
    firstOption,
    secondOption,
    onChange,
    value,
    name,
    label,
    ...rest
  } = props;

  const radioButton = option => (
    <RadioWrapper>
      <Radio.Input
        name={name}
        checked={option.value === value}
        onChange={event => onChange(event, option)}
        {...option}
      >
        {option.label || option.value}
      </Radio.Input>
    </RadioWrapper>
  );

  return (
    <Wrapper {...testIdProps(rest)}>
      <Label>{label}</Label>
      <RadioGroup>
        {radioButton(firstOption)}
        {radioButton(secondOption)}
      </RadioGroup>
    </Wrapper>
  );
};

BinarySelector.defaultProps = {
  onChange: noop
};

BinarySelector.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  firstOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  secondOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string.isRequired
  }).isRequired,
  value: PropTypes.string
};

export default BinarySelector;

//#region Styles
const Wrapper = styled.div`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RadioWrapper = styled.div`
  display: inline-block;
  width: calc(50% - 4px); /* 8px padding between each radio button */
`;

const RadioGroup = styled.div`
  label {
    margin: 0;
  }
`;

const Label = withTheme(styled.div`
  font-family: ${props => props.coreTheme.ff_sec_thin};
  color: ${props => props.coreTheme.cl_ter_l2};
  font-size: ${props => props.coreTheme.fs_sm};
  line-height: 1.5;
  margin-bottom: ${props => props.coreTheme.space_2}px;

  @media (min-width: ${props => props.coreTheme.brk_xs}) {
    margin: 0;
  }
`);
//#endregion Styles
