import { useEffect, useState } from 'react';
import { store } from '../index';
import { getCampaignStatus } from '../actions';

export function useCampaign(campaignName) {
  const [campaign, setCampaign] = useState({});

  useEffect(
    () => {
      let state = store.getState();
      if (campaignName && !state.campaign.name) {
        getCampaignStatus(campaignName)(store.dispatch);
      }
      const unsubscribe = store.subscribe(() => {
        state = store.getState();
        setCampaign(state.campaign);
      });

      return () => {
        unsubscribe();
      };
    },
    [campaignName]
  );

  return campaign || {};
}

export default useCampaign;
