export * from './components';
import utils from './utils';
import theme from './theme';
import ThemeProvider from './core/ThemeProvider';
import gomoColors from './theme/advance/colours';

export {
  /** DLS theme */
  theme,
  /**
   * Top level Wrapper for all DLS components.
   * Provides config props for theming to all child DLS components.
   */
  ThemeProvider,
  /** Utility functions */
  utils,
  // GOMO colors
  gomoColors
};
