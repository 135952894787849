import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTheme } from '../../core/ThemeProvider';
import { testIdProps } from '../../helpers';

const Stack = ({ space, align, ...rest }) => {
  return (
    <StackContainer {...testIdProps(rest)} {...rest}>
      {React.Children.map(rest.children, child => (
        <StackChild space={space} align={align}>
          {child}
        </StackChild>
      ))}
    </StackContainer>
  );
};

Stack.defaultProps = {
  space: 1
};

Stack.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Horizontal alignment for the child components
   */
  align: PropTypes.oneOf(['center', 'left', 'none', 'right']),
  /**
   * Vertical space between the child elements.
   */
  space: PropTypes.number
};

export default Stack;

const StackContainer = styled.div`
  display: block;
`;

const StackChild = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.align !== 'none' && `align-items: ${alignFlexStyles[p.align]};`}

  &:not(:last-child) {
    margin-bottom: ${p => p.space * 8}px;
  }
`);

const alignFlexStyles = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};
