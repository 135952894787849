import React, { useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { FormFieldContext } from './FormField';
import { withTheme } from '../../ThemeProvider';
import { isDefined } from '../../../helpers';

const animation = keyframes``;

// TODO Sudhir: Add documentation specific to this component.
const FormInputComponent = (props, ref) => {
  const { onFocus, onBlur, value, setValue } = useContext(FormFieldContext);
  const { onChange } = props;

  useLayoutEffect(() => {
    setValue(isDefined(props.value) ? props.value : props.defaultValue);
  }, [props.defaultValue, props.value]);

  const handleOnkeyUp = e => {
    if (props.type !== 'number') return;

    const isInvalidKeycodes = (() => {
      if (e.location !== 0) return false; // approve function keys (general keys = 0)
      if (e.keyCode === 8 || e.keyCode === 13) return false; // approve Delete and Return keys
      if (e.keyCode >= 37 && e.keyCode <= 40) return false; // approve navigation keys
      if (e.keyCode < 48 || e.keyCode > 57) return true; // not allowed non-numeric keys
      return false;
    })();

    if (isInvalidKeycodes) e.preventDefault();
  };

  function handleAnimationEvent(e) {
    // Fix: to handle autoFill
    // Ref Issue: https://github.com/mui-org/material-ui/issues/22488#issuecomment-687293183
    // Ref Code: https://github.com/mui-org/material-ui/blob/a927c9f842cbebed7edffe58dee51f08e0ad8054/packages/material-ui/src/InputBase/InputBase.js#L401
    if (e.animationName === animation.getName()) setValue(true);
  }

  const handleOnPaste = e => {
    if (props.type !== 'number') return;

    const val = e.clipboardData.getData('text/plain');
    const isNum = /^\d+$/.test(val);

    if (!isNum) e.preventDefault();
  };

  return (
    <StyledInput
      {...props}
      value={props.value || props.value === '' ? props.value : value}
      ref={ref}
      onKeyUp={handleOnkeyUp}
      onPaste={handleOnPaste}
      onMouseDown={handleMouseDown}
      onAnimationStart={handleAnimationEvent}
      onChange={e => {
        setValue(e.target.value);
        return onChange && onChange(e);
      }}
      onFocus={e => {
        if (props.disabled) return;
        onFocus(e);
        props.onFocus && props.onFocus(e);
      }}
      onBlur={e => {
        if (props.disabled) return;
        onBlur(e);
        props.onBlur && props.onBlur(e);
      }}
    />
  );
};

const handleMouseDown = e => e.stopPropagation();

const FormInput = React.forwardRef(FormInputComponent);

FormInput.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

const StyledInput = withTheme(styled.input`
  &&& {
    background-color: transparent;
    border-radius: 8px;
    border: none;
    box-sizing: border-box;
    color: ${p =>
      p.disabled ? p.coreTheme.cl_ter_l3 : p.coreTheme.cl_ter_base};
    font-family: ${({ coreTheme }) => coreTheme.ff_sec_thin};
    font-size: ${({ coreTheme }) => coreTheme.fs_xl};
    line-height: 1.5;
    outline: none;
    transition: all 0.2s ease-in-out;
    width: 100%;
    z-index: 0;
    resize: none;
    padding: 0;
    margin: 0;
    appearance: textfield; /* firefox */

    &:-webkit-autofill {
      animation-duration: 5000s;
      animation-name: ${animation};
    }

    /* ie11 and edge */
    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }
    &::-ms-reveal {
      display: none;
    }

    ${p =>
      p.as === 'input' &&
      css`
        height: 30px;
      `}

    ${p =>
      p.as === 'textarea' &&
      css`
        overflow: auto;
      `}

  ::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    ::placeholder {
      color: ${({ coreTheme }) => coreTheme.cl_ter_l3};
      transition: all 0.2s ease-in-out 0.1s;
    }

    ::-ms-input-placeholder {
      color: ${({ coreTheme }) => coreTheme.cl_ter_l3};
      transition: all 0.2s ease-in-out 0.1s;
    }

    ::-webkit-input-placeholder {
      color: ${({ coreTheme }) => coreTheme.cl_ter_l3};
      transition: all 0.2s ease-in-out 0.1s;
    }

    ::-moz-placeholder {
      color: ${({ coreTheme }) => coreTheme.cl_ter_l3};
      transition: all 0.2s ease-in-out 0.1s;
    }

    &:not(:focus)::placeholder {
      color: ${p =>
        p.alwaysShowAsHint ? p.coreTheme.cl_ter_l3 : 'transparent'};
      transition: all 0.2s ease-in-out 0s;
    }

    &:not(:focus)::-ms-input-placeholder {
      color: ${p =>
        p.alwaysShowAsHint ? p.coreTheme.cl_ter_l3 : 'transparent'};
      transition: all 0.2s ease-in-out 0s;
    }

    &:not(:focus)::-webkit-input-placeholder {
      color: ${p =>
        p.alwaysShowAsHint ? p.coreTheme.cl_ter_l3 : 'transparent'};
      transition: all 0.2s ease-in-out 0s;
    }

    &:not(:focus)::-moz-placeholder {
      color: ${p =>
        p.alwaysShowAsHint ? p.coreTheme.cl_ter_l3 : 'transparent'};
      transition: all 0.2s ease-in-out 0s;
    }
  }
`);

export default FormInput;
