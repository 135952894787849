import React from 'react';
import ReactGA from 'react-ga';
import Raven from 'raven-js';
import ErrorPage from '../../containers/ErrorPage';

class ErrorBoundary extends React.Component {
  state = { error: false };

  componentDidCatch(error, info) {
    Raven.captureException(error, { extra: info });
    this.setState({ error: true, componentStack: info.componentStack });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.error !== this.state.error) {
      ReactGA.event({
        category: 'ErrorBoundary Catch',
        action: window.location.href,
        label: this.state.componentStack,
        nonInteraction: true
      });
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
