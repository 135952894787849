import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { getStyledText } from '../../helpers/styled';
import SelectorBody from './SelectorBody';

export const SelectorWrapper = styled.div`
  border: ${p => !p.selected && '1px solid transparent'};
  height: ${p => (p.autoHeight ? 'auto' : '100%')};
`;

export const SelectContainer = withTheme(styled.div`
  box-sizing: border-box;
  border: ${props =>
    props.selected
      ? props.coreTheme.selector?.selectedBorder ||
        `2px solid ${props.coreTheme.cl_sec_d1}`
      : props.coreTheme.selector?.border ||
        `1px solid ${props.coreTheme.cl_ter_l4}`};
  border-radius: ${props => props.coreTheme.selector?.borderRadius || '8px'};
  width: 100%;
  height: 100%;
  padding: ${props =>
    props.noPadding
      ? '0'
      : props.coreTheme.selector?.padding ||
        (props.altPadding ? '16px 8px' : '24px')};
  background-color: ${props => props.coreTheme.cl_white};
  pointer-events: default;
  display: flex;
  flex-direction: ${props => props.alignment};
  align-items: center;
  justify-content: ${props => props.justifyContent || 'space-between'};

  ${props =>
    props.disabled &&
    `opacity: ${props.coreTheme.selector?.disabledOpacity || 0.5};
    background: ${props.coreTheme.selector?.disabledColor ||
      props.coreTheme.cl_white};
    pointer-events: none;
  `};
`);

export const Image = withTheme(styled.img`
  height: auto;
  width: ${p => (p.width ? p.width : '48px')};
`);

export const Body = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${p =>
    p.align === 'start'
      ? 'flex-start'
      : p.align === 'end'
      ? 'flex-end'
      : 'center'};
  justify-content: center;
`);

export const EndContent = styled(SelectorBody)`
  ${({ alignment }) => {
    switch (alignment) {
      case 'column':
        return css`
          margin-top: 24px;
        `;
      case 'column-reverse':
        return css`
          margin-bottom: 24px;
        `;
      case 'row-reverse':
        return css`
          align-self: flex-start;
          margin-right: 24px;
        `;
      default:
        return css`
          align-self: flex-start;
          margin-left: 24px;
        `;
    }
  }}
`;

export const BodyTitle = withTheme(getStyledText('body')`
  color: ${p => (p.disabled ? p.coreTheme.cl_ter_l4 : p.coreTheme.cl_ter_base)};
`);

export const BodySubtitle = withTheme(getStyledText('smallBody')`
  color: ${p => (p.disabled ? p.coreTheme.cl_ter_l4 : p.coreTheme.cl_ter_base)};
`);

export const OuterCircle = withTheme(styled.div`
  position: relative;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  text-align: center;
  border: ${p => `1px solid ${p.coreTheme.cl_ter_l4}`};
`);

export const InnerCircle = withTheme(styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${p => p.hexCode};
  top: 50%;
  left: 50%;
  margin: -16px 0px 0px -16px;
  border: ${p => `1px solid ${p.coreTheme.cl_ter_l5}`};
`);

export const Slash = withTheme(styled.div`
  border-bottom: ${p =>
    p.filledColor === p.coreTheme.cl_white
      ? `2px solid ${p.coreTheme.cl_ter_l5}`
      : `2px solid ${p.coreTheme.cl_white}`};
  border-radius: 0;
  width: 32px;
  transform: rotate(-45deg);
  height: 14px;
  transform-origin: 50% 100%;
`);
