import React from 'react';
import { Grid, Column, Row, Theme } from '@lux/components';
import styled from 'styled-components';
import HeroBanner from '../../components/HeroBanner';
import { Text, Spacing, Button, InputText } from '@dls/web';

const ImageContainer = styled.div`
  img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  ${Theme.media.sm`
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -20px;
    margin-bottom: 10px;
  `};
  ${Theme.media.md`
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    margin-bottom: 20px;
  `};
`;

const Landing = props => {
  const {
    image,
    title,
    description,
    loading,
    onClick,
    onChange,
    showNric,
    onKeyPress,
    onBlur,
    nricErrorMsg,
    registrationErrorMsg
  } = props;

  return (
    <Grid>
      <Row>
        <Column sm={12}>
          <ImageContainer>
            <HeroBanner imagePath={image} />
          </ImageContainer>
          <Text type='pageTitle'>{title}</Text>
          <Spacing top={2} bottom={2}>
            <Text type='body'>{description}</Text>
          </Spacing>
        </Column>
        <Column sm={12} md={10}>
          {showNric && (
            <React.Fragment>
              <InputText
                id="NRIC"
                type="text"
                label="NRIC/FIN"
                onChange={onChange}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
                placeholder="S0012345A or F1234567G"
                disabled={loading}
                error={nricErrorMsg && nricErrorMsg !== ''}
                data-testid="nricField"
                hintMessage={nricErrorMsg}
                bgColor="haze"
              />
            </React.Fragment>
          )}
        </Column>
        {registrationErrorMsg && (
          <Column sm={12} md={12}>
            <Spacing top={1} >
              {registrationErrorMsg}
            </Spacing>
          </Column>
        )}
        {showNric && (
          <Column sm={12} md={6}>
            <Spacing top={3} bottom={1}>
            <Button
              text="Choose device"
              fullWidth
              onClick={onClick}
              disabled={loading}
            />
            </Spacing>
          </Column>
        )}
      </Row>
    </Grid>
  );
};

export default Landing;
