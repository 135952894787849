import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import FormInput from '../../core/components/Forms/FormInput';
import supportedFormats from './supportedFormats';

/**
 * Using wrapper to convert the component to a stateful component.
 * This is a workaround for a known issue. backspace does not update the display correctly.
 * https://github.com/text-mask/text-mask/issues/480
 *
 */

const StatefulMaskedInput = ({ onChange, displayFormat, ...props }) => {
  const [value, setValue] = useState(props.value);

  return (
    <MaskedInput
      {...props}
      value={props.value || value}
      onChange={e => {
        setValue(e.target.value);
        return onChange && onChange(e, _unmask(e.target.value));
      }}
      guide={false}
      mask={supportedFormats[displayFormat]}
      render={(ref, inputProps) => (
        <FormInput
          {...props}
          {...inputProps}
          as="input"
          ref={ref}
          type={remapInputType(props.type, displayFormat) || 'text'}
          disabled={props.disabled}
          error={props.error}
        />
      )}
    />
  );
};

const remapInputType = (type, displayFormat) => {
  if ('date' === displayFormat) return 'text';
  else return type;
};

export default StatefulMaskedInput;

/**
 *
 * @param {String} value formatted value
 * @return {string}
 * @private
 */
export const _unmask = value => {
  return value.replace(/( |\/|\+65)/g, '');
};
