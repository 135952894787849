import styled from 'styled-components';

export const StyledMapContainer = styled.div`
  ${props => !props.show && `opacity: 0; z-index: -1`}
`;

export const getMapStyles = theme => {
  return [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.map.mapTerrain.waterGeometry
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.map.mapTerrain.landscapeGeometry
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: theme.map.mapTerrain.roadHighwayGeometryFill
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: theme.map.mapTerrain.roadHighwayGeometryStroke
        },
        {
          lightness: 29
        },
        {
          weight: 0.2
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.map.mapTerrain.roadArterialGeometry
        },
        {
          lightness: 18
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.map.mapTerrain.roadLocalGeometry
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.map.mapTerrain.poiGeometry
        },
        {
          lightness: 21
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.map.mapTerrain.poiParkGeometry
        },
        {
          lightness: 21
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on'
        },
        {
          color: theme.map.mapTerrain.labelsTextStroke
        },
        {
          lightness: 16
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36
        },
        {
          color: theme.map.mapTerrain.labelsTextFill
        },
        {
          lightness: 30
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: theme.map.mapTerrain.transitGeometry
        },
        {
          lightness: 19
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: theme.map.mapTerrain.administrativeGeometryFill
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: theme.map.mapTerrain.administrativeGeometryStroke
        },
        {
          lightness: 17
        },
        {
          weight: 1.2
        }
      ]
    }
  ];
};
