import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import TabContext from './TabContext';
import useDevice from '../../../core/hooks/useDevice';
import { withOpacity } from '../../../helpers';
import { scrollElement } from '../../../helpers/smoothScrollingPolyfill';
import { withWindowSize } from '../../../core/hooks/windowSize';
import { ArrowLeft, ArrowRight } from '@dls/assets/icons';
import { withTheme, useTheme } from '../../../core/ThemeProvider';

const TabBar = ({
  selected,
  onTabItemClick,
  children,
  windowSize,
  secondary
}) => {
  const { theme } = useTheme();
  const { isMobile } = useDevice();
  const indicatorRef = useRef(null);
  const tabsContainerRef = useRef(null);
  const [indicatorEl, setIndicatorEl] = useState(null);
  const [tabsContainerEl, setTabsContainerEl] = useState(null);
  const [shouldShowLeftArrow, setShouldShowLeftArrow] = useState(false);
  const [shouldShowRightArrow, setShouldShowRightArrow] = useState(false);

  const arrowTriggerOffset = 40;

  useEffect(() => {
    setIndicatorEl(indicatorRef.current);
  }, [indicatorRef]);

  useEffect(() => {
    setTabsContainerEl(tabsContainerRef.current);
  }, [tabsContainerRef]);

  useEffect(() => {
    tabsContainerEl && toggleArrows(tabsContainerEl);
  }, [tabsContainerEl, isMobile, windowSize]);

  const onTabBarContainerScroll = container => {
    toggleArrows(container);
  };

  const toggleArrows = container => {
    const showLeftArrow =
      !isMobile && container.scrollLeft > arrowTriggerOffset;
    setShouldShowLeftArrow(showLeftArrow);

    const showRightArrow =
      !isMobile &&
      container.scrollWidth - (container.offsetWidth + container.scrollLeft) >
        arrowTriggerOffset;
    setShouldShowRightArrow(showRightArrow);
  };

  const onArrowClick = side => {
    tabsContainerRef &&
      tabsContainerRef.current.scrollTo &&
      scrollElement(tabsContainerRef.current, {
        left: side === 'left' ? 0 : tabsContainerRef.current.clientWidth,
        behavior: 'smooth'
      });
  };

  return (
    <Container>
      <TabBarContainer
        data-testid="tabbar-container"
        ref={tabsContainerRef}
        onScroll={e => onTabBarContainerScroll(e.target)}
      >
        <TabBarDivider secondary={secondary} />
        <TabsContainer>
          {React.Children.map(children, (c, i) => (
            <TabContext.Provider
              key={i}
              value={{
                isMobile,
                index: i,
                isSelected: i === selected,
                onItemClick: onTabItemClick,
                indicatorEl: indicatorEl,
                tabsContainerEl: tabsContainerEl,
                windowSize,
                secondary: secondary
              }}
            >
              {c}
            </TabContext.Provider>
          ))}
        </TabsContainer>
        <TabItemIndicator secondary={secondary} ref={indicatorRef} />
      </TabBarContainer>
      <ArrowWrapper
        onClick={() => onArrowClick('left')}
        opacity={shouldShowLeftArrow ? 1 : 0}
        data-testid="left-arrow"
      >
        <ArrowHighlight>
          <ArrowLeft size={22} color={theme.cl_sec_d1} />
        </ArrowHighlight>
      </ArrowWrapper>
      <ArrowWrapper
        onClick={() => onArrowClick('right')}
        opacity={shouldShowRightArrow ? 1 : 0}
        rightPosition
        data-testid="right-arrow"
      >
        <ArrowHighlight>
          <ArrowRight size={22} color={theme.cl_sec_d1} />
        </ArrowHighlight>
      </ArrowWrapper>
    </Container>
  );
};

export const TabBarPropTypes = {
  /**
   * Index of the selected tab
   */
  selected: PropTypes.number,
  /**
   * secondary style for tab
   */
  secondary: PropTypes.bool,
  /**
   * Callback for handling click events on each the tab item.
   */
  onTabItemClick: PropTypes.func
};

TabBar.defaultProps = {};

TabBar.propTypes = TabBarPropTypes;

export default withWindowSize(TabBar);

const TabsContainer = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      position: relative;
      display: table;
      width: ${coreTheme.tab.tabContainerWidth
        ? coreTheme.tab.tabContainerWidth
        : '100%'};
    `
  )
);

const TabItemIndicator = withTheme(
  styled.div(
    ({ coreTheme, secondary }) => css`
      position: absolute;
      left: 0;
      bottom: ${coreTheme.tab.tabBarDividerPositionBottom};
      width: 0;
      height: 4px;
      border-radius: 2px;
      background-color: ${coreTheme.tab.tabItemIndicatorBgColor};

      ${secondary &&
        css`
          background-color: ${coreTheme.tab.secondary?.tabItemIndicatorBgColor};
        `}
    `
  )
);

export const TabBottomBorder = styled.div`
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 2px;
`;

const TabBarDivider = withTheme(
  styled(TabBottomBorder)(
    ({ coreTheme, secondary }) => css`
      position: absolute;
      bottom: ${coreTheme.tab.tabBarDividerPositionBottom};
      background-color: ${coreTheme.tab.tabBarDividerBgColor};
      ${secondary &&
        coreTheme.tab.secondary &&
        css`
          background-color: ${coreTheme.tab.secondary?.tabBarDividerBgColor};
        `}
    `
  )
);

const TabBarContainer = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      position: relative;
      overflow: auto;
      width: 100%;
      text-transform: ${coreTheme.tab.textTransform};
      padding-bottom: ${coreTheme.tab.tabBarContainerPaddingBottom};
      /* stylelint-disable property-no-unknown  */
      scrollbar-width: none; /* Firefox */
      /* -ms-overflow-style: none; */ /* Internet Explorer 10+ This disables scrolling al-together */

      border-radius: ${coreTheme.tab.TabBarContainerBorderRadius};

      ::-webkit-scrollbar {
        /* Chrome, Safari */
        display: none;
      }
    `
  )
);

const Container = styled.div`
  position: relative;
  display: flex;
`;

const ArrowWrapper = withTheme(styled.div`
  position: absolute;
  cursor: pointer;
  height: 100%;
  pointer-events: ${p => (p.opacity === 0 ? 'none' : 'all')};
  background-color: ${p => p.coreTheme.cl_white};
  opacity: ${p => (p.coreTheme.tab.hideScrollArrow ? 0 : p.opacity)};
  transition: opacity 0.2s;
  ${p => p.rightPosition && 'right: 0'};
`);

const ArrowHighlight = withTheme(styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  ${p => css`
    &:hover {
      background-color: ${withOpacity(p.coreTheme.cl_sec_l1, 0.8)};
    }
  `}
`);
