import React, { useState, Fragment } from 'react';
import Grid from '../Grid';
import Row from '../Row';
import Column from '../Column';
import Text from '../Text';
import Logo from './Logo';
import {
  ArrowLeft as IconMenuBack,
  ArrowUp as IconChevronUp,
  ArrowDown as IconChevronDown,
  Close as IconMenuClose
} from '@dls/assets/icons';
import { withTheme } from '../../core/ThemeProvider';
import styled, { css } from 'styled-components';
import { remCalc } from '@lux/helpers';
import { noop } from '../../helpers';
import Search from './Search';
import Spacing from '../Spacing';

const SCHamburgerMenu = withTheme(
  styled.div(
    props => css`
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: ${props.backgroundColor};
      border-top: ${props.coreTheme.header.borderTop};
      opacity: 0;
      overflow-y: auto;
      visibility: hidden;
      z-index: 99999;

      transition: all 400ms ease-out;
      ${!props.showHamburgerRight &&
        css`
          transform: translate(-200px, 0);
        `}

      ${props.isHamburgerMenuOpen &&
        css`
          transform: translate(0, 0);
          opacity: 1;
          visibility: visible;
        `};
    `
  )
);

const SCHamburgerGrid = styled(Grid)`
  padding-top: 0;
`;

const SCHamburgerHeader = withTheme(
  styled(Row)(
    props => css`
      height: ${props.coreTheme.header.mobileHeight};
      align-items: center;
    `
  )
);

const SCSearchInputSpacing = styled(Spacing)`
  width: 100%;
`;

const SCIconMenuBack = styled(IconMenuBack)`
  cursor: pointer;
`;

const SCLogoColumn = styled(Column)`
  display: flex !important;
  align-items: center !important;
`;

const SCMenuCloseAnchor = styled(Text)`
  display: block;
  height: ${remCalc(22)};
  width: ${remCalc(22)};
  float: right;
`;

const SCMenuCloseIcon = styled.img`
  height: 100%;
  width: 100%;
`;

const SCMenuTextAnchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      color: ${props.active
        ? props.coreTheme.header.mobileMenuActiveFontColor
        : props.coreTheme.header.mobileMenuFontColor};
      font-size: ${props.coreTheme.header.mobileMenuFontSize};
      font-family: ${props.secondary
        ? props.coreTheme.ff_sec_thin
        : props.coreTheme.ff_sec_bold};

      & svg {
        float: right;
      }
    `
  )
);

const Divider = withTheme(
  styled.div(
    props => css`
      margin: ${remCalc(16)} ${remCalc(-16)};
      border-bottom: 1px solid ${props.coreTheme.cl_ter_l5};
    `
  )
);

const SCColumnPrimaryMenuItem = styled(Column)`
  padding: 0 ${remCalc(8)};
`;

const SCColumnSubMenuItem = styled(SCColumnPrimaryMenuItem)`
  padding-left: ${remCalc(8)};
`;

const SCColumnSubSubMenuItem = styled(Column)`
  padding-left: ${remCalc(32)};
  padding-bottom: 0;
`;

const StyledFourthLevelMenuColumn = styled(Column)`
  padding-left: ${remCalc(32)};
  padding-top: 0;
  padding-bottom: 0;
`;

const SCMenuIconChevronUp = styled(IconChevronUp)`
  cursor: pointer;
`;

const SCMenuIconChevronDown = styled(IconChevronDown)`
  cursor: pointer;
`;

const SCMenuIconClose = styled(IconMenuClose)`
  cursor: pointer;
`;

const NestedMenu = ({
  baseUrl,
  activeSubMenuItem,
  activeMenuItem,
  firstLevelLinks,
  menuItems,
  onAccordionHeaderClick,
  isFourthLevelMenuOpen,
  onThirdLevelMenuClick,
  onMenuItemClick
}) => {
  const [thirdLevelMenu, setThirdLevelMenu] = useState();

  const onThirdLevelMenuClickCallback = menu => {
    setThirdLevelMenu(menu);
    onThirdLevelMenuClick();
  };

  return isFourthLevelMenuOpen ? (
    <Fragment>
      <SCMenuTextAnchor
        type="link"
        href={`${baseUrl}${thirdLevelMenu.item.href}`}
        style={{ paddingLeft: '8px' }}
        data-testid="fourth-level-menu"
        onClick={e => {
          onMenuItemClick(e, thirdLevelMenu.item);
        }}
      >
        {thirdLevelMenu.item.title}
      </SCMenuTextAnchor>
      <Divider />
      {thirdLevelMenu.subMenu &&
        thirdLevelMenu.subMenu.map((menuItem, menuKey) => (
          <Fragment key={menuKey}>
            <Row key={menuKey}>
              <StyledFourthLevelMenuColumn xs={12}>
                <SCMenuTextAnchor
                  type="link"
                  href={`${baseUrl}${menuItem.item.href}`}
                  secondary
                  onClick={e => {
                    onMenuItemClick(e, menuItem.item);
                  }}
                >
                  {menuItem.item.title}
                </SCMenuTextAnchor>
              </StyledFourthLevelMenuColumn>
            </Row>
            <Divider />
          </Fragment>
        ))}
    </Fragment>
  ) : (
    firstLevelLinks.map((menuItem, menuKey) => {
      return (
        <Row key={menuKey}>
          {/* First level menu items */}
          <SCColumnPrimaryMenuItem xs={12}>
            <SCMenuTextAnchor
              type="link"
              href={`${baseUrl}${menuItem.href}`}
              onClick={e => {
                onMenuItemClick(e, menuItem);
              }}
            >
              {menuItem.title}
            </SCMenuTextAnchor>

            <Divider />
          </SCColumnPrimaryMenuItem>
          {/* Second level menu items */}
          {menuItem.active &&
            menuItems &&
            menuItems.map((subMenuItem, subKey) => {
              return (
                <Column key={subKey} xs={12} style={{ padding: '0 auto' }}>
                  <SCColumnSubMenuItem xs={12}>
                    {/* We display accordion style if submenus exists */}
                    {(subMenuItem.subMenu || []).length > 0 && (
                      <SCMenuTextAnchor
                        type="link"
                        active={subKey === activeSubMenuItem}
                        onClick={() => onAccordionHeaderClick(menuKey, subKey)}
                        secondary
                        data-testid={`second-level-menu-${subKey + 1}`}
                      >
                        {subMenuItem.item.title}
                        {subKey === activeSubMenuItem && (
                          <SCMenuIconChevronUp size={20} />
                        )}
                        {subKey !== activeSubMenuItem && (
                          <SCMenuIconChevronDown size={20} />
                        )}
                      </SCMenuTextAnchor>
                    )}
                    {/* If no submenu is found, its a html href link */}
                    {(subMenuItem.subMenu || []).length === 0 && (
                      <SCMenuTextAnchor
                        type="link"
                        href={`${baseUrl}${subMenuItem.item.href}`}
                        secondary
                        onClick={e => {
                          onMenuItemClick(e, subMenuItem.item);
                        }}
                      >
                        {subMenuItem.item.title}
                      </SCMenuTextAnchor>
                    )}
                  </SCColumnSubMenuItem>
                  {/* Third level menu items */}
                  {menuKey === activeMenuItem &&
                    subKey === activeSubMenuItem &&
                    subMenuItem.subMenu
                      .filter(({ subMenu }) => subMenu) // Filtering away empty subMenu
                      .map((subSubMenuItem, subSubKey) => {
                        return (
                          <Fragment key={subSubKey}>
                            {/* Display the fourth level menu if subMenu exists */}
                            {subSubMenuItem.subMenu.length > 0 && (
                              <SCColumnSubSubMenuItem xs={12}>
                                <SCMenuTextAnchor
                                  type="link"
                                  onClick={e => {
                                    onThirdLevelMenuClickCallback(
                                      subSubMenuItem
                                    );

                                    onMenuItemClick(e, subSubMenuItem.item);
                                  }}
                                  secondary
                                  data-testid={`third-level-menu-${subSubKey +
                                    1}`}
                                >
                                  {subSubMenuItem.item.title}
                                </SCMenuTextAnchor>
                              </SCColumnSubSubMenuItem>
                            )}
                            {/* If no third level menu is found, it's a html href link */}
                            {(subSubMenuItem.subMenu || []).length === 0 && (
                              <SCColumnSubSubMenuItem sm={12}>
                                <SCMenuTextAnchor
                                  type="link"
                                  href={`${baseUrl}${subSubMenuItem.item.href}`}
                                  secondary
                                  onClick={e => {
                                    onMenuItemClick(e, subSubMenuItem.item);
                                  }}
                                >
                                  {subSubMenuItem.item.title}
                                </SCMenuTextAnchor>
                              </SCColumnSubSubMenuItem>
                            )}
                          </Fragment>
                        );
                      })}
                  <Divider />
                </Column>
              );
            })}
        </Row>
      );
    })
  );
};

const SimpleMenu = ({ baseUrl, menuItems, onMenuItemClick }) => {
  return menuItems.map((menuItem, key) => (
    <Row key={key}>
      {/* First level menu items */}
      <SCColumnPrimaryMenuItem xs={12}>
        <SCMenuTextAnchor
          type="link"
          href={`${baseUrl}${menuItem.item.href}`}
          active={menuItem.item.active}
          style={{ padding: '15px 0px 20px 19px' }}
          onClick={e => {
            onMenuItemClick(e, menuItem.item);
          }}
        >
          {menuItem.item.title}
        </SCMenuTextAnchor>
      </SCColumnPrimaryMenuItem>
      {/* Second level menu items */}
      {menuItem.subMenu &&
        menuItem.subMenu.map((subMenuItem, subKey) => {
          return (
            <Column key={subKey} xs={12} style={{ padding: '0 auto' }}>
              <SCColumnSubMenuItem
                style={{ paddingLeft: '46px', paddingBottom: '15px' }}
                xs={12}
              >
                <SCMenuTextAnchor
                  type="link"
                  href={`${baseUrl}${subMenuItem.item.href}`}
                  secondary
                  onClick={e => {
                    onMenuItemClick(e, subMenuItem.item);
                  }}
                >
                  {subMenuItem.item.title}
                </SCMenuTextAnchor>
              </SCColumnSubMenuItem>
            </Column>
          );
        })}
    </Row>
  ));
};

const MobileMenu = ({
  baseUrl,
  backgroundColor,
  activeSubMenuItem,
  activeMenuItem,
  firstLevelLinks,
  isHamburgerMenuOpen,
  logo,
  menuItems,
  onClose,
  onAccordionHeaderClick,
  showLogoHamburger,
  showHamburgerRight,
  hamburgerCloseIcon: HamburgerCloseIcon,
  onMenuItemClick = noop,
  searchConfigOptions
}) => {
  const [isFourthLevelMenuOpen, setIsFourthLevelMenuOpen] = useState(false);

  const closeFourthLevelMenu = () => setIsFourthLevelMenuOpen(false);

  const onCloseMenu = () => {
    closeFourthLevelMenu();
    onClose();
  };

  const onThirdLevelMenuClick = () => {
    setIsFourthLevelMenuOpen(true);
  };

  return (
    <SCHamburgerMenu
      isHamburgerMenuOpen={isHamburgerMenuOpen}
      showHamburgerRight={showHamburgerRight}
      backgroundColor={backgroundColor}
      data-testid="mobile-menu"
    >
      <SCHamburgerGrid>
        {/* The hamburger menu header */}
        <SCHamburgerHeader>
          <Column>
            {isFourthLevelMenuOpen && (
              <SCIconMenuBack size={20} onClick={closeFourthLevelMenu} />
            )}
          </Column>
          {/* Logo in the middle */}
          {showLogoHamburger && logo && (
            <SCLogoColumn>
              <Logo
                baseUrl={baseUrl}
                href={logo.href}
                alt={logo.alt}
                src={logo.src}
              />
            </SCLogoColumn>
          )}
          {/* The hamburger close icon */}
          <Column>
            <SCMenuCloseAnchor type="link" onClick={onCloseMenu}>
              {HamburgerCloseIcon ? (
                typeof HamburgerCloseIcon === 'string' ? (
                  <SCMenuCloseIcon src={HamburgerCloseIcon} />
                ) : (
                  <HamburgerCloseIcon />
                )
              ) : (
                <SCMenuIconClose size={22} />
              )}
            </SCMenuCloseAnchor>
          </Column>
        </SCHamburgerHeader>

        {searchConfigOptions.showSearch && (
          <Row data-testid="mobile-search-menu-item">
            <SCSearchInputSpacing top={1} bottom={2}>
              <Search {...searchConfigOptions} isActive={isHamburgerMenuOpen} />
            </SCSearchInputSpacing>
          </Row>
        )}
        <Row>
          <Column noGutter sm={12}>
            {/* Loop through all menu items */}
            {firstLevelLinks && (
              <NestedMenu
                baseUrl={baseUrl}
                activeSubMenuItem={activeSubMenuItem}
                activeMenuItem={activeMenuItem}
                firstLevelLinks={firstLevelLinks}
                menuItems={menuItems}
                onAccordionHeaderClick={onAccordionHeaderClick}
                isFourthLevelMenuOpen={isFourthLevelMenuOpen}
                onThirdLevelMenuClick={onThirdLevelMenuClick}
                onMenuItemClick={onMenuItemClick}
              />
            )}
            {!firstLevelLinks && (
              <SimpleMenu
                baseUrl={baseUrl}
                menuItems={menuItems}
                onMenuItemClick={onMenuItemClick}
              />
            )}
          </Column>
        </Row>
      </SCHamburgerGrid>
    </SCHamburgerMenu>
  );
};

export default MobileMenu;
