import React from 'react';
import CompletedIcon from '@dls/assets/icons/Completed';
import PropTypes from 'prop-types';
import useDevice from '../../core/hooks/useDevice';
import { useTheme } from '../../core/ThemeProvider';
import { testIdProps } from '../../helpers';
import {
  ActiveBorder,
  CircleWrapper,
  getCircleText,
  Line,
  StepContainer,
  Title,
  TitleWrapper,
  VerticalWrapper,
  Wrapper
} from './style';

const MAX_STEP = 5;
const Step = ({
  current,
  active,
  title,
  isFirst,
  isLast,
  visible,
  isVertical,
  status
}) => {
  const { theme } = useTheme();
  const { isMobile } = useDevice();
  const isBeforeActive = status
    ? status === 'completed' && current !== active
    : current < active;
  const isActive = current === active;
  const isAfterActive = !isBeforeActive && !isActive;

  const CircleText = getCircleText(isActive);
  const circleColor = isAfterActive ? theme.cl_ter_l3 : theme.cl_white;
  const circleBgColor = isBeforeActive
    ? theme.cl_white
    : isActive
    ? theme.cl_sec_d2
    : theme.cl_ter_l5;
  const lineColor =
    isAfterActive || isActive ? theme.cl_ter_l5 : theme.cl_ter_l3;
  return (
    <StepContainer isLast={isLast} isVertical={isVertical}>
      {visible && (
        <>
          <CircleWrapper isVertical={isVertical}>
            <ActiveBorder isActive={isActive} isVertical={isVertical}>
              <CircleText
                bgColor={circleBgColor}
                color={circleColor}
                data-testid={`stepId-${current}`}
              >
                {(isAfterActive || isActive) && current}
                {isBeforeActive && (
                  <CompletedIcon
                    color={theme.cl_ter_l3}
                    size={isMobile ? theme.space_6 : theme.space_8}
                  />
                )}
              </CircleText>
            </ActiveBorder>
            {!isVertical && (
              <Line
                data-testid={`lineId-${current}`}
                color={lineColor}
                isActive={isActive}
                isFirst={isFirst}
                isLast={isLast}
                isVertical={isVertical}
              />
            )}
          </CircleWrapper>
          {!isVertical && title && !isMobile && <Title>{title}</Title>}
          {isVertical && (
            <Line
              data-testid={`lineId-${current}`}
              color={lineColor}
              isActive={isActive}
              isFirst={isFirst}
              isLast={isLast}
              isVertical={isVertical}
            />
          )}
        </>
      )}
    </StepContainer>
  );
};
const Stepper = React.forwardRef((props, ref) => {
  const {
    totalSteps,
    activeStep,
    titles,
    isVertical,
    children,
    status,
    ...rest
  } = props;
  const stepperChildrens = React.Children.toArray(children);
  const NoOfSteps =
    totalSteps &&
    stepperChildrens.length === 0 &&
    totalSteps !== stepperChildrens.length &&
    isVertical
      ? totalSteps
      : stepperChildrens.length;

  return (
    <Wrapper isVertical={isVertical} {...testIdProps(rest)}>
      {!isVertical
        ? [...Array(MAX_STEP)].map((_, index) => (
            <Step
              key={index}
              current={index + 1}
              active={activeStep}
              title={titles ? titles[index] : null}
              isFirst={index === 0}
              isLast={index + 1 === totalSteps}
              visible={index + 1 <= totalSteps}
              isVertical={isVertical}
              ref={ref}
            />
          ))
        : [...Array(NoOfSteps)].map((_, index) => (
            <VerticalWrapper key={index} isLast={index + 1 === NoOfSteps}>
              <Step
                key={index}
                current={index + 1}
                active={activeStep}
                title={titles ? titles[index] : null}
                isFirst={index === 0}
                isLast={index + 1 === NoOfSteps}
                visible={index + 1 <= NoOfSteps}
                isVertical={isVertical}
                status={status ? status[index] : null}
              />

              {
                <TitleWrapper>
                  {stepperChildrens && stepperChildrens[index]
                    ? stepperChildrens[index]
                    : titles &&
                      titles[index] && (
                        <Title isVertical={isVertical}>{titles[index]}</Title>
                      )}
                </TitleWrapper>
              }
            </VerticalWrapper>
          ))}
    </Wrapper>
  );
});

Stepper.defaultProps = {
  totalSteps: MAX_STEP
};

Stepper.displayName = 'Stepper';

Stepper.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The active step
   */
  activeStep: PropTypes.number.isRequired,
  /**
   * Children component from DLS Assets
   */
  children: PropTypes.any,
  /**
   * Flag to enable the Vertical Stepper
   */
  isVertical: PropTypes.bool,
  /**
   * Status props helps to override the previous step status in the Stepper.In default it will show as completed.
   */
  status: PropTypes.arrayOf(PropTypes.oneOf(['completed', 'inprogress'])),
  /**
   * The title for each step (only show in Desktop view)
   */
  titles: PropTypes.arrayOf(PropTypes.string),
  /**
   * The total steps
   */
  totalSteps: PropTypes.number.isRequired
};

export default Stepper;
