/**
 * Convert theme config object to a flat key-value object
 * for ease of use inside DLS components.
 *
 * Note format is for internal use.
 * DLS users use the theme config object format
 *
 * @param {Object} theme Theme config object
 * @return {
 *  {
 *    space_0,
 *    space_1,
 *    space_2,
 *    space_3,
 *    space_4,
 *    space_5,
 *    space_6,
 *    space_7,
 *    space_8,
 *    space_9,
 *    space_10,
 *    brk_xs,
 *    brk_sm,
 *    brk_md,
 *    brk_lg,
 *    brk_xl,
 *    rad_xs,
 *    rad_sm,
 *    rad_md,
 *    rad_lg,
 *    rad_xl,
 *    rad_xxl,
 *    ff_pri_thin,
 *    ff_pri_regular,
 *    ff_pri_bold,
 *    ff_sec_thin,
 *    ff_sec_regular,
 *    ff_sec_bold,
 *    cl_white,
 *    cl_black,
 *    cl_successGreen,
 *    cl_errorRed,
 *    cl_divider,
 *    cl_pri_l3,
 *    cl_pri_l2,
 *    cl_pri_l1,
 *    cl_pri_base,
 *    cl_pri_d1,
 *    cl_pri_d2,
 *    cl_pri_d3,
 *    cl_pri_d4,
 *    cl_sec_l2,
 *    cl_sec_l1,
 *    cl_sec_base,
 *    cl_sec_d1,
 *    cl_sec_d2,
 *    cl_sec_d3,
 *    cl_ter_l6,
 *    cl_ter_l5,
 *    cl_ter_l4,
 *    cl_ter_l3,
 *    cl_ter_l2,
 *    cl_ter_l1,
 *    cl_ter_base,
 *    cl_ter_d1,
 *    fs_xs,
 *    fs_sm,
 *    fs_md,
 *    fs_lg,
 *    fs_xl,
 *    fs_xxl,
 *    sd_size_1,
 *    sd_size_2
 *  }
 * }
 */
export function flattenTheme(theme) {
  return {
    ...flattenSpace(theme.space),
    ...flattenBreakpoint(theme.breakpoints),
    ...flattenBorderRadius(theme.borderRadius),
    ...flattenFontFamily(theme.main.fontFamily),
    ...flattenColors(theme.main.colours),
    ...flattenFontSize(theme.main.fontSizes),
    ...flattenShadow(theme.main.shadows)
  };
}

function flattenSpace(space) {
  return space.reduce((acc, val, index) => {
    acc[`space_${index}`] = space[index];
    return acc;
  }, {});
}

function flattenBreakpoint(breakpoints) {
  return {
    brk_xs: breakpoints.xs,
    brk_sm: breakpoints.sm,
    brk_md: breakpoints.md,
    brk_lg: breakpoints.lg,
    brk_xl: breakpoints.xl
  };
}

function flattenBorderRadius(borderRadius) {
  return Object.keys(borderRadius).reduce((acc, val) => {
    acc[`rad_${val}`] = borderRadius[val];
    return acc;
  }, {});
}

function flattenColors(colors) {
  // TODO compute values from config.
  return {
    cl_white: colors.white,
    cl_black: colors.black,

    cl_successGreen: colors.successGreen,
    cl_errorRed: colors.errorRed,
    cl_divider: colors.divider,
    /* Primary colors */
    cl_pri_l3: colors.primary.l3,
    cl_pri_l2: colors.primary.l2,
    cl_pri_l1: colors.primary.l1,
    cl_pri_base: colors.primary.base,
    cl_pri_d1: colors.primary.d1,
    cl_pri_d2: colors.primary.d2,
    cl_pri_d3: colors.primary.d3,
    cl_pri_d4: colors.primary.d4,

    /* Secondary colors */
    cl_sec_l2: colors.secondary.l2,
    cl_sec_l1: colors.secondary.l1,
    cl_sec_base: colors.secondary.base,
    cl_sec_d1: colors.secondary.d1,
    cl_sec_d2: colors.secondary.d2,
    cl_sec_d3: colors.secondary.d3,

    /* Tertiary colors */
    cl_ter_l6: colors.tertiary.l6,
    cl_ter_l5: colors.tertiary.l5,
    cl_ter_l4: colors.tertiary.l4,
    cl_ter_l3: colors.tertiary.l3,
    cl_ter_l2: colors.tertiary.l2,
    cl_ter_l1: colors.tertiary.l1,
    cl_ter_base: colors.tertiary.base,
    cl_ter_d1: colors.tertiary.d1
  };
}

function flattenFontFamily(fontFamily) {
  // TODO compute values from config.
  return {
    ff_pri_thin: fontFamily.primaryThin,
    ff_pri_regular: fontFamily.primaryRegular,
    ff_pri_bold: fontFamily.primaryBold,
    ff_sec_thin: fontFamily.secondaryThin,
    ff_sec_regular: fontFamily.secondaryRegular,
    ff_sec_bold: fontFamily.secondaryBold
  };
}

function flattenFontSize(fontSizes) {
  // TODO compute values from config.
  return {
    fs_xs: fontSizes.xs,
    fs_sm: fontSizes.sm,
    fs_md: fontSizes.md,
    fs_lg: fontSizes.lg,
    fs_xl: fontSizes.xl,
    fs_xxl: fontSizes.xxl
  };
}

function flattenShadow(shadows) {
  // TODO compute values from config.
  return {
    sd_size_1: shadows.size1,
    sd_size_2: shadows.size2
  };
}
