import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { withTheme, useTheme } from '../../ThemeProvider';

export const FormFieldContext = React.createContext(null);

// TODO Sudhir: Add documentation specific to this component.
const FormField = ({
  disabled,
  children,
  defaultValue,
  error,
  bgColor,
  ...rest
}) => {
  const { theme } = useTheme();
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(!!defaultValue);

  const onFocus = useCallback(() => !disabled && setFocus(true), [disabled]);
  const onBlur = useCallback(() => setFocus(false), []);
  const backgroundColor = useMemo(
    () => (bgColor === 'haze' ? theme.cl_ter_l6 : theme.cl_white),
    [bgColor]
  );

  const handleMouseDown = e => {
    /**
     * If the field is already in focus, clicking on it again
     * causes the input field to lose focus.
     * To prevent this the event should be stopped here from being
     * propagated to the input/select children element(s).
     */
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (!focus) {
      onFocus();
    } else {
      e.preventDefault();
    }
  };

  const formFieldApi = useMemo(
    () => ({
      onFocus,
      onBlur,
      setValue,
      hasFocus: focus,
      hasValue: !!value
    }),
    [focus, value]
  );

  return (
    <FormFieldContainer
      onMouseDown={handleMouseDown}
      {...rest}
      disabled={!!disabled}
      bgColor={backgroundColor}
      error={error}
      hasFocus={focus}
    >
      <InputContainer>
        <FormFieldContext.Provider value={formFieldApi}>
          {children}
        </FormFieldContext.Provider>
      </InputContainer>
    </FormFieldContainer>
  );
};

const FormFieldContainer = withTheme(
  styled.label(
    ({ coreTheme, hasFocus, error }) => css`
      display: block;
      align-items: center;
      background-color: ${p => (p.disabled ? 'transparent' : p.bgColor)};
      border: 1px solid transparent;
      position: relative;
      width: 100%;
      transition: all 0.2s ease-in-out;
      border-radius: ${coreTheme.space_2}px;

      ${hasFocus &&
        css`
          border-color: ${coreTheme.cl_sec_d1};
          background-color: ${coreTheme.cl_white};
        `}

      ${error &&
        css`
          border-color: ${coreTheme.cl_pri_d3};
        `}
    `
  )
);

const InputContainer = withTheme(styled.div`
  padding: ${p =>
    `${p.coreTheme.space_9}px ${p.coreTheme.space_4}px ${p.coreTheme.space_4}px`};
`);

export default FormField;
