import React from 'react';
import { withRouter } from 'react-router-dom';
import { Anchor } from '@lux/components';
import DocumentTitle from 'react-document-title';
import Container from '../../components/Container';
import Landing from '../../components/Landing';
import { devices } from '../../configs/devices';
import NoMatch from '../../containers/NoMatch';

export const StaticEarly = props => {
  if (!devices[props.match.params.campaign]) {
    return <NoMatch />;
  }

  const heroImage = devices[props.match.params.campaign].banner;

  return (
    <DocumentTitle title="Pre-registration">
      <Container>
        <Landing
          image={heroImage}
          title="You are early"
          description={
            <React.Fragment>
              Visit{' '}
              <Anchor href="//www.singtel.com/iphone">
                www.singtel.com/iphone
              </Anchor>{' '}
              for the latest updates on when you can pre-register for the new{' '}
              {devices[props.match.params.campaign].name}.
            </React.Fragment>
          }
          showNric={false}
        />
      </Container>
    </DocumentTitle>
  );
};

const RoutedStaticEarly = withRouter(StaticEarly);

export default RoutedStaticEarly;
