import styled, { css } from 'styled-components';
import Config from '../../config';
import { withTheme } from '../../core/ThemeProvider';
import { getStyledText } from '../../helpers/styled';

const CIRCLE_TO_RING_WIDTH = '10px';

export const Wrapper = styled.div(
  ({ isVertical }) => css`
    ${isVertical &&
      css`
        flex-direction: column;
        height: 100%;
      `}
    display: flex;
    align-items: stretch;
    box-sizing: content-box;
  `
);

export const VerticalWrapper = styled.div(css`
  display: flex;
`);

export const StepContainer = styled.div(
  ({ isVertical }) => css`
    ${!isVertical &&
      css`
        flex: 1;
      `}
    ${isVertical &&
      css`
        justify-content: center;
        position: relative;
        height: auto;
        margin-top: 24px;
        padding-right: 20px;
      `}

    ${p =>
      p.isLast &&
      css`
        flex: 0;

        ${!isVertical &&
          Config.media.sm`
        flex: 1;
      `};
      `}
  `
);

export const CircleWrapper = styled.div(
  ({ isVertical }) => css`
    ${!isVertical &&
      css`
        display: flex;
      `}
    align-items: center;
    position: relative;
    ${isVertical &&
      css`
        z-index: 1;
      `}
  `
);

export const Line = styled.div(
  ({ color, isActive, isFirst, isLast, isVertical }) => css`

	   ${isVertical &&
       css`
         width: 1px;
         background-color: ${color};
         right: -17px;
         top: -5px;
       `}${!isVertical &&
    css`
      flex: 1;
      height: 1px;
      background-color: ${color};
    `}
    ${isVertical &&
      Config.media.sm`
    right: -20px;
    top: -10px;
  `};

    ${!isActive &&
      !isVertical &&
      css`
        position: absolute;
        right: 0;
        width: 100%;
      `}

    ${isActive &&
      isLast &&
      !isVertical &&
      css`
        display: none;
      `}

    ${isLast &&
      !isVertical &&
      css`
        width: ${CIRCLE_TO_RING_WIDTH};
        left: 0;
      `}

    ${isFirst &&
      !isVertical &&
      css`
        width: calc(100% - ${CIRCLE_TO_RING_WIDTH});
      `}

    ${!isActive &&
      isVertical &&
      css`
        position: relative;
        height: 100%;
        bottom: 0;
      `}

    ${isActive &&
      isLast &&
      isVertical &&
      css`
        display: none;
      `}

    ${isFirst &&
      isVertical &&
      css`
        height: 100%;
      `}
    ${isLast &&
      isVertical &&
      css`
        height: ${CIRCLE_TO_RING_WIDTH};
        top: 0;
        display: none;
      `}

    ${isActive &&
      isVertical &&
      css`
        position: relative;
        bottom: 0;
        height: 100%;
      `}
  `
);

export const ActiveBorder = withTheme(
  styled.div(
    ({ coreTheme, isActive, isVertical }) => css`
      background: transparent;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;

      ${Config.media.sm`
        width: 42px;
        height: 42px;
      `};

      ${isActive &&
        css`
          border: 2px solid ${coreTheme.cl_sec_d2};
          width: 30px;
          height: 30px;

          ${isVertical &&
            css`
              background: ${coreTheme.cl_white};
            `}

          ${Config.media.sm`
            width: 38px;
            height: 38px;
          `};
        `}
    `
  )
);

export const getCircleText = isActive =>
  withTheme(
    getStyledText(isActive ? 'boldBody' : 'body', { lineHeight: 'compact' })(
      ({ bgColor, color, coreTheme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: ${bgColor};
        color: ${color};
        width: ${coreTheme.space_6}px;
        height: ${coreTheme.space_6}px;
        z-index: 1;

        ${Config.media.sm`
          width: ${coreTheme.space_8}px;
          height: ${coreTheme.space_8}px;
        `};
      `
    )
  );
export const TitleWrapper = styled.div(css`
  width: 100%;
  margin-top: 24px;

  & > div:first-child {
    margin-top: 0;
  }
`);
export const Title = withTheme(
  getStyledText('smallBody', { lineHeight: 'compact' })(
    ({ coreTheme }) => css`
      color: ${coreTheme.cl_ter_base};
      padding-top: ${coreTheme.space_2}px;
      padding-right: ${coreTheme.space_4}px;
    `
  )
);
