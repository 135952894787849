import React from 'react';
import PropTypes from 'prop-types';
import {
  WrapperVariant1,
  WrapperVariant2,
  ThumbnailVariant1,
  ThumbnailVariant2,
  Image,
  ContentVariant1,
  ContentVariant2,
  Title,
  getBodyVariant1,
  BodyVariant2
} from './style';
import useDevice from '../../core/hooks/useDevice';

const CirclePattern = ({ type, thumbnail, title, flush, body }) => {
  const { isMobile } = useDevice();
  const BodyVariant1 = getBodyVariant1(isMobile);

  return type === 'variant1' ? (
    <WrapperVariant1>
      <ThumbnailVariant1>
        <Image src={thumbnail.url} alt={thumbnail.alt} />
      </ThumbnailVariant1>

      <ContentVariant1>
        <BodyVariant1>{body}</BodyVariant1>
      </ContentVariant1>
    </WrapperVariant1>
  ) : (
    <WrapperVariant2 flush={flush}>
      <ThumbnailVariant2>
        <Image src={thumbnail.url} alt={thumbnail.alt} />
      </ThumbnailVariant2>

      <ContentVariant2>
        <Title>{title}</Title>
        <BodyVariant2>{body}</BodyVariant2>
      </ContentVariant2>
    </WrapperVariant2>
  );
};

CirclePattern.defaultProps = {
  type: 'variant1'
};

CirclePattern.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Content text body
   */
  body: PropTypes.node,
  /**
   * Item flush to the left or right (on Desktop for variant 2)
   */
  flush: PropTypes.oneOf(['left', 'right']),
  /**
   * Thumbnail image
   */
  thumbnail: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string.isRequired
  }).isRequired,
  /**
   * Item title, shows only for `variant2`
   */
  title: PropTypes.string,
  /**
   * Circle pattern types
   * @default `variant1`
   */
  type: PropTypes.oneOf(['variant1', 'variant2'])
};

export default CirclePattern;
