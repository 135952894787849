import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { testIdProps } from '../../helpers';
import { SelectorWrapper, SelectContainer, Image, EndContent } from './style';
import SelectorBody from './SelectorBody';
import SelectorColor from './SelectorColor';

const Selector = props => {
  const {
    alignment,
    justifyContent,
    noPadding,
    selected,
    disabled,
    onClick,
    children,
    endContent,
    value,
    autoHeight,
    ...rest
  } = props;

  const getEndContentPropKey = () =>
    React.isValidElement(endContent) ? 'children' : 'title';

  const renderEndContent = () => {
    if (endContent) {
      const propKey = getEndContentPropKey();
      const endContentProps = {
        alignment,
        disabled,
        [propKey]: endContent,
        style: endContent?.props?.endContentStyle || {}
      };

      return <EndContent {...endContentProps} />;
    }
    return endContent;
  };

  let selectorContainerAltPadding;
  const arrayChildren = Children.toArray(children);
  if (
    arrayChildren.some(
      child =>
        (child.type === Image && alignment === 'column') ||
        child.type === SelectorColor
    )
  ) {
    selectorContainerAltPadding = true;
  }

  return (
    <SelectorWrapper selected={selected} autoHeight={autoHeight}>
      <SelectContainer
        alignment={alignment}
        justifyContent={justifyContent}
        selected={selected}
        disabled={disabled}
        onClick={event => onClick(value, event)}
        noPadding={noPadding}
        altPadding={selectorContainerAltPadding}
        {...testIdProps(rest)}
      >
        {children}
        {renderEndContent()}
      </SelectContainer>
    </SelectorWrapper>
  );
};

Selector.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Alignment - row or column
   */
  alignment: PropTypes.oneOf(['column', 'row']),
  /**
   * flag when set to true, makes selector container height to auto
   * @default false
   */
  autoHeight: PropTypes.bool,
  /**
   * Is disabled
   */
  disabled: PropTypes.bool,
  /**
   * endContent - ReactNode
   */
  endContent: PropTypes.node,
  /**
   * JustifyContent
   */
  justifyContent: PropTypes.string,
  /**
   * selector without padding
   */
  noPadding: PropTypes.bool,
  /**
   * click event handler
   */
  onClick: PropTypes.func,
  /**
   * Is selected
   */
  selected: PropTypes.bool,
  /**
   * selected value
   */
  value: PropTypes.any
};

Selector.defaultProps = {
  alignment: 'row',
  selected: false,
  disabled: false,
  onClick: () => {},
  justifyContent: 'space-between',
  noPadding: false,
  autoHeight: false
};

Selector.Body = SelectorBody;
Selector.Image = Image;
Selector.Color = SelectorColor;

export default Selector;
