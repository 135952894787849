import React from 'react';
import PropTypes from 'prop-types';
import {
  Completed as CompletedIcon,
  Alert as AlertIcon
} from '@dls/assets/icons';
import Card from '../Card';
import {
  Box,
  Column,
  Columns,
  LeftContainer,
  RightContainer,
  ListItemImage,
  ListItemThumbnail,
  SubTitle,
  Tag,
  Title,
  ListItemPointsContainer
} from './style';
import {
  createLegoComponent,
  getLegoElementName
} from '../../core/utils/legoUtils';
import { getFilteredList } from '../../core/utils/getFilteredList';
import { testIdProps } from '../../helpers';
import Toggle from '../Toggle';
import { useTheme } from '../../core/ThemeProvider';

const STATUS_TYPES = {
  success: { Icon: CompletedIcon, color: 'cl_successGreen' },
  error: { Icon: AlertIcon, color: 'cl_errorRed' }
};

const ListItem = p => {
  const { leftContent, rightContent } = p;

  const isListItemTall =
    getLegoElementName(leftContent) === 'ListItemThumbnail';
  const tagContent = isListItemTall ? getFilteredList(p.body, 'tag')[0] : null;

  return (
    <div {...testIdProps(p)}>
      <Card>
        <Columns tall={isListItemTall}>
          {/* Render left content */}
          <Column>
            <LeftContainer>
              {leftContent}
              {tagContent && <Tag key={tagContent.tag}>{tagContent.tag}</Tag>}
            </LeftContainer>
          </Column>

          {/* Render body content */}
          <Column width="content">
            <Box>{p.body && renderBody(p.body, isListItemTall)}</Box>
          </Column>

          {/* TODO: Render right content */}
          <Column>{renderRightContent(rightContent)}</Column>
        </Columns>
      </Card>
    </div>
  );
};

ListItem.defaultProps = {
  leftContent: null,
  rightContent: null
};

ListItem.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Data model for body content of the list item
   * Currently it support 3 types of body content
   * 1. title
   * 2. sub title
   * 3. tag
   * 4. points
   *
   */
  body: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ title: PropTypes.string }),
      PropTypes.shape({ subTitle: PropTypes.string }),
      PropTypes.shape({ tag: PropTypes.string }),
      PropTypes.shape({ points: PropTypes.element })
    ])
  ),
  leftContent: PropTypes.shape({
    type: PropTypes.oneOf([ListItemImage, ListItemThumbnail])
  }),
  rightContent: PropTypes.shape({
    toggle: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func
    }),
    status: PropTypes.oneOf(['success', 'error'])
  })
};

const ListItemIcon = createLegoComponent('ListItemIcon', ({ icon: Icon }) => {
  const { theme } = useTheme();
  return (
    <Icon
      size={theme.listItem.leftContent.icon.size}
      color={theme.listItem.leftContent.icon.color}
    />
  );
});

const ListItemStatus = createLegoComponent('ListItemStatus', p => {
  const status = STATUS_TYPES[p.type];
  if (!status) return null;
  const { theme } = useTheme();
  const { Icon, color } = status;
  return <Icon color={theme[color]} size={24} />;
});

const ListItemToggle = createLegoComponent('ListItemToggle', Toggle);

const ListItemPoints = createLegoComponent('ListItemPoints', p => {
  const { text, icon: Icon } = p;
  const { theme } = useTheme();
  return (
    <ListItemPointsContainer>
      <Icon
        size={theme.listItem.bodyContent.points.icon.size}
        color={theme.listItem.bodyContent.points.icon.color}
      />
      <span>{text}</span>
    </ListItemPointsContainer>
  );
});

ListItem.Icon = ListItemIcon;
ListItem.Image = ListItemImage;
ListItem.Thumbnail = ListItemThumbnail;
ListItem.Toggle = ListItemToggle;
ListItem.Status = ListItemStatus;
ListItem.Points = ListItemPoints;

export default ListItem;

/**
 * Render main body content
 * @param bodyProps
 * @param bodyProps.title
 * @param bodyProps.subTitle
 * @param bodyProps.tag
 * @param bodyProps.points
 * @return {any[]}
 */
function renderBody(bodyProps = [], isListItemTall = false) {
  let newBodyProps = [];
  let nowrapTitle = false;
  let nowrapSubTitle = false;

  if (isListItemTall) {
    newBodyProps = bodyProps;
    nowrapTitle = getFilteredList(bodyProps, ['tag'], true).length >= 3;
    nowrapSubTitle = true;
  } else {
    newBodyProps = getFilteredList(bodyProps, ['points', 'tag'], true);
    if (newBodyProps.length > 1) {
      nowrapTitle = true;
      nowrapSubTitle = true;
    }
  }

  return newBodyProps.map(({ title, subTitle, points }, index) => {
    if (title)
      return (
        <Title key={title + index} nowrap={nowrapTitle}>
          {title}
        </Title>
      );

    if (subTitle)
      return (
        <SubTitle key={subTitle + index} nowrap={nowrapSubTitle}>
          {subTitle}
        </SubTitle>
      );

    if (points) return <div key={index}>{points}</div>;
  });
}

function renderRightContent(content) {
  const legoElName = getLegoElementName(content);

  if (legoElName) {
    return <RightContainer>{content}</RightContainer>;
  } else {
    /**
     * Content is not a ListItem lego block.
     * render as is.
     * */
    return content;
  }
}
