import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Text from '../Text';
import { withTheme } from '../../core/ThemeProvider';
import { withStyledAttrs } from '../../helpers/styled';

const Wrapper = withStyledAttrs(Text, { type: 'body', tag: 'span' })`
  position: relative;
`;

const TextWrapper = withTheme(styled.div`
  padding: 0 0 8px 26px;
  ${props => props.size === 'small' && 'padding-bottom: 0px;'}
`);

const DotWrapper = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      position: absolute;
      margin: 0 ${coreTheme.space_3}px 0 ${coreTheme.space_2}px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: '.';
        visibility: hidden;
        width: 0;
      }
    `
  )
);

const Dot = withTheme(
  styled.span`
    line-height: inherit;
    height: 6px;
    width: 6px;
    background-color: ${p => p.coreTheme.cl_ter_base};
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 2px;
  `
);

const BulletList = ({ children, size }) => (
  <Wrapper>
    <DotWrapper>
      <Dot />
    </DotWrapper>
    <TextWrapper size={size}>{children}</TextWrapper>
  </Wrapper>
);

BulletList.defaultProps = {
  size: 'default'
};

BulletList.propTypes = {
  /* padding bottom */
  size: PropTypes.oneOf(['small', 'default'])
};

export default BulletList;
