import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trash as TrashIcon } from '@dls/assets/icons';
import { withClearInteraction } from '../../helpers/styled';
import { withTheme } from '../../core/ThemeProvider';

const IconTrashButton = ({ coreTheme, onClick, ...rest }) => {
  return (
    <IconContainer {...rest} onClick={onClick} enableInteraction={true}>
      <TrashIcon
        size={22}
        color={coreTheme.cl_sec_d1}
        data-testid="trash-icon"
      />
    </IconContainer>
  );
};

IconTrashButton.propTypes = {
  onClick: PropTypes.func
};

export const IconContainer = withTheme(
  withClearInteraction(
    styled.div`
      cursor: pointer;
      position: relative;
      display: inline-block;
      padding: ${p => p.coreTheme.space_1}px;
    `,
    8
  )
);

export default withTheme(IconTrashButton);
