export const validateApi = {
  onlyPrestigeAllowed: resp =>
    resp &&
    (!resp.eligible ||
      resp.registrationContext === RegistrationContextTypes.PRESTIGE_ONLY),
  requireValidToken: resp =>
    resp && resp.registrationContext === RegistrationContextTypes.INVALID_TOKEN,
  appointmentNotAllowedDuringROIPhase: resp =>
    resp && resp.registrationContext === RegistrationContextTypes.ROI_ONLY,
  haveOrderSubmitted: resp =>
    resp &&
    resp.registrationContext === RegistrationContextTypes.ORDER_SUBMITTED
};

const RegistrationContextTypes = {
  INVALID_TOKEN: 'INVALID_TOKEN',
  PRESTIGE_ONLY: 'PRESTIGE_ONLY',
  ROI_ONLY: 'ROI_ONLY',
  ORDER_SUBMITTED: 'ORDER_SUBMITTED'
};

const RegistrationContextError = {
  PRESTIGE_ONLY: 'NRIC_NOT_ELIGIBLE_PRESTIGE',
  ROI_ONLY: 'ROI_ONLY'
};

export async function getError(response) {
  if (response.status < 200 || response.status >= 300) {
    return {
      type: null
    };
  }
  try {
    const data = await response.json();
    const { registrationContext } = data;
    return {
      data,
      errorType: RegistrationContextError[registrationContext],
      type: RegistrationContextError[registrationContext]
    };
  } catch (e) {
    return {
      type: null
    };
  }
}
