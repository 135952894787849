import PropTypes from 'prop-types';
import React from 'react';

const TextLinkText = ({ children }) => <span>{children}</span>;

TextLinkText.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * text
   */
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default TextLinkText;
