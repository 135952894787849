import styled from 'styled-components';
import { Panel, Theme } from '@lux/components';

const Container = styled(Panel)`
  max-width: ${p => (p.fullWidth ? '100%' : '800px')};
  margin: 0 auto;

  ${Theme.media.sm`
    min-height: auto;
    max-width: 100vw;
  `};

  ${Theme.media.md`
    min-height: auto;
    max-width: ${p => (p.fullWidth ? '100%' : '800px')};
    margin-top: 15px;
  `};
`;

export default Container;
