import styled, { css, keyframes } from 'styled-components';

const getSlideDownAnimation = translateYPos => keyframes`
  from {
    transform: translateY(${translateYPos}px);
  }

  to {
    transform: translateY(0);
  }
`;

const getSlideUpAnimation = translateYPos => keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(${translateYPos}px);
  }
`;

export const SlideAnimation = styled.div`
  ${({ transitionState, translateYPos, animationDuration }) => {
    const slideDownAnimation = getSlideDownAnimation(translateYPos);
    const slideUpAnimation = getSlideUpAnimation(translateYPos);

    switch (transitionState) {
      case 'entering':
        return css`
          animation: ${slideDownAnimation} ease-out ${animationDuration}ms;
        `;
      case 'entered':
        return css`
          transform: translateY(0);
        `;
      case 'exiting':
        return css`
          animation: ${slideUpAnimation} ease-in ${animationDuration}ms;
        `;
      case 'exited':
        return css`
          transform: translateY(${translateYPos}px);
        `;
    }
  }}
`;
