import styled, { createGlobalStyle, css } from 'styled-components';
import Config from '../../config';
import { withTheme } from '../../core/ThemeProvider';
import Spacing from '../Spacing';

export const Content = styled.div`
  width: auto;
`;

export const AnimationWrapper = styled.div`
  z-index: ${p => p.zIndex};
  position: fixed;
  pointer-events: none;
`;

export const ModalContainer = styled.div`
  pointer-events: auto;
  position: fixed;
  padding-top: 10vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.75);
  box-sizing: border-box;
  overflow-y: ${p => !p.scrollable && 'auto'};

  ${Config.media.sm`
    ${p =>
      p.alignCenter &&
      `
      padding-top:0;
    `};
  `};

  ${p =>
    p.alignCenter &&
    `
    display: flex;
    align-items: center;
    justify-content:center;
    padding-top:0;
    && > * {
      flex:1;
    }
  `};

  /* Add a space below the modal, have to use
  this way because bottom padding is not
  working in Firefox when using overflow scroll */
  ::after {
    content: '';
    height: 64px;
    display: block;
  }
`;

export const Wrapper = withTheme(
  styled.div(
    ({ coreTheme, bgColor }) => css`
      box-sizing: border-box;
      position: relative;
      background-color: ${bgColor === 'haze'
        ? coreTheme.cl_ter_l6
        : coreTheme.cl_white};
      text-align: left;
      margin: 0 auto;
      border-radius: ${coreTheme.rad_xl};
      padding: ${coreTheme.modal?.padding || 0};
    `
  )
);

export const ContentWrapper = withTheme(
  styled.div(
    ({ coreTheme, hasStickyFooter }) => css`
      max-height: ${hasStickyFooter ? '48vh' : '57vh'};
      ${Config.media.md`
       max-height: ${hasStickyFooter ? '62vh' : '72vh'};
      `}
      overflow-y: scroll;

      ::-webkit-scrollbar {
        width: 20px;
      }

      /* Chrome, Safari, Edge */
      ::-webkit-scrollbar-thumb {
        background-color: ${coreTheme.cl_ter_l4};
        border-radius: 9999px;
        border: 8px solid ${coreTheme.cl_white};
      }

      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: ${coreTheme.cl_ter_l4};
    `
  )
);

// Disable background scroll when modal is open
export const NoBackgroundActivity = createGlobalStyle`
  body {
    height: 100vh;
    overflow-y: hidden;
  }
`;

export const CloseWrapper = styled.span`
  position: absolute;
  top: 32px;
  right: 25px;
  cursor: pointer;
`;

export const ScrollBottomFade = withTheme(
  styled.div(
    ({ isScrollable, scrolledBottom, coreTheme }) => css`
      display: ${!isScrollable || (isScrollable && scrolledBottom)
        ? 'none'
        : 'block'};
      position: absolute;
      bottom: 1.5rem;
      ${Config.media.md`
          bottom: 2rem;
      `};
      left: 0;
      width: 100%;
      height: 66px;
      background: linear-gradient(
        ${coreTheme.cl_white}00,
        ${coreTheme.cl_white}
      );
    `
  )
);

export const ModalFooterSpacing = styled(Spacing)`
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.12);
`;
