import React from 'react';
import { withRouter } from 'react-router-dom';
import { Anchor } from '@lux/components';
import DocumentTitle from 'react-document-title';
import Container from '../../components/Container';
import Landing from '../../components/Landing';
import { devices } from '../../configs/devices';
import NoMatch from '../NoMatch';

export const AppointmentStaticEarly = props => {
  if (!devices[props.match.params.campaign]) {
    return <NoMatch />;
  }

  const heroImage = devices[props.match.params.campaign].banner;
  const campaignDescription = devices[props.match.params.campaign].name;

  return (
    <DocumentTitle title="Book An Appointment">
      <Container>
        <Landing
          image={heroImage}
          title={`You are now 1 step closer to getting your hands on the new ${campaignDescription}`}
          description={
            <React.Fragment>
              For the latest updates on {campaignDescription} pre-order, please
              visit{' '}
              <Anchor href="//www.singtel.com/iphone">
                www.singtel.com/iphone
              </Anchor>
              .
            </React.Fragment>
          }
          showNric={false}
        />
      </Container>
    </DocumentTitle>
  );
};

const RoutedStaticEarly = withRouter(AppointmentStaticEarly);

export default RoutedStaticEarly;
