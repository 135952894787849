import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/symbol';
import React from 'react';
import { hydrate, render } from 'react-dom';
import ReactGA from 'react-ga';
import Raven from 'raven-js';
import App from './containers/App';
import configureStore from './store';
import { GoogleAnalyticsId } from './configs';
import TagManager from 'react-gtm-module';

Raven.config('https://9d7f8f50b0eb4e98b0fe6f1e5c4d3fff@sentry.io/1277518', {
  environment: process.env.NODE_ENV
}).install();

require('@lux/components/build/lux-components.css');
require('./styles.css');

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const initialState = {
  campaign: {
    name: '',
    description: '',
    registrationStatus: 'LOADING',
    registrationStart: '',
    registrationEnd: '',
    appointmentStatus: 'LOADING',
    appointmentStart: '',
    appointmentEnd: ''
  },
  customer: {
    name: '',
    mobile: '',
    email: '',
    portIn: ''
  },
  user: {},
  selectedProducts: {},
  reservedProducts: {}
};

export const store = configureStore(preloadedState || initialState);

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});

ReactGA.initialize(GoogleAnalyticsId);

const tagManagerArgs = {
  gtmId: process.env.LUX_GTM_CONTAINER_ID
}

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App store={store} />, rootElement);
} else {
  render(<App store={store} />, rootElement);
}
