import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import lottie from 'lottie-web';

/**
 * Motion component renders motion graphics,
 * using Lottie
 *
 * TODO: Sudhir: Update Lottie component documentation.
 *
 */
class Lottie extends Component {
  refContainer = React.createRef();

  componentDidMount() {
    this.loadAnimation();
    this.updateAnimationState();
  }

  _onLoopComplete = () => {
    if (!this.props.play) return this.stop();
    if (this.props.config.loop) {
      this.play(this.props.segments);
    } else {
      this.pause();
    }
    this.props.onLoopComplete && this.props.onLoopComplete();
  };

  _onComplete = () => {
    if (!this.props.play) return this.stop();
    if (this.props.config.loop) {
      this.play(this.props.segments);
    } else {
      this.pause();
    }
    this.props.onComplete && this.props.onComplete();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.play !== this.props.play ||
      prevProps.pause !== this.props.pause ||
      prevProps.segments !== this.props.segments
    ) {
      this.updateAnimationState();
    }

    if (prevProps.animationData !== this.props.animationData) {
      this.reloadAnimation();
    }
  }

  updateAnimationState() {
    const { play, pause, segments } = this.props;
    if (!this.anim) return;
    play ? this.play(segments) : this.stop();
    pause && this.pause();
  }

  play(segments) {
    if (segments) {
      this.anim.playSegments(segments, true);
    } else {
      this.anim.play();
    }
  }

  stop() {
    this.anim.stop();
  }

  pause() {
    this.anim.pause();
  }

  reloadAnimation() {
    this.stop();
    this.loadAnimation();
    this.updateAnimationState();
  }

  loadAnimation() {
    const { config = {}, animationData } = this.props;

    this.anim = lottie.loadAnimation({
      container: this.refContainer.current, // the dom element that will contain the animation
      renderer: 'svg/canvas/html',
      loop: !!config.loop,
      autoplay: !!config.autoplay,
      animationData // animation data in JSON format.
    });

    this.anim.addEventListener('complete', this._onComplete);
    this.anim.addEventListener('loopComplete', this._onLoopComplete);
  }

  render() {
    /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
    const {
      height,
      width,
      onComplete,
      onLoopComplete,
      onSegmentStart,
      ...rest
    } = this.props;
    return (
      <LottieWebAnimationCanvas
        {...rest}
        height={height}
        width={width}
        ref={this.refContainer}
      />
    );
  }
}

const LottieWebAnimationCanvas = styled.div`
  position: relative;

  ${({ height, width }) => ({ height, width })}

  svg:not(:root) {
    overflow: visible;
  }
`;

Lottie.propTypes = {
  /**
   * Height of the canvas where the animation is rendered
   */
  height: PropTypes.string.isRequired,

  /**
   * Width of the canvas where the animation is rendered
   */
  width: PropTypes.string.isRequired,

  /**
   * Animation file data. in JSON format.
   */
  animationData: PropTypes.any.isRequired,

  /**
   * Lottie configuration
   */
  config: PropTypes.shape({
    /**
     * enable loop to loop the animation foreva
     */
    loop: PropTypes.bool,

    /**
     * Enable start the animation immediately after the component renders
     */
    autoplay: PropTypes.bool
  }),

  /**
   * Set true to start the animation
   */
  play: PropTypes.bool,

  /**
   * Set true to pause the animation
   */
  pause: PropTypes.bool,

  onComplete: PropTypes.func,
  onLoopComplete: PropTypes.func,
  onSegmentStart: PropTypes.func
};

export default Lottie;
