import React from 'react';
import PropTypes from 'prop-types';
import { ModalFooterSpacing } from './styles';

const ModalFooter = ({ children }) => (
  <ModalFooterSpacing top={2} right={3} bottom={2} left={3}>
    {children}
  </ModalFooterSpacing>
);

ModalFooter.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Modal footer content
   */
  children: PropTypes.node
};

export default ModalFooter;
