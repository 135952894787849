import React from 'react';
import Grid from '../Grid';
import Row from '../Row';
import Column from '../Column';
import Text from '../Text';
import DesktopMenu from './DesktopMenu';
import Logo from './Logo';
import IconMenu from './IconMenu';
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import Search from './Search';

const StyledDesktopHeader = withTheme(
  styled.div(
    props => css`
      display: none;
      width: 100%;
      z-index: 99;

      @media (min-width: ${props.coreTheme.brk_md}) {
        display: block;
      }
    `
  )
);

const StyledDesktopHeaderGrid = styled(Grid)`
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledDesktopHeaderColumn = styled(Column)`
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledMenuItemList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  float: right;

  > li {
    margin-left: 22px;
  }
`;

const StyledListItem = styled.li`
  display: flex;
  margin: 0;
  padding: 0;
`;

const StyledListItemText = withTheme(
  styled(Text)(
    props => css`
      color: ${props.coreTheme.header.deskTopRHSMenuFontColor};
      font-size: ${props.coreTheme.fs_sm};
      font-family: ${props.secondary
        ? props.coreTheme.header.deskTopRHSMenuInactiveFont
        : props.coreTheme.header.deskTopRHSMenuActiveFont};
    `
  )
);

const StyledMenuItemListPrimary = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
`;

const RHSControlListItem = styled.li`
  display: flex;
  align-items: center;

  &:first-child {
    margin: 0;
  }
`;

const DesktopTopRHSMenuItems = ({ baseUrl, items }) => {
  return items.map((item, key) => {
    return (
      <StyledListItem key={key}>
        {item.active ? (
          <StyledListItemText
            lineHeight="medium"
            type="link"
            href={`${baseUrl}${item.href}`}
          >
            {item.title}
          </StyledListItemText>
        ) : (
          <StyledListItemText
            type="link"
            lineHeight="medium"
            href={`${baseUrl}${item.href}`}
            secondary
          >
            {item.title}
          </StyledListItemText>
        )}
      </StyledListItem>
    );
  });
};

const DesktopRHSControls = ({ baseUrl, menuIcons, searchConfigOptions }) => (
  <StyledMenuItemList>
    {searchConfigOptions.showSearch && (
      <RHSControlListItem
        data-testid="desktop-search-menu-list-item"
        key={'icon-menu-search'}
      >
        <Search {...searchConfigOptions} />
      </RHSControlListItem>
    )}
    {menuIcons.map((menuIcon, key) => {
      return (
        <RHSControlListItem key={key}>
          <IconMenu
            baseUrl={baseUrl}
            icon={menuIcon.icon}
            subMenu={menuIcon.subMenu}
            data-testid={`icon-menu-${key + 1}`}
          />
        </RHSControlListItem>
      );
    })}
  </StyledMenuItemList>
);

const DesktopHeader = ({
  baseUrl,
  logo,
  firstLevelLinks,
  menuItems,
  menuIcons,
  menuHoverKey,
  onMenuMouseEnter,
  onMenuMouseLeave,
  onMenuItemClick,
  searchConfigOptions
}) => (
  <StyledDesktopHeader data-testid="desktop-header">
    <StyledDesktopHeaderGrid>
      {firstLevelLinks && (
        <Row>
          <StyledDesktopHeaderColumn xs={9} lg={8} />
          <StyledDesktopHeaderColumn xs={3} lg={4}>
            <StyledMenuItemList>
              {/* Top right hand corner RHS menu items */}
              <DesktopTopRHSMenuItems
                baseUrl={baseUrl}
                items={firstLevelLinks}
              />
            </StyledMenuItemList>
          </StyledDesktopHeaderColumn>
        </Row>
      )}
      <Row>
        <Column xs={8}>
          <StyledMenuItemListPrimary>
            <StyledListItem>
              {/* Desktop logo */}
              {logo && (
                <Logo
                  baseUrl={baseUrl}
                  href={logo.href}
                  alt={logo.alt}
                  src={logo.src}
                  mode="deskTop"
                />
              )}
            </StyledListItem>
            {/* Primary menu items in desktop */}
            {menuIcons && (
              <DesktopMenu
                baseUrl={baseUrl}
                menuItems={menuItems}
                hasMenuIcons={menuIcons}
                menuHoverKey={menuHoverKey}
                onMenuMouseEnter={onMenuMouseEnter}
                onMenuMouseLeave={onMenuMouseLeave}
                onMenuItemClick={onMenuItemClick}
              />
            )}
          </StyledMenuItemListPrimary>
        </Column>
        <Column xs={4}>
          {/* Right hand side items */}
          {menuIcons && (
            <DesktopRHSControls
              baseUrl={baseUrl}
              menuIcons={menuIcons}
              searchConfigOptions={searchConfigOptions}
            />
          )}
          {!menuIcons && (
            <StyledMenuItemList>
              <DesktopMenu
                baseUrl={baseUrl}
                menuItems={menuItems}
                hasMenuIcons={menuIcons}
                menuHoverKey={menuHoverKey}
                onMenuMouseEnter={onMenuMouseEnter}
                onMenuMouseLeave={onMenuMouseLeave}
              />
            </StyledMenuItemList>
          )}
        </Column>
      </Row>
    </StyledDesktopHeaderGrid>
  </StyledDesktopHeader>
);

export default DesktopHeader;
