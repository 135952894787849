import React from 'react';
import DocumentTitle from 'react-document-title';
import Container from '../../components/Container';
import Landing from '../../components/Landing';

import { devices } from '../../configs/devices';
import useCampaign from '../../helpers/useCampaign';
import { withRouter } from 'react-router-dom';
import { ignoreQueryString } from '../../helpers';

const heroImage = devices.default.banner;

const PaymentSuccessPage = props => {
  const campaignName = ignoreQueryString(props.match.params.campaign);
  const campaign = useCampaign(campaignName);

  const content = {
    title:
      campaign.name &&
      `Congratulations Your pre-order for upcoming stocks of ${
        campaign.description
      } is successful`,
    description: campaign.name && (
      <span>
        We will reach out to you via phone once your iPhone is ready in{' '}
        <span style={{ display: 'inline-block' }}>2 – 4 weeks</span>. $200 will
        be held on your credit card and will be released within 21 days.
      </span>
    )
  };

  return (
    <DocumentTitle title="Dashboard">
      <Container>
        <Landing
          image={heroImage}
          title={content.title}
          description={content.description}
          showNric={false}
        />
      </Container>
    </DocumentTitle>
  );
};

export default withRouter(PaymentSuccessPage);
