import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FormFieldHint from './FormFieldHint';
import HintText from './HintText';
import { CheckboxWrapper } from '../../../components/Checkbox/CheckboxInput';
import { withTheme } from '../../ThemeProvider';

// TODO Sudhir: !CODE-DUPLICATION Use this component for Radio component.
const FieldGroup = ({ children, error }) => {
  return (
    <div>
      <FieldGroupContainer>{children}</FieldGroupContainer>
      {error && (
        <FormFieldHint>
          <HintText error={true}>{error}</HintText>
        </FormFieldHint>
      )}
    </div>
  );
};

FieldGroup.propTypes = {
  /**
   * Error message for the field group
   */
  error: PropTypes.string
};

export default FieldGroup;

const FieldGroupContainer = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      ${CheckboxWrapper} {
        margin-bottom: ${coreTheme.space_4}px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    `
  )
);
