import { FooterButton, FooterText, FooterWapper } from './style';
import PropTypes from 'prop-types';
import React from 'react';

const CardFooter = ({ rightContent, children }) => {
  return (
    <FooterWapper>
      <FooterText>{children}</FooterText>
      <FooterButton>{rightContent}</FooterButton>
    </FooterWapper>
  );
};

CardFooter.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Footer text
   */
  children: PropTypes.node,
  /**
   * The content on the right side, normally use DLS Button
   */
  rightContent: PropTypes.node
};

export default CardFooter;
