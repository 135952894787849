import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';

export const StyledPaginationRoot = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      margin-top: ${coreTheme.pagination.marginTop};
      font-size: ${coreTheme.pagination.fontSize};
    `
  )
);
export const StyledPaginationUl = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
`;

export const StyledPaginationButton = withTheme(
  styled.button(
    ({ coreTheme, disabled, selected }) => css`
      border: 0;
      cursor: pointer;
      display: inline-flex;
      outline: 0;
      position: relative;
      align-items: center;
      user-select: none;
      vertical-align: middle;
      justify-content: center;
      text-decoration: none;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
      color: ${coreTheme.pagination.button.color};
      height: ${coreTheme.pagination.button.height};
      margin: ${coreTheme.pagination.button.margin};
      padding: ${coreTheme.pagination.button.padding};
      font-size: inherit;
      min-width: ${coreTheme.pagination.button.minWidth};
      box-sizing: border-box;
      text-align: center;
      font-family: ${coreTheme.pagination.button.fontFamily};
      font-weight: 400;
      line-height: 1.43;
      border-radius: ${coreTheme.pagination.button.borderRadius};
      letter-spacing: 0.01071em;
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background-color: ${coreTheme.pagination.button.hoverBg};
      }

      & > svg {
        width: ${coreTheme.pagination.button.svg.width};
        height: ${coreTheme.pagination.button.svg.height};
      }

      ${disabled &&
        `cursor: default;
      pointer-events: none;
      opacity: 0.38;`}

      ${selected &&
        `background-color: ${coreTheme.pagination.button.selected.backgroundColor};color: ${coreTheme.pagination.button.selected.color};pointer-events: none;`}
    `
  )
);

export const StyledPaginationItemEllipsis = styled.div`
  height: auto;
`;
