import { Image, ImageWrapper, Tag, TagWrapper } from './style';
import PropTypes from 'prop-types';
import React from 'react';

const CardImage = ({ src, ratio, tag }) => (
  <ImageWrapper ratio={ratio}>
    <Image src={src.url} alt={src.alt} />
    {tag && (
      <TagWrapper>
        <Tag>{tag}</Tag>
      </TagWrapper>
    )}
  </ImageWrapper>
);

CardImage.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Image ratio
   */
  ratio: PropTypes.oneOf(['16:9', '3:2']),
  /**
   * Image source and alternative text
   */
  src: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string.isRequired
  }).isRequired,
  /**
   * Image tag
   */
  tag: PropTypes.string
};

export default CardImage;
