import React, { useState } from 'react';
import useDevice from '../../core/hooks/useDevice';
import Text from '../Text';
import Column from '../Column';
import { testIdProps } from '../../helpers';
import { withTheme } from '../../core/ThemeProvider';
import styled, { css } from 'styled-components';
import { remCalc } from '@lux/helpers';

const StyledIconMenuContainer = styled.div`
  position: relative;
`;

const StyledIconAnchor = withTheme(
  styled(Text)(
    props => css`
      display: flex;

      .hover {
        display: none;
      }

      svg {
        height: ${props.coreTheme.header.mobileIconHeight};
        margin-left: ${remCalc(16)};

        @media (min-width: ${props.coreTheme.brk_md}) {
          height: ${props.coreTheme.header.deskTopIconHeight};
          margin-left: 0;
        }
      }
      ${props.handleHover &&
        css`
          &:hover {
            svg,
            img {
              display: none;
            }

            .hover {
              display: block;
            }
          }
        `}
    `
  )
);

const StyledIconImgDesktop = withTheme(
  styled.img(
    props => css`
      height: ${props.coreTheme.header.deskTopIconHeight};
      width: ${props.coreTheme.header.deskTopIconWidth};
    `
  )
);

const StyledIconImgMobile = withTheme(
  styled.img(
    props => css`
      margin-left: ${remCalc(16)};
      height: ${props.coreTheme.header.mobileIconHeight};
      width: ${props.coreTheme.header.mobileIconWidth};
    `
  )
);

const IconSubMenuContainer = withTheme(
  styled.div(
    props => css`
      position: absolute;
      top: ${remCalc(24)};

      @media (min-width: ${props.coreTheme.brk_md}) {
        top: ${remCalc(40)};
      }

      right: ${remCalc(-15)};
      width: ${remCalc(180)};
      border: ${remCalc(2)} solid ${props.coreTheme.cl_ter_l5};
      border-radius: ${remCalc(3)};
      margin-top: ${remCalc(12)};
      box-shadow: ${props.coreTheme.sd_size_1};
      z-index: 9999;
      overflow-x: visible;

      &::before {
        position: absolute;
        top: ${remCalc(-10)};
        right: ${remCalc(20)};
        content: '';
        border-style: solid;
        border-color: transparent transparent ${props.coreTheme.cl_ter_l5}
          transparent;
        border-width: 0 ${remCalc(10)} ${remCalc(10)} ${remCalc(10)};
      }
    `
  )
);

const IconSubMenuList = withTheme(
  styled.ul(
    props => css`
      display: block;
      list-style-type: none;
      background: ${props.coreTheme.cl_white};
      overflow: hidden;
      margin: 0;
      padding-left: 0;
      z-index: 9;

      &::before {
        position: absolute;
        top: ${remCalc(-8)};
        right: ${remCalc(22)};
        content: '';
        border-style: solid;
        border-color: transparent transparent ${props.coreTheme.cl_white}
          transparent;
        border-width: 0 ${remCalc(8)} ${remCalc(8)} ${remCalc(8)};
      }
    `
  )
);

const IconSubMenuListItem = withTheme(
  styled.li(
    props => css`
      &:hover {
        background: ${props.coreTheme.cl_ter_l5};
      }
    `
  )
);

const IconSubMenuListItemAnchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      padding: ${remCalc(9)} ${remCalc(20)};
      font-family: ${props.coreTheme.header.iconMenuFont};
      font-size: ${props.coreTheme.fs_md};
      color: ${props.coreTheme.header.iconMenuFontColor};

      &:not([disabled]):hover {
        color: ${props.coreTheme.header.iconMenuActiveFontColor};
      }
    `
  )
);

const CartCount = withTheme(
  styled.span(
    props => css`
      color: ${props.coreTheme.cl_white};
      height: 1rem;
      min-width: 1rem;
      font-size: 0.625rem;
      border-radius: 0.5rem;
      background-color: ${props.coreTheme.cl_pri_base};
      position: absolute;
      z-index: 1;
      display: inline-block;
      right: 0;
      text-align: center;
      margin: -6px -6px 0 0;

      @media (min-width: ${props.coreTheme.brk_md}) {
        margin: 0;
      }
    `
  )
);

const ResponsiveColumn = withTheme(
  styled(Column)(
    props => css`
      height: ${props.mobile
        ? props.coreTheme.header.mobileIconHeight
        : props.coreTheme.header.deskTopIconHeight};
    `
  )
);

const IconMenu = ({ baseUrl, icon, subMenu, ...rest }) => {
  const { isMobile, isTablet, isLandscapeTablet } = useDevice();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClick = () => setIsMenuOpen(!isMenuOpen);

  const renderIcon = () => {
    const MobileIcon = icon.mobile ? icon.mobile : icon.desktop;
    const DesktopIcon = icon.desktop;
    const DesktopHoverIcon = icon.desktopHover;

    let desktopIcon = null;
    let desktopHoverIcon = null;
    let mobileIcon = null;

    if (DesktopIcon) {
      if (typeof DesktopIcon === 'string') {
        desktopIcon = (
          <StyledIconImgDesktop data-testid="desktop-icon" src={DesktopIcon} />
        );
      } else {
        desktopIcon = <DesktopIcon data-testid="desktop-svg-icon" />;
      }
    }

    if (DesktopHoverIcon) {
      if (typeof DesktopHoverIcon === 'string') {
        desktopHoverIcon = (
          <StyledIconImgDesktop
            src={DesktopHoverIcon}
            className="hover"
            data-testid="desktop-icon-hover"
          />
        );
      } else {
        desktopHoverIcon = (
          <DesktopHoverIcon
            className="hover"
            data-testid="desktop-svg-icon-hover"
          />
        );
      }
    }

    if (MobileIcon) {
      if (typeof MobileIcon === 'string') {
        mobileIcon = (
          <StyledIconImgMobile src={MobileIcon} data-testid="mobile-icon" />
        );
      } else {
        mobileIcon = <MobileIcon data-testid="mobile-svg-icon" />;
      }
    }

    return (
      <>
        <ResponsiveColumn noGutter xs={false} lg={12}>
          {desktopIcon}
          {desktopHoverIcon}
        </ResponsiveColumn>
        <ResponsiveColumn noGutter mobile xs={12} lg={false}>
          {mobileIcon}
        </ResponsiveColumn>
      </>
    );
  };

  return (
    <StyledIconMenuContainer>
      <StyledIconAnchor
        type="link"
        href={icon.href && `${baseUrl}${icon.href}`}
        onClick={subMenu ? handleClick : icon.onClick}
        {...testIdProps(rest)}
        handleHover={
          !(isMobile || isTablet || isLandscapeTablet) && icon.desktopHover
        }
      >
        {icon.cartItemCount >= 0 && (
          <CartCount data-testid="cart-count">{icon.cartItemCount}</CartCount>
        )}
        {renderIcon()}
      </StyledIconAnchor>
      {subMenu && isMenuOpen && (
        <IconSubMenuContainer data-testid="icon-sub-menu">
          <IconSubMenuList>
            {subMenu.map((menu, key) => (
              <IconSubMenuListItem key={key}>
                <IconSubMenuListItemAnchor
                  type="link"
                  href={menu.href && `${baseUrl}${menu.href}`}
                  onClick={menu.onClick}
                >
                  {menu.title}
                </IconSubMenuListItemAnchor>
              </IconSubMenuListItem>
            ))}
          </IconSubMenuList>
        </IconSubMenuContainer>
      )}
    </StyledIconMenuContainer>
  );
};

export default IconMenu;
