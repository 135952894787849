import { setUrlParams } from '@lux/helpers';
import { api } from '../configs';
import { isLoading } from './index';
import {
  sendApiToGaEvent,
  sendGaEvent
} from '../helpers';

const productCatalogSuccess = products => ({
  type: 'SET_PRODUCT_CATALOG_SUCCESS',
  products
});

// List of selected products in ROI
export const setSelectedProducts = products => ({
  type: 'SET_SELECTED_PRODUCTS',
  products
});

// List of reserved products in Appointment Booking
export const setReservedProducts = products => ({
  type: 'SET_RESERVED_PRODUCTS',
  products
});

export const setSelectedProduct = (product, index) => ({
  type: 'SET_SELECTED_PRODUCT',
  product,
  index
});

export const setReservedProduct = (product, index) => ({
  type: 'SET_RESERVED_PRODUCT',
  product,
  index
});

export function getProductCatalog(campaignId) {
  const url = setUrlParams(
    api.roi.productCatalog.replace(':campaignId', campaignId),
    {
      _: new Date().getTime()
    }
  );

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url)
      .then(response => {
        sendApiToGaEvent('getProductCatalog', response.status);
        return response;
      })
      .then(response => response.json())
      .then(response => {
        dispatch(isLoading(false));
        dispatch(productCatalogSuccess(response));
      })
      .catch(() => {});
  };
}

export function reserveProduct(locationHeader, authToken, product, index) {
  const url =
    locationHeader +
    api.appointment.reserveProduct.replace(':productId', product.id);

  const options = {
    method: 'POST',
    headers: {
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));

    fetch(url, options)
      .then(response => {
        sendApiToGaEvent('reserveProduct', response.status);
        let status = '';
        switch (response.status) {
          case 422:
            status = 'OUT_OF_STOCK';
            break;
          case 401:
            status = 'SESSION_NOT_FOUND';
            break;
          case 201:
          case 204:
            sendGaEvent('Reserved Products', product.description);
            status = 'PRODUCT_RESERVED';
            break;
        }

        dispatch(setReservedProduct({ ...product, status }, index));
        dispatch(isLoading(false));

        return response;
      })
      .catch(() => {});
  };
}
