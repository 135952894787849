import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import CloseIcon from './small-cross.svg';
import { testIdProps } from '../../helpers';
import { getStyledText } from '../../helpers/styled';
import Config from '../../config';

const Chip = ({ variant, children, onClick, ...rest }) => {
  return (
    <StyledChip
      onClick={onClick}
      clickable={Boolean(onClick)}
      variant={variant}
      {...testIdProps(rest)}
    >
      {typeof children === 'string' ? (
        <Chip.Text>{children}</Chip.Text>
      ) : (
        children
      )}
    </StyledChip>
  );
};

Chip.defaultProps = {
  variant: 'solid'
};

Chip.propTypes = {
  /**
   * Chip variant.
   * defaults to 'solid'
   */
  variant: PropTypes.oneOf(['solid', 'outline', 'clear']),

  /**
   * Click handler
   */
  onClick: PropTypes.func,

  /**
   * Chip renders its contents.
   * Use Chip.Icon to render the icon.
   * Use Chip.Text to render the text.
   */
  children: PropTypes.node
};

const TRANSITION_DURATION = `0.1s`;

/* stylelint-disable no-descending-specificity */
const solidVariant = (coreTheme, clickable) => css`
  color: ${coreTheme.cl_white};
  border-color: ${coreTheme.cl_sec_d1};
  background: ${coreTheme.cl_sec_d1};

  svg {
    fill: ${coreTheme.cl_white};
  }

  ${clickable &&
    css`
      &:hover {
        background: ${coreTheme.cl_sec_base};
        border-color: transparent;
      }

      &:active {
        color: ${coreTheme.cl_white};
        background: ${coreTheme.cl_sec_d1};
        border-color: ${coreTheme.cl_sec_d1};

        svg {
          fill: ${coreTheme.cl_white};
        }
      }
    `};
`;

const outlineVariant = (coreTheme, clickable) => css`
  color: ${coreTheme.cl_sec_d1};
  border-color: ${coreTheme.cl_sec_d1};
  background: ${coreTheme.cl_white};

  svg {
    fill: ${coreTheme.cl_sec_d1};
  }

  ${clickable &&
    css`
      &:hover {
        background-color: ${coreTheme.cl_sec_l2};
      }

      &:active {
        color: ${coreTheme.cl_white};
        background: ${coreTheme.cl_sec_d1};

        svg {
          fill: ${coreTheme.cl_white};
        }
      }
    `};
`;

const clearVariant = (coreTheme, clickable) => css`
  color: ${coreTheme.cl_sec_d1};
  background: ${coreTheme.cl_sec_l2};

  svg {
    fill: ${coreTheme.cl_sec_d1};
  }

  ${clickable &&
    css`
      &:hover {
        background-color: ${coreTheme.cl_sec_l1};
      }

      &:active {
        color: ${coreTheme.cl_white};
        background: ${coreTheme.cl_sec_d1};

        svg {
          fill: ${coreTheme.cl_white};
        }
      }
    `};
`;

const chipTransitionStyle = `background-color ${TRANSITION_DURATION},
    color ${TRANSITION_DURATION}, fill ${TRANSITION_DURATION};`;

const StyledChip = withTheme(styled.div`
  box-sizing: border-box;
  border: 1px solid transparent;
  display: inline-block;
  white-space: nowrap;

  ${({ variant, clickable, coreTheme }) => {
    return css`
      font-family: ${coreTheme.ff_sec_bold};
      border-radius: ${coreTheme.space_5}px;
      margin: ${coreTheme.space_2}px ${coreTheme.space_2}px;
      padding: ${coreTheme.space_1 - 1}px ${coreTheme.space_4 - 1}px;
      cursor: ${clickable ? 'pointer' : 'default'};
      ${() => {
        switch (variant) {
          case 'outline':
            return outlineVariant(coreTheme, clickable);
          case 'clear':
            return clearVariant(coreTheme, clickable);
          default:
            return solidVariant(coreTheme, clickable);
        }
      }};

      ${Config.media.sm`
        padding: ${coreTheme.space_1 - 1}px ${coreTheme.space_6 - 1}px;
      `};
    `;
  }};

  transition: ${chipTransitionStyle};
  user-select: none;
`);

const ChipText = getStyledText('boldBody', {
  tag: 'span',
  lineHeight: 'compact'
})(
  () => css`
    white-space: nowrap;
    color: inherit;
  `
);

const ChipIconContainer = withTheme(styled.span`
  display: inline-block;
  line-height: 0;
  position: relative;
  top: 2px;
  margin: ${p => `0 ${p.coreTheme.space_2}px`};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`);

const ChipButtonContainer = styled.span`
  line-height: 0;
  display: inline-block;
  position: relative;
  padding: 10px;
  top: 0.5px;
  cursor: pointer;

  svg > path {
    fill: inherit;
  }

  &:first-child {
    margin: -5px 0 -5px -11px;
  }

  &:last-child {
    margin: -5px -11px -5px 0;
  }
`;

const ChipsContainer = styled.div`
  margin: -8px;
`;

const ChipIcon = ({ icon: Icon }) => (
  <ChipIconContainer>
    <Icon size={16} color="currentColor" />
  </ChipIconContainer>
);

const ChipCloseButton = props => (
  <ChipButtonContainer {...testIdProps(props)} onClick={props.onClick}>
    <CloseIcon viewBox="0 0 13 12" width={13} height={12} />
  </ChipButtonContainer>
);

Chip.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  children: PropTypes.node,
  /**
   * Click handler
   */
  onClick: PropTypes.func,
  /**
   * Chip variant.
   * @default 'solid'
   */
  variant: PropTypes.oneOf(['clear', 'outline', 'solid']).isRequired
};

Chip.Container = ChipsContainer;
Chip.Icon = ChipIcon;
Chip.CloseButton = ChipCloseButton;
Chip.Text = ChipText;

export default Chip;
