const diff = require('jest-diff');
import React from 'react';
import ThemeProvider from '../core/ThemeProvider';

export const wrapThemeProvider = (ui, { theme } = {}, brand) => {
  return (
    <ThemeProvider brand={brand} theme={theme}>
      {ui}
    </ThemeProvider>
  );
};

export const PropsTestComponent = props => {
  return <div data-testid="props">{JSON.stringify(props)}</div>;
};

export const getComponentProps = getByTestId => {
  const propsString = getByTestId('props').innerHTML;
  return propsString ? JSON.parse(propsString) : {};
};

export const jestMatchers = {
  /**
   * @example
   * expect([1, 2, 3]).toBeSubsetOf([1, 2, 3]); // PASS
   * expect([1, 2, 3]).toBeSubsetOf([1, 2, 3, 4]); // PASS
   *
   * expect([1, 2, 3, 4]).toBeSubsetOf([1, 2, 3]); // FAIL
   */
  toBeSubsetOf(_received, _collection) {
    const received = _received.sort(),
      collection = _collection.sort();
    const unExpectedModules = received.filter(m => !collection.includes(m));
    const numUnExpectedModules = unExpectedModules.length;
    const pass = numUnExpectedModules <= 0;

    const message = pass
      ? () =>
          this.utils.matcherHint('.not.toOnlyContain') +
          '\n\n' +
          `Expected collection contain additional values:\n` +
          `  ${this.utils.printExpected(collection)}\n` +
          `Received:\n` +
          `  ${this.utils.printReceived(received)}`
      : () => {
          const diffString = diff(collection.sort(), received.sort(), {
            expand: this.expand
          });
          return (
            this.utils.matcherHint('.toOnlyContain') +
            '\n\n' +
            `Expected collection only contain:\n` +
            `  ${this.utils.printExpected(collection)}\n` +
            `Received:\n` +
            `  ${this.utils.printReceived(received)}` +
            (diffString ? `\n\nDifference:\n\n${diffString}` : '')
          );
        };

    return { actual: received, message, pass };
  }
};
