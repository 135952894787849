import styled, { css, keyframes } from 'styled-components';

const DURATION = '0.4s';
const DURATION_ERROR = '1s';

export const Fade = styled.span`
  line-height: 0;
  transition: ${DURATION};
  /* change opacity */
  ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return css`
          opacity: 0;
        `;
      case 'entered':
      case 'exiting':
        return css`
          opacity: 1;
        `;
    }
  }}
`;

export const RevealBottomIn = styled.span`
  line-height: 0;
  transition: ${DURATION};
  /* change opacity */
  ${({ state }) => {
    switch (state) {
      case 'entering':
        return css`
          animation: ${fade_in} ${DURATION};
        `;
      case 'entered':
        return css`
          opacity: 1;
        `;
      case 'exiting':
        return css`
          animation: ${fade_out} ${DURATION};
        `;
      case 'exited':
        return css`
          opacity: 0;
        `;
    }
  }}
`;

export const RotateIn = styled.span`
  line-height: 0;
  transition: ${DURATION_ERROR};
  /* change opacity */
  ${({ state }) => {
    switch (state) {
      case 'entering':
        return css`
          animation: ${rotate_in} ${DURATION};
        `;
      case 'entered':
        return css`
          opacity: 1;
        `;
      case 'exiting':
        return css`
          animation: ${fade_out} ${DURATION};
        `;
      case 'exited':
        return css`
          opacity: 0;
        `;
    }
  }}
`;

const fade_in = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, 10px);
  }

  80% {
    transform: translate(0, -2px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const rotate_in = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0);
  }
`;

const fade_out = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;
