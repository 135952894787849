import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Text from '../Text';
import { Minus as MinusIcon, Plus as PlusIcon } from '@dls/assets/icons';
import PropTypes from 'prop-types';
import { isNumber, noop } from '../../helpers';
import { withTheme, useTheme } from '../../core/ThemeProvider';
import { withClearInteraction } from '../../helpers/styled';

const ACTION_INCREASE = 'ACTION_INCREASE';
const ACTION_DECREASE = 'ACTION_DECREASE';

const QuantityInput = ({
  text,
  variant,
  value,
  minValue,
  maxValue,
  interval,
  onValueChange,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const { theme } = useTheme();

  useEffect(() => {
    isNumber(value) && setCurrentValue(value);
  }, [value]);

  const handleControlClick = (action, flag) => {
    if (flag) return;

    const value = parseInt(currentValue);
    const remainder = value % interval;
    let nextValue;

    if (action === ACTION_INCREASE) {
      nextValue = remainder ? value - remainder + interval : value + interval;
    } else {
      nextValue = remainder ? value - remainder : value - interval;
    }

    setCurrentValue(nextValue);
    onValueChange && onValueChange(nextValue);
  };

  const handleOnChange = event => {
    let newVal = event.target.value;

    if (newVal.startsWith('0')) newVal = newVal.slice(1);
    if (newVal > maxValue) newVal = maxValue;
    if (newVal < minValue || !newVal) newVal = minValue;

    setCurrentValue(newVal);
    onValueChange && onValueChange(newVal);
  };

  const disableDecreaseState = minValue >= currentValue;
  const disableIncreaseState = maxValue <= currentValue;

  const hasBorder = variant === 'whiteBox' || variant === 'haze';
  const backgroundColor = variant === 'haze' ? theme.cl_ter_l6 : theme.cl_white;

  return (
    <QuantityInputWrapper hasBorder={hasBorder} bgColor={backgroundColor}>
      <LabelText type="body">{text}</LabelText>
      <ControlWrapper>
        <RoundIcon
          disableAction={disableDecreaseState}
          enableInteraction={!disableDecreaseState}
          onClick={() =>
            handleControlClick(ACTION_DECREASE, disableDecreaseState)
          }
          data-testid="decreaseId"
        >
          <MinusIcon size={15} color={rest.coreTheme.cl_white} />
        </RoundIcon>
        <ValueText
          data-testid="valueId"
          value={currentValue}
          min={minValue}
          max={maxValue}
          onChange={handleOnChange}
          onKeyPress={event => {
            if (['.', '-', '+'].includes(event.key)) {
              event.preventDefault();
            }
          }}
          onPaste={event => {
            event.preventDefault();
          }}
        />

        <RoundIcon
          disableAction={disableIncreaseState}
          enableInteraction={!disableIncreaseState}
          onClick={() =>
            handleControlClick(ACTION_INCREASE, disableIncreaseState)
          }
          data-testid="increaseId"
        >
          <PlusIcon size={15} color={rest.coreTheme.cl_white} />
        </RoundIcon>
      </ControlWrapper>
    </QuantityInputWrapper>
  );
};

QuantityInput.defaultProps = {
  value: 0,
  minValue: 0,
  maxValue: 10,
  interval: 1,
  onChange: noop
};

QuantityInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * interval value
   */
  interval: PropTypes.number,
  /**
   * max input value
   */
  maxValue: PropTypes.number.isRequired,
  /**
   * min input value
   */
  minValue: PropTypes.number.isRequired,
  /**
   * Change event handler
   */
  onValueChange: PropTypes.func,
  /**
   * label text
   */
  text: PropTypes.string,
  /**
   * input value
   */
  value: PropTypes.number,
  /**
   * Background color of Input fields.
   * @default white
   */
  variant: PropTypes.oneOf(['haze', 'whiteBox'])
};

const RoundIcon = withTheme(
  withClearInteraction(
    styled.div`
      display: flex;
      position: relative;
      background-color: ${p => p.coreTheme.cl_sec_d1};
      border-radius: 32px;
      height: 32px;
      width: 32px;
      cursor: pointer;
      transition: 0.1s all ease;
      justify-content: center;
      align-items: center;

      ${p => {
        let styleObject = {};
        if (p.disableAction) {
          styleObject.backgroundColor = p.coreTheme.cl_sec_l1;
          styleObject.cursor = 'default';
          styleObject['&:hover'] = {
            backgroundColor: p.coreTheme.cl_sec_l1
          };
        }
        return styleObject;
      }}
    `,
    8
  )
);

const QuantityInputWrapper = withTheme(
  styled.div(
    ({ hasBorder, bgColor, coreTheme }) => css`
      display: flex;
      align-items: center;
      ${hasBorder &&
        css`
          padding: ${coreTheme.space_4}px;
          background-color: ${bgColor};
          border-radius: ${coreTheme.space_2}px;
        `}
    `
  )
);

const ControlWrapper = withTheme(styled.div`
  display: flex;
  align-items: center;
  box-sizing: content-box;
  padding: ${p => p.coreTheme.space_2}px;
`);

const LabelText = withTheme(styled(Text)`
  flex: 1 1 auto;
  font-size: ${p => p.coreTheme.fs_xl};
`);

const ValueText = withTheme(styled.input.attrs({
  type: 'number'
})`
  align-items: center;
  border: 0;
  padding: 0 16px;
  text-align: center;
  width: 40px;
  appearance: textfield; /* firefox */
  background-color: transparent;

  ${p => css`
    font-family: ${p.coreTheme.ff_sec_bold};
    font-size: ${p.coreTheme.fs_xl};
    color: ${p.coreTheme.cl_sec_d3};
  `}

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none; /* safari, chrome */
    margin: 0;
  }

  :focus {
    outline: none;
  }
`);

export default withTheme(QuantityInput);
