import React from 'react';
import ContentLoader from 'react-content-loader';
import Card from '../Card';
import styled from 'styled-components';
import { testIdProps } from '../../helpers';

const LoaderContainer = styled.div`
  display: flex;
`;

export const ListItem = p => (
  <Card {...testIdProps(p)}>
    <LoaderContainer>
      <ContentLoader {...defaultSkeletonLoaderProps} viewBox={`0 0 312 96`}>
        <rect x="16" y="16" rx="8" ry="8" width="64" height="64" />
        <rect x="100" y="18" rx="8" ry="8" width="60" height="16" />
        <rect x="100" y="53" rx="8" ry="8" width="195" height="16" />
      </ContentLoader>
    </LoaderContainer>
  </Card>
);

export const CardSkeleton = p => (
  <Card padding="md" {...testIdProps(p)}>
    <LoaderContainer>
      <ContentLoader {...defaultSkeletonLoaderProps} viewBox={`0 0 327 232`}>
        <rect x="24" y="24" rx="8" ry="8" width="128" height="16" />
        <rect x="24" y="64" rx="8" ry="8" width="64" height="64" />
        <rect x="24" y="152" rx="8" ry="8" width="280" height="16" />
        <rect x="24" y="192" rx="8" ry="8" width="280" height="16" />
      </ContentLoader>
    </LoaderContainer>
  </Card>
);

export const CardWithBanner = p => (
  <Card padding="md" radius="md" {...testIdProps(p)}>
    <LoaderContainer>
      <ContentLoader {...defaultSkeletonLoaderProps} viewBox={`0 0 327 312`}>
        {/* Banner */}
        <rect x="0" y="0" rx="0" ry="0" width="327" height="184" />

        {/* Text 1 */}
        <rect x="24" y="216" rx="8" ry="8" width="280" height="16" />

        {/* Text 2 */}
        <rect x="24" y="264" rx="8" ry="8" width="280" height="16" />
      </ContentLoader>
    </LoaderContainer>
  </Card>
);

export const Tabs = p => {
  return (
    <div {...testIdProps(p)}>
      <LoaderContainer>
        <ContentLoader {...defaultSkeletonLoaderProps} viewBox="0 0 375 52">
          <rect x="184" y="24" rx="8" ry="8" width="120" height="16" />
          <rect x="24" y="24" rx="8" ry="8" width="120" height="16" />
          <rect x="0" y="48" rx="2" ry="2" width="375" height="5" />
        </ContentLoader>
      </LoaderContainer>
    </div>
  );
};

const defaultSkeletonLoaderProps = {
  width: '100%',
  height: '100%',
  speed: 5,
  backgroundColor: '#F7F7F7',
  foregroundColor: '#E1E1E1'
};

export default {
  Card: CardSkeleton,
  CardWithBanner,
  ListItem,
  Tabs
};
