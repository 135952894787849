import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';

const IconButton = ({ onClick, text, ...rest }) => {
  return (
    <ActionTextWrapper {...rest} onClick={onClick} enableInteraction={true}>
      {text}
    </ActionTextWrapper>
  );
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string
};

export const ActionTextWrapper = withTheme(
  styled.div`
    ${({ coreTheme }) => css`
      color: ${coreTheme.cl_sec_d1};
      line-height: 21px;
      font-size: ${coreTheme.fs_xl};
      font-family: ${coreTheme.ff_sec_bold};
      cursor: pointer;
    `};
  `
);

export default withTheme(IconButton);
