import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchIcon from '@dls/assets/icons/Search';
import { useTheme } from '../../../core/ThemeProvider';
import InputText from '../../InputText';

const StyledSearchIconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const enterKeyCode = 13;

const SearchInput = ({ label, errorMessage, onSearch, preSearchTerm = '' }) => {
  const [searchTerm, setSearchItem] = useState(preSearchTerm);
  const { theme } = useTheme();

  /**
   * Notify the parent component with search term, when user hits the 'enter' key
   */
  const onSearchInputKeyPress = useCallback(
    event => {
      if (event.charCode === enterKeyCode) {
        event.preventDefault();
        onSearch && onSearch(searchTerm);
      }
    },
    [onSearch, searchTerm]
  );

  const onSearchIconClick = useCallback(() => {
    onSearch && onSearch(searchTerm);
  }, [onSearch, searchTerm]);

  const searchIcon = useMemo(() => {
    return (
      <StyledSearchIconContainer
        onClick={onSearchIconClick}
        data-testid="search-icon"
      >
        <SearchIcon color={theme.cl_sec_d1} size={22} />
      </StyledSearchIconContainer>
    );
  }, [onSearchIconClick]);

  const onSearchInputChange = useCallback(e => {
    setSearchItem(e.target.value);
  }, []);

  return (
    <InputText
      type={'number'}
      value={searchTerm}
      label={label}
      bgColor="haze"
      suffix={searchIcon}
      onChange={onSearchInputChange}
      error={!!errorMessage}
      hintMessage={errorMessage}
      onKeyPress={onSearchInputKeyPress}
      data-testid="search-input"
    />
  );
};

SearchInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Error message to show below the search input
   */
  errorMessage: PropTypes.string,
  /**
   * Search input label text
   */
  label: PropTypes.string.isRequired,
  /**
   * Handler to get notified when user searched
   */
  onSearch: PropTypes.func.isRequired
};

export default SearchInput;
