import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import {
  compareAsc,
  format,
  startOfMonth,
  isFirstDayOfMonth,
  isLastDayOfMonth
} from 'date-fns';
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon
} from '@dls/assets/icons';
import StrikeIcon from './strike.svg';
import { en } from './locale';
import styled, { css } from 'styled-components';

import { withTheme } from '../../core/ThemeProvider';
import useDevice from '../../core/hooks/useDevice';
import { getDaysNearDisabledDays } from './utils';
import { getStyledText } from '../../helpers/styled';
import Config from '../../config';

const SMALL_MOBILE_BREAKPOINT = '320px'; // An exception breakpoint for this component only

const Calendar = ({
  highlightToday,
  selectedDays,
  availableDays = {},
  showSelectedDaysAsRange,
  disableNextNavAction,
  disablePrevNavAction,
  onPrevMonthClick,
  onNextMonthClick,
  onMonthChange,
  onDayClick,
  Container,
  defaultOptions = {},
  disabledDays,
  ...rest
}) => {
  const { isMobile } = useDevice();
  const { today = new Date(), month = new Date() } = defaultOptions;

  const selectedDayRange =
    selectedDays &&
    selectedDays.from &&
    selectedDays.to &&
    correctSelectedDateRange(selectedDays);

  const getDayPickerModifiers = () => {
    let modifiers = {};
    modifiers.highlighted = today;

    if (disabledDays) {
      const nearDays = getDaysNearDisabledDays(disabledDays);
      modifiers.disabled = disabledDays;
      modifiers.nextLeftDisabled = nearDays.nextLeftDisabledDays;
      modifiers.nextRightDisabled = nearDays.nextRightDisabledDays;
    }

    if (selectedDayRange) {
      modifiers.selectedDays = selectedDayRange;
      modifiers.from = selectedDayRange && selectedDayRange.from;
      modifiers.to = selectedDayRange && selectedDayRange.to;
    }
    return modifiers;
  };

  const handleDayClick = (day, modifiers = {}, ...restArgs) => {
    if (modifiers.disabled) {
      return;
    }
    onDayClick(day, modifiers, ...restArgs);
  };

  const renderDayPicker = () => (
    <DayPicker
      selectedDays={selectedDayRange ? selectedDayRange : selectedDays}
      modifiers={getDayPickerModifiers()}
      initialMonth={month}
      numberOfMonths={isMobile ? 12 : 2} // Show a full year in mobile view
      navbarElement={p => {
        return (
          <NavBar
            {...p}
            calendarProps={{ onPrevMonthClick, onNextMonthClick }}
            disableNextNavAction={disableNextNavAction}
            disablePrevNavAction={disablePrevNavAction}
          />
        );
      }}
      captionElement={p => <Caption {...p} />}
      weekdayElement={({ weekday }) => (
        <WeekDay>
          <abbr>{en.weekDay.abbr[weekday]}</abbr>
        </WeekDay>
      )}
      renderDay={(day, modifiers) => (
        <DayElement
          day={day}
          modifiers={modifiers}
          highlightToday={highlightToday}
          inSelectedRange={showSelectedDaysAsRange}
        />
      )}
      onMonthChange={onMonthChange}
      fromMonth={startOfMonth(availableDays.from)}
      toMonth={startOfMonth(availableDays.to)}
      onDayClick={handleDayClick}
    />
  );

  return (
    <CalendarContainer data-testid={rest['data-testid']}>
      {Container ? (
        <Container selectedDays={selectedDays}>{renderDayPicker()}</Container>
      ) : (
        renderDayPicker()
      )}
    </CalendarContainer>
  );
};

// TODO Sudhir: Documentation and examples, update readme.

Calendar.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  availableDays: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired
    })
  ]),
  /**
   * Container render prop for the calendar.
   * Container component is rendered with the calendar instance as the children.
   */
  Container: PropTypes.func,
  defaultOptions: PropTypes.shape({
    /**
     * Starting month of the calender
     */
    month: PropTypes.instanceOf(Date),
    /**
     * Custom today date.
     */
    today: PropTypes.instanceOf(Date)
  }),
  /**
   * Dates to be shown as disabled in the calendar.
   */
  disabledDays: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired
    })
  ]),
  disableNextNavAction: PropTypes.bool,
  disablePrevNavAction: PropTypes.bool,
  /**
   * Event handler when user clicks on the day.
   * @callback onDayClickCallback
   * @param {Date} day The day on which the user clicked
   * @param {Object} modifiers set of values describing the state of the day
   * @param {MouseEvent} event The actual click event.
   */
  onDayClick: PropTypes.func,
  /**
   * Event handler when the month is changed,
   * i.e. clicking the navigation buttons or using the keyboard.
   */
  onMonthChange: PropTypes.func,
  /**
   * Callback invoked when user clicks the previous button on the calendar.
   * This function should return true if the navigation to next month is allowed.
   * returning false from this function prevents navigation.
   */
  onNextMonthClick: PropTypes.func,
  /**
   * Callback invoked when user clicks the next button on the calendar.
   * This function should return true if the navigation to previous month is allowed.
   * returning false from this function prevents navigation.
   */
  onPrevMonthClick: PropTypes.func,
  selectedDays: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired
    })
  ]),
  /**
   * When set to true, the range of dates provided in `selectedDays`
   * is shown as a range of days, instead of individual days.
   * @default: false
   */
  showSelectedDaysAsRange: PropTypes.bool
};

Calendar.defaultProps = {
  highlightToday: true,
  showSelectedDaysAsRange: false,
  selectedDays: []
};

export const NavBar = withTheme(
  ({
    month,
    previousMonth,
    nextMonth,
    showNextButton,
    showPreviousButton,

    disablePrevNavAction,
    disableNextNavAction,
    calendarProps,
    onPreviousClick,
    onNextClick,
    ...rest
  }) => {
    const onPrevMonthClick = e => {
      if (typeof calendarProps.onPrevMonthClick === 'function') {
        // TODO Sudhir:Test callback props for `onPrevMonthClick`
        const allowNavigation = calendarProps.onPrevMonthClick(e, {
          month,
          previousMonth,
          nextMonth
        });

        return allowNavigation && onPreviousClick();
      } else onPreviousClick();
    };

    const onNextMonthClick = e => {
      if (typeof calendarProps.onNextMonthClick === 'function') {
        // TODO Sudhir:Test callback props for `onNextMonthClick`
        const allowNavigation = calendarProps.onNextMonthClick(e, {
          month,
          previousMonth,
          nextMonth
        });

        return allowNavigation && onNextClick();
      } else onNextClick();
    };

    const { coreTheme } = rest;

    return (
      <CalendarNavigationContainer>
        <NavigationContainer
          onClick={onPrevMonthClick}
          data-testid="calendar-nav-item-prev"
        >
          <ArrowLeftIcon
            size={20}
            color={
              showPreviousButton && !disablePrevNavAction
                ? coreTheme.cl_sec_d1
                : coreTheme.cl_ter_l4
            }
          />
        </NavigationContainer>
        <NavigationContainer
          onClick={onNextMonthClick}
          data-testid="calendar-nav-item-next"
        >
          <ArrowRightIcon
            size={20}
            color={
              showNextButton && !disableNextNavAction
                ? coreTheme.cl_sec_d1
                : coreTheme.cl_ter_l4
            }
          />
        </NavigationContainer>
      </CalendarNavigationContainer>
    );
  }
);

const Caption = ({ date /*, onClick*/ }) => {
  const currentMonthStr = format(date, 'MMMM yyyy');
  return <CalendarMonthHeader>{currentMonthStr}</CalendarMonthHeader>;
};

// TODO Sudhir:Render disabled and other states.
export const DayElement = ({
  highlightToday,
  inSelectedRange,
  day,
  modifiers
}) => {
  const {
    highlighted,
    selected,
    from,
    to,
    disabled,
    nextLeftDisabled,
    nextRightDisabled
  } = modifiers;
  const dayString = format(day, 'd'),
    testIdRef = format(day, 'dd/MM/yyyy');

  const isDayCellInRangeStart = selected && from;
  const isDayCellInRange = inSelectedRange && selected && !(from || to);
  const isDayCellInRangeEnd = selected && to;
  const isRangeStart =
    isFirstDayOfMonth(day) || day.getDay() === 0 || nextRightDisabled;
  const isRangeEnd =
    isLastDayOfMonth(day) || day.getDay() === 6 || nextLeftDisabled;
  const isSelected = !isDayCellInRange && selected && !disabled;

  // Today day cell UI is not rendered as of now. Will be added once the design is ready.
  // const isTodayHighlighted = highlightToday && highlighted;
  const isTodayHighlighted = highlightToday && highlighted && false;

  return (
    <DayContainer
      selected={selected}
      isRangeEnd={isRangeEnd}
      isRangeStart={isRangeStart}
      todayHighlight={isTodayHighlighted}
      isDayCellInRangeStart={isDayCellInRangeStart}
      isDayCellInRange={inSelectedRange && selected}
      isDayCellInRangeEnd={isDayCellInRangeEnd}
      data-testid={testIdRef}
      disabled={disabled}
    >
      <DayCell selected={isSelected}>{dayString}</DayCell>
      {disabled && <DisabledIcon />}
    </DayContainer>
  );
};

const NavigationContainer = withTheme(styled.div`
  ${({ coreTheme }) => css`
    padding: ${coreTheme.space_6}px ${coreTheme.space_2}px
      ${coreTheme.space_2}px ${coreTheme.space_6}px;
    flex: 1;
    z-index: 1;
    position: absolute;
    left: 0;
    right: auto;
    top: 0;

    :last-child {
      padding-right: ${coreTheme.space_6}px;
      padding-left: ${coreTheme.space_2}px;
      text-align: right;
      left: auto;
      right: 0;
    }
  `}
`);

const CalendarContainer = withTheme(styled.div`
  display: inline-block;
  overflow: hidden;
  ${({ coreTheme }) => css`
    border-radius: ${coreTheme.rad_xs};

    ${Config.media.sm`
      border: 1px solid ${coreTheme.cl_ter_l4};
    `}

    .DayPicker {
      display: inline-block;
      flex-direction: row;
    }

    .DayPicker-wrapper {
      padding: 0;
      position: relative;
      flex-direction: row;
      user-select: none;

      :focus {
        outline: none;
      }
    }

    .DayPicker-Months {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      flex-direction: column;

      ${Config.media.sm`
        flex-direction: row;
      `}
    }

    .DayPicker-Month {
      position: relative;
      margin: 0;
      padding: ${coreTheme.space_4}px ${coreTheme.space_6}px 0;
      display: block;
      border-spacing: 0;
      border-collapse: collapse;

      ${Config.media.sm`
        padding: ${coreTheme.space_6}px;
        border-right: 1px solid ${coreTheme.cl_ter_l4};
      `}

      @media (max-width: ${SMALL_MOBILE_BREAKPOINT}) {
        padding-left: ${coreTheme.space_4}px;
        padding-right: ${coreTheme.space_4}px;
      }

      :last-child {
        border-right: none;
      }
    }

    .DayPicker-Week {
      display: table-row;
    }

    .DayPicker-Weekdays {
      display: none;

      ${Config.media.sm`
        display: table-header-group;
      `}
    }

    .DayPicker-WeekdaysRow {
      margin-bottom: ${coreTheme.space_6}px;
      display: table-row;
    }

    .DayPicker-Body {
      display: table-row-group;
    }

    .DayPicker-Day {
      padding: 0;
      display: table-cell;
      border-radius: 50%;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;

      :focus {
        outline: none;
      }
    }

    font-family: ${coreTheme.ff_sec_thin};
  `};
`);

const CalendarMonthHeader = withTheme(
  getStyledText('boldBody', { lineHeight: 'compact' })(
    ({ coreTheme }) => css`
      display: table-caption;
      box-sizing: border-box;
      text-align: left;
      margin: 0 calc(((100vw - 48px) / 14) - 10px); /* to make it align with the dates (side paddings and 7 cols) */
      font-size: ${coreTheme.fs_xl};

      ${Config.media.sm`
        text-align: center;
        margin: 0 ${coreTheme.space_4}px ${coreTheme.space_6}px;
      `}
    `
  )
);

const WeekDay = withTheme(
  getStyledText('boldBody', { lineHeight: 'compact' })(
    ({ coreTheme }) => css`
      display: table-cell;
      text-align: center;
      color: ${coreTheme.cl_sec_d2};
      font-size: ${coreTheme.fs_sm};
      text-transform: uppercase;
      padding: 0 0 ${coreTheme.space_4}px;
    `
  )
);

const DayContainer = withTheme(styled.div`
  ${({
    todayHighlight,
    isRangeStart,
    isRangeEnd,
    isDayCellInRange,
    isDayCellInRangeStart,
    isDayCellInRangeEnd,
    disabled,
    coreTheme
  }) => {
    const doRenderRange = !(isDayCellInRangeStart && isDayCellInRangeEnd);

    const getColor = () =>
      disabled
        ? coreTheme.cl_ter_l4
        : todayHighlight
        ? coreTheme.cl_sec_d1
        : coreTheme.cl_ter_l1;
    const getTypeStyle = () =>
      todayHighlight
        ? css`
            font-family: ${coreTheme.ff_sec_bold};
            letter-spacing: -0.04em;
            position: relative;
          `
        : css`
            font-family: ${coreTheme.ff_sec_thin};
            letter-spacing: normal;
          `;
    const renderRange = () => css`
      ::before {
        content: '';
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        height: 32px;
        left: ${isDayCellInRangeStart ? '50%' : 'auto'};
        right: ${isDayCellInRangeEnd ? '50%' : 'auto'};
        width: ${isDayCellInRangeStart || isDayCellInRangeEnd ? '51%' : '101%'};
        background-color: ${coreTheme.cl_sec_l2};
        color: inherit;
        display: ${doRenderRange ? 'flex' : 'none'};
        align-items: center;
        justify-content: center;

        ${disabled &&
          css`
            background-color: ${coreTheme.cl_white};
          `}
      }
    `;
    const renderRangeStart = () => css`
      ::before {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    `;
    const renderRangeEnd = () => css`
      ::before {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    `;
    return css`
      font-size: ${coreTheme.fs_xl};
      color: ${getColor()};
      ${getTypeStyle()};

      ${isDayCellInRange &&
        !(isRangeStart && isDayCellInRangeEnd) &&
        !(isRangeEnd && isDayCellInRangeStart) &&
        renderRange()}
      ${isRangeStart && renderRangeStart()}
      ${isRangeEnd && renderRangeEnd()}
    `;
  }};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc((100vw - 43px) / 7);
  height: calc((100vw - 43px) / 7);
  position: relative;

  ${Config.media.sm`
    height: 43px;
    width: 43px;
  `}

  @media (max-width: ${SMALL_MOBILE_BREAKPOINT}) {
    height: calc(((100vw - 43px) / 7) + 8px);
  }
`);

const DayCell = withTheme(
  getStyledText('body', { lineHeight: 'compact' })(
    ({ selected, coreTheme }) => css`
      border-radius: ${coreTheme.space_6}px;
      width: 38px;
      height: 38px;
      background-color: transparent;
      color: inherit;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;

      ${selected &&
        css`
          background-color: ${coreTheme.cl_sec_d1};
          color: ${coreTheme.cl_white};
          font-family: ${coreTheme.ff_sec_bold};
        `}

      ${Config.media.sm`
        font-size: ${coreTheme.fs_xl};
      `}
    `
  )
);

const DisabledIcon = withTheme(
  styled(StrikeIcon)`
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 1;
  `
);

const CalendarNavigationContainer = withTheme(styled.div`
  display: none;

  ${Config.media.sm`
    display: flex;
  `}
`);

const correctSelectedDateRange = ({ from, to }) => {
  return compareAsc(to, from) === -1 ? { to: from, from: to } : { from, to };
};

export default Calendar;
