import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRight } from '@dls/assets/icons';
import { withTheme, useTheme } from '../../core/ThemeProvider';
import { withStyledAttrs } from '../../helpers/styled';
import Text from '../Text';
import styled, { css } from 'styled-components';

export const BreadcrumbText = withStyledAttrs(Text, {
  type: 'smallBody',
  fontWeight: 'thin',
  tag: 'span'
})`
`;

export const BreadcrumbItemContainer = withTheme(
  styled.span(
    ({ coreTheme }) => css`
      .separator {
        vertical-align: sub;
        padding-left: 5px;
        padding-right: 5px;

        @media (min-width: ${coreTheme.brk_xs}) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    `
  )
);

const BreadcrumbItem = ({ children, onClick, className }) => {
  const { theme } = useTheme();

  return (
    <BreadcrumbItemContainer className={className}>
      <BreadcrumbText onClick={onClick}>{children}</BreadcrumbText>
      <ArrowRight
        className="separator"
        size={16}
        color={theme.cl_ter_l4}
        stroke={theme.cl_ter_l4}
      />
    </BreadcrumbItemContainer>
  );
};

BreadcrumbItem.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Breadcrumb item text
   */
  children: PropTypes.any.isRequired,
  /**
   * Set the handler to handle click event
   */
  onClick: PropTypes.func
};

export default BreadcrumbItem;
