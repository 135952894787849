import styled from 'styled-components';
import React, { useContext } from 'react';
import { FormFieldContext } from './FormField';
import MultilineTruncateParagraph from '../../../core/components/MultilineTruncateParagraph';
import { withTheme } from '../../ThemeProvider';

const TRANSITION_TIMEOUT = 200;

// TODO Sudhir: Add documentation.
const LabelTextComponent = ({ children, alwaysShowAsHint, ...rest }, ref) => {
  const { onFocus, hasFocus, hasValue } = useContext(FormFieldContext);

  const renderAsHint = alwaysShowAsHint || hasFocus || hasValue;

  return (
    <MultilineTruncateParagraph
      Typography={StyledLabel}
      numOfLines={renderAsHint ? 1 : 2}
      timeout={TRANSITION_TIMEOUT}
      onMouseDown={onFocus}
      renderAsHint={renderAsHint}
      ref={ref}
      {...rest}
    >
      {children}
    </MultilineTruncateParagraph>
  );
};

const StyledLabel = withTheme(styled.span`
  user-select: none;
  text-align: left;
  ${({ coreTheme, renderAsHint }) => ({
    ...(renderAsHint ? hintTextCss(coreTheme) : labelTextCss(coreTheme)),
    left: `${coreTheme.space_4}px`
  })};

  line-height: 1.5;
  position: absolute;
  transition: ${TRANSITION_TIMEOUT}ms ease;
`);

export const labelTextCss = coreTheme => ({
  color: coreTheme.cl_sec_d1,
  fontSize: coreTheme.fs_xl,
  fontFamily: coreTheme.ff_sec_thin,
  top: '42px',
  transform: 'translate(0, -50%)'
});

export const hintTextCss = coreTheme => ({
  color: coreTheme.cl_ter_l2,
  fontSize: coreTheme.fs_sm,
  fontFamily: coreTheme.ff_sec_thin,
  top: '16px',
  width: 'calc(100% - 32px)'
});

const LabelText = React.forwardRef(LabelTextComponent);

export default LabelText;
