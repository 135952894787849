import TabContext from './TabContext';
import React from 'react';
import getComponentName from '../../../helpers/getComponentName';

/**
 * Inject TabContext props into the component
 * @param Component Target React component
 * @return {React.Component} Wrapped react component with `tabContext` prop.
 */
const withTabContext = Component => {
  const WithTabContext = React.forwardRef((props, ref) => (
    <TabContext.Consumer>
      {tabContext => {
        return <Component {...props} tabContext={tabContext} ref={ref} />;
      }}
    </TabContext.Consumer>
  ));

  WithTabContext.displayName = `WithTabContext${getComponentName(Component)}`;

  return WithTabContext;
};

export default withTabContext;
