import styled from 'styled-components';
import { withTheme } from '../../ThemeProvider';

// TODO Sudhir: Add documentation and test cases.
const HintText = withTheme(
  styled.div(({ coreTheme, error }) => ({
    color: error ? coreTheme.cl_pri_d3 : coreTheme.cl_ter_base,
    fontSize: coreTheme.fs_md,
    fontFamily: coreTheme.ff_sec_thin,
    flex: `0 0 85%`
  }))
);

export default HintText;
