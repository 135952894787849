/**
 * Create styles for different breakpoints using array prop notation
 *
 * Array prop notation example:
 * -----------------------
 * fontSize: [24, 24, 28, 32, 32]
 * Each value in the array creates a font-size css for each breakpoint.
 *
 * - xs: 24px
 * - sm: 24px
 * - md: 28px
 * - lg: 32px
 * - xl: 32px
 *
 * @example
 * ```js
 * responsiveStyles({
 *   fontSize: [24, 24, 28, 32, 32]
 * }, theme)
 * ```
 *
 * @param {Object} styleObject style object
 *        { fontSize: 10px, color: '#' }
 *
 * @param {Object} theme DLS theme object
 * @return {{}} new style object for different viewports
 */
export const responsiveStyles = (styleObject, theme) => {
  return Object.keys(styleObject).reduce((style, name) => {
    const value = styleObject[name];
    if (value instanceof Array) {
      style[name] = value[0];
      value[1] &&
        (style[`@media(min-width: ${theme.brk_xs})`] = { [name]: value[1] });
      value[2] &&
        (style[`@media(min-width: ${theme.brk_sm})`] = { [name]: value[2] });
      value[3] &&
        (style[`@media(min-width: ${theme.brk_md})`] = { [name]: value[3] });
      value[4] &&
        (style[`@media(min-width: ${theme.brk_lg})`] = { [name]: value[4] });
      value[5] &&
        (style[`@media(min-width: ${theme.brk_xl})`] = { [name]: value[5] });
    } else {
      style[name] = value;
    }
    return style;
  }, {});
};
