import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactOtpInput from 'react-otp-input';
import styled, { css } from 'styled-components';
import loadingAnimation from '@dls/assets/lottiefiles/loading';
import { useTheme, withTheme } from '../../core/ThemeProvider';
import { testIdProps, withOpacity, isNumber } from '../../helpers';
import { isString } from '../../core/utils/helpers';
import { Fade } from '../../core/animation';
import Animation from '../Animation';
import HintText from '../../core/components/Forms/HintText';
import FormFieldHint from '../../core/components/Forms/FormFieldHint';

export const ReactOtpInputClasses = {
  containerStyle: 'react-otp-input--containerStyle',
  inputStyle: 'react-otp-input--inputStyle',
  focusStyle: 'react-otp-input--focusStyle'
};

const PinInput = ({
  size = 4,
  loading,
  error,
  message,
  autoFocus,
  onChange,
  defaultValue,
  value,
  bgColor,
  digitsOnly,
  ...rest
}) => {
  const [pinValue, setValue] = useState(value || defaultValue);
  const inputRef = useRef(null);

  const onPinChange = pinInputValue => {
    setValue(pinInputValue);
    onChange && onChange(pinInputValue);
  };

  const { theme } = useTheme();

  useEffect(() => {
    //when value changes, set new value to pinValue
    if (isString(value) || isNumber(value) || value === null) {
      setValue(value);
    }
  }, [value]);

  useEffect(() => {
    // when start loading, we need to out focus the PinInput
    // but 'react-otp-input' does not forward the ref to the nested inputs
    // so we need to focus and blur on a hidden input to out focus the PinInput
    if (loading) {
      const inputEl = inputRef ? inputRef.current : null;
      if (inputEl) {
        inputEl.focus();
        inputEl.blur();
      }
    }
  }, [loading]);

  return (
    <Container
      {...testIdProps(rest)}
      error={error}
      isLoading={loading}
      bgColor={bgColor}
    >
      <ReactOtpInput
        numInputs={size}
        value={pinValue}
        shouldAutoFocus={autoFocus}
        onChange={onPinChange}
        containerStyle={ReactOtpInputClasses.containerStyle}
        inputStyle={ReactOtpInputClasses.inputStyle}
        focusStyle={ReactOtpInputClasses.focusStyle}
        isInputNum={digitsOnly}
      />

      <Fade
        visible={loading}
        css={{
          /**
           * - width of each cell --- 50px
           * - gutter width --------- 8px
           * --------------------------------------------------------------------
           * total max width  ------- (num-cells * 58px) - 8px(last gutter width)
           */
          maxWidth: size * 58 - 8,
          width: '100%',
          height: 73,
          position: 'absolute',
          top: 0,
          left: 0
        }}
      >
        <LoadingOverlay>
          <Animation
            data-testid="loading-animation"
            source={loadingAnimation}
            size="30px"
            color={theme.cl_sec_d1}
            action="play"
          />
        </LoadingOverlay>
      </Fade>
      <HiddenInput ref={inputRef} />
      {error && (
        <FormFieldHint>
          <HintText error={true} data-testid="hintId">
            {message}
          </HintText>
        </FormFieldHint>
      )}
    </Container>
  );
};

PinInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Auto focuses input on initial page load.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * Background color of Input fields.
   * @default white
   */
  bgColor: PropTypes.oneOf(['haze', 'white']),
  /**
   * Default prefilled values for the input
   */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Restrict input to only numbers.
   * @default false
   */
  digitsOnly: PropTypes.bool,
  /**
   * Show error state
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Show loading spinner
   */
  loading: PropTypes.bool,
  /**
   * Text for error message
   */
  message: PropTypes.string,
  /**
   * Change handler
   * @param value New value of the field
   */
  onChange: PropTypes.func,
  /**
   * Number of digits in Pin input
   */
  size: PropTypes.oneOf([4, 6]),
  /**
   * value prop for the field.
   * Used to render values in fully controlled state.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default PinInput;

const Container = withTheme(
  styled.div(({ coreTheme, error, isLoading, bgColor }) => {
    const inputStyle = {
      borderColor: 'transparent',
      color: coreTheme.cl_ter_base,
      backgroundColor:
        bgColor === 'haze' ? coreTheme.cl_ter_l6 : coreTheme.cl_white
    };

    const inputFocusStyle = {
      borderColor: coreTheme.cl_sec_d1
    };

    if (isLoading) {
      inputStyle.color = withOpacity(coreTheme.cl_ter_l3, 0.5);
      inputStyle.borderColor = 'transparent';
      inputFocusStyle.borderColor = 'transparent';
    }

    if (error) {
      inputStyle.borderColor = coreTheme.cl_errorRed;
      inputStyle.backgroundColor = coreTheme.cl_white;
      inputFocusStyle.borderColor = coreTheme.cl_errorRed;
    }

    return css`
      display: flex;
      flex-direction: column;
      position: relative;

      .${/* sc-selector */ ReactOtpInputClasses.containerStyle} {
        > div:not(:last-of-type) {
          margin-right: ${coreTheme.space_2}px;
        }
      }

      /* stylelint-disable-next-line no-duplicate-selectors */
      .${/* sc-selector */ ReactOtpInputClasses.inputStyle} {
        display: inline-block;
        outline: none;
        height: 73px;
        max-width: 50px !important;
        width: 100% !important;
        box-sizing: border-box;
        padding: 0;
        border: 1px solid transparent;
        border-color: ${inputFocusStyle.borderColor};
        border-radius: ${coreTheme.rad_xs};
        font-family: ${coreTheme.ff_sec_regular};
        font-size: 24px;
        color: ${inputStyle.color};
        background-color: ${inputStyle.backgroundColor};

        &:focus {
          border-color: ${inputFocusStyle.borderColor};
          background-color: ${coreTheme.cl_white};
        }

        &:not([value='']) {
          background-color: ${isLoading || error
            ? inputStyle.backgroundColor
            : coreTheme.cl_white};
        }

        &[value=''] {
          border-color: ${inputStyle.borderColor};

          &:focus {
            border-color: ${inputFocusStyle.borderColor};
          }
        }
      }
    `;
  })
);

const LoadingOverlay = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;
