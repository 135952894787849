export const googleSearchApiBaseUrl = 'https://www.google.com/maps/search/';
export const locationSearchRadiusesInKm = [5, 10, 15];
export const locationDetailsCardClassName = 'location-details-card';
export const markerLabelClassName = 'marker-label';
export const singaporeCountryCode = 'SG';
export const errorMessages = {
  invalidPostalCode: 'Make sure this is a valid postal code',
  invalidPostalCodeLength: 'This must consist of 6 digits'
};
export const responseStatus = {
  OK: 'OK'
};
export const slideAnimationDurationInMillis = 300;
