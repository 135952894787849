import PropTypes from 'prop-types';
import styled from 'styled-components';
import Config from '../../config';

const Grid = styled.div`
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding: ${Config.grid.outerMargin}rem;

  ${Config.media.md`
    padding: ${Config.grid.outerMargin / 2}rem;
  `};

  ${p =>
    p.fluid &&
    `padding: 0;
  `};
  ${p => !p.fluid && Config.media.lg`width: ${Config.grid.container.lg}rem;`};
  ${p => !p.fluid && Config.media.xl`width: ${Config.grid.container.xl}rem;`};
`;

Grid.defaultProps = {
  fluid: false
};

Grid.propTypes = {
  className: PropTypes.node,
  fluid: PropTypes.bool
};

export default Grid;
