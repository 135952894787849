import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Text from '../Text';
import { withTheme } from '../../core/ThemeProvider';
import useDevice from '../../core/hooks/useDevice';
import Star from './star.svg';
import StarFilled from './star_filled.svg';
import StarFilledHalf from './star_filled_half.svg';

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TotalText = withTheme(styled(Text)`
  display: inline-block;
  color: ${p => p.coreTheme.cl_ter_l3};
  padding-left: ${p => p.coreTheme.space_1}px;
`);

const IconWrapper = withTheme(
  styled.span(
    ({ coreTheme, size }) => css`
      padding-right: ${coreTheme.space_1}px;

      svg {
        height: ${size}px;
        min-height: ${size}px;
        width: ${size}px;
      }
    `
  )
);

const Rating = props => {
  const { value, maxValue, total } = props;
  const { isMobile, isTablet, isLandscapeTablet } = useDevice();

  const iMaxValue = parseInt(maxValue, 10);
  const iconSize = isMobile || isTablet ? 16 : isLandscapeTablet ? 18 : 20;

  return (
    <RatingContainer>
      {[...Array(iMaxValue)].map((_, i) => {
        const isWholeStar = value >= i + 1;
        const isHalfStar = value >= i + 0.5;

        let StarIcon;
        let iconName;

        if (isWholeStar) {
          StarIcon = StarFilled;
          iconName = 'star_filled';
        } else if (isHalfStar) {
          StarIcon = StarFilledHalf;
          iconName = 'star_filled_half';
        } else {
          StarIcon = Star;
          iconName = 'star';
        }

        return (
          <IconWrapper key={i} data-testid={`rating-stars`} size={iconSize}>
            <StarIcon data-testid={`rating-stars-${iconName}`} />
          </IconWrapper>
        );
      })}
      {!!total && (
        <TotalText type="body" lineHeight="compact">
          ({total})
        </TotalText>
      )}
    </RatingContainer>
  );
};

Rating.defaultProps = {
  value: 0,
  maxValue: 5,
  total: 0
};

Rating.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  maxValue: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default Rating;
