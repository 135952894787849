import { setUrlParams } from '@lux/helpers';
import { api } from '../configs';
import { isLoading, setSessionTimeout, startTimer } from './index';
import { setReservedLocationAndTimeSlot } from './timeslot';
import { createOrder } from './order';
import {
  getProductCatalog,
  setReservedProducts,
  setSelectedProducts
} from './product';
import {
  normalizedProductItem,
  sendApiToGaEvent,
  sendGaEvent
} from '../helpers';
import { getError, validateApi } from '../api/Campaign';

const submitCustomerRegistrationSuccess = () => ({
  type: 'SET_SUBMIT_CUSTOMER_REGISTRATION_SUCCESS'
});

const submitCustomerRegistrationError = () => ({
  type: 'SET_SUBMIT_CUSTOMER_REGISTRATION_ERROR'
});

const customerRegistrationStatusSuccess = status => ({
  type: 'SET_CUSTOMER_REGISTRATION_SUCCESS',
  ...status
});

const resendConfirmationSuccess = () => ({
  type: 'SET_RESEND_CONFIRMATION_SUCCESS'
});

const setAuthToken = token => ({
  type: 'SET_AUTH_TOKEN',
  token
});

const submitCustomerAppointmentSuccess = ({ backorderRedirectUrl }) => ({
  type: 'SET_SUBMIT_CUSTOMER_APPOINTMENT_SUCCESS',
  backorderRedirectUrl
});

const submitCustomerAppointmentError = error => ({
  type: 'SET_SUBMIT_CUSTOMER_APPOINTMENT_ERROR',
  error
});

const resetAppointmentSuccess = () => ({
  type: 'SET_RESET_APPOINTMENT_SUCCESS'
});

export const setCustomerConsent = payload => ({
  type: 'SET_MARKETING_CONSENT',
  payload
});

export const customerAppointmentError = error => ({
  type: 'SET_CUSTOMER_APPOINTMENT_ERROR',
  error
});

export const setNric = nric => ({
  type: 'SET_NRIC',
  nric
});

export const setRegistrationAccessToken = token => ({
  type: 'SET_REGISTRATION_ACCESS_TOKEN',
  registrationAccessToken: token
});

export const setNricToken = token => ({
  type: 'SET_NRIC_TOKEN',
  token
});

export const setCustomerDetails = customer => ({
  type: 'SET_CUSTOMER_DETAILS',
  customer
});

const setAllCustomerDetails = (dispatch, response) => {
  dispatch(customerAppointmentError(undefined));

  dispatch(
    customerRegistrationStatusSuccess({
      registered: true
    })
  );

  dispatch(
    setCustomerDetails({
      id: response.id,
      name: response.name,
      email: response.email,
      mobile: response.mobile,
      nric: response.nric,
      portIn: response.portIn,
      orderId: response.orderId,
      eligible: response.eligible,
      presale: response.presale,
      apptEligible: response.apptEligible,
      backorderEligible: response.backorderEligible,
      payOnlineEligible: response.payOnlineEligible,
      orders: response.orders
    })
  );

  // These are the products that were selected in ROI
  if (response.products) {
    const selectedProducts = response.products.reduce(
      (accum, product, index) => {
        accum[index] = normalizedProductItem(product);
        return accum;
      },
      {}
    );
    dispatch(setSelectedProducts(selectedProducts));
  }

  // Customer has reserved products from the appointment booking
  if (
    response.orders &&
    response.orders.length > 0 &&
    response.orders[0].orderItems
  ) {
    const orderItems = response.orders[0].orderItems;

    const reservedProducts = orderItems.reduce((accum, orderItem, index) => {
      accum[index] = normalizedProductItem(orderItem.product);
      return accum;
    }, {});

    dispatch(setReservedProducts(reservedProducts));

    const appointment = response.orders[0].appointments[0];
    const timeSlot = appointment.timeSlot;
    const appointmentLocation = timeSlot.appointmentLocation;

    dispatch(
      setReservedLocationAndTimeSlot({
        location: {
          id: appointmentLocation.id,
          name: appointmentLocation.name,
          payonline: appointmentLocation.payonline
        },
        timeSlot: {
          id: timeSlot.id,
          date: timeSlot.date,
          time: timeSlot.time
        }
      })
    );
  }
};

export function getCustomerRegistrationStatus(campaignId, nric) {
  const url = api.roi.searchRegistration.replace(':campaignId', campaignId);

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        nric
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        sendApiToGaEvent('getCustomerRegistrationStatus', response.status);
        dispatch(isLoading(false));
        // Customer registration not found
        if (response.status === 204) {
          dispatch(
            customerRegistrationStatusSuccess({
              registered: false
            })
          );
        } else if (response.status === 200) {
          return response;
        } else {
          throw response;
        }
      })
      .then(response => response.json())
      .then(response => {
        setAllCustomerDetails(dispatch, response);
      })
      .catch(e => {
        console.error(e);
      });
  };
}

export function submitCustomerRegistration(
  campaignId,
  customer,
  okToContact,
  selectedProducts = {},
  { gReCaptchaResponse, registrationAccessToken, resetRegistration } = {}
) {
  let url = resetRegistration
    ? setUrlParams(
        api.roi.submitRegistration.replace(':campaignId', campaignId),
        {
          reset: 'true',
          timeout: 'false'
        }
      )
    : api.roi.submitRegistration.replace(':campaignId', campaignId);

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      productIds: Object.keys(selectedProducts).map(
        product => selectedProducts[product].id
      ),
      gReCaptchaResponse: gReCaptchaResponse,
      nric: customer.nric,
      name: customer.name,
      email: customer.email,
      mobile: customer.mobile,
      portIn: customer.portIn,
      okToContact: okToContact,
      registrationAccessToken: registrationAccessToken || ''
    })
  };

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(async response => {
        const authToken = response.headers.get('x-auth-token');
        if (authToken) {
          dispatch(setAuthToken(authToken));
        }
        sendApiToGaEvent('submitCustomerRegistration', response.status);
        dispatch(isLoading(false));
        if (response.status >= 200 && response.status < 300) {
          Object.keys(selectedProducts).forEach(product =>
            sendGaEvent('ROI Products', selectedProducts[product].description)
          );

          sendGaEvent('ROI Count', Object.keys(selectedProducts).length);
          sendGaEvent('ROI OkToContact', okToContact);
          sendGaEvent('ROI PortIn', customer.portIn);

          dispatch(submitCustomerRegistrationSuccess());

          return response.json();
        }
        if (response.status === 409) {
          dispatch(submitCustomerRegistrationError());
        }
      })
      .catch(() => {
        dispatch(isLoading(false));
      });
  };
}

export function resendConfirmation(registrationId) {
  const url = api.roi.resendConfirmation.replace(
    ':registrationId',
    registrationId
  );

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return dispatch => {
    dispatch(isLoading(true));

    return (
      fetch(url, options)
        .then(response => {
          sendApiToGaEvent('resendConfirmation', response.status);
          sendGaEvent('Resend Confirmation', 'ROI');
          return response;
        })
        //No handling needed
        //.then(response => response.json())
        .then(() => {
          dispatch(isLoading(false));
          dispatch(resendConfirmationSuccess());
        })
        .catch(() => {})
    );
  };
}

export function getCustomerAppointmentStatus(
  appointmentStatus,
  campaignId,
  nric,
  nricToken,
  validateOptions = {}
) {
  const url = api.appointment.registration.replace(':campaignId', campaignId);

  let body = {
    nric: nric.toUpperCase(),
    token: nricToken
  };
  if (typeof validateOptions.reset != 'undefined')
    body['reset'] = validateOptions.reset;
  if (typeof validateOptions.timeout != 'undefined')
    body['timeout'] = validateOptions.timeout;
  if (typeof validateOptions.gReCaptchaResponse != 'undefined')
    body['gReCaptchaResponse'] = validateOptions.gReCaptchaResponse;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify(body),
    credentials: 'same-origin'
  };

  let authToken;

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(async response => {
        sendApiToGaEvent('getCustomerAppointmentStatus', response.status);
        const { data, errorType } = await getError(response);

        // Invalid NRIC token
        if (response.status === 400) {
          dispatch(customerAppointmentError(errorType || 'NRIC_INVALID_TOKEN'));
          dispatch(isLoading(false));
          throw response;
        }

        // NRIC not found, not eligible
        if (response.status === 204) {
          dispatch(customerAppointmentError('NRIC_NOT_REGISTERED'));
          dispatch(isLoading(false));
          throw response;
        }

        // Session already exists, show modal to reset
        if (response.status === 401) {
          dispatch(
            customerAppointmentError(errorType || 'SESSION_ALREADY_EXISTS')
          );
          dispatch(isLoading(false));
          throw response;
        }

        // Store the auth token
        authToken = response.headers.get('x-auth-token');
        dispatch(setAuthToken(authToken));
        if (response.status === 200) {
          return data;
        } else {
          throw {
            response,
            data
          };
        }
      })
      .then(async response => {
        const isOpen = appointmentStatus === 'OPEN';
        const isClosed = appointmentStatus === 'CLOSED';

        const registrationId = response.id;
        const isEligible = response.eligible;
        const isPresales = response.presale;
        // const isApptEligible = response.apptEligible;
        const hasOrders = response.orders && response.orders.length;

        setAllCustomerDetails(dispatch, response);

        // Appointment not allowed during ROI phase
        if (
          isOpen &&
          validateApi.appointmentNotAllowedDuringROIPhase(response)
        ) {
          dispatch(customerAppointmentError('ROI_ONLY'));
          return dispatch(isLoading(false));
        }

        if (validateApi.requireValidToken(response)) {
          dispatch(customerAppointmentError('NRIC_NEEDS_VALID_TOKEN'));
          return dispatch(isLoading(false));
        }

        // Customer is not prestige eligible
        if (isOpen && isEligible && validateApi.onlyPrestigeAllowed(response)) {
          dispatch(customerAppointmentError('NRIC_NOT_ELIGIBLE_PRESTIGE'));
          return dispatch(isLoading(false));
        }

        // Customer Already submitted order
        if (validateApi.haveOrderSubmitted(response)) {
          dispatch(customerAppointmentError('NRIC_HAS_ORDERS_SUBMITTED'));
          return dispatch(isLoading(false));
        }

        // Customer is not eligible
        if (isOpen && !isEligible) {
          dispatch(customerAppointmentError('NRIC_NOT_ELIGIBLE'));
          return dispatch(isLoading(false));
        }

        // Presales Customer
        if (isPresales) {
          dispatch(setNric(nric));
          dispatch(
            setRegistrationAccessToken(response.registrationAccessToken)
          );
          return await getProductCatalog(campaignId)(dispatch);
        }

        // Customer did not complete the order
        if (isClosed && !hasOrders) {
          dispatch(customerAppointmentError('NRIC_NOT_FOUND'));
          return dispatch(isLoading(false));
        }

        // No order found, create one and fetch products
        if (isOpen && !hasOrders) {
          createOrder(registrationId, authToken)(dispatch);
          return getProductCatalog(campaignId)(dispatch);
        } else {
          dispatch(isLoading(false));
        }
      })
      .catch(() => {});
  };
}

export function submitCustomerAppointment(locationHeader, authToken) {
  const url = locationHeader + api.appointment.submit;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'same-origin'
  };

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    // Total time the customer took to submit an appointment
    // This is from the time the countdown timer started
    const timeNow = new Date().getTime();
    // Time to expiry time
    const timeDiff = getState().expireTime - timeNow;
    // Time different of 5 minutes
    const timeTaken = 300000 - timeDiff;
    // Minutes left
    const minutes = Math.floor(timeTaken / 60000);
    // Seconds left
    const seconds = ((timeTaken % 60000) / 1000).toFixed(0);
    // Total output
    const timeDiffOutput = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;

    sendGaEvent('Timer', timeDiffOutput);

    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('submitCustomerAppointment', response.status);
        dispatch(isLoading(false));
        if (response.status === 201 || response.status === 204) {
          const backorderRedirectUrl = response.headers.get('location');
          const successPayload = {
            backorderRedirectUrl
          };
          if (backorderRedirectUrl) {
            dispatch(isLoading(true));
          } else {
            dispatch(submitCustomerAppointmentSuccess(successPayload));
          }
          return successPayload;
        }
        if (response.status === 401) {
          dispatch(submitCustomerAppointmentError('SESSION_NOT_FOUND'));
        } else {
          dispatch(
            submitCustomerAppointmentError('SUBMIT_APPOINTMENT_UNKNOWN_ERROR')
          );
        }
        return response;
      })
      .catch(() => {});
  };
}

// Session timer ended, we need to reset the session on the backend
// And then display the session timeout message on the front page
export function resetSession(campaignId, nric, nricToken, timeout = false) {
  const url = api.appointment.resetSession.replace(':campaignId', campaignId);

  let formData = new FormData();
  formData.append('nric', nric);
  formData.append('token', nricToken);
  formData.append('reset', true);
  // an additional param to let the backend know we are resetting session
  // on a client-side timeout so backend does not create a new session
  // until the customer re-enters the app again
  formData.append('timeout', timeout);

  const options = {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: formData,
    credentials: 'same-origin'
  };

  let authToken;
  let status;

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(response => {
        // Store the auth token
        authToken = response.headers.get('x-auth-token');
        status = response.status;
        dispatch(setAuthToken(authToken));
        return response;
      })
      .then(response => response.json())
      .then(response => {
        // If we have a client side countdown expiration
        // We only dispatch an event to go to landing page
        // with error
        if (timeout) {
          sendApiToGaEvent('resetSessionTimeout', status);
          sendGaEvent('Session Timeout', timeout);
          dispatch(isLoading(false));
          // set sessionTimoutError
          dispatch(setSessionTimeout());
          return response;
        }

        // The below is triggered when customer confirms
        // they want to reset session via the popup modal
        // on landing page

        sendApiToGaEvent('resetSession', status);
        setAllCustomerDetails(dispatch, response);

        const registrationId = response.id;
        const isEligible = response.eligible;
        const isApptEligible = response.apptEligible;

        // Customer is not prestige eligible
        if (isEligible && !isApptEligible) {
          dispatch(customerAppointmentError('NRIC_NOT_ELIGIBLE_PRESTIGE'));
          return dispatch(isLoading(false));
        }

        // Customer is not eligible
        if (!isEligible) {
          dispatch(customerAppointmentError('NRIC_NOT_ELIGIBLE'));
          return dispatch(isLoading(false));
        }

        createOrder(registrationId, authToken)(dispatch);
        getProductCatalog(campaignId)(dispatch);

        return response;
      })
      .catch(() => {});
  };
}

export function resetAppointment(
  locationHeader,
  registrationId,
  authToken,
  campaignId
) {
  const url = locationHeader + api.appointment.resetAppointment;

  const options = {
    method: 'POST',
    headers: {
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));
    return fetch(url, options)
      .then(response => {
        // Send GA
        sendApiToGaEvent('resetAppointment', response.status);
        // reset reserved Products
        dispatch(setReservedProducts({}));
        // reset timeslot
        dispatch(setReservedLocationAndTimeSlot({}));
        // Get product catalog
        return dispatch(getProductCatalog(campaignId));
      })
      .then(() => {
        // start timer
        dispatch(startTimer());
        dispatch(resetAppointmentSuccess());
        dispatch(isLoading(false));
      })
      .catch(() => {});
  };
}

export function resendAppointmentConfirmation(orderId) {
  const url = api.appointment.resendConfirmation.replace(':orderId', orderId);

  let options = {
    method: 'POST'
  };

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('resendAppointmentConfirmation', response.status);
        sendGaEvent('Resend Confirmation', 'Appointment');
        dispatch(isLoading(false));
        dispatch(resendConfirmationSuccess());
        return response;
      })
      .catch(() => {});
  };
}

export function searchCustomers(campaignId, category, term, authToken) {
  const url = api.cco.searchCustomers.replace(':campaignId', campaignId);

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify({
      [category]: term
    }),
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(response => {
        dispatch(isLoading(false));
        return response;
      })
      .catch(() => {});
  };
}
