import merge from 'lodash/merge';
import { baseTheme, updateTheme } from './index';
import { flattenTheme } from './utils';
import { withOpacity } from '../helpers';

const kioskBaseTheme = merge({}, baseTheme, {
  main: {
    fontSizes: {
      lg: `34px`,
      md: `28px`
    },
    colours: {
      secondary: {
        d1: '#36AEED'
      },
      tertiary: {
        l1: '#010101',
        l4: '#959595',
        l5: '#E0E0E0',
        l6: '#CECECE',
        d1: '#B4B4B4'
      }
    }
  }
});

export const getKioskTheme = newTheme => {
  const flatTheme = flattenTheme(updateTheme(kioskBaseTheme, newTheme));
  const componentsTheme = {
    accordion: {
      margin: `${flatTheme.space_4}px 0`,
      mdMargin: `${flatTheme.space_4}px 0`,
      iconSize: '22px',
      mdIconSize: '32px'
    },
    button: {
      minWidth: '180px',
      minHeight: 'auto',
      border: '2px solid transparent',
      borderRadius: flatTheme.rad_xl,
      padding: `${flatTheme.space_3}px ${flatTheme.space_6}px`,
      fontSize: flatTheme.fs_md,
      background: flatTheme.cl_pri_base,
      secondary: {
        background: flatTheme.cl_white,
        borderColor: `${flatTheme.cl_pri_base}`,
        color: flatTheme.cl_pri_base,
        hoverBg: flatTheme.cl_pri_base,
        hoverColor: flatTheme.cl_white
      },
      link: {
        color: flatTheme.cl_sec_d1,
        fontFamily: flatTheme.ff_sec_thin
      },
      outline: {},
      large: {
        fontSize: flatTheme.fs_lg,
        minHeight: '120px',
        minWidth: '128px',
        borderWidth: '3px',
        padding: `${flatTheme.space_2}px ${flatTheme.space_6}px`
      },
      disabled: {
        background: flatTheme.cl_ter_l5,
        color: flatTheme.cl_ter_d1,
        borderColor: flatTheme.cl_ter_l5
      },
      customStyle: {}
    },
    tab: {
      bottomBorderColor: flatTheme.cl_ter_l6,
      tabBarDividerPositionBottom: 0,
      tabItemContainerPadding: `0 0 6px 0`,
      selectedTabTitleColor: flatTheme.cl_white,
      tabTitleColor: withOpacity(flatTheme.cl_white, 0.4),
      tabItemContainerHoverBg: 'transparent',
      tabItemContainerActiveBg: 'transparent',
      tabItemContainerBg: 'transparent',
      tabItemIndicatorBgColor: flatTheme.cl_white,
      tabBarDividerBgColor: flatTheme.cl_ter_l6,
      fontSize: '32px',
      textTransform: 'uppercase',
      tabItemMinWidth: '225px',
      hideScrollArrow: true,
      tabContainerWidth: 'auto',
      secondary: {
        tabTitleColor: withOpacity(flatTheme.cl_ter_l1, 0.4),
        selectedTabTitleColor: flatTheme.cl_black,
        tabItemContainerActiveBg: 'transparent',
        tabItemContainerBg: 'transparent',
        afterTriangleColor: flatTheme.cl_white,
        tabItemIndicatorBgColor: flatTheme.cl_black,
        bottomBorderColor: flatTheme.cl_ter_l6,
        tabItemContainerHoverBg: 'transparent',
        tabBarDividerBgColor: flatTheme.cl_ter_l6
      }
    },
    text: {
      fontFamilyType: 'secondary',
      bannerTitle: {
        fontWeight: 'bold',
        fontSize: [42, 42, 84, 84, 84]
      },
      pageTitle: {
        fontWeight: 'bold',
        fontSize: [42, 42, 84, 84, 84]
      },
      sectionTitle: {
        fontWeight: 'bold',
        fontSize: [32, 32, 42, 42, 42]
      },
      sectionTitle02: {
        fontWeight: 'bold',
        fontSize: [32, 32, 42, 42, 42]
      },
      header: {
        fontSize: [28, 28, 32, 32, 32]
      },
      boldBody: {
        fontSize: [24, 24, 28, 28, 28]
      },
      body: {
        fontSize: [24, 24, 28, 28, 28],
        lineHeight: 1.5
      },
      smallBody: {
        fontSize: [18, 18, 24, 24, 24]
      }
    },
    modal: {
      titlePadding: `0 0 ${flatTheme.space_4} 0`,
      padding: '54px 54px 54px 54px',
      iconColor: flatTheme.cl_black,
      maxWide: 9
    },
    selector: {
      border: `1px solid ${flatTheme.cl_ter_l4}`,
      selectedBorder: `3px solid ${flatTheme.cl_sec_d1}`,
      borderRadius: '16px',
      padding: '32px 36px 32px 36px',
      disabledOpacity: 1,
      disabledColor: flatTheme.cl_ter_l4
    },
    listItem: {
      leftContent: {
        backgroundColor: flatTheme.cl_ter_l6,
        minHeight: '80px',
        minWidth: '80px',
        image: {
          height: '48px',
          width: '48px'
        },
        thumbnail: {
          height: '104px',
          width: '104px'
        },
        icon: {
          size: '32px',
          color: flatTheme.cl_sec_d1
        },
        tag: {
          backgroundColor: flatTheme.cl_pri_d2,
          height: '20px',
          fontSize: [12, 12, 14, 14, 14],
          color: flatTheme.cl_white,
          lineHeight: '20px',
          letterSpacing: '0.002em',
          fontFamily: flatTheme.ff_sec_bold,
          padding: '0 16px'
        }
      },
      bodyContent: {
        title: {
          color: flatTheme.cl_ter_l1,
          lineHeight: '24px',
          mdLineHeight: '30px'
        },
        subTitle: {
          color: flatTheme.cl_ter_l3,
          lineHeight: '24px',
          mdLineHeight: '28px'
        },
        points: {
          icon: {
            size: '24px',
            color: flatTheme.cl_sec_d1
          }
        }
      }
    },
    map: {
      mapTerrain: {
        waterGeometry: flatTheme.cl_ter_l5,
        landscapeGeometry: flatTheme.cl_ter_l6,
        roadHighwayGeometryFill: flatTheme.cl_white,
        roadHighwayGeometryStroke: flatTheme.cl_white,
        roadArterialGeometry: flatTheme.cl_white,
        roadLocalGeometry: flatTheme.cl_white,
        poiGeometry: flatTheme.cl_ter_l6,
        poiParkGeometry: flatTheme.cl_ter_l5,
        labelsTextStroke: flatTheme.cl_white,
        labelsTextFill: flatTheme.cl_ter_l4,
        transitGeometry: flatTheme.cl_ter_l6,
        administrativeGeometryFill: flatTheme.cl_ter_l6,
        administrativeGeometryStroke: flatTheme.cl_ter_l6
      },
      marker: {
        labelTextShadow: `1px 1px 1px ${flatTheme.cl_white}`,
        labelLineHeight: '16px',
        labelLetterSpacing: '0.2px',
        labelFontSize: flatTheme.fs_xs,
        labelColor: flatTheme.cl_black,
        labelFontFamily: flatTheme.ff_sec_regular
      }
    },
    table: {
      backgroundColor: {
        hover: flatTheme.cl_sec_l2,
        active: flatTheme.cl_sec_l2
      }
    }
  };

  return {
    ...flatTheme,
    ...componentsTheme
  };
};

export const kioskTheme = getKioskTheme();

export default kioskTheme;
