import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { getStyledText } from '../../helpers/styled';
import Config from '../../config';

export const TextVariant1 = withTheme(
  getStyledText('smallBody', { tag: 'span', lineHeight: 'compact' })(
    ({ coreTheme }) => css`
      text-transform: uppercase;
      letter-spacing: 0.8px;
      font-family: ${coreTheme.ff_sec_bold};
      ${Config.media.md`
        line-height: 24px;
      `};
    `
  )
);

export const TextVariant2 = styled(TextVariant1)`
  letter-spacing: 0.2px;
  text-transform: unset;
`;

export const Ribbon = styled.div`
  display: inline-block;
`;

export const Container = withTheme(styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${p => {
    const bgColor =
      p.color === 'secondary' ? p.coreTheme.cl_sec_l1 : p.coreTheme.cl_pri_d2;

    const textColor =
      p.color === 'secondary' ? p.coreTheme.cl_sec_d2 : p.coreTheme.cl_pri_d2;

    return css`
      span {
        color: ${textColor};
      }

      ${/* sc-sel */ BadgeIconContainer} {
        background-color: ${/* sc-value */ bgColor};
      }
    `;
  }}
`);
export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const BadgeIconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  + ${/* sc-selector */ Content} {
    margin-left: 8px;
  }
`;
