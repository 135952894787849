import React, { Component } from 'react';
import Container from '../../components/Container';
import Landing from '../../components/Landing';

import { devices } from '../../configs/devices';

const heroImage = devices.default.banner;

class ErrorPage extends Component {
  render() {
    return (
      <Container>
        <Landing
          image={heroImage}
          title="OOps, not getting to the page you want?"
          description={`Don't worry. Just turn off your Adblock or DNS-based Adblock temporarily on your browser while you complete your transaction. Remember to refresh your page before you try again.`}
          showNric={false}
        />
      </Container>
    );
  }
}

export default ErrorPage;
