import React from 'react';
import PropTypes from 'prop-types';
import { SpacingStyle } from './style';

const Spacing = ({
  responsive = true,
  topBottom = 0,
  leftRight = 0,
  top = topBottom,
  right = leftRight,
  bottom = topBottom,
  left = leftRight,
  type = 'padding',
  children,
  ...rest
}) => {
  return (
    <SpacingStyle
      {...rest}
      responsive={responsive}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      type={type}
    >
      {children}
    </SpacingStyle>
  );
};

Spacing.defaultProps = {};

Spacing.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leftRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  responsive: PropTypes.bool,
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['margin', 'padding'])
};

export default Spacing;
