import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { defaultTheme, getDefalutTheme } from '../../theme';
import getComponentName from '../../helpers/getComponentName';
import { kioskTheme, getKioskTheme } from '../../theme/kiosk';
import { advanceTheme, getAdvanceTheme } from '../../theme/advance';

/**
 * DLS Theme Context
 * @type {React.Context<{coreTheme: {}}>}
 */
export const ThemeContext = React.createContext({
  coreTheme: defaultTheme
});

/**
 * Inject ThemeContext values into the component props, namely `coreTheme`
 * Ensure any of the props used in the target component do not use `coreTheme` to prevent prop overwriting.
 *
 * @param Component React component which receives the injected prop from ThemeContext.
 * @return {React.Component} New react component.
 */
export const withTheme = Component => {
  const WithCoreContextComponent = React.forwardRef((props, ref) => {
    const themeContext = useContext(ThemeContext) || {};
    const theme = themeContext.theme || defaultTheme;
    return <Component {...props} coreTheme={theme} ref={ref} />;
  });

  WithCoreContextComponent.displayName = `DLSContext.${getComponentName(
    Component
  )}`;

  return WithCoreContextComponent;
};

/**
 * Hook for accessing DLS theme values in DLS components.
 * @return {{theme: {} }}
 */
export const useTheme = () => {
  const themeContext = useContext(ThemeContext) || {};
  const theme = themeContext.theme || defaultTheme;
  return { theme };
};

const brands = {
  default: {
    theme: defaultTheme,
    getTheme: getDefalutTheme
  },
  kiosk: {
    theme: kioskTheme,
    getTheme: getKioskTheme
  },
  advance: {
    theme: advanceTheme,
    getTheme: getAdvanceTheme
  }
};

/**
 * React Component to provide ThemeContext values to all the child components.
 */
export const ThemeProvider = props => {
  // Memoize context values since global configs such as 'theme' do not change often.
  const themeValue = useMemo(() => ({
    theme: props.theme
      ? brands[props.brand].getTheme(props.theme)
      : brands[props.brand].theme
  }));

  return (
    <>
      <ThemeContext.Provider value={themeValue}>
        {props.children}
      </ThemeContext.Provider>
    </>
  );
};

ThemeProvider.defaultProps = {
  brand: 'default'
};

ThemeProvider.propTypes = {
  children: PropTypes.element,
  /**
   * Custom theme for all DLS components
   */
  theme: PropTypes.object
};
