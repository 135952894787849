import { locationSearchRadiusesInKm } from '../config';

const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

/**
 * Calculates distance between the two points. The shortest distance over the earth’s surface,
 * using the ‘Haversine’ formula.
 *
 * @param {number} lat1 - Latitude of point 1
 * @param {number} lon1 - Longitude of point 1
 * @param {number} lat2 - Latitude of point 2
 * @param {number} lon2 - Longitude of point 2
 * @return {number} Distance between the two points in km
 */
const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const earthRadius = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c; // Distance in km
  return distance;
};

/**
 * Get the locations with additional "distance" property added.
 * Distance is location's distance from start point in km
 * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
 *
 * @param {Location[]} locations - List of locations
 * @param {{lat: number, lng: number}} startPoint - Latitude, Longitude point to calculate distance from
 * @return {Location[]} locations - Locations list with the 'distance' property added to each location
 */
export const getLocationsWithDistanceFromPoint = (
  locations,
  startPoint,
  searchConfig
) => {
  const locationsWithDistance = locations?.map(endPoint => {
    const distanceFromPoint = getDistanceFromLatLonInKm(
      parseFloat(startPoint.lat),
      parseFloat(startPoint.lng),
      parseFloat(endPoint.lat),
      parseFloat(endPoint.lng)
    );

    return { ...endPoint, distance: distanceFromPoint };
  });

  // Filter locations based on configured list of radiuses (e.g. 5km, 10km, 15km).
  // If no results within first 5km radius from the location entered, it will search
  // within 10km radius. If no results from 10km radius, it will search within 15km radius.
  let filteredLocations = [];
  const searchRadiusConfig = searchConfig || locationSearchRadiusesInKm;

  for (let i = 0; i < searchRadiusConfig.length; i++) {
    if (locationsWithDistance && filteredLocations.length === 0) {
      filteredLocations = locationsWithDistance?.filter(location => {
        return location.distance < searchRadiusConfig[i];
      });
    }
  }

  return filteredLocations.sort((a, b) => {
    return a.distance - b.distance;
  });
};

/**
 * Gets unique categories from the locations datamodel provided.
 *
 * @param {Location[]} locations- List of locations
 * @return {Category[]} categories - List of unique categories
 */
export const getUniqueCategories = locations => {
  const uniqueCategoriesObjMap = {};
  const uniqueCategoriesArr = [];

  locations?.forEach(location => {
    location.categories?.forEach(category => {
      const { value, name } = category;
      if (!uniqueCategoriesObjMap[value]) {
        uniqueCategoriesObjMap[value] = { name };
        uniqueCategoriesArr.push({
          name,
          value
        });
      }
    });
  });

  return uniqueCategoriesArr;
};

/**
 * Calculate map height based on aspect ratio
 *
 * @param {number} width - Map width
 * @param {number} widthRatio - Width portion from the aspect ratio
 * @param {number} heightRatio - Height portion from the aspect ratio
 * @return {numnber} - Calculated map height
 */
export const getCalculatedMapHeight = (width, widthRatio, heightRatio) => {
  if (!width || !widthRatio || !heightRatio) {
    return 0;
  }
  const ratio = width / widthRatio;
  return ratio * heightRatio;
};

/**
 * Round the distanceInKm value to maximum 3 decimals. Trailing zeros in the rounded distance value
 * will be removed. Given suffix will be appended to the distance number value.
 *
 * @param {number} distanceInKm - Distance value in Kilometers
 * @param {string} [suffix=''] - Suffix to append to the formatted distance number value
 * @return {string} - Formatted distance value rounded to maximum 3 decimals
 */
export const getDistanceInKmFormattedValue = (distanceInKm, suffix = '') => {
  if (!distanceInKm || isNaN(distanceInKm)) {
    return '';
  }
  const distanceNum = parseFloat(
    (Math.round(distanceInKm * 1000) / 1000).toFixed(3)
  );
  return `${distanceNum} ${suffix}`;
};

/**
 * Get the location index for store selection
 * @param {object} locationsWithDistance
 * @param {object} preLocationSelection
 * @returns location index
 */
export const getLocationIndex = (
  preLocationSelection,
  locationsWithDistance = []
) => {
  if (!preLocationSelection) {
    return 0;
  }

  const { selectedLocation } = preLocationSelection;
  const preSelectionLocationIndex = locationsWithDistance.findIndex(
    location => {
      return (
        location.lat === selectedLocation.lat &&
        location.lng === selectedLocation.lng
      );
    }
  );
  return preSelectionLocationIndex === -1 ? 0 : preSelectionLocationIndex;
};
