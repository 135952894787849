import { useWindowSize } from './windowSize';
import { defaultTheme as theme } from '../../theme/index';

/**
 * @param {number} defaultPixelSize Default pixel size for body (usually 16px)
 * @param {number} debounceDuration Debounce duration for window resize event
 * @return {{
 *  isMobile: boolean,
 *  isTablet: boolean,
 *  isLandscapeTablet: boolean,
 *  isDesktop: boolean,
 *  isTV: boolean
 * }}
 */
const useDevice = ({ defaultPixelSize = 16, debounceDuration = 100 } = {}) => {
  const { width } = useWindowSize({ debounceDuration });

  const convertEmToPixel = breakpoint => {
    return parseInt(breakpoint.split('em')[0]) * defaultPixelSize;
  };

  const isMobile = width < convertEmToPixel(theme.brk_xs);

  const isTablet = !isMobile && width < convertEmToPixel(theme.brk_sm);

  const isLandscapeTablet =
    !isMobile && !isTablet && width < convertEmToPixel(theme.brk_md);

  const isDesktop =
    !isMobile &&
    !isTablet &&
    !isLandscapeTablet &&
    width < convertEmToPixel(theme.brk_lg);

  const isTV = !isMobile && !isTablet && !isLandscapeTablet && !isDesktop;

  return {
    isMobile, // break point XS
    isTablet, // break point SM
    isLandscapeTablet, // break point MD
    isDesktop, // break point LG
    isTV // break point XL
  };
};

export default useDevice;
