import { testIdProps } from '../../helpers';
import PropTypes from 'prop-types';
import React from 'react';

const TextLinkIcon = props => {
  const { icon: Icon } = props;
  return (
    <span {...testIdProps(props)}>
      <Icon size={24} color="currentColor" />
    </span>
  );
};

TextLinkIcon.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Icon component (from DLS Assets) used in TextLink
   */
  icon: PropTypes.node
};

export default TextLinkIcon;
