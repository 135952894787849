import format from 'date-fns/format';
import { isString } from './helpers';

export const formatDate = (date, formatter) => {
  if (isString(formatter)) {
    return isDateRange(date)
      ? `${format(date.from, formatter)} - ${format(date.to, formatter)}`
      : format(date, formatter);
  } else {
    return isDateRange(date)
      ? `${formatter(date.from)} - ${formatter(date.to)}`
      : formatter(date);
  }
};

export const isDateRange = date =>
  date && date.from instanceof Date && date.to instanceof Date;
