import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

class GAPageTracker extends React.Component {
  componentDidMount() {
    this.sendPageView(this.props.location);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.sendPageView(this.props.location);
    }
  }

  sendPageView(location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return null;
  }
}

export default withRouter(GAPageTracker);
