import PropTypes from 'prop-types';
import React from 'react';
import { Container, Ribbon, TextVariant1 } from './styles';

const Variant1 = ({ children, color }) => {
  return (
    <Ribbon>
      <Container color={color}>
        <TextVariant1>{children}</TextVariant1>
      </Container>
    </Ribbon>
  );
};

Variant1.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Ribbon text
   */
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * color scheme
   */
  color: PropTypes.oneOf(['primary', 'secondary'])
};

export default Variant1;
