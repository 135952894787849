import { setUrlParams } from '@lux/helpers';
import { api } from '../configs';
import { isLoading } from './index';
import { sendApiToGaEvent } from '../helpers';

const setGoogleRecaptchaKey = key => ({
  type: 'SET_GOOGLE_RECAPTCHA_KEY',
  key
});

const campaignStatusSuccess = campaign => ({
  type: 'SET_CAMPAIGN_STATUS_SUCCESS',
  campaign
});

const allCampaignDetailsSuccess = campaigns => ({
  type: 'SET_ALL_CAMPAIGNS_DETAILS_SUCCESS',
  campaigns
});

const campaignStatusError = error => ({
  type: 'SET_CAMPAIGN_STATUS_ERROR',
  error
});

export const setCampaignName = name => ({
  type: 'SET_CAMPAIGN_NAME',
  name
});

export const setCampaignContext = context => ({
  type: 'SET_CAMPAIGN_CONTEXT',
  context
});

export function getCampaignStatus(campaignName) {
  const url = setUrlParams(api.roi.campaignStatus, {
    name: campaignName,
    _: new Date().getTime()
  });

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url)
      .then(response => {
        sendApiToGaEvent('getCampaignStatus', response.status);
        let key = response.headers.get('g-recaptcha-sitekey');
        dispatch(setGoogleRecaptchaKey(key));
        return response;
      })
      .then(response => response.json())
      .then(response => {
        dispatch(isLoading(false));
        dispatch(campaignStatusSuccess(response));
      })
      .catch(() => dispatch(campaignStatusError(true)));
  };
}

export function getCcoCampaignDetails(campaignName, authToken) {
  const url = setUrlParams(api.cco.campaignStatus, {
    name: campaignName
  });

  const options = {
    method: 'GET',
    headers: {
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('getCcoCampaignDetails', response.status);

        if (response.status !== 200) {
          dispatch(isLoading(false));
          dispatch(campaignStatusError(true));
        }

        return response;
      })
      .then(response => response.json())
      .then(response => {
        dispatch(campaignStatusSuccess(response));
        dispatch(isLoading(false));
      })
      .catch(() => {});
  };
}

export function getAllCampaigns(authToken) {
  const url = api.thomasHarriot.campaign;

  const options = {
    method: 'GET',
    headers: {
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('getAllCampaigns', response.status);

        if (response.status !== 200) {
          dispatch(isLoading(false));
          dispatch(campaignStatusError(true));
        }

        return response;
      })
      .then(response => response.json())
      .then(response => {
        dispatch(allCampaignDetailsSuccess(response));
        dispatch(isLoading(false));
      })
      .catch(() => {});
  };
}

export function setCampaignDetails(campaign, authToken) {
  const url = `${api.thomasHarriot.campaign}/${campaign.id}`;

  const options = {
    method: 'PUT',
    headers: {
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(campaign),
    credentials: 'same-origin'
  };

  return dispatch => {
    dispatch(isLoading(true));

    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('setCampaignDetails', response.status);

        if (response.status !== 200) {
          dispatch(isLoading(false));
          return response;
        }

        dispatch(campaignStatusSuccess(campaign));
        dispatch(isLoading(false));
        return response;
      })
      .catch(() => {});
  };
}
