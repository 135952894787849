import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import getRandomId from '../../core/utils/getRandomId';
import { testIdProps } from '../../helpers';
import { Transition } from 'react-transition-group';
import { Sliding, SLIDING_DURATION } from './animation';
import { Row } from '../..';
import Column from '../Column';

import {
  AnimationWrapper,
  NoPaddingGrid,
  ToastComponent,
  IconWrapper,
  Title,
  Action
} from './styles';
import { useWindowSize } from '../../core/hooks/windowSize';

const Toast = ({
  visible,
  text,
  icon: Icon,
  autoDismiss = true,
  action,
  onClose,
  ...rest
}) => {
  const windowSize = useWindowSize();

  const toastRef = useRef(null);
  const [timer, setTimer] = useState(null);
  const [exited, setExited] = useState(true);

  if (typeof document === 'undefined') {
    return null;
  }

  const body = document.getElementsByTagName('body')[0];

  const toastNode = useMemo(() => {
    let node = document.querySelector('[id^="toast-root-"]');

    if (!node && visible) {
      node = document.createElement('div');
      node.setAttribute('id', `toast-root-${getRandomId()}`);
      node.setAttribute(
        'style',
        'position:fixed;left:0;top:0;width:100%;height:100%,pointer-event:none'
      );

      body.appendChild(node);
    }

    return node;
  }, [visible]);

  if (!visible && exited) return null;

  const toastElement = (
    <AnimationWrapper zIndex={1000}>
      <Transition
        timeout={SLIDING_DURATION}
        appear
        in={visible}
        onEnter={() => {
          setExited(false);
          autoDismiss &&
            setTimer(
              setTimeout(() => {
                onClose && onClose();
              }, 5000)
            );
        }}
        onExited={() => {
          setExited(true);
          timer && clearTimeout(timer);
          setTimer(null);
        }}
      >
        {state => (
          <NoPaddingGrid>
            <Row center="xs">
              <Column xs={12} sm={8} md={8} noGutter>
                <Sliding state={state} windowHeight={windowSize.height}>
                  <ToastComponent {...testIdProps(rest)} ref={toastRef}>
                    {Icon && (
                      <IconWrapper>
                        <Icon size={24} />
                      </IconWrapper>
                    )}

                    {text && <Title>{text}</Title>}
                    {action && (
                      <Action
                        data-testid="toast-action-id"
                        onClick={action.onPress}
                      >
                        {action.text}
                      </Action>
                    )}
                  </ToastComponent>
                </Sliding>
              </Column>
            </Row>
          </NoPaddingGrid>
        )}
      </Transition>
    </AnimationWrapper>
  );

  return createPortal(toastElement, toastNode);
};

Toast.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Modal z-index value
   */
  action: PropTypes.shape({
    onPress: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
  }),
  /**
   * if true toast will be dismissed automatically after 5 seconds
   * @default: true
   */
  autoDismiss: PropTypes.bool,
  /**
   * Icon component from DLS Assets
   */
  icon: PropTypes.node,
  /**
   * close event handler for auto dimiss after 5 seconds
   */
  onClose: PropTypes.func,
  /**
   * The toast's text message
   */
  text: PropTypes.string.isRequired,
  /**
   * Whether the toast is visible or not
   */
  visible: PropTypes.bool.isRequired
};

export default Toast;
