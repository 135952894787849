import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { render } from 'react-dom';
import { useLoadScript, GoogleMap } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spacing from '../Spacing';
import { useTheme, withTheme } from '../../core/ThemeProvider';
import useDevice from '../../core/hooks/useDevice';
import { getMapStyles, StyledMapContainer } from './styles';
import LocationDetailsCard from './LocationDetailsCard';
import Marker from './Marker';
import {
  getLocationsWithDistanceFromPoint,
  getUniqueCategories,
  getCalculatedMapHeight,
  getLocationIndex
} from './helpers';
import FilterModal from './FilterModal';
import SearchResults from './SearchResults';
import SearchInput from './SearchInput';
import {
  errorMessages,
  locationDetailsCardClassName,
  markerLabelClassName,
  responseStatus,
  singaporeCountryCode
} from './config';

const StyledContainer = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .${markerLabelClassName} {
    text-align: center;
    line-height: ${p => p.coreTheme.map.marker.labelLineHeight};
    letter-spacing: ${p => p.coreTheme.map.marker.labelLetterSpacing};
    width: 104px;
    white-space: normal;
    text-shadow: ${p => p.coreTheme.map.marker.labelTextShadow};
  }
`);

export const Map = ({
  locations = [],
  searchConfig,
  filterConfig,
  locationDetailsConfig,
  defaultCenterCoordinates,
  zoomConfig = {},
  onLocationSelected,
  googleMapsApiKey,
  showMapWithoutResults = true,
  searchRequiredError = '',
  onLocationSearchError = () => null,
  preSelection,
  showMarkersWithoutSearch = true
}) => {
  const {
    defaultZoom = 12,
    focusedAreaZoom = 15,
    labelHidingZoom = 14
  } = zoomConfig;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey
  });

  const { theme } = useTheme();
  const { isMobile } = useDevice();

  const [searchError, setSearchError] = useState(searchRequiredError);
  const [preLocationSelection, setPreLocationSelection] = useState(
    preSelection
  );

  const [searchedTerm, setSearchedTerm] = useState('');
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [mapHeight, setMapHeight] = useState(100);
  const [zoom, setZoom] = useState(defaultZoom);
  const [categories, setCategories] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [markersData, setMarkersData] = useState(locations);
  const [showMapContainer, setShowMapContainer] = useState(true);

  const mapContainerRef = useRef();
  const mapRef = useRef();
  const geocoderRef = useRef(null);

  const setMapHeightValue = isLoaded => {
    const widthRatio = 16;
    const heightRatio = 9;
    const showMapContainerValue = !!searchedLocation || showMapWithoutResults;
    setShowMapContainer(showMapContainerValue);

    if (isLoaded && mapContainerRef.current) {
      const mapWidth = mapContainerRef.current.offsetWidth;
      const mapHeight = showMapContainerValue
        ? getCalculatedMapHeight(mapWidth, widthRatio, heightRatio)
        : 0;
      setMapHeight(mapHeight);
    }
  };

  useEffect(() => {
    setMapHeightValue(isLoaded);

    const handleResize = () => {
      setMapHeightValue(isLoaded);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoaded, searchedLocation]);

  const mapOptions = useMemo(
    () => ({
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: !isMobile,
      styles: getMapStyles(theme)
    }),

    [isMobile, theme]
  );

  const mapContainerStyle = useMemo(
    () => ({
      height: `${mapHeight}px`
    }),

    [mapHeight]
  );

  const onLoad = useCallback(map => {
    mapRef.current = map;
    geocoderRef.current = new window.google.maps.Geocoder();

    const locationDetailsCardElem = document.createElement('div');
    locationDetailsCardElem.setAttribute('class', locationDetailsCardClassName);
    mapRef.current.getDiv().firstChild.appendChild(locationDetailsCardElem);

    if (geocoderRef.current && preLocationSelection) {
      onLocationSearch(preLocationSelection.searchTerm);
    }
  }, []);

  const onMapZoomChanged = useCallback(() => {
    if (mapRef.current && mapRef.current.zoom) {
      setZoom(mapRef.current.zoom);
    }
  }, []);

  const onMapBoundsChanged = useCallback(() => {
    const mapFirstChild = mapRef.current.getDiv().firstChild;
    const locationDetailsCard = mapRef.current
      .getDiv()
      .firstChild.getElementsByClassName(locationDetailsCardClassName)[0];

    if (
      mapFirstChild.clientHeight === window.innerHeight &&
      mapFirstChild.clientWidth === window.innerWidth
    ) {
      locationDetailsCard.style.display = 'block';
    } else {
      locationDetailsCard.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    const markers = locations.map(point => ({
      ...point,
      isSelected: false
    }));

    setMarkersData(markers);
  }, [locations]);

  useEffect(() => {
    setShowMapContainer(!!searchedLocation || showMapWithoutResults);
  }, [searchedLocation, showMapWithoutResults]);

  useEffect(() => {
    onLocationSearchError(searchError);
  }, [searchError]);

  useEffect(() => {
    setSearchError(searchRequiredError);
  }, [searchRequiredError]);

  const handleMarkerSelection = (
    markersDataList = [],
    index,
    searchTerm = ''
  ) => {
    const markers = markersDataList.map((markerData, i) => ({
      ...markerData,
      isSelected: i === index
    }));

    setMarkersData(markers);

    const selectedMarker = markers[index];

    mapRef.current.panTo({
      lng: selectedMarker.lng,
      lat: selectedMarker.lat
    });

    if (zoom < focusedAreaZoom) {
      setZoom(focusedAreaZoom);
    }

    render(
      <LocationDetailsCard
        name={selectedMarker.name}
        descriptionLiner={selectedMarker.descriptionLiner}
        subDescriptionLiner={selectedMarker.subDescriptionLiner}
        postalCode={selectedMarker.postalCode}
        directionsLinkText={
          locationDetailsConfig && locationDetailsConfig.directionsLinkText
        }
      />,

      mapRef.current
        .getDiv()
        .firstChild.getElementsByClassName(locationDetailsCardClassName)[0]
    );

    if (typeof onLocationSelected === 'function') {
      onLocationSelected({
        ...selectedMarker,
        searchTerm: searchTerm || searchedTerm
      });
    }
  };

  const resetMarkerSelection = () => {
    const markers = locations.map(point => ({
      ...point,
      isSelected: false
    }));

    setMarkersData(markers);
  };

  const onLocationSearch = searchTerm => {
    setSearchedLocation(null);
    if (searchTerm.length != 6) {
      setSearchError(
        searchConfig.invalidPostalCodeLengthError ||
          errorMessages.invalidPostalCodeLength
      );

      resetMarkerSelection();
      return;
    }
    setSearchedTerm(searchTerm);

    geocoderRef.current.geocode(
      {
        componentRestrictions: {
          country: singaporeCountryCode,
          postalCode: searchTerm
        }
      },

      (results, status) => {
        if (status == responseStatus.OK) {
          const { lat, lng } = results[0].geometry.location;
          const searchedPosition = { lat: lat(), lng: lng() };

          const locationsWithDistance = getLocationsWithDistanceFromPoint(
            locations,
            searchedPosition,
            searchConfig.searchRadius
          );

          if (locationsWithDistance && locationsWithDistance.length > 0) {
            mapRef.current.panTo({
              lng: locationsWithDistance[0].lng,
              lat: locationsWithDistance[0].lat
            });
          }
          const locationIndex = getLocationIndex(
            preLocationSelection,
            locationsWithDistance
          );

          handleMarkerSelection(
            locationsWithDistance,
            locationIndex,
            searchTerm
          );

          const uniqueCategories = getUniqueCategories(locationsWithDistance);

          const categories = [];
          uniqueCategories.forEach(uniqueCategory => {
            categories.push({ ...uniqueCategory, selected: true });
          });
          setCategories(categories);

          setSearchError('');
          setSearchedLocation(searchedPosition);
        } else {
          setSearchError(
            searchConfig.invalidPostalCodeError ||
              errorMessages.invalidPostalCode
          );

          resetMarkerSelection();
        }

        setPreLocationSelection(null);
      }
    );
  };

  const onFilterClick = () => {
    setShowFilterModal(true);
  };

  const onFilterModalClose = () => {
    setShowFilterModal(false);
  };

  const onFiltersUpdated = filterOptions => {
    setCategories(filterOptions);

    const selectedFilters = filterOptions
      .filter(opt => opt.selected)
      .map(opt => opt.value);

    const locationsWithDistance = getLocationsWithDistanceFromPoint(
      locations,
      searchedLocation,
      searchConfig.searchRadius
    );

    const filteredLocations = locationsWithDistance.filter(location => {
      if (location.categories) {
        const locationCategories = location.categories.map(cat => cat.value);
        const matchingCategories = locationCategories.filter(item =>
          selectedFilters.includes(item)
        );

        return matchingCategories.length > 0;
      }
      return true;
    });
    handleMarkerSelection(filteredLocations, 0);

    mapRef.current.panTo({
      lng: filteredLocations[0].lng,
      lat: filteredLocations[0].lat
    });

    setShowFilterModal(false);
  };

  const renderSearchResultsHeading = () => {
    const selectedFilters = categories
      .filter(opt => opt.selected)
      .map(opt => opt.name);

    return (
      typeof searchConfig.renderSearchResultsHeading === 'function' &&
      searchConfig.renderSearchResultsHeading(
        markersData.length,
        selectedFilters,
        searchedTerm
      )
    );
  };

  if (!isLoaded) return <div>Loading...</div>;

  const showMarkersOnLoad =
    showMarkersWithoutSearch || (!showMarkersWithoutSearch && !!searchedTerm);

  return (
    <>
      <StyledContainer>
        {searchConfig && (
          <Spacing bottom={2} responsive={false}>
            <SearchInput
              preSearchTerm={preSelection?.searchTerm}
              label={searchConfig.searchInputLabel}
              errorMessage={searchError}
              onSearch={searchTerm => {
                onLocationSearch(searchTerm);
              }}
            />
          </Spacing>
        )}

        <StyledMapContainer show={showMapContainer} ref={mapContainerRef}>
          <GoogleMap
            zoom={zoom}
            mapContainerStyle={mapContainerStyle}
            options={mapOptions}
            onLoad={onLoad}
            onZoomChanged={onMapZoomChanged}
            onBoundsChanged={onMapBoundsChanged}
            center={defaultCenterCoordinates}
          >
            {showMarkersOnLoad && (
              <>
                {markersData.map((markerData, index) => (
                  <Marker
                    markerData={markerData}
                    key={index}
                    onClick={() => {
                      handleMarkerSelection(markersData, index);
                    }}
                    showLabel={zoom >= labelHidingZoom}
                    markerLabelClassName={markerLabelClassName}
                  />
                ))}
              </>
            )}
          </GoogleMap>
        </StyledMapContainer>

        {showFilterModal && (
          <FilterModal
            filters={categories}
            title={filterConfig.modalTitle}
            buttonLabel={filterConfig.modalButtonLabel}
            onFilterModalClose={onFilterModalClose}
            onFiltersUpdated={onFiltersUpdated}
          />
        )}

        {searchedLocation && (
          <SearchResults
            showFilter={!!filterConfig}
            onFilterClick={onFilterClick}
            locations={markersData}
            onLocationSelected={index => {
              handleMarkerSelection(markersData, index);
            }}
            renderHeading={renderSearchResultsHeading}
            viewMoreLabel={searchConfig.viewMoreResultsLabel}
            viewLessLabel={searchConfig.viewLessResultsLabel}
            initialSearchResultCount={searchConfig.initialSearchResultCount}
          />
        )}
      </StyledContainer>
    </>
  );
};

Map.defaultProps = {};

Map.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Map will be centered to this coordinate value at the initial map load
   */
  defaultCenterCoordinates: PropTypes.shape({
    /**
     * Latitude
     */
    lat: PropTypes.number.isRequired,
    /**
     * Longitude
     */
    lng: PropTypes.number.isRequired
  }).isRequired,
  /**
   * Config options to control the filter behaviour of searched locations. If this property was
   * not defined, it will not render the filter icon
   */
  filterConfig: PropTypes.shape({
    /**
     * Button label text to display in filter modal
     */
    modalButtonLabel: PropTypes.string.isRequired,
    /**
     * Title to display in filter modal
     */
    modalTitle: PropTypes.string.isRequired
  }),
  /**
   * Api Key of Google Maps API
   */
  googleMapsApiKey: PropTypes.string.isRequired,
  /**
   * Config options to control the behaviour of location details card
   */
  locationDetailsConfig: PropTypes.shape({
    /**
     * Text label for directions link in Location details card component
     */
    directionsLinkText: PropTypes.string
  }),
  /**
   * List of locations
   */
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * List of categories which location is part of
       */
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * Category name
           */
          name: PropTypes.string.isRequired,
          /**
           * Unique value to differentiate the category
           */
          value: PropTypes.string.isRequired
        })
      ),
      /**
       * Description liner to be displayed in the location details card
       */
      descriptionLiner: PropTypes.string.isRequired,
      /**
       * Location latitude
       */
      lat: PropTypes.number.isRequired,
      /**
       * Location longitude
       */
      lng: PropTypes.number.isRequired,
      /**
       * Location name
       */
      name: PropTypes.string.isRequired,
      /**
       * Location postal code
       */
      postalCode: PropTypes.string.isRequired,
      /**
       * Sub description liner to be displayed in the location details card
       */
      subDescriptionLiner: PropTypes.string
    })
  ).isRequired,
  /**
   * Handler to get notified when there is search error
   */
  onLocationSearchError: PropTypes.func.isRequired,
  /**
   * Handler to get notified when location is selected
   */
  onLocationSelected: PropTypes.func.isRequired,
  /**
   * Pre selection parameter to fill the postal code and pre-select the specified store
   */
  preSelection: PropTypes.shape({
    /**
     * Postal code/Search Term
     */
    searchTerm: PropTypes.string.isRequired,
    /**
     * Store location consists on lat and lng
     */
    selectedLocation: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    }).isRequired
  }),
  /**
   * Config options to control the search behaviour of the map. If this property was
   * not defined, it will not render the search input
   */
  searchConfig: PropTypes.shape({
    /**
     * Define how many search results to be displayed after the search. If number of results
     * is more than this count, it will display a 'View more' button.
     */
    initialSearchResultCount: PropTypes.number.isRequired,
    /**
     * Error message to be displayed when user searches for a invalid postal code
     */
    invalidPostalCodeError: PropTypes.string.isRequired,
    /**
     * Error message to be displayed when user searches for a invalid length of postal code
     */
    invalidPostalCodeLengthError: PropTypes.string.isRequired,
    /**
     * Render prop to display the search results heading text. number of search results and
     * selected filter names will be passed to the render prop function
     */
    renderSearchResultsHeading: PropTypes.func.isRequired,
    /**
     * Search input label text
     */
    searchInputLabel: PropTypes.string.isRequired,
    /**
     * Define search ranges in kms of array
     */
    searchRadius: PropTypes.arrayOf(PropTypes.number),
    /**
     * View less search results label text
     */
    viewLessResultsLabel: PropTypes.string.isRequired,
    /**
     * View more search results label text
     */
    viewMoreResultsLabel: PropTypes.string.isRequired
  }),
  /**
   * Config error message for validation
   */
  searchRequiredError: PropTypes.string,
  /**
   * Config true or false to toggle the Map display until search results are available
   */
  showMapWithoutResults: PropTypes.bool,
  /**
   * Config true or false to toggle the Markers display until search results are available
   */
  showMarkersWithoutSearch: PropTypes.bool,
  /**
   * Config options to control the zoom within the map
   */
  zoomConfig: PropTypes.shape({
    /**
     * Map will be zoomed to this provided default zoom value
     */
    defaultZoom: PropTypes.number,
    /**
     * Map will be zoomed to this provided zoom value once the marker is selected
     */
    focusedAreaZoom: PropTypes.number,
    /**
     * Marker label would be hidden when the map zoom value go below this provided value
     */
    labelHidingZoom: PropTypes.number
  })
};

export default Map;
