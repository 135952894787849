import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../core/ThemeProvider';
import {
  Container,
  CouponCover,
  CouponBody,
  Tag,
  TagText,
  Title,
  SubTitle,
  ValueWrapper,
  Value,
  CutOut
} from './VoucherListView.style';

const TITLE_MAX_LINES = {
  TITLE_ONLY: 3,
  OTHER: 2
};

const VoucherListView = ({
  tag,
  tagText,
  cover,
  title,
  status,
  statusText,
  value,
  valueIcon,
  disabled,
  curveStyle
}) => {
  const { theme } = useTheme();

  const titleMaxLines = useMemo(() => {
    return statusText !== undefined || value !== undefined
      ? TITLE_MAX_LINES.OTHER
      : TITLE_MAX_LINES.TITLE_ONLY;
  }, [statusText, value]);

  const TAG_COLOR = useMemo(() => {
    return {
      primary: theme.cl_pri_d2,
      secondary: theme.cl_sec_d2
    };
  }, []);

  return (
    <Container curveStyle={curveStyle} disabled={disabled}>
      <CouponCover>
        {cover}
        {tagText && (
          <Tag backgroundColor={TAG_COLOR[tag]}>
            <TagText>{tagText}</TagText>
          </Tag>
        )}
      </CouponCover>

      <CouponBody>
        {/* this wrapper is to fix the spacing from overflow:hidden */}
        <div style={{ paddingBottom: 0 }}>
          <Title maxLines={titleMaxLines}>{title}</Title>
        </div>

        {statusText && (
          <SubTitle expiring={status === 'expiring'}>{statusText}</SubTitle>
        )}

        {value && (
          <ValueWrapper>
            {valueIcon}
            <Value>{value}</Value>
          </ValueWrapper>
        )}
      </CouponBody>
      <CutOut backgroundColor={curveStyle?.backgroundColor} />
    </Container>
  );
};

VoucherListView.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * voucher cover
   */
  cover: PropTypes.any.isRequired,
  /**
   * curve css styles
   */
  curveStyle: PropTypes.object,
  /**
   * if disabled
   */
  disabled: PropTypes.bool,
  /**
   * voucher status / type
   */
  status: PropTypes.oneOf(['default', 'expiring']),
  /**
   * subtitle in body
   */
  statusText: PropTypes.string,
  /**
   * tag color
   */
  tag: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * text in tag
   */
  tagText: PropTypes.string,
  /**
   * title in body
   */
  title: PropTypes.string.isRequired,
  /**
   * value text
   */
  value: PropTypes.string,
  /**
   * value icon
   */
  valueIcon: PropTypes.any
};

export default VoucherListView;
