import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { remCalc } from '@lux/helpers';
import { responsiveStyles } from '../../core/utils';

const SPACER = 8;
const spacing = size =>
  typeof size === 'string' ? size : remCalc(SPACER * size);

const getResponsivePadding = (
  coreTheme,
  isResponsive,
  type = 'padding',
  value,
  position
) => {
  const desktopValue =
    typeof value === 'number' && value > 0
      ? isResponsive
        ? value + 1
        : value
      : value;
  return {
    ...responsiveStyles(
      {
        [`${type}${position}`]: [
          spacing(value),
          spacing(value),
          spacing(desktopValue),
          spacing(desktopValue),
          spacing(desktopValue)
        ]
      },
      coreTheme
    )
  };
};
export const SpacingStyle = withTheme(styled.div`
  ${p => css`
    ${getResponsivePadding(p.coreTheme, p.responsive, p.type, p.top, 'Top')};
    ${getResponsivePadding(
      p.coreTheme,
      p.responsive,
      p.type,
      p.right,
      'Right'
    )};
    ${getResponsivePadding(
      p.coreTheme,
      p.responsive,
      p.type,
      p.bottom,
      'Bottom'
    )};
    ${getResponsivePadding(p.coreTheme, p.responsive, p.type, p.left, 'Left')};
  `};
`);
