import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { BackArrow as BackArrowIcon } from '@dls/assets/icons';
import Button from '../Button';
import TextLink from '../TextLink';
import { withTheme } from '../../core/ThemeProvider';
import { noop } from '../../helpers';
import useDevice from '../../core/hooks/useDevice';
import { getStyledText } from '../../helpers/styled';
import Config from '../../config';

const DatePickerCalendarContainer = ({
  children,
  onConfirm,
  onClear,
  goBack,
  selectedDateText
}) => {
  const { isMobile } = useDevice();

  return (
    <>
      {isMobile && (
        <CalendarHeader>
          <HeaderAction>
            <TextLink onClick={goBack}>
              <TextLink.Icon icon={BackArrowIcon}></TextLink.Icon>
            </TextLink>
            <TextLink onClick={onClear}>
              <TextLink.Text>Clear</TextLink.Text>
            </TextLink>
          </HeaderAction>
          <DaysHeader>
            <WeekDay>S</WeekDay>
            <WeekDay>M</WeekDay>
            <WeekDay>T</WeekDay>
            <WeekDay>W</WeekDay>
            <WeekDay>T</WeekDay>
            <WeekDay>F</WeekDay>
            <WeekDay>S</WeekDay>
          </DaysHeader>
        </CalendarHeader>
      )}
      <CalendarContainer>{children}</CalendarContainer>
      <CalendarActionContainer>
        {!isMobile && (
          <ButtonGroupBigScreen>
            <Button
              text={'Confirm'}
              onClick={onConfirm}
              data-testid="datePickerActionBtn-Confirm"
            />
            <ClearButton
              onClick={onClear}
              data-testid="datePickerActionBtn-Clear"
            >
              <TextLink>Clear</TextLink>
            </ClearButton>
          </ButtonGroupBigScreen>
        )}
        {isMobile && (
          <ButtonGroupMobileView>
            <DateText>{selectedDateText || 'Select a date'}</DateText>
            <Button
              text={'Confirm'}
              onClick={onConfirm}
              data-testid="datePickerActionBtn-Confirm"
              fullWidth
            />
          </ButtonGroupMobileView>
        )}
      </CalendarActionContainer>
    </>
  );
};

const CalendarHeader = withTheme(styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
`);

const HeaderAction = withTheme(styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 ${p => p.coreTheme.space_6}px;
`);

const DaysHeader = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      background-color: ${coreTheme.cl_ter_l6};
      display: flex;
      align-items: center;
      padding: ${coreTheme.space_4}px ${coreTheme.space_6}px;
    `
  )
);

const WeekDay = withTheme(
  getStyledText('boldBody', { lineHeight: 'compact' })`
    margin: 0 auto;
    color: ${p => p.coreTheme.cl_sec_d2};
    font-size: ${p => p.coreTheme.fs_sm};
  `
);

const CalendarContainer = withTheme(styled.div`
  margin-top: 110px;
  height: calc(100vh - 230px);
  overflow: auto;

  ${Config.media.sm`
    margin-top: unset;
    height: unset;
    overflow: unset;
  `}
`);

const CalendarActionContainer = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      position: fixed;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100vw;
      box-shadow: 17px 2px 12px rgba(0, 0, 0, 0.14);

      ${Config.media.sm`
        border-top: 1px solid ${coreTheme.cl_ter_l4};
        position: unset;
        width: unset;
        box-shadow: unset;
      `}
    `
  )
);

const ClearButton = withTheme(styled.div`
  ${({ coreTheme }) => css`
    padding: ${coreTheme.space_2}px ${coreTheme.space_5}px;
    margin-right: ${coreTheme.space_5}px;
    display: flex;
    align-items: center;
  `}
`);

const ButtonGroupBigScreen = withTheme(styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: ${p => p.coreTheme.space_4}px;
`);

const ButtonGroupMobileView = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      height: 120px;
      box-sizing: border-box;
      padding: ${coreTheme.space_4}px ${coreTheme.space_6}px
        ${coreTheme.space_8}px;
      background-color: ${coreTheme.cl_white};
    `
  )
);

const DateText = withTheme(getStyledText('body', { lineHeight: 'compact' })`
  margin-bottom: ${p => p.coreTheme.space_2}px;
`);

DatePickerCalendarContainer.defaultProps = {
  onConfirm: noop,
  onClear: noop,
  goBack: noop
};

DatePickerCalendarContainer.propTypes = {
  selectedDateText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClear: PropTypes.func,
  goBack: PropTypes.func
};

export default DatePickerCalendarContainer;
