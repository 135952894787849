import React, { createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FilterIcon from '@dls/assets/icons/Filter';
import { ArrowUp, ArrowDown } from '@dls/assets/icons';
import { useTheme } from '../../../core/ThemeProvider';
import Text from '../../Text';
import Spacing from '../../Spacing';
import Selector from '../../Selector';
import TextLink from '../../TextLink';
import { getDistanceInKmFormattedValue } from '../helpers';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  StyledArrowIconContainer,
  StyledFilterContainer,
  StyledFilterIconContainer,
  StyledSearchResultsHeading,
  StyledViewMoreLessLinkContainer,
  StyledInitialResultSelectorContainer
} from './styles';
import { slideAnimationDurationInMillis } from '../config';
import {
  getLocationSelectorsTransitionStartYPosition,
  getViewLessLinkTransitionStartYPosition
} from './helpers';
import { SlideAnimation } from './animation';

const SearchResults = ({
  renderHeading,
  showFilter,
  onFilterClick,
  locations,
  onLocationSelected,
  viewMoreLabel,
  viewLessLabel,
  initialSearchResultCount
}) => {
  const { theme } = useTheme();

  const [showMoreLocations, setShowMoreLocations] = useState(false);
  const [showViewMoreLabel, setShowViewMoreLabel] = useState(true);
  const [locationsList, setLocationsList] = useState([]);
  const viewLessLinkNodeRef = useRef(null);

  const moreLocationsAvailable = locations.length > initialSearchResultCount;

  useEffect(() => {
    const resultSet = locations.map(location => {
      const ref = createRef();
      return { ...location, nodeRef: ref };
    });
    setLocationsList(resultSet);
  }, [locations]);

  const renderLocationSelector = (location, index) => {
    const formattedDistance =
      ` (${getDistanceInKmFormattedValue(location.distance, 'KM')})` || '';

    const title = `${location.name}${formattedDistance}`;

    return (
      <Spacing top={2} responsive={false} key={location.name} index={index}>
        <Selector
          alignment="row"
          selected={location.isSelected}
          onClick={() => {
            onLocationSelected(index);
          }}
          data-testid="location-selector"
        >
          <Selector.Body title={title} align={'start'}>
            {location.descriptionLiner && (
              <Text type="smallBody" color={theme.cl_ter_l2}>
                {location.descriptionLiner}
              </Text>
            )}
          </Selector.Body>
        </Selector>
      </Spacing>
    );
  };

  const renderInitialResultSet = () => {
    return locationsList
      .slice(0, initialSearchResultCount)
      .map((location, index) => {
        return (
          <StyledInitialResultSelectorContainer
            key={location.name}
            ref={location.nodeRef}
          >
            {renderLocationSelector(location, index)}
          </StyledInitialResultSelectorContainer>
        );
      });
  };

  const getMoreLocationSelectorsTransitions = (
    moreLocations,
    animationDuration
  ) => {
    return moreLocations.map((location, index) => {
      return (
        <CSSTransition
          timeout={animationDuration}
          key={location.name}
          nodeRef={location.nodeRef}
        >
          {transitionState => {
            const translateYPos = getLocationSelectorsTransitionStartYPosition(
              locationsList,
              location.nodeRef,
              initialSearchResultCount
            );

            return (
              <SlideAnimation
                transitionState={transitionState}
                animationDuration={animationDuration}
                translateYPos={translateYPos}
                ref={location.nodeRef}
              >
                {renderLocationSelector(
                  location,
                  index + initialSearchResultCount
                )}
              </SlideAnimation>
            );
          }}
        </CSSTransition>
      );
    });
  };

  const getViewLessLinkTransition = animationDuration => {
    return (
      <CSSTransition
        timeout={animationDuration}
        key={'viewLessLink'}
        onExited={() => {
          setShowViewMoreLabel(true);
        }}
        nodeRef={viewLessLinkNodeRef}
      >
        {transitionState => {
          const translateYPos = getViewLessLinkTransitionStartYPosition(
            locationsList,
            viewLessLinkNodeRef,
            initialSearchResultCount
          );

          return (
            <SlideAnimation
              transitionState={transitionState}
              animationDuration={animationDuration}
              translateYPos={translateYPos}
              ref={viewLessLinkNodeRef}
            >
              <StyledViewMoreLessLinkContainer
                onClick={() => {
                  setShowMoreLocations(false);
                }}
              >
                <TextLink data-testid="view-less-label">
                  {viewLessLabel}
                </TextLink>
                <StyledArrowIconContainer>
                  <ArrowUp
                    color={theme.cl_sec_d1}
                    size={16}
                    data-testid="arrow-up"
                  />
                </StyledArrowIconContainer>
              </StyledViewMoreLessLinkContainer>
            </SlideAnimation>
          );
        }}
      </CSSTransition>
    );
  };

  const renderViewMoreResultSet = () => {
    let transitionsList = [];

    if (showMoreLocations) {
      const moreLocations = locationsList.slice(initialSearchResultCount);

      transitionsList = getMoreLocationSelectorsTransitions(
        moreLocations,
        slideAnimationDurationInMillis
      );

      if (moreLocationsAvailable) {
        const viewLessLinkTransition = getViewLessLinkTransition(
          slideAnimationDurationInMillis
        );

        transitionsList.push(viewLessLinkTransition);
      }
    }
    return <TransitionGroup appear>{transitionsList}</TransitionGroup>;
  };

  return (
    <>
      <Spacing top={2} responsive={false}>
        <StyledFilterContainer>
          <StyledSearchResultsHeading
            type="boldBody"
            data-testid="search-results-heading"
          >
            {typeof renderHeading === 'function' && renderHeading()}
          </StyledSearchResultsHeading>
          {showFilter && (
            <StyledFilterIconContainer
              onClick={onFilterClick}
              data-testid="filter-icon"
            >
              <FilterIcon color={theme.cl_sec_d1} size={24} />
            </StyledFilterIconContainer>
          )}
        </StyledFilterContainer>
      </Spacing>

      {renderInitialResultSet()}

      {renderViewMoreResultSet()}

      {moreLocationsAvailable && showViewMoreLabel && (
        <StyledViewMoreLessLinkContainer
          onClick={() => {
            setShowMoreLocations(true);
            setShowViewMoreLabel(false);
          }}
        >
          <TextLink data-testid="view-more-label">{viewMoreLabel}</TextLink>
          <StyledArrowIconContainer>
            <ArrowDown
              color={theme.cl_sec_d1}
              size={16}
              data-testid="arrow-down"
            />
          </StyledArrowIconContainer>
        </StyledViewMoreLessLinkContainer>
      )}
    </>
  );
};

SearchResults.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Number of search results initially to be displayed after the search.
   */
  initialSearchResultCount: PropTypes.number.isRequired,
  /**
   * List of locations
   */
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * List of categories which location is part of
       */
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * Category name
           */
          name: PropTypes.string.isRequired,
          /**
           * Unique value to differentiate the category
           */
          value: PropTypes.string.isRequired
        })
      ),
      /**
       * Description liner to be displayed in the location details card
       */
      descriptionLiner: PropTypes.string.isRequired,
      /**
       * Location latitude
       */
      lat: PropTypes.number.isRequired,
      /**
       * Location longitude
       */
      lng: PropTypes.number.isRequired,
      /**
       * Location name
       */
      name: PropTypes.string.isRequired,
      /**
       * Location postal code
       */
      postalCode: PropTypes.string.isRequired,
      /**
       * Sub description liner to be displayed in the location details card
       */
      subDescriptionLiner: PropTypes.string
    })
  ).isRequired,
  /**
   * Handler to get notified when clicked on the filter icon
   */
  onFilterClick: PropTypes.func.isRequired,
  /**
   * Handler to get notified when selected a location result. Handler
   * will be invoked with selected result index
   */
  onLocationSelected: PropTypes.func.isRequired,
  /**
   * Render prop to display the search results heading text.
   */
  renderHeading: PropTypes.func.isRequired,
  /**
   * Show filter icon
   */
  showFilter: PropTypes.bool.isRequired,
  /**
   * View less search results label text
   */
  viewLessLabel: PropTypes.string.isRequired,
  /**
   * View more search results label text
   */
  viewMoreLabel: PropTypes.string.isRequired
};

export default SearchResults;
