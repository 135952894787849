import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { getStyledText } from '../../helpers/styled';

export const CardContainer = withTheme(
  styled.div(
    ({ coreTheme, shadow, radius, padding, autoHeight }) => css`
      font-family: ${coreTheme.ff_sec_regular};
      position: relative;
      background-color: ${coreTheme.cl_white};
      overflow: hidden;
      box-shadow: ${shadow};
      border-radius: ${radius};
      display: inline-block;
      width: 100%;
      height: ${autoHeight ? 'auto' : '100%'};

      ${/* sc-selector */ Content} {
        padding: ${padding};
      }
    `
  )
);

export const Content = styled.div`
  width: auto;
`;

const ratios = { '3:2': '66.6%', '16:9': '56.25%' };

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${p => ratios[p.ratio || '16:9']};
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const TagWrapper = withTheme(styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${p => p.coreTheme.cl_pri_base};
  opacity: 0.7;
  padding: ${p => p.coreTheme.space_1}px ${p => p.coreTheme.space_4}px;
`);

export const Tag = withTheme(getStyledText('smallBody', {
  lineHeight: 'compact'
})`
  font-weight: bold;
  color: ${p => p.coreTheme.cl_white};
`);

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const Thumbnail = withTheme(styled.img`
  height: 72px;
  width: 72px;
  border-radius: ${p => p.coreTheme.rad_xs};
  margin-right: ${p => p.coreTheme.space_4}px;

  ${p =>
    p.showThumbnailBorder &&
    css`
      border: 1px solid ${p.coreTheme.cl_ter_l5};
    `}
`);

export const getTitle = isHeavy =>
  withTheme(getStyledText('header')`
    line-height: 27px;
    ${!isHeavy &&
      css`
        font-family: ${p => p.coreTheme.ff_sec_regular};
      `}
  `);

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: top;
`;

export const IconWrapper = styled.div`
  padding-top: 3px;
`;

export const Info = getStyledText('body', { lineHeight: 'compact' })`
  padding-left: 8px;
`;

export const BodyWrapper = styled.div`
  width: 100%;
`;

export const BodyText = getStyledText('body', { lineHeight: 'compact' })``;

export const ViewMoreWrapper = withTheme(styled.div`
  padding-top: ${p => p.coreTheme.space_1}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`);

export const ViewMoreText = withTheme(getStyledText('link', {
  lineHeight: 'compact'
})`
  padding-right: ${p => p.coreTheme.space_2}px;
`);

export const FooterWapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const FooterText = withTheme(getStyledText('body', {
  lineHeight: 'compact'
})`
  margin-right: ${p => p.coreTheme.space_4}px;
`);

export const FooterButton = styled.div`
  align-self: flex-end;
`;
