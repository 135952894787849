import React from 'react';
import { InnerCircle, OuterCircle, Slash } from './style';

const SelectorColor = ({ hexCode, slash }) => (
  <OuterCircle>
    <InnerCircle hexCode={hexCode}>
      {slash && <Slash filledColor={hexCode} />}
    </InnerCircle>
  </OuterCircle>
);

export default SelectorColor;
