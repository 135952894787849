import React from 'react';
import styled, { css } from 'styled-components';
import { useTheme, withTheme } from '../../core/ThemeProvider';
import { testIdProps } from '../../helpers';
import NoGutterYGrid from './NoGutterYGrid';
import PropTypes from 'prop-types';
import Text from '../Text';
import Button from '../Button';
import TextLink from '../TextLink';
import Row from '../Row';
import Column from '../Column';
import {
  ActionTypes,
  HorizontalPaddingTypes,
  NotificationTypes
} from './types';
import Inline from '../Inline';
import config from '../../config';

export const testIds = {
  action: 'notificationAction'
};

const Notification = ({
  type,
  title,
  icon,
  content,
  fullWidth,
  horizontalPadding,
  action = {},
  ...rest
}) => {
  const { theme } = useTheme();
  const paddingX = fullWidth ? 0 : getPaddingX(horizontalPadding, theme);
  const paddingY = theme.space_4;
  const hasContent = !!content;

  const renderContent = () => (
    <>
      {title && (
        <Text type="boldBody" lineHeight="compact">
          {title}
        </Text>
      )}

      {content && (
        <Text
          type="smallBody"
          css={{ marginTop: theme.space_1 }}
          lineHeight="compact"
        >
          {content}
        </Text>
      )}
    </>
  );

  return (
    <NotificationContainer
      type={type}
      paddingX={paddingX}
      paddingY={paddingY}
      fullWidth={fullWidth}
      {...testIdProps(rest)}
    >
      {title && icon && <TitleIcon data-testid="title-icon">{icon}</TitleIcon>}

      {fullWidth &&
      type !== NotificationTypes.promo &&
      action.type === ActionTypes.button ? (
        <StyledInline align="spaceBetween" alignY="center">
          <div>{renderContent()}</div>
          {action.type === ActionTypes.button && (
            <ActionContainer type={action.type} noMargin>
              {renderAction(type, action, hasContent)}
            </ActionContainer>
          )}
        </StyledInline>
      ) : (
        <div style={{ flex: 1 }}>
          {renderContent()}
          {action && action.text && (
            <ActionContainer type={action.type}>
              {renderAction(type, action, hasContent)}
            </ActionContainer>
          )}
        </div>
      )}
    </NotificationContainer>
  );
};

const renderAction = (
  notificationType,
  { type = ActionTypes.link, onClick, text },
  useSmallBody
) => {
  let _type = type;
  if (
    notificationType === NotificationTypes.promo &&
    type === ActionTypes.button
  ) {
    console.warn(
      'notification type="promo" can only show actionable items as link only. action.type="link"'
    );

    _type = ActionTypes.link;
  }

  switch (_type) {
    case ActionTypes.button:
      return (
        <Button onClick={onClick} data-testid={testIds.action}>
          {text}
        </Button>
      );

    case ActionTypes.link:
      return (
        <Text type={useSmallBody ? 'smallBody' : 'body'} lineHeight="compact">
          <TextLink onClick={onClick} data-testid={testIds.action}>
            {text}
          </TextLink>
        </Text>
      );
  }
};
Notification.defaultProps = {
  type: NotificationTypes.info,
  horizontalPadding: 'md',
  fullWidth: false
};

Notification.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Show action button / text link.
   * Only works if title or content or both title & content are available.
   */
  action: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
    type: PropTypes.oneOf(['button', 'link'])
  }),
  /**
   * main content of the notification
   */
  content: PropTypes.string,
  /**
   * flag when set to true, makes the notification box full width,
   * and removes the horizontal padding.'
   * The content follows the grid system
   */
  fullWidth: PropTypes.bool,
  /**
   * Only work if fullWidth = false. </br>
   * Horizontal padding is configurable for Notifications which are not full-width.
   */
  horizontalPadding: PropTypes.oneOf(['lg', 'md', 'xl']),
  /**
   * title text.
   */
  title: PropTypes.string,
  /**
   * title icon.
   */
  icon: PropTypes.node,
  /**
   * Notification type, look & feel.
   */
  type: PropTypes.oneOf(['alert', 'info', 'promo'])
};

export default Notification;

const notificationBg = {
  [NotificationTypes.alert]: 'cl_pri_l3',
  [NotificationTypes.info]: 'cl_ter_l6',
  [NotificationTypes.promo]: 'cl_sec_l2'
};

const getPaddingX = (padding, theme) => {
  return theme[HorizontalPaddingTypes[padding]];
};

const NotificationContainer = withTheme(
  styled(({ children, fullWidth, className }) => {
    return fullWidth ? (
      <div className={className}>
        <NoGutterYGrid
          css={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}
          noGutterY
        >
          <Row>
            <Column
              css={{ paddingTop: 0, paddingBottom: 0 }}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {children}
            </Column>
          </Row>
        </NoGutterYGrid>
      </div>
    ) : (
      <div className={className}>{children}</div>
    );
  })(p => {
    let style = { display: 'flex', flex: 1 };
    style.padding = `${p.paddingY}px ${p.paddingX}px`;
    style.backgroundColor = p.coreTheme[notificationBg[p.type]];
    !p.fullWidth && (style.borderRadius = p.coreTheme.rad_xs);
    return style;
  })
);

const ActionContainer = withTheme(
  styled.div(
    p => css`
      margin-top: ${p.type === ActionTypes.link
        ? p.coreTheme.space_1
        : p.coreTheme.space_4}px;

      ${p.type === ActionTypes.button &&
        css`
          ${config.media.lg`
          margin-top: 0;
        `}
        `}
    `
  )
);

const StyledInline = withTheme(styled(Inline)`
  flex: 1;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${p => p.coreTheme.brk_xs}) {
    flex-direction: row;
    align-items: center;
  }
`);

const TitleIcon = withTheme(styled.div`
  margin-right: ${p => p.coreTheme.space_2}px;

  ${config.media.md`
margin-right: ${p => p.coreTheme.space_3}px;
`}

  svg {
    width: ${p => p.coreTheme.space_6}px;
    height: ${p => p.coreTheme.space_6}px;
  }
`);
