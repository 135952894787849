import React from 'react';
import Grid from '../Grid';
import Row from '../Row';
import Column from '../Column';
import Text from '../Text';
import { withTheme } from '../../core/ThemeProvider';
import styled, { css } from 'styled-components';
import { remCalc } from '@lux/helpers';
import { noop } from '../../helpers';

const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
`;

const StyledListItemText = withTheme(
  styled(Text)(
    props => css`
      display: block;
      color: ${props.coreTheme.cl_black};
      font-size: ${props.coreTheme.fs_sm};
      font-family: ${props.secondary
        ? props.coreTheme.ff_sec_thin
        : props.coreTheme.ff_sec_bold};
    `
  )
);

const DesktopPrimaryMenuItems = withTheme(
  styled(Text)(
    props => css`
      line-height: ${remCalc(64)};
      padding: 0 0 0 ${remCalc(20)};
      font-size: ${props.coreTheme.header.desktopMenuFontSize};
      color: ${props.active
        ? props.coreTheme.header.desktopMenuActiveFontColor
        : props.coreTheme.header.desktopMenuInActiveFontColor};
    `
  )
);

// TODO: Standardise DropDown
const DeskTopSubMenuItems = withTheme(
  styled.ul(
    props => css`
      display: block;
      list-style-type: none;
      position: absolute;
      visibility: hidden;
      width: ${remCalc(150)};
      border-radius: ${remCalc(8)};
      background-color: ${props.coreTheme.cl_white};
      padding: 0;
      margin-left: ${remCalc(-10)};
      margin-top: ${remCalc(-10)};
      box-shadow: ${props.coreTheme.sd_size_1};
      z-index: 999;

      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: ${remCalc(-10)};
        border-width: ${remCalc(7)};
        border-style: solid;
        border-color: transparent transparent ${props.coreTheme.cl_white}
          transparent;
      }

      /* stylelint-disable-next-line */
      ${StyledListItem}:hover & {
        visibility: visible;
      }
    `
  )
);

const DesktopSubMenuListItemAnchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      padding: ${remCalc(9)} ${remCalc(20)};
      font-size: ${props.coreTheme.header.desktopSubMenuFontSize};
      color: ${props.coreTheme.header.desktopSubMenuFontColor};

      &:not([disabled]):hover {
        color: ${props.coreTheme.header.desktopSubMenuActiveFontColor};
      }
    `
  )
);

const SecondaryDesktop = withTheme(
  styled.div(
    props => css`
      width: 100%;
      background-color: ${props.coreTheme.cl_white};
      box-shadow: 1px 2px 10px 1px ${props.coreTheme.cl_ter_l4};
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 99999;
    `
  )
);

const SecondaryDesktopMenuColumn = withTheme(
  styled(Column)(
    props => css`
      padding: ${remCalc(30)};
      padding-left: ${props.index === 0 ? remCalc(10) : remCalc(30)};
      border-right: 1px solid ${props.coreTheme.cl_ter_l5};
    `
  )
);

const ThirdDesktopMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  text-align: left;
  padding: 0;
  margin: 0;
`;

const DesktopMenu = ({
  baseUrl,
  menuItems,
  hasMenuIcons,
  menuHoverKey,
  onMenuMouseEnter,
  onMenuMouseLeave,
  onMenuItemClick = noop
}) => {
  return menuItems.map((menuItem, key) => {
    const showSecondaryMenu =
      menuHoverKey === key && menuItem.subMenu && menuItem.subMenu.length > 0;

    return (
      <StyledListItem
        key={key}
        onMouseEnter={() => onMenuMouseEnter(key)}
        onMouseLeave={() => onMenuMouseLeave(key)}
        data-testid={`desktop-menu-hover-${key + 1}`}
      >
        {/* Primary navigation items */}
        <DesktopPrimaryMenuItems
          type="link"
          href={`${baseUrl}${menuItem.item.href}`}
          active={menuItem.item.active}
          onClick={e => {
            onMenuItemClick(e, menuItem.item);
          }}
        >
          {menuItem.item.title}
        </DesktopPrimaryMenuItems>

        {/* When user hovers over a primary navigation item */}

        {!hasMenuIcons &&
          menuItem.subMenu &&
          menuItem.subMenu.map((subMenu, key) => (
            <DeskTopSubMenuItems key={key}>
              <StyledListItem>
                <DesktopSubMenuListItemAnchor
                  type="link"
                  href={`${baseUrl}${subMenu.item.href}`}
                  onClick={subMenu.item.onClick}
                >
                  {subMenu.item.title}
                </DesktopSubMenuListItemAnchor>
              </StyledListItem>
            </DeskTopSubMenuItems>
          ))}

        {hasMenuIcons && showSecondaryMenu && (
          <SecondaryDesktop data-testid="secondary-desktop-menu">
            <Grid>
              <Row>
                {/* Loop through second level items */}
                {menuItem.subMenu &&
                  menuItem.subMenu.map((subMenuItem, key) => {
                    return (
                      <SecondaryDesktopMenuColumn sm key={key} index={key}>
                        <StyledListItemText
                          type="link"
                          href={`${baseUrl}${subMenuItem.item.href}`}
                          onClick={e => {
                            onMenuItemClick(e, subMenuItem.item);
                          }}
                        >
                          {subMenuItem.item.title}
                        </StyledListItemText>

                        <ThirdDesktopMenuList>
                          {/* Loop through third level items */}
                          {subMenuItem.subMenu &&
                            subMenuItem.subMenu.map((subSubMenuItem, key) => {
                              return (
                                <StyledListItem key={key}>
                                  <StyledListItemText
                                    type="link"
                                    href={`${baseUrl}${subSubMenuItem.item.href}`}
                                    secondary
                                    onClick={e => {
                                      onMenuItemClick(e, subSubMenuItem.item);
                                    }}
                                  >
                                    {subSubMenuItem.item.title}
                                  </StyledListItemText>
                                </StyledListItem>
                              );
                            })}
                        </ThirdDesktopMenuList>
                      </SecondaryDesktopMenuColumn>
                    );
                  })}
              </Row>
            </Grid>
          </SecondaryDesktop>
        )}
      </StyledListItem>
    );
  });
};

export default DesktopMenu;
