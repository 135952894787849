import styled, { css, keyframes } from 'styled-components';

export const SLIDING_DURATION = 250;

const slideIn = windowHeight => keyframes`
  from { height: ${windowHeight + 140}px; }
  to { height: ${windowHeight}px; }
`;

const slideOut = windowHeight => keyframes`
  from { height: ${windowHeight}px; }
  to { height: ${windowHeight + 140}px; }
`;

export const Sliding = styled.div`
  position: relative;
  width: 100%;

  ${({ state, windowHeight }) => {
    switch (state) {
      case 'entering':
        return css`
          animation: ${slideIn(windowHeight)} ease-in ${SLIDING_DURATION}ms;
        `;
      case 'entered':
        return css`
          height: ${windowHeight}px;
        `;
      case 'exiting':
        return css`
          animation: ${slideOut(windowHeight)} ease-out ${SLIDING_DURATION}ms;
        `;
      case 'exited':
        return css`
          height: ${windowHeight + 140}px;
        `;
    }
  }}
`;
