import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { withTheme } from '../../core/ThemeProvider';

const StyledDivider = withTheme(styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  ${({ coreTheme, type }) => {
    switch (type) {
      case 'horizontal':
        return css`
          display: flex;
          clear: both;
          width: 100%;
          min-width: 100%;
          border-top: 1px solid ${coreTheme.cl_divider};
        `;
      case 'vertical':
        return css`
          display: flex;
          clear: both;
          min-height: 100%;
          border-left: 1px solid ${coreTheme.cl_divider};
        `;
      default:
        break;
    }
  }};
`);

export const Divider = props => {
  return <StyledDivider type={props.type} data-testid={props['data-testid']} />;
};

Divider.defaultProps = {
  type: 'horizontal'
};

Divider.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * horizontal OR vertical
   */
  type: PropTypes.oneOf(['horizontal', 'vertical']).isRequired
};

export default Divider;
