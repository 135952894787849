import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './DatePicker';
import { noop } from '../../helpers';
import { SingleDatePickerCalendar } from './calendarViews';
import supportedFormats from './supportedFormats';

const SingleDatePicker = ({
  label,
  hintMessage,
  error,
  value,
  defaultValue,
  onChange,
  onClear,
  onConfirm,
  onBlur,
  formatter,
  calendarProps: { availableDays, defaultOptions = {} } = {},
  disabledDays,
  bgColor,
  ...rest
}) => {
  return (
    <DatePicker
      label={label}
      hintMessage={hintMessage}
      error={error}
      data-testid={rest['data-testid']}
      value={value}
      defaultValue={defaultValue}
      formatter={formatter}
      bgColor={bgColor}
      onBlur={onBlur}
      renderCalendar={props => (
        <SingleDatePickerCalendar
          {...props}
          calendarProps={{
            availableDays,
            defaultOptions
          }}
          datePickerProps={{
            onChange,
            onClear,
            onConfirm
          }}
          formatter={formatter}
          disabledDays={disabledDays}
        />
      )}
    />
  );
};

SingleDatePicker.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  value: PropTypes.instanceOf(Date)
};

SingleDatePicker.defaultProps = {
  onChange: noop,
  onClear: noop,
  onConfirm: noop,
  formatter: supportedFormats['dd MMM yyyy']
};

export default SingleDatePicker;
