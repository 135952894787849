/* eslint-disable flowtype/no-types-missing-file-annotation */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import PlusIcon from '@dls/assets/icons/Plus';
import { space } from '@styled-system/space';
import FileListItem, {
  FileType,
  ListItemWrapper,
  ListType,
  FileUploadStatus
} from './FileListItem';
import IconButton, { ActionTextWrapper } from './IconButton';
import IconTrashButton from './IconTrashButton';
import { withTheme } from '../../core/ThemeProvider';
import Text from '../Text';
import { testIdProps } from '../../helpers';

const FileInput = ({ id, label, children }) => {
  return (
    <div id={id}>
      {label && <LabelText type="smallBody">{label}</LabelText>}
      {children}
    </div>
  );
};

FileInput.defaultProps = {};

FileInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string
};

const singleFileUploadInitialState = {
  file: null,
  fileType: null,
  fileLoadedStatus: false,
  fileLoadedError: false
};

const DropZone = props => {
  const fileInputRef = React.createRef();

  const [isDragActive, setDragActive] = useState(false);
  const [isFileDoneLoading, setFileDoneLoading] = useState(false);
  // single file upload mode states for file, file type and upload status
  const [loadedFile, setLoadedFile] = useState(singleFileUploadInitialState);

  const removeLoadedFile = () => {
    setLoadedFile(singleFileUploadInitialState);
    singleModeOnDelete();
  };
  const triggerFileInput = () => {
    !props.disabled &&
      fileInputRef &&
      fileInputRef.current &&
      fileInputRef.current.click();
    setDragActive(false);
  };

  const onLoadFiles = files => {
    if (!files || !files.length) return;
    const { onDrop } = props;
    let loadedCount = 0;
    [...files].forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        loadedCount++;
        file.src = reader.result;
        if (isSingleUploadMode) {
          setFileDoneLoading(true);
          setLoadedFile(prevLoadedState => ({
            ...prevLoadedState,
            file: file,
            fileType: file?.type.split('/').shift(),
            fileLoadedStatus: false,
            fileLoadedError: false
          }));

          onDrop && onDrop([file]);
        } else if (loadedCount === files.length) {
          const fileListArr = Array.prototype.map.apply(files, [file => file]);
          onDrop && onDrop(fileListArr);
          setFileDoneLoading(true);
        }
      };
    });
  };

  const handleFileChange = e => {
    setFileDoneLoading(false);
    const fileList = e.target.files || [];
    onLoadFiles(fileList);
  };

  const onDragOver = e => {
    e.preventDefault();
    const { disabled } = props;
    /* Enable drag effect
     * https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/dropEffect */
    e.dataTransfer && (e.dataTransfer.dropEffect = disabled ? 'none' : 'copy');
    if (props.disabled) return;
    setDragActive(true);
  };

  const onDragLeave = e => {
    e.preventDefault();
    if (props.disabled) return;
    setDragActive(false);
  };

  const onDrop = e => {
    e.preventDefault();
    if (props.disabled) return;
    setFileDoneLoading(false);
    setDragActive(false);

    /** Ref: https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/files */
    const { dataTransfer: { files = [] } = {} } = e;
    onLoadFiles(files);
  };

  const {
    disabled,
    labelText,
    hintText,
    coreTheme,
    inputProps: { accept, multiple } = {},
    isError,
    errorMessage,
    isSingleUploadMode,
    singleUploadStatus,
    singleModeOnDelete = () => {},
    singleModePreloadedFile
  } = props;

  useEffect(() => {
    if (singleModePreloadedFile) {
      setLoadedFile(prevLoadedState => ({
        ...prevLoadedState,
        file: singleModePreloadedFile,
        fileType: singleModePreloadedFile?.type.split('/').shift(),
        fileLoadedStatus: false,
        fileLoadedError: false
      }));
    }
  }, [singleModePreloadedFile]);

  const isErrorState = () => {
    return isError === true && isFileDoneLoading;
  };

  const isFileTypeImage = (file, fileType) => {
    return file && fileType && fileType === FileType.IMAGE;
  };

  const renderSingleModeContent = () => {
    const { file, fileType } = loadedFile;

    // TODO: to get listType as prop and handle for single file upload mode
    const fileInputProps = {
      fileType: fileType,
      listType: fileType === FileType.IMAGE ? ListType.PICTURE : ListType.TEXT,
      imageSrc: file?.src,
      isFullWidth: fileType === FileType.IMAGE,
      status: singleUploadStatus,
      error: isErrorState(),
      animate: !singleModePreloadedFile
    };

    if (!file || isErrorState()) {
      return (
        <SingleModeWrapper>
          <SingleModeContent>{renderDropZone()}</SingleModeContent>
        </SingleModeWrapper>
      );
    }

    if (isFileTypeImage(file, fileType)) {
      return (
        <SingleModeWrapper>
          <StyledSingleModeContent>
            <FileInput.FileListItem {...fileInputProps} />
          </StyledSingleModeContent>
        </SingleModeWrapper>
      );
    } else {
      return (
        <FileInput.FileListItem
          {...fileInputProps}
          action={<IconTrashButton onClick={removeLoadedFile} />}
        >
          {file.name}
        </FileInput.FileListItem>
      );
    }
  };

  const renderSingleUploadMode = () => {
    const { file, fileType } = loadedFile;

    return (
      <SingleModeContentWrapper>
        {isFileTypeImage(file, fileType) &&
          singleUploadStatus === FileUploadStatus.SUCCESS &&
          !isErrorState() && (
            <DeleteButton onClick={removeLoadedFile} enableInteraction={true}>
              Delete
            </DeleteButton>
          )}

        {renderSingleModeContent()}
      </SingleModeContentWrapper>
    );
  };

  const renderDropZone = () => {
    return (
      <>
        <DropZoneContainer
          {...testIdProps(props, 'dropZone')}
          disabled={disabled}
          onClick={triggerFileInput}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          isDragActive={isDragActive}
          isError={isErrorState()}
          isSingleUploadMode={isSingleUploadMode}
        >
          <input
            data-testid="fileInputEl"
            ref={fileInputRef}
            type="file"
            accept={accept}
            multiple={!!multiple && !isSingleUploadMode}
            onChange={handleFileChange}
            /** reset the value onClick, triggering onChange to allow uploading same file */
            onClick={e => (e.target.value = null)}
          />

          {labelText && (
            <Flex m="8px" alignItems="center">
              <PlusIcon size={21} color={coreTheme.cl_sec_d1} />
              <DropZoneText type="link">{labelText}</DropZoneText>
            </Flex>
          )}

          {hintText && <HintText type="smallBody">{hintText}</HintText>}
        </DropZoneContainer>
        {isErrorState() && <ErrorText>{errorMessage}</ErrorText>}
      </>
    );
  };

  if (isSingleUploadMode) {
    return renderSingleUploadMode();
  }

  return renderDropZone();
};

DropZone.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  errorMessage: PropTypes.string,
  /**
   * Hint text in the dropzone
   */
  hintText: PropTypes.string,
  /**
   * Set what file types are allowed to be selected
   */
  inputProps: PropTypes.shape({
    /**
     * This string is a comma-separated list of unique file type specifiers.
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
     * @example: ".doc,.docx,application/msword"
     */
    accept: PropTypes.string,
    /**
     * When true, the file input allows the user to select more than one file.
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#multiple
     * @default false
     */
    multiple: PropTypes.bool
  }),
  isError: PropTypes.bool,
  isSingleUploadMode: PropTypes.bool,
  /**
   * Text in the dropzone
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Callback called when a file is dropped in the dropzone area.
   */
  onDrop: PropTypes.func,
  singleModeOnDelete: PropTypes.func,
  singleModePreloadedFile: PropTypes.shape({
    arrayBuffer: PropTypes.func.isRequired,
    lastModified: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    slice: PropTypes.func.isRequired,
    stream: PropTypes.func.isRequired,
    text: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
  }),
  singleUploadStatus: PropTypes.string
};

DropZone.defaultProps = {
  inputProps: {
    multiple: false
  }
};

FileInput.FileListItem = FileListItem;
FileInput.ListItemWrapper = ListItemWrapper;
FileInput.IconButton = IconButton;
FileInput.IconTrashButton = IconTrashButton;
FileInput.DropZone = withTheme(DropZone);

const DropZoneContainer = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ coreTheme, isDragActive, isError, isSingleUploadMode }) => css`
    height: ${isSingleUploadMode ? `calc(100% - 2px)` : 0}; /* For IE11 */
    padding: ${isSingleUploadMode ? 0 : `${coreTheme.space_4}px`};
    text-align: center;
    box-sizing: border-box;
    border: 1px
      ${isDragActive || isError
        ? `solid ${isError ? coreTheme.cl_pri_d3 : coreTheme.cl_sec_d1}`
        : `dashed ${coreTheme.cl_sec_d1}`};
    border-radius: ${coreTheme.rad_xs};
    margin-top: ${isSingleUploadMode ? 0 : `${coreTheme.space_2}px`};
    min-height: 200px;
  `};

  &:active {
    border-style: solid;
  }

  input[type='file'] {
    display: none !important;
    visibility: hidden;
  }
`);

/* TODO: Consider moving this to a shared utility component */
const Flex = styled.div`
  display: flex;
  ${({ alignItems = 'start' }) => css`
    align-items: ${alignItems};
  `}
  ${space}
`;

const DropZoneText = withTheme(
  styled(Text)(
    ({ coreTheme }) => css`
      line-height: 28px;
      color: ${coreTheme.cl_sec_d1};
      font-size: ${coreTheme.fs_xl};
      margin-left: ${coreTheme.space_2}px;
    `
  )
);

const LabelText = withTheme(
  styled(Text)(
    ({ coreTheme }) => css`
      line-height: 21px;
      color: ${coreTheme.cl_ter_l2};
      font-size: ${coreTheme.fs_sm};
      display: flex;
      margin-bottom: ${coreTheme.space_2}px;
    `
  )
);

const HintText = withTheme(styled(Text)`
  ${({ coreTheme }) => css`
    color: ${coreTheme.cl_ter_l2};
    line-height: 21px;
    font-size: ${coreTheme.fs_md};
  `};
`);

const ErrorText = withTheme(styled(Text)`
  ${({ coreTheme }) => css`
    color: ${coreTheme.cl_pri_d3};
    font-size: ${coreTheme.fs_md};
    margin-top: ${coreTheme.space_1}px;
  `};
`);

const DeleteButton = withTheme(styled(ActionTextWrapper)`
  position: absolute;
  right: 0;
  top: -30px;
`);

const SingleModeWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 200px;
  height: 100%;
`;

const SingleModeContent = styled.div`
  min-height: inherit;
`;

const StyledSingleModeContent = styled(SingleModeContent)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const SingleModeContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export default FileInput;
