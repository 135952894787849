import React from 'react';
import Text from '../Text';
import TextLink from '../TextLink';
import { noop } from '../../helpers';
import PropTypes from 'prop-types';

const DropdownActionLink = ({ onClick, children }) => {
  return (
    <Text type="smallBody">
      <TextLink inline onClick={onClick}>
        {children}
      </TextLink>
    </Text>
  );
};

DropdownActionLink.defaultProps = {
  onClick: noop,
  children: noop
};

DropdownActionLink.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Action link's text
   */
  children: PropTypes.string,
  /**
   * On click handler, invoked when the user click on the action link
   */
  onClick: PropTypes.func
};

export default DropdownActionLink;
