import { IconWrapper, Info, InfoWrapper } from './style';
import PropTypes from 'prop-types';
import React from 'react';
import useDevice from '../../core/hooks/useDevice';

const CardInfo = ({ icon: Icon, children }) => {
  const { isDesktop, isTV } = useDevice();

  return (
    <InfoWrapper>
      <IconWrapper>
        <Icon size={isDesktop || isTV ? 20 : 16} />
      </IconWrapper>
      <Info>{children}</Info>
    </InfoWrapper>
  );
};

CardInfo.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Info text
   */
  children: PropTypes.node,
  /**
   * Icon component (from DLS Assets) used in TextLink
   */
  icon: PropTypes.node
};

export default CardInfo;
