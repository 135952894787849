import React from 'react';
import styled, { css } from 'styled-components';
import { MainText, DividerWrapper } from './DropdownInput';
import Divider from '../Divider';
import { withTheme } from '../../core/ThemeProvider';
import { noop } from '../../helpers';
import PropTypes from 'prop-types';

const DropdownActionItem = ({ children }) => {
  return (
    <>
      <NoResultsWrapper>
        <MainText>{children}</MainText>
      </NoResultsWrapper>
      <DividerWrapper>
        <Divider />
      </DividerWrapper>
    </>
  );
};

const NoResultsWrapper = withTheme(
  styled.span(
    ({ coreTheme }) => css`
      display: flex;
      flex-direction: column;
      transition: all 0.2s ease;
      font-family: ${coreTheme.secondaryThin};
      font-size: ${coreTheme.fs_xl};
      align-content: center;
      background-color: ${coreTheme.cl_white};
      padding: ${coreTheme.space_2}px ${coreTheme.space_4}px;
      padding-bottom: 0;
      color: ${coreTheme.cl_ter_base};
    `
  )
);

DropdownActionItem.defaultProps = {
  children: noop
};

DropdownActionItem.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Text or DropdownActionLink component
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default DropdownActionItem;
