import React from 'react';
import Text from '../Text';
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';

const StyledLogoAnchor = withTheme(
  styled(Text)(
    props => css`
      display: block;
      height: ${props.coreTheme.header.deskTopLogoHeight};
      align-self: ${props.mode === 'deskTop' &&
        props.coreTheme.header.logoAlignment};
    `
  )
);

const StyledLogoImg = styled.img`
  height: 100%;
`;

const Logo = ({ baseUrl, href, alt, src, mode }) => (
  <StyledLogoAnchor type="link" href={`${baseUrl}${href}`} mode={mode}>
    <StyledLogoImg src={src} alt={alt} />
  </StyledLogoAnchor>
);

export default Logo;
