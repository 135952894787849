import Grid from '../Grid';
import Row from '../Row';
import Text from '../Text';
import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';

export const Wrapper = withTheme(styled.div`
  ${({ mobileImage, desktopImage, coreTheme }) => css`
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: ${`url('${mobileImage || desktopImage}')`};

    @media (min-width: ${coreTheme.brk_xs}) {
      background-image: ${`url('${desktopImage}')`};
    }
  `}
`);

export const StyledGrid = styled(Grid)`
  && {
    padding: 0;
    width: 100%;
  }
`;

export const StyledRow = withTheme(styled(Row)`
  ${({ reverse, coreTheme }) => css`
    flex-direction: ${reverse ? `column-reverse` : 'column'};

    @media (min-width: ${coreTheme.brk_xs}) {
      flex-direction: ${reverse ? `row-reverse` : 'row'};
    }
  `}
`);

export const StyledEmptyContentContainer = withTheme(styled.div`
  ${({ coreTheme }) => css`
    height: 300px;

    @media (min-width: ${coreTheme.brk_xs}) {
      width: 100%;
      height: 480px;
    }
  `}
`);

export const StyledTextContainer = withTheme(styled.div`
  ${({ coreTheme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${coreTheme.space_5}px ${coreTheme.space_6}px;
    min-height: 260px;
    align-items: center;

    @media (min-width: ${coreTheme.brk_xs}) {
      padding: 0 50px;
      height: 480px;
      align-items: flex-start;
    }

    @media (min-width: ${coreTheme.brk_sm}) {
      padding: 0 80px;
    }

    @media (min-width: ${coreTheme.brk_md}) {
      padding: 0 95px;
    }

    svg {
      width: 48px;
      height: 48px;

      @media (min-width: ${coreTheme.brk_xs}) {
        width: 60px;
        height: 60px;
      }

      @media (min-width: ${coreTheme.brk_md}) {
        width: 72px;
        height: 72px;
      }
    }
  `}
`);

export const StyledIconImage = withTheme(styled.img`
  ${({ coreTheme }) => css`
    width: 48px;
    height: 48px;

    @media (min-width: ${coreTheme.brk_xs}) {
      width: 60px;
      height: 60px;
    }

    @media (min-width: ${coreTheme.brk_md}) {
      width: 72px;
      height: 72px;
    }
  `}
`);

export const StyledHeaderText = withTheme(styled(Text)`
  ${({ coreTheme }) => css`
    text-align: center;

    @media (min-width: ${coreTheme.brk_xs}) {
      text-align: left;
    }
  `}
`);

export const StyledBodyText = withTheme(styled(Text)`
  ${({ coreTheme }) => css`
    padding-top: ${coreTheme.space_3}px;
    text-align: center;

    @media (min-width: ${coreTheme.brk_xs}) {
      padding-top: ${coreTheme.space_4}px;
      text-align: left;
    }
  `}
`);
