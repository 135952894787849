import React, { useState } from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import MobileMenu from './MobileMenu';
import { testIdProps } from '../../helpers';
import { withTheme } from '../../core/ThemeProvider';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledHeader = withTheme(
  styled.div(
    props => css`
      display: flex;
      align-items: center;
      height: ${props.coreTheme.header.mobileHeight};
      background-color: ${props.backgroundColor};
      border-top: ${props.coreTheme.header.borderTop};
      box-shadow: ${props.coreTheme.header.boxShadow};
      z-index: 99999;

      @media (min-width: ${props.coreTheme.brk_md}) {
        height: ${props.coreTheme.header.desktopHeight};
      }
    `
  )
);

const Header = ({
  backgroundColor,
  baseUrl,
  logo,
  firstLevelLinks,
  menuItems = [],
  menuIcons,
  showHamburger,
  showLogoHamburger,
  showHamburgerRight,
  hamburgerOpenIcon,
  hamburgerCloseIcon,
  onMenuItemClick,
  searchConfig,
  ...rest
}) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [menuHoverKey, setMenuHoverKey] = useState(-1);
  const [activeMenuItem, setActiveMenuItem] = useState(-1);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(-1);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleDesktopMenuMouseEnter = key => setMenuHoverKey(key);

  const handleDesktopMenuMouseLeave = () => setMenuHoverKey(-1);

  const handleHamburgerClick = () =>
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  const handleMobileAccordionHeaderClick = (menuKey, subKey) => {
    setActiveMenuItem(menuKey);
    setActiveSubMenuItem(activeSubMenuItem !== subKey ? subKey : -1);
  };

  const onSearchIconClick = () => {
    setIsSearchActive(!isSearchActive);
  };

  const searchConfigOptions = {
    showSearch: !!searchConfig,
    isActive: isSearchActive,
    onSearchIconClick,
    ...searchConfig
  };

  return (
    <StyledHeader backgroundColor={backgroundColor} {...testIdProps(rest)}>
      <MobileHeader
        baseUrl={baseUrl}
        logo={logo}
        menuIcons={menuIcons}
        onHamburgerClick={handleHamburgerClick}
        showHamburger={showHamburger}
        showHamburgerRight={showHamburgerRight}
        hamburgerOpenIcon={hamburgerOpenIcon}
      />

      <DesktopHeader
        baseUrl={baseUrl}
        logo={logo}
        firstLevelLinks={firstLevelLinks}
        menuItems={menuItems}
        menuIcons={menuIcons}
        menuHoverKey={menuHoverKey}
        onMenuMouseEnter={handleDesktopMenuMouseEnter}
        onMenuMouseLeave={handleDesktopMenuMouseLeave}
        onMenuItemClick={onMenuItemClick}
        searchConfigOptions={searchConfigOptions}
      />

      {/* The mobile menu that toggles when clicking hamburger icon */}
      <MobileMenu
        baseUrl={baseUrl}
        backgroundColor={backgroundColor}
        activeSubMenuItem={activeSubMenuItem}
        activeMenuItem={activeMenuItem}
        firstLevelLinks={firstLevelLinks}
        isHamburgerMenuOpen={isHamburgerMenuOpen}
        logo={logo}
        menuItems={menuItems}
        onClose={handleHamburgerClick}
        onAccordionHeaderClick={handleMobileAccordionHeaderClick}
        showLogoHamburger={showLogoHamburger}
        showHamburgerRight={showHamburgerRight}
        hamburgerCloseIcon={hamburgerCloseIcon}
        onMenuItemClick={onMenuItemClick}
        searchConfigOptions={searchConfigOptions}
      />
    </StyledHeader>
  );
};

Header.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Header backgroundColor
   */
  backgroundColor: PropTypes.string.isRequired,
  /**
   * Base url for absolute paths
   */
  baseUrl: PropTypes.string.isRequired,
  /**
   * Top right hand corner RHS menu items
   */
  firstLevelLinks: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  /**
   * hamburger close icon
   */
  hamburgerCloseIcon: PropTypes.any,
  /**
   * hamburger open icon
   */
  hamburgerOpenIcon: PropTypes.any,
  /**
   * Header logo properties
   */
  logo: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired
  }).isRequired,
  /**
   * RHS menu icons
   */
  menuIcons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        desktop: PropTypes.any.isRequired,
        desktopHover: PropTypes.any,
        mobile: PropTypes.any
      }).isRequired,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string,
          onClick: PropTypes.func,
          title: PropTypes.string.isRequired
        })
      )
    })
  ),
  /**
   * Primary menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        href: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      }).isRequired,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          item: PropTypes.object.isRequired,
          subMenu: PropTypes.arrayOf(PropTypes.object)
        })
      )
    })
  ).isRequired,
  /**
   * menu item callback method on clik icon
   */
  onMenuItemClick: PropTypes.func,
  /**
   * Configuration properties for search input
   */
  searchConfig: PropTypes.shape({
    /**
     * to enable to change the hover icon
     */
    isIconHoverRequired: PropTypes.bool,
    /**
     * Callback to get notified when search input is activated and mounted on DOM.
     * When using third party scripts like siteSearch360 for search functionality,
     * this callback could be used to load and initialize the scripts.
     */
    onSearchInputActivated: PropTypes.func,
    /**
     * className to set on search input
     */
    searchInputClassName: PropTypes.string
  }),
  /**
   * show hamburger menu
   */
  showHamburger: PropTypes.bool,
  /**
   * show hamburger menu in right corner
   */
  showHamburgerRight: PropTypes.bool,
  /**
   * show logo in hamburger menu
   */
  showLogoHamburger: PropTypes.bool
};

export default Header;
