import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import Checkbox from '../Checkbox';
import Radio from '../Radio';
import useSelect from './hooks/useSelect';
import useSorting from './hooks/useSorting';
import useDevice from '../../core/hooks/useDevice';
import SortingUp from '@dls/assets/icons/SortingUp';
import SortingDown from '@dls/assets/icons/SortingDown';
import SortingNeutral from '@dls/assets/icons/SortingNeutral';
import * as SC from './style';

const sortingIcons = {
  asc: <SortingUp data-testid="table-asc-icon" />,
  desc: <SortingDown data-testid="table-desc-icon" />,
  null: <SortingNeutral data-testid="table-neutral-icon" />
};

const Table = ({
  columns,
  data,
  rowSelection,
  sortModel,
  onSortChange,
  mobileHeader,
  mobileStyle,
  ...rest
}) => {
  const altStyle = columns.length > 3;
  const { isMobile } = useDevice();

  const { onSelect, onSelectAll, getRecordIndex, isAllSelected } = useSelect({
    data,
    rowSelection
  });

  const { onSortClick, sortingModels } = useSorting({
    sortModel,
    onSortChange,
    columns
  });

  const renderColumn = (column, record, index) => {
    if (column.render && typeof column.render === 'function') {
      return column.render({ value: record[column.key], record, index });
    } else {
      return record[column.key];
    }
  };

  const renderSortingIcon = useCallback(
    columnKey => {
      const sort = sortingModels[columnKey]?.sort || null;
      return sortingIcons[sort];
    },
    [sortingModels]
  );

  return (
    <SC.TableContainer data-testid={rest['data-testid']}>
      <SC.Table>
        <thead>
          <SC.tr>
            {rowSelection?.type === 'checkbox' ? (
              <SC.Headth
                width={rowSelection.width}
                styles={rowSelection.style}
                className="checkbox"
              >
                <Checkbox.Input
                  checked={isAllSelected}
                  onChange={onSelectAll}
                />
              </SC.Headth>
            ) : rowSelection?.type === 'radio' ? (
              <SC.Headth
                width={rowSelection.width}
                styles={rowSelection.style}
              />
            ) : null}
            {!isMobile || !altStyle ? (
              columns.map(item => (
                <SC.Headth
                  key={'th-' + item.key}
                  alignment={item.alignment}
                  width={item.width}
                  styles={item.style}
                >
                  <SC.HeadContent
                    data-testid="table-sortBtn"
                    sortable={item.sortable}
                    onClick={() => onSortClick(item)}
                  >
                    <Text
                      type={!isMobile ? 'header' : 'smallBody'}
                      tag="span"
                      fontWeight="bold"
                    >
                      {item.title}
                    </Text>
                    {item.sortable ? (
                      <SC.SortWrapper>
                        <SC.HeadSorter enableInteraction={item.sortable}>
                          {renderSortingIcon(item.key)}
                        </SC.HeadSorter>
                      </SC.SortWrapper>
                    ) : null}
                  </SC.HeadContent>
                </SC.Headth>
              ))
            ) : (
              <SC.Headth>
                <Text type="smallBody" tag="span" fontWeight="bold">
                  {mobileHeader}
                </Text>
              </SC.Headth>
            )}
          </SC.tr>
        </thead>
        <tbody>
          {data.map((record, index) => (
            <SC.tr
              key={'tr-' + index}
              className={getRecordIndex(record) > -1 ? 'active' : undefined}
            >
              {rowSelection?.type === 'checkbox' ? (
                <SC.td
                  width={rowSelection.width}
                  styles={rowSelection.style}
                  altStyle={altStyle}
                  className="checkbox"
                >
                  <Checkbox.Input
                    checked={getRecordIndex(record) > -1}
                    onChange={(e, checked) => onSelect(e, checked, record)}
                  />
                </SC.td>
              ) : rowSelection?.type === 'radio' ? (
                <SC.td
                  width={rowSelection.width}
                  styles={rowSelection.style}
                  altStyle={altStyle}
                  className={
                    rowSelection?.type === 'radio' ? 'radio' : undefined
                  }
                >
                  <Radio.Input
                    checked={getRecordIndex(record) > -1}
                    onChange={(e, checked) => onSelect(e, checked, record)}
                    data-testid="radioInputId"
                  />
                </SC.td>
              ) : null}
              {!isMobile || !altStyle ? (
                columns.map(item => (
                  <SC.td
                    key={'td-' + item.key}
                    alignment={item.alignment}
                    width={item.width}
                    styles={item.style}
                  >
                    <Text type={!isMobile ? 'body' : 'smallBody'} tag="span">
                      {renderColumn(item, record, index)}
                    </Text>
                  </SC.td>
                ))
              ) : (
                <SC.td>
                  {columns.map(item => (
                    <SC.MobileContainer key={item.key}>
                      <SC.MobileTitleTextWrapper
                        styles={mobileStyle?.titleStyle}
                      >
                        <Text type="smallBody" tag="span" fontWeight="bold">
                          {item.title}
                        </Text>
                      </SC.MobileTitleTextWrapper>
                      <SC.MobileDataTextWrapper styles={mobileStyle?.dataStyle}>
                        <Text type="smallBody" tag="span">
                          {renderColumn(item, record, index)}
                        </Text>
                      </SC.MobileDataTextWrapper>
                    </SC.MobileContainer>
                  ))}
                </SC.td>
              )}
            </SC.tr>
          ))}
        </tbody>
      </SC.Table>
    </SC.TableContainer>
  );
};

Table.defaultProps = {
  columns: [],
  data: [],
  sortModel: [],
  onSortChange: () => {},
  mobileStyle: {}
};

Table.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Columns mapping
   * to tell how to render each columns.
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Column Alignment
       */
      alignment: PropTypes.oneOf(['center', 'left', 'right']),
      /**
       * Key of the column, must be unique and will be used as dataIndex
       */
      key: PropTypes.string.isRequired,
      /**
       * custom render function, renderer of the table cell.
       * The return value should be a ReactNode or anything can be rendered.
       * value: initial value to be rendered.
       * record: whole object of current record.
       * index: index of current record.
       */
      render: PropTypes.func,
      /**
       * enable/disable sorting for this column
       */
      sortable: PropTypes.bool,
      /**
       * style applies to all cells in this column
       */
      style: PropTypes.object,
      /**
       * Title of the column, will be rendered as column header
       */
      title: PropTypes.any.isRequired,
      /**
       * Column Width
       */
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,
  /**
   * data source of the table
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * altStyle table header. This header will be only shown when there are more than three columns and in mobile.
   */
  mobileHeader: PropTypes.string,
  /**
   * styling for altStyle table. Will be only applied when there are more than three columns and in mobile.
   */
  mobileStyle: PropTypes.shape({
    /**
     * style applies to data cells
     */
    dataStyle: PropTypes.object,
    /**
     * style applies to title cells
     */
    titleStyle: PropTypes.object
  }),
  /**
   * Callback fired when the column sorting changed.
   */
  onSortChange: PropTypes.func,
  rowSelection: PropTypes.shape({
    /**
     * to customize default id attribute name in datasource, default value is "id"
     */
    defaultIdAttr: PropTypes.string,
    /**
     * onSelect callback, will be fired after row select/deselect
     * record: current record being selected, could be a collection of selected rows when user click on select/deselect all.
     * selected: selected status
     * selectedRowIds: collection of selected Row id.
     * event: native event of click action
     */
    onSelect: PropTypes.func,
    /**
     * collection of selected Row Ids, will be applied with selected status
     */
    selectedRowIds: PropTypes.arrayOf(PropTypes.string),
    /**
     * style applies to all cells in rowSelection column
     */
    style: PropTypes.object,
    /**
     * Type of rowSelection, now support `checkbox` and `radio`
     */
    type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
    /**
     * Width of rowSelection
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  /**
   * Controlled value for sorted columns/fields.
   */
  sortModel: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * sort field(column) key
       */
      field: PropTypes.string.isRequired,
      /**
       * sort order
       */
      sort: PropTypes.oneOf(['asc', 'desc'])
    })
  )
};

export default Table;
