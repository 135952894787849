import { api, isProduction } from '../configs';
import { makeSecure, sendApiToGaEvent } from '../helpers';

export const setLocationHeader = location => ({
  type: 'SET_LOCATION_HEADER',
  location: isProduction ? makeSecure(location) : location
});

export function createOrder(registrationId, authToken) {
  const url = api.appointment.productOrder.replace(
    ':registrationId',
    registrationId
  );

  const options = {
    method: 'POST',
    headers: {
      'x-auth-token': authToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'same-origin'
  };

  return dispatch => {
    return fetch(url, options)
      .then(response => {
        sendApiToGaEvent('createOrder', response.status);
        let key = response.headers.get('Location') || api.common.location;
        dispatch(setLocationHeader(key));
        return response;
      })
      .catch(() => {});
  };
}
