import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Close as CloseIcon,
  Document as DocumentIcon
} from '@dls/assets/icons';
import loadingAnimation from '@dls/assets/lottiefiles/loading';
import tickAnimation from '@dls/assets/lottiefiles/tick';
import Animation from '../Animation';
import { withTheme } from '../../core/ThemeProvider';
import { testIdProps } from '../../helpers';
import Config from '../../config';

export const FileUploadStatus = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
};

export const FileType = {
  IMAGE: 'image',
  APPLICATION: 'application'
};

export const ListType = {
  TEXT: 'text',
  PICTURE: 'picture'
};

const FileListItem = withTheme(props => {
  /* Start animations if status prop is set. */
  const [animActive, setAnimActive] = useState(props.status);
  const {
    action,
    status,
    error,
    index,
    size,
    imageSrc,
    isFullWidth,
    fileType = FileType.IMAGE,
    listType = ListType.PICTURE,
    animate = true
  } = props;

  const isFileLoading = uploadStatus => {
    return uploadStatus === FileUploadStatus.LOADING;
  };

  const renderStatus = uploadStatus => {
    if (uploadStatus === FileUploadStatus.FAILURE) {
      setTimeout(() => {
        setAnimActive(false);
      }, 2000);
      return <CloseIcon size={22} color={props.coreTheme.cl_pri_d3} />;
    }

    const isLoading = isFileLoading(uploadStatus);

    return (
      <StatusWrapper>
        <Animation
          data-testid="lottie-animation"
          source={isLoading ? loadingAnimation : tickAnimation}
          size="22px"
          color={!isLoading && props.coreTheme.cl_successGreen}
          action="play"
          loop={isLoading}
          onComplete={() => !isLoading && setAnimActive(false)}
        />
        {listType === ListType.PICTURE ? (
          <StatusTextWrapper>
            {isLoading && <LoadingText>Loading</LoadingText>}
            {!isLoading && <UploadedText>Uploaded</UploadedText>}
          </StatusTextWrapper>
        ) : null}
      </StatusWrapper>
    );
  };

  const renderAsset = (src, fileName) => {
    if (fileType === FileType.IMAGE) {
      return <UploadedImage src={src} />;
    }

    return (
      <UploadedDocumentWrapper>
        <UploadedDocument>
          <DocumentIcon size={24} />
          <TextFileNameWrapper data-testid="picture-file-name">
            <TextFileName>{fileName}</TextFileName>
          </TextFileNameWrapper>
        </UploadedDocument>
      </UploadedDocumentWrapper>
    );
  };

  if (error) {
    return null;
  }

  const renderContent = () => {
    let fileName = '';
    let nameFirstPart = '';
    let nameLastPart = '';

    if (props.children) {
      fileName = props.children;
      nameLastPart =
        fileName && fileName.length > 8
          ? fileName.substr(fileName.length - 8, fileName.length)
          : '';
      nameFirstPart = fileName ? fileName.replace(nameLastPart, '') : '';
    }

    if (listType === ListType.TEXT) {
      return (
        <>
          <FileInfo>
            <StyledFileNameWrapper data-testid="file-name">
              <NameFirstPart>{nameFirstPart}</NameFirstPart>
              <NameLastPart>{nameLastPart}</NameLastPart>
            </StyledFileNameWrapper>
          </FileInfo>
          <ActionContainer>
            {status && animActive && renderStatus(status)}
            {action && !animActive && action}
          </ActionContainer>
        </>
      );
    }

    return (
      <>
        {!isFullWidth && (
          <FileInfo>
            <FileNameWrapper>
              <span data-testid="file-name">
                {size ? `${index + 1}/${size}` : ''}
              </span>
              {action && !animActive && action}
            </FileNameWrapper>
          </FileInfo>
        )}
        <FileWrapper
          isLoading={isFileLoading(status)}
          isFullWidth={isFullWidth}
        >
          <FileContainer data-testid="file-container">
            {animate && (
              <>
                {status && animActive && renderStatus(status)}
                {!animActive && imageSrc && renderAsset(imageSrc, fileName)}
              </>
            )}

            {!animate && <>{imageSrc && renderAsset(imageSrc, fileName)}</>}
          </FileContainer>
        </FileWrapper>
      </>
    );
  };

  if (listType === ListType.TEXT) {
    return <FileItemRow {...testIdProps(props)}>{renderContent()}</FileItemRow>;
  } else {
    if (isFullWidth) {
      return (
        <FileListItemRowFullWidth {...testIdProps(props)}>
          {renderContent()}
        </FileListItemRowFullWidth>
      );
    }

    return (
      <FileListItemRow {...testIdProps(props)}>
        {renderContent()}
      </FileListItemRow>
    );
  }
});

FileListItem.propTypes = {
  /**
   * Upload status of the file.
   * Changing these values shows the corresponding status animation for the file.
   */
  status: PropTypes.oneOf(['loading', 'success', 'failure']),

  /**
   * Action instance to be rendered on the right side of the file.
   *
   * Usually used to render a delete icon.
   *
   * Sample
   * ```jsx
   * <FileInput.FileListItem
   *   action={
   *     <FileInput.IconButton
   *       onClick={() => { ... }} name={'trash'} />
   *   }
   *   status={status}
   *   error={error} >File_name.png</FileInput.FileListItem>
   * ```jsx
   */
  action: PropTypes.node,

  /**
   * Error string for the file.
   */
  error: PropTypes.string,
  index: PropTypes.number,
  size: PropTypes.number,
  imageSrc: PropTypes.string.isRequired,
  isFullWidth: PropTypes.bool,
  fileType: PropTypes.string,
  listType: PropTypes.string
};

export const ListItemWrapper = withTheme(styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`);

const FileNameWrapper = withTheme(styled.div`
  white-space: nowrap;
  overflow: hidden;
  font-size: ${p => p.coreTheme.fs_xl};
  font-family: ${p => p.coreTheme.ff_sec_thin};
  color: ${p => p.coreTheme.cl_ter_base};
  line-height: 30px;
  padding: ${p => p.coreTheme.space_5} 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`);

const TextFileNameWrapper = withTheme(styled.div`
  font-size: ${p => p.coreTheme.fs_xl};
  font-family: ${p => p.coreTheme.ff_sec_thin};
  color: ${p => p.coreTheme.cl_ter_base};
  line-height: 30px;
`);

const TextFileName = styled.span`
  overflow-wrap: break-word;
`;

const StyledFileNameWrapper = styled(FileNameWrapper)`
  justify-content: start;
`;

const NameFirstPart = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: bottom;
  max-width: calc(100% - 100px);
`;

const NameLastPart = styled.span`
  width: 100px;
  display: inline-block;
  vertical-align: bottom;
`;

const UploadedImage = styled.img.attrs(props => ({
  src: props.src
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0;
`;

const UploadedDocumentWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadedDocument = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    align-self: center;
    text-align: center;
    margin: 5px;
    width: 80%;
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

const FileListItemRowFullWidth = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 100%;
`);

const FileItemRow = withTheme(styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: ${p => p.coreTheme.space_3}px 0;
`);

const FileListItemRow = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  margin: ${p => p.coreTheme.space_4}px 0;
  overflow: auto;
  flex: 1 0 48%;
  max-width: calc(50% - ${p => (1 / 2) * p.coreTheme.space_5}px);
  margin-right: ${p => p.coreTheme.space_5}px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  ${Config.media.sm`
      flex: 1 0 31%;
      max-width: calc(33.3% - ${p => (2 / 3) * p.coreTheme.space_5}px);
    
      &:nth-child(2n) {
         margin-right: ${p => p.coreTheme.space_5}px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    `}
`);

const StatusWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const StatusText = withTheme(styled.span`
  font-size: ${p => p.coreTheme.fs_xl};
  font-family: ${p => p.coreTheme.ff_sec_thin};
  line-height: 30px;
`);

const LoadingText = withTheme(styled(StatusText)`
  color: ${p => p.coreTheme.cl_ter_l3};
`);

const UploadedText = withTheme(styled(StatusText)`
  color: ${p => p.coreTheme.cl_successGreen};
`);

const StatusTextWrapper = withTheme(styled.div`
  margin-left: ${p => p.coreTheme.space_3}px;
`);

const FileInfo = styled.span`
  flex: 1;
  overflow-wrap: break-word;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
`;

const ActionContainer = styled.span`
  display: flex;
  min-width: 36px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

const FileWrapper = withTheme(styled.div`
  ${({ isFullWidth, isLoading }) => css`
    border: 1px ${isLoading ? 'dashed' : 'solid'}
      ${isLoading ? p => p.coreTheme.cl_sec_d1 : p => p.coreTheme.cl_divider};
    border-radius: ${p => p.coreTheme.rad_xs};
    padding-bottom: ${isFullWidth ? 0 : `100%`};
    position: relative;
    overflow: hidden;
    flex: ${isFullWidth ? 1 : 'none'};
  `}
`);

const FileContainer = withTheme(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`);

export default FileListItem;
