export const NotificationTypes = {
  alert: 'alert',
  info: 'info',
  promo: 'promo'
};

export const ActionTypes = {
  link: 'link',
  button: 'button'
};

export const HorizontalPaddingTypes = {
  md: 'space_4',
  lg: 'space_6',
  xl: 'space_8'
};
