import React from 'react';
import { Footer as BaseFooter } from '@lux/components';

const Footer = () => (
  <BaseFooter
    copyrightText="&copy; Singtel (CRN: 199201624D) All Rights Reserved."
    links={[]}
  />
);

export default Footer;
