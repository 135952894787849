import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import Checkbox from '../../Checkbox';
import Button from '../../Button';
import Spacing from '../../Spacing';

const FilterModal = ({
  filters,
  title,
  buttonLabel,
  onFilterModalClose,
  onFiltersUpdated
}) => {
  const [filterOptions, setFilterOptions] = useState(filters);

  useEffect(() => {
    setFilterOptions(filters);
  }, [filters]);

  const onModalClose = () => {
    onFilterModalClose();
  };

  const onFilterOptionClick = (filterOption, selected) => {
    const updatedFilterOptions = filterOptions.map(opt => {
      if (opt.value === filterOption.value) {
        return {
          ...opt,
          selected
        };
      }
      return opt;
    });
    setFilterOptions(updatedFilterOptions);
  };

  const onApplyButtonClick = () => {
    const selectedFilters = filterOptions
      .filter(opt => opt.selected)
      .map(opt => opt.value);

    if (selectedFilters.length > 0) {
      onFiltersUpdated(filterOptions);
    }
  };

  return (
    <Modal
      visible={true}
      title={title}
      onClose={onModalClose}
      data-testid="filter-modal"
    >
      <Modal.Content>
        {filterOptions.map(filterOption => {
          return (
            <Spacing bottom={2} key={filterOption.value}>
              <Checkbox.Input
                label={filterOption.name}
                onChange={(e, selected) => {
                  onFilterOptionClick(filterOption, selected);
                }}
                checked={filterOption.selected}
                data-testid={`checkbox-${filterOption.value}`}
              />
            </Spacing>
          );
        })}
        <Spacing top={1}>
          <Button
            fullWidth
            onClick={onApplyButtonClick}
            data-testid="filter-modal-button"
          >
            {buttonLabel}
          </Button>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};

FilterModal.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Button label text to display in filter modal
   */
  buttonLabel: PropTypes.string.isRequired,
  /**
   * List of unique filters of the locations
   */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * filter name
       */
      name: PropTypes.string.isRequired,
      /**
       * Indicates whether filter should be selected
       */
      selected: PropTypes.bool.isRequired,
      /**
       * Unique value to differentiate the filter
       */
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  /**
   * Handler to get notified when closing filter modal
   */
  onFilterModalClose: PropTypes.func.isRequired,
  /**
   * Handler to get notified when filters are updated
   */
  onFiltersUpdated: PropTypes.func.isRequired,
  /**
   * Title to display in filter modal
   */
  title: PropTypes.string.isRequired
};

export default FilterModal;
