import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { responsiveStyles } from '../../core/utils/responsiveStyles';
import getFontFamily from '../../helpers/getFontFamily';

const bodyLineHeights = { default: 1.75, compact: 1.5, medium: 1.3 };
const fontWeights = { thin: 'thin', regular: 'regular', bold: 'bold' };
const fontFamilyType = { primary: 'primary', secondary: 'secondary' };

/**
 * CSS styles for the type system
 */
export const typePreset = {
  bannerTitle: {
    tag: 'h1',
    style: ({ coreTheme, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.bannerTitle?.fontSize || [32, 32, 36, 48, 48]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.primary,
        fontWeight ||
          coreTheme.text?.bannerTitle?.fontWeight ||
          fontWeights.thin,
        coreTheme
      ),
      color:
        color || coreTheme.text?.bannerTitle?.color || coreTheme.cl_ter_base,
      letterSpacing: 0.1,
      lineHeight: 1.3
    })
  },
  pageTitle: {
    tag: 'h2',
    style: ({ coreTheme, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.pageTitle?.fontSize || [32, 32, 36, 40, 40]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.primary,
        fontWeight ||
          coreTheme.text?.pageTitle?.fontWeight ||
          fontWeights.regular,
        coreTheme
      ),
      color: color || coreTheme.text?.pageTitle?.color || coreTheme.cl_ter_base,
      letterSpacing: 0.1,
      lineHeight: 1.35
    })
  },
  sectionTitle: {
    tag: 'h3',
    style: ({ coreTheme, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.sectionTitle?.fontSize || [24, 24, 28, 32, 32]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.primary,
        fontWeight ||
          coreTheme.text?.sectionTitle?.fontWeight ||
          fontWeights.regular,
        coreTheme
      ),
      color:
        color || coreTheme.text?.sectionTitle?.color || coreTheme.cl_ter_base,
      letterSpacing: 0.1,
      lineHeight: 1.35
    })
  },
  sectionTitle02: {
    tag: 'h3',
    style: ({ coreTheme, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.sectionTitle02?.fontSize || [24, 24, 28, 32, 32]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.secondary,
        fontWeight ||
          coreTheme.text?.sectionTitle02?.fontWeight ||
          fontWeights.bold,
        coreTheme
      ),
      color:
        color || coreTheme.text?.sectionTitle02?.color || coreTheme.cl_ter_base,
      letterSpacing: 0.1,
      lineHeight: 1.35
    })
  },
  header: {
    tag: 'h4',
    style: ({ coreTheme, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.header?.fontSize || [20, 20, 22, 24, 24]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.secondary,
        fontWeight || coreTheme.text?.header?.fontWeight || fontWeights.bold,
        coreTheme
      ),
      color: color || coreTheme.text?.header?.color || coreTheme.cl_ter_base,
      letterSpacing: 0.1,
      lineHeight: 1.35
    })
  },
  boldBody: {
    tag: 'p',
    style: ({ coreTheme, lineHeight, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.boldBody?.fontSize || [16, 16, 18, 20, 20]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.secondary,
        fontWeight || coreTheme.text?.boldBody?.fontWeight || fontWeights.bold,
        coreTheme
      ),
      color: color || coreTheme.text?.boldBody?.color || coreTheme.cl_ter_base,
      lineHeight: bodyLineHeights[lineHeight],
      letterSpacing: coreTheme.text?.boldBody?.letterSpacing || 0
    })
  },
  body: {
    tag: 'p',
    style: ({ coreTheme, lineHeight, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.body?.fontSize || [16, 16, 18, 20, 20]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.secondary,
        fontWeight || coreTheme.text?.body?.fontWeight || fontWeights.thin,
        coreTheme
      ),
      color: color || coreTheme.text?.body?.color || coreTheme.cl_ter_base,
      letterSpacing: coreTheme.text?.body?.letterSpacing || 0.1,
      lineHeight: bodyLineHeights[lineHeight]
    })
  },
  smallBody: {
    tag: 'p',
    style: ({ coreTheme, lineHeight, color, fontWeight, fontSize }) => ({
      ...typeReset,
      ...responsiveStyles(
        {
          fontSize: fontSize ||
            coreTheme.text?.smallBody?.fontSize || [14, 14, 15, 16, 16]
        },
        coreTheme
      ),
      fontFamily: getFontFamily(
        coreTheme.text?.fontFamilyType || fontFamilyType.secondary,
        fontWeight || coreTheme.text?.smallBody?.fontWeight || fontWeights.thin,
        coreTheme
      ),
      color: color || coreTheme.text?.smallBody?.color || coreTheme.cl_ter_base,
      letterSpacing: coreTheme.text?.smallBody?.letterSpacing || 0.2,
      lineHeight: bodyLineHeights[lineHeight]
    })
  }
};

const LinkComponent = styled.a`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 'unset';
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  text-decoration: none;

  ${p => ({
    ...typePreset.boldBody.style(p),
    color: p.coreTheme.cl_sec_d1
  })}
`;

/**
 * Create Styled components using the type system css
 * @type {{}}
 */
export const typeComponents = Object.keys(typePreset).reduce(
  (components, typeName) => {
    const { tag, style } = typePreset[typeName];
    const TypeComponent = styled[tag](
      p => css`
        ${style(p)};

        ${/* sc-selector */ LinkComponent} {
          font-size: inherit;
          letter-spacing: inherit;
          line-height: inherit;
        }
      `
    );
    components[typeName] = withTheme(TypeComponent);
    return components;
  },
  {}
);

typeComponents.link = withTheme(LinkComponent);

/**
 * Default styles for typography
 */
const typeReset = {
  marginTop: 0,
  marginBottom: 0,
  fontWeight: 'unset',
  letterSpacing: 0,
  textRendering: 'optimizeLegibility',
  '-webkit-font-smoothing': 'antialiased'
};
