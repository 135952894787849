import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import GAPageTracker from '../../components/GAPageTracker';
import ErrorBoundary from '../../components/ErrorBoundary';
import routes from '../../routes';

class App extends Component {
  render() {
    const { store } = this.props;

    return (
      <React.Fragment>
        <Header />
        <Router>
          <ErrorBoundary>
            <Provider store={store}>
              <ScrollToTop>
                <GAPageTracker />
                {routes(store)}
              </ScrollToTop>
            </Provider>
          </ErrorBoundary>
        </Router>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
