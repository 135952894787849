import styled, { css, keyframes } from 'styled-components';

export const DURATION = 300;

const getExpandAnimation = (height, textMargin) => keyframes`
  from {
    opacity: 0;
    margin-top: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: ${height}px;
    margin-top: ${textMargin}px;
  }
`;

const getCollapseAnimation = (height, textMargin) => keyframes`
  from {
    opacity: 1;
    height: ${height}px;
    margin-top: ${textMargin}px;
  }

  to {
    opacity: 0;
    margin-top: 0;
    height: 0;
  }
`;

export const Fade = styled.div`
  ${({ state, height, textMargin }) => {
    const expandAnimation = getExpandAnimation(height, textMargin);
    const collapseAnimation = getCollapseAnimation(height, textMargin);

    switch (state) {
      case 'entering':
        return css`
          animation: ${expandAnimation} ease-in ${DURATION}ms;
        `;
      case 'entered':
        return css`
          opacity: 1;
          margin-top: ${textMargin}px;
        `;
      case 'exiting':
        return css`
          animation: ${collapseAnimation} ease-out ${DURATION}ms;
        `;
      case 'exited':
        return css`
          opacity: 0;
        `;
    }
  }}
`;
