import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { withClearInteraction } from '../../helpers/styled';
import { isNumber } from '../../helpers';

const getAlignment = input => {
  const alignmentList = ['left', 'right', 'center'];
  return alignmentList.includes(input) ? input : 'left';
};

const getTableCell = p => {
  const { width, styles, alignment, altStyle, coreTheme } = p || {};
  return css`
    width: ${width ? (isNumber(width) ? width + 'px' : width) : 'auto'};
    padding: ${coreTheme.space_2}px 0;

    @media (min-width: ${coreTheme.brk_xs}) {
      padding: ${coreTheme.space_6}px;
    }
    text-align: ${alignment ? getAlignment(alignment) : 'left'};
    position: relative;

    &.checkbox {
      padding: ${coreTheme.space_2}px ${coreTheme.space_4}px;
      ${altStyle && `vertical-align: top`};

      @media (min-width: ${coreTheme.brk_xs}) {
        padding: ${coreTheme.space_6}px;
      }
    }

    &.radio {
      padding: ${coreTheme.space_2}px ${coreTheme.space_4}px;
      ${altStyle && `vertical-align: top`};

      @media (min-width: ${coreTheme.brk_xs}) {
        padding: 0 ${coreTheme.space_6}px;
      }
    }

    &::after {
      position: absolute;
      border-bottom: 1px solid ${coreTheme.cl_ter_l5};
      height: 1px;
      width: 100%;
      content: '';
      bottom: 0;
      right: 0;
    }

    &:first-child {
      padding-left: ${coreTheme.space_4}px;

      @media (min-width: ${coreTheme.brk_xs}) {
        padding-left: ${coreTheme.space_6}px;
      }

      &::after {
        width: calc(100% - ${coreTheme.space_4}px);
        left: auto;
        right: 0;

        @media (min-width: ${coreTheme.brk_xs}) {
          width: calc(100% - ${coreTheme.space_6}px);
        }
      }
    }

    &:last-child {
      padding-right: ${coreTheme.space_4}px;

      @media (min-width: ${coreTheme.brk_xs}) {
        padding-right: ${coreTheme.space_6}px;
      }

      &::after {
        width: calc(100% - ${coreTheme.space_4}px);
        left: 0;
        right: auto;

        @media (min-width: ${coreTheme.brk_xs}) {
          width: calc(100% - ${coreTheme.space_6}px);
        }
      }
    }

    &:only-child {
      &::after {
        width: calc(100% - ${coreTheme.space_8}px);
        left: ${coreTheme.space_4}px;
        right: ${coreTheme.space_4}px;
      }
    }
    ${styles}
  `;
};

export const TableContainer = withTheme(
  styled.div(({ coreTheme }) => {
    return css`
      width: 100%;
      overflow-x: auto;
      box-shadow: ${coreTheme.sd_size_1};
      border-radius: ${coreTheme.rad_xs};

      @media (min-width: ${coreTheme.brk_xs}) {
        border-radius: ${coreTheme.rad_xl};
      }
    `;
  })
);

export const Table = styled.table`
  width: 100%;
  display: table;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
`;

export const Headth = withTheme(
  styled.th(
    p => css`
      background: ${p.coreTheme.cl_ter_l6};
      font-weight: 500;

      ${getTableCell(p)}

      &::after {
        border-bottom: none;
      }
    `
  )
);

export const HeadContent = styled.div`
  display: inline-flex;
  align-items: center;
  user-select: ${p => (p.sortable ? 'none' : 'auto')};
  cursor: ${p => (p.sortable ? 'pointer' : 'initial')};
`;

export const SortWrapper = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      margin-right: ${coreTheme.space_1}px;

      @media (min-width: ${coreTheme.brk_xs}) {
        margin-left: ${coreTheme.space_4}px;
      }
    `
  )
);

export const HeadSorter = withClearInteraction(
  withTheme(
    styled.div`
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    `
  ),
  10,
  3
);

export const tr = withTheme(
  styled.tr(
    ({ coreTheme }) => css`
      :hover {
        background-color: ${coreTheme.table.backgroundColor.hover};
      }

      &:last-child > td::after {
        border-bottom: none;
      }

      &.active {
        background-color: ${coreTheme.table.backgroundColor.active};
      }
    `
  )
);

export const td = withTheme(
  styled.td(
    p => css`
      ${getTableCell(p)}
    `
  )
);

export const MobileContainer = styled.div`
  display: flex;
`;

export const MobileTitleTextWrapper = styled.div(
  p => css`
    &::after {
      content: ':';
    }
    ${p.styles}
  `
);

export const MobileDataTextWrapper = styled.div(
  p => css`
    ${p.styles}
  `
);
