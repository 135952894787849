import styled from 'styled-components';
import Text from '../Text';
import { withTheme } from '../../core/ThemeProvider';
import { VOUCHER_STATUS } from './VoucherItem';
import VoucherLeftCorner from './assets/voucher_left.svg';
import VoucherRightCorner from './assets/voucher_right.svg';

export const StyledVoucherLeftCorner = styled(VoucherLeftCorner)`
  height: 100%;
`;
export const StyledVoucherRightCorner = styled(VoucherRightCorner)`
  height: 100%;
  width: 124px;
`;

export const StyledVoucherContentCorner = styled.div`
  min-width: 100px;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  margin-left: -1px;
  display: flex;
  align-items: center;
`;

export const StyledText = styled(Text)`
  ${props =>
    props.type !== 'link' &&
    `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;`}
`;
export const StyledAmount = styled(Text)`
  display: block;
`;

export const StyledVoucher = withTheme(styled.div`
  position: relative;
  display: flex;
  height: 128px;
  margin-bottom: 10px;

  ${({ type, coreTheme }) => {
    if (type === VOUCHER_STATUS.NOT_AVAILABLE) {
      return `
      ${StyledText} {
        opacity: 0.65;
        color: ${coreTheme.cl_ter_base};
      }
      ${StyledAmount} {
        opacity: 0.65;
        color: ${coreTheme.cl_ter_base};
      }
      ${StyledVoucherLeftCorner} {
        fill: ${coreTheme.cl_ter_l5};
      }
      ${StyledVoucherContentCorner} {
        background-color: ${coreTheme.cl_ter_l5};
      }
      ${StyledVoucherRightCorner} {
        fill: ${coreTheme.cl_ter_l5};
      }
      `;
    } else if (type === VOUCHER_STATUS.SELECTED) {
      return `
      ${StyledText} {
        color: ${coreTheme.cl_white};
      }
      ${StyledAmount} {
        color: ${coreTheme.cl_white};
      }
      ${StyledVoucherLeftCorner} {
        fill: #108668;
      }
      ${StyledVoucherContentCorner} {
        background-color: #108668;
      }
      ${StyledVoucherRightCorner} {
        fill: #108668;
      }
    `;
    } else {
      return `${StyledAmount} {
        color: ${coreTheme.cl_sec_d1};
      }
      ${StyledVoucherLeftCorner} {
        fill: ${coreTheme.cl_sec_l2};
      }
      ${StyledVoucherContentCorner} {
        background-color: ${coreTheme.cl_sec_l2};
      }
      ${StyledVoucherRightCorner} {
        fill: ${coreTheme.cl_sec_l2};
      }
      `;
    }
  }}
`);

export const StyledVoucherContent = styled.div`
  display: block;
  overflow: hidden;
`;

export const StyledLeftCorner = styled.div`
  display: block;
`;
export const StyledRightCorner = styled.div`
  position: relative;
  margin-left: -1px;
  text-align: center;
`;

export const StyledRightCornerItem = styled.div`
  position: absolute;
  left: 65%;
  top: 50%;
  transform: translate(-60%, -50%);
`;
