import styled from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { getStyledText } from '../../helpers/styled';
import Config from '../../config';

export const BannerContainerVariant1 = withTheme(styled.div`
  position: relative;
  background-color: ${p => p.coreTheme.cl_white};
  overflow: hidden;
  border-radius: ${p => p.coreTheme.rad_xs};
  cursor: pointer;
`);

export const BannerContainerVariant2 = withTheme(styled.div`
  position: relative;
  overflow: hidden;
`);

export const ContentWrapper = withTheme(styled.div`
  display: flex;
  padding: ${p => p.coreTheme.space_3}px;

  ${Config.media.sm`
    padding-right: ${p => p.coreTheme.space_4}px;
  `};
`);

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1;
  min-width: 0;
`;

export const TitleVariant1 = withTheme(getStyledText('boldBody', {
  lineHeight: 'compact'
})`
  color: ${p => p.coreTheme.cl_ter_base};
  padding-left: ${p => p.coreTheme.space_3}px;

  ${Config.media.md`
    padding-left: ${p => p.coreTheme.space_4}px;
  `};

  .highlight {
    color: ${p => p.coreTheme.cl_pri_d2};
  }
`);

export const TitleVariant2 = withTheme(getStyledText('header')`
  color: ${p => p.coreTheme.cl_ter_base};
  padding-left: ${p => p.coreTheme.space_4}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .highlight {
    color: ${p => p.coreTheme.cl_pri_d2};
  }
`);

export const ContentBody = withTheme(getStyledText('body', {
  lineHeight: 'compact'
})`
  color: ${p => p.coreTheme.cl_ter_base};
  padding-left: ${p => p.coreTheme.space_4}px;
`);

export const IconWrapper = withTheme(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.coreTheme.cl_pri_d2};
  width: 56px;
  height: 56px;
  border-radius: ${p => p.coreTheme.rad_xs};
  flex: none;
`);
