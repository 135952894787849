import React from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { noop, testIdProps } from '../../helpers';
import { withTheme } from '../../core/ThemeProvider';
import Text from '../Text';
import { withStyledAttrs } from '../../helpers/styled';
import TextLinkIcon from './TextLinkIcon';
import TextLinkText from './TextLinkText';

const TextLinkWrapper = withTheme(
  withStyledAttrs(Text, { type: 'link' })(
    ({ coreTheme, inline }) => css`
      align-items: center;
      color: ${coreTheme.cl_sec_d1};
      cursor: pointer;
      display: inline-flex;
      transition: color 0.1s ease-in;

      ${inline &&
        css`
          font-size: inherit;
        `}

      span {
        padding-right: ${coreTheme.space_2}px;
        line-height: 0;
      }

      span:last-of-type {
        padding: 0;
      }

      &:hover,
      &:active {
        color: ${coreTheme.cl_sec_d3};
      }
    `
  )
);

const TextLink = ({ onClick, inline, href, children, ...rest }) => {
  return (
    <TextLinkWrapper
      inline={inline}
      href={href}
      onClick={onClick}
      {...testIdProps(rest)}
    >
      {children}
    </TextLinkWrapper>
  );
};

TextLink.defaultProps = {
  onClick: noop
};

TextLink.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The URL that the hyperlink points to.
   */
  href: PropTypes.string,
  /**
   * whether the text fontsize will inherit from the parent
   */
  inline: PropTypes.bool,
  /**
   * Click event handler
   */
  onClick: PropTypes.func
};

TextLink.Text = TextLinkText;
TextLink.Icon = TextLinkIcon;

export default TextLink;
