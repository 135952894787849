import styled, { css } from 'styled-components';
import { withTheme } from '../../core/ThemeProvider';
import { createLegoComponent } from '../../core/utils/legoUtils';
import Config from '../../config';
import { getStyledText } from '../../helpers/styled';
import { responsiveStyles } from '../../core/utils/responsiveStyles';

export const Box = withTheme(
  styled.div(
    ({ coreTheme }) => css`
      box-sizing: border-box;
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      max-height: 100%;
      padding: ${coreTheme.space_2}px ${coreTheme.space_3}px;

      ${Config.media.md`
        padding: ${coreTheme.space_2}px ${coreTheme.space_4}px;
      `};
    `
  )
);

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  height: ${props => (props.tall ? '104px' : '80px')};
`;

export const Column = styled.div`
  display: flex;
  flex: ${props => (props.width === 'content' ? 1 : 'none')};
  align-items: center;
  min-width: 0;
`;

export const Title = withTheme(
  getStyledText('boldBody')(({ coreTheme, nowrap }) => {
    const { title } = coreTheme.listItem.bodyContent;
    return css`
      overflow: hidden;
      color: ${title.color};
      line-height: ${title.lineHeight};
      text-overflow: ellipsis;

      ${Config.media.md`
        line-height: ${title.mdLineHeight};
      `};

      /* stylelint-disable value-no-vendor-prefix */
      display: -webkit-box;
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      ${nowrap &&
        `
        display: block;
        white-space: nowrap;
        -webkit-line-clamp: 1;
      `}
    `;
  })
);

export const SubTitle = withTheme(
  getStyledText('smallBody', { lineHeight: 'compact' })(
    ({ coreTheme, nowrap }) => {
      const { subTitle } = coreTheme.listItem.bodyContent;
      return css`
        flex: none;
        overflow: hidden;
        color: ${subTitle.color};
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: ${subTitle.lineHeight};

        ${Config.media.md`
        line-height:  ${subTitle.mdLineHeight};
      `};

        ${nowrap &&
          `
      display: block;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      `}
      `;
    }
  )
);

export const Tag = withTheme(
  styled.span(({ coreTheme }) => {
    const { tag } = coreTheme.listItem.leftContent;

    return css`
      text-align: center;
      height: ${tag.height};
      position: absolute;
      bottom: 0;
      background: ${tag.backgroundColor};
      font-family: ${tag.fontFamily};
      letter-spacing: ${tag.letterSpacing};
      line-height: ${tag.lineHeight};
      color: ${tag.color};
      padding: ${tag.padding};
      width: 100%;
      box-sizing: border-box;
      ${{
        ...responsiveStyles(
          {
            fontSize: tag.fontSize
          },
          coreTheme
        )
      }};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-line-clamp: 1;
    `;
  })
);

export const ListItemPointsContainer = withTheme(
  getStyledText('smallBody')(
    () => css`
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }
    `
  )
);

export const LeftContainer = withTheme(styled.div`
  position: relative;
  height: 100%;
  min-height: ${props => props.coreTheme.listItem.leftContent.minHeight};
  min-width: ${props => props.coreTheme.listItem.leftContent.minWidth};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.coreTheme.listItem.leftContent.backgroundColor};
`);

export const RightContainer = withTheme(styled.div`
  padding: 0 ${p => p.coreTheme.space_4}px 0 0;
`);

export const ListItemThumbnail = createLegoComponent(
  'ListItemThumbnail',
  withTheme(styled.img`
    height: ${props => props.coreTheme.listItem.leftContent.thumbnail.height};
    width: ${props => props.coreTheme.listItem.leftContent.thumbnail.width};
    display: block;
  `)
);

export const ListItemImage = createLegoComponent(
  'ListItemImage',
  withTheme(styled.img`
    height: ${props => props.coreTheme.listItem.leftContent.image.height};
    width: ${props => props.coreTheme.listItem.leftContent.image.width};
    display: block;
  `)
);
